<template>
    <QitButton
        color="white"
        class="facet-delete-button"
        :label="$t('core.Reset')"
        data-qs="facets-delete-all-selected-filter-button"
        @click="deleteAllSelectedFilter()"
        :disabled="disabled"
    >
        <q-tooltip v-if="!disabled">{{ $t("core.Reset filter") }}</q-tooltip>
    </QitButton>
</template>

<script setup lang="ts">
import QitButton from "@/shared/components/buttons/qit-button.vue";
import { toRefs } from "vue";

const props = defineProps<{
    disabled: boolean;
}>();

const emits = defineEmits<{
    deleteFilterSelection: [];
}>();

const { disabled } = toRefs(props);

const deleteAllSelectedFilter = () => {
    emits("deleteFilterSelection");
};
</script>
