import { DocumentNode } from "graphql";
import { gql } from "graphql-tag";

gql`
    query availablePartsList(
        $filter: MechanicFilter
        $assetId: ID
        $productId: ID
        $first: NonNegativeInt
        $after: String
    ) {
        mechanics(filter: $filter, assetId: $assetId, productId: $productId) {
            mechanics(first: $first, after: $after) {
                node {
                    id
                    assemblyId
                }
            }
        }
    }
`;

gql`
    query partslistFiles($filter: MechanicFilter, $assetId: ID, $productId: ID, $first: NonNegativeInt) {
        mechanics(filter: $filter, assetId: $assetId, productId: $productId) {
            mechanics(first: $first) {
                node {
                    id
                    files {
                        objectType
                        objectSubType
                        file {
                            blobId
                            url
                            mimeType
                        }
                        ... on FileWithHotspots {
                            hotspots {
                                x1
                                x2
                                y1
                                y2
                                linkType
                                linkValue
                            }
                        }
                        ... on FileSvgWithHotspots {
                            hotspots {
                                linkText
                                linkType
                                linkValue
                            }
                        }
                    }
                }
            }
        }
    }
`;

interface PartsListQueryOptions {
    withPreviewImages?: boolean;
    datafieldsFragment?: Record<string, string[]>;
    teaserDatafieldsFragment?: Record<string, string[]>;
}

export function createMechanicPartsListQuery(options: PartsListQueryOptions): DocumentNode {
    return gql(
        getQueryString({
            previewImages: options.withPreviewImages,
            datafieldsFragment: options.datafieldsFragment,
            teaserDatafieldsFragment: options.teaserDatafieldsFragment,
        })
    );
}

function getQueryString(options: {
    previewImages?: boolean;
    datafieldsFragment?: Record<string, string[]>;
    teaserDatafieldsFragment?: Record<string, string[]>;
}): string {
    let preViewImages = "";
    if (options.previewImages) {
        preViewImages = `previewImage {
            url
            mimeType
        }`;
    }

    const datafieldsFragment = options.datafieldsFragment ?? {};
    const teasersFragment = options.teaserDatafieldsFragment ?? {};

    const allDataFieldFragments = Object.values(datafieldsFragment).join(" ");
    const allLocalizedDataFieldFragments = Object.values(teasersFragment).join(" ");

    const result = `query mechanics(
        $filter: MechanicFilter
        $assetId: ID
        $productId: ID
        $first: NonNegativeInt
        $after: String
        $acceptedLanguages: [LanguageWithWildcard!]
        $sort: [MechanicSort]                  
    ) {
        mechanics(filter: $filter, assetId: $assetId, productId: $productId) {
            mechanics(first: $first, after: $after, sort: $sort) {
                node {
                    id
                    assemblyId
                    parentAssemblyId
                    articleId
                    quantity
                    option
                    isAssembly
                    itemNumber
                    reference2d
                    reference3d
                    unit
                    articleNumber
                    referenceId
                    teasers {
                        title(acceptedLanguages: $acceptedLanguages)
                        ${allLocalizedDataFieldFragments}
                    }
                    ${preViewImages}
                    ${allDataFieldFragments}
                }
                cursor
            }            
            total {
              count
              countRelation
            }
        }
    }
`;
    return result;
}
