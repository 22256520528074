import { FacetValue } from "../facets.model";
import { TreeNode } from "@/shared/facets/tree/facet-tree.types";

export const convertToTree = (values: FacetValue[], root: string, aggregations: Record<string, number>): TreeNode[] => {
    const findWhere = (nodes: TreeNode[], value: string) => {
        return nodes.find((node) => node.id === value);
    };

    const tree: TreeNode[] = [];

    const approveUnknown = values.filter((x) => {
        return !!x.approveUnknownValue;
    });

    const normal = values.filter((x) => {
        return !x.approveUnknownValue;
    });

    for (const val of normal) {
        const keys = val?.pathKeys;
        if (!keys) continue;

        let currentLevel = tree;
        for (let j = 0; j < keys.length; j++) {
            const part = keys[j];
            const existingPath = findWhere(currentLevel, part);

            if (existingPath) {
                currentLevel = existingPath.childNodes;
            } else {
                const count = aggregations[val.id] || 0;
                const newNode: TreeNode = {
                    id: val.id,
                    root,
                    teaser: `${val.text} (${count})`,
                    childNodes: [],
                    count,
                };

                currentLevel.push(newNode);
                currentLevel = newNode.childNodes;
            }
        }
    }

    // put all approve unknown FacetValues in root
    for (const val of approveUnknown) {
        const count = aggregations[val.id] || 0;

        tree.push({
            id: val.id,
            root,
            teaser: `${val.text} (${count})`,
            childNodes: [],
            count,
        });
    }

    return tree;
};
