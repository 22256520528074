import { Options, parse } from "csv-parse/browser/esm";
import { Readable as ReadableStream } from "stream";

export const DEFAULT_OPTIONS: CsvParseOptions = {
    encoding: "utf8",
    columns: false,
    delimiter: "\t",
    skipEmptyLines: true,
    escape: '"',
    relax_quotes: true,
};

export function getHeader(stream: ReadableStream, options: CsvParseOptions = DEFAULT_OPTIONS): Promise<string[]> {
    return new Promise((resolve, reject) => {
        options = deleteUndefinedOptions(options);
        const parseOptions: Options = { ...DEFAULT_OPTIONS, ...options, to: 1 };
        const parser = stream.pipe(parse(parseOptions));
        parser.on("data", (header: Record<string, any>) => {
            if (parseOptions.columns === true) {
                resolve(Object.keys(header));
            } else {
                resolve(Object.values(header));
            }
        });
        parser.on("error", (error) => {
            reject(error);
        });
        parser.on("end", () => {
            resolve([]);
        });
    });
}

export function getHeaderFromStringOrBuffer(
    fileContent: string | Buffer,
    options: CsvParseOptions = DEFAULT_OPTIONS
): Promise<string[]> {
    return new Promise((resolve, reject) => {
        options = deleteUndefinedOptions(options);
        const parseOptions: Options = { ...DEFAULT_OPTIONS, ...options, to: 1 };
        const parser = parse(fileContent, parseOptions);
        parser.on("data", (header: Record<string, any>) => {
            if (parseOptions.columns === true) {
                resolve(Object.keys(header));
            } else {
                resolve(Object.values(header));
            }
        });
        parser.on("error", (error) => {
            reject(error);
        });
        parser.on("end", () => {
            resolve([]);
        });
    });
}

function deleteUndefinedOptions(options: CsvParseOptions): CsvParseOptions {
    return Object.fromEntries(Object.entries(options).filter(([, val]) => val !== undefined));
}

export type CsvParseOptions = Partial<
    Pick<Options, "encoding" | "columns" | "delimiter" | "escape" | "skipEmptyLines" | "bom" | "relax_quotes">
>;
