import { LanguageSettings } from "@/shared/store/modules/languages";
import { getStore } from "@/shared/store/store";
import { getI18n } from "@/shared/i18n/i18n";
import { watch } from "vue";
import { LanguageWithWildcard } from "@/shared/services/graphql/generated/consumer-graph-types";

/**
 *  Initiales Setzen der Spracheinstellungen.
 *  Die Anzeige Sprache wird in folgender Reihenfolge ermittelt:
 *      1. vorhandene Einstellung aus LocalStorage
 *      2. bevorzugte Browsersprache, wenn diese in Tenant-Sprachen enthalten ist
 *      3. im Tenant eingestellte default-Sprache
 */
export function initLanguage(defaultLng: string, available: string[]) {
    let locale = getUiLanguage();
    if (getLanguageSettingsFromLocalStorage()) {
        locale = getLanguageSettingsFromLocalStorage().uiLocale;
    } else if (getPreferredBrowserLanguage(available)) {
        locale = getPreferredBrowserLanguage(available)!;
    } else if (defaultLng) {
        locale = defaultLng;
    }

    const fallbackLocales = defaultLng ? [defaultLng] : getFallbackLanguages();
    const availableLocales = available ?? getAvailableLanguages();

    setLanguage({
        uiLocale: locale,
        databaseLocale: locale,
        fallbackLocales: fallbackLocales,
        availableLocales: availableLocales,
    });
}

export function addI18nWatcher() {
    // Watcher auf Spracheinstellungen um i18n zu aktualisieren
    watch(
        () => getLanguageSettings(),
        () => {
            updateI18n();
        }
    );
}

// Setter

export function setUiLanguage(uiLang: string) {
    setLanguage({
        uiLocale: uiLang,
        databaseLocale: uiLang,
        fallbackLocales: getFallbackLanguages(),
        availableLocales: getAvailableLanguages(),
    });
}

function setLanguage(settings: LanguageSettings) {
    getStore().commit("lang/setLanguages", settings);
}

function getPreferredBrowserLanguage(availableLangs: string[]): string | undefined {
    const shortLocales = navigator.languages.map((lang) => lang.toLocaleLowerCase().split("-")[0]);
    return shortLocales.find((lang) => availableLangs.includes(lang));
}

export function updateI18n() {
    const { locale, fallbackLocale } = getI18n().global;
    //@ts-ignore
    locale.value = getUiLanguage();
    //@ts-ignore
    fallbackLocale.value = getFallbackLanguages();
}

// Convenience - Getter

export function getUiLanguage(): string {
    return getLanguageSettings().uiLocale;
}

export function getDatabaseLanguage(): string {
    return getLanguageSettings().databaseLocale;
}

export function getFallbackLanguages(): string[] {
    return getLanguageSettings().fallbackLocales;
}

export function getDataQueryLanguages(): LanguageWithWildcard[] {
    const languages = [getLanguageSettings().databaseLocale, ...getLanguageSettings().fallbackLocales];

    // Die Sprachen in eine Array aus LanguageWithWildcard wandeln.
    // Dazu für jedes Element prüfen, ob es in dem Enum ist und nur hinzufügen zum Ergebnis wenn es auch gefunden wurde
    return languages.flatMap((elem) => {
        const value = Object.values(LanguageWithWildcard).find((v) => v === elem);
        if (value) {
            return [value];
        } else {
            return [];
        }
    });
}

export function getAvailableLanguages(): string[] {
    return getLanguageSettings().availableLocales;
}

export function getLanguageSettings(): LanguageSettings {
    return getStore().getters["lang/languageSettings"];
}

function getLanguageSettingsFromLocalStorage(): LanguageSettings {
    return getStore().getters["lang/languageFromLocalStorage"];
}
