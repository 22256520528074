<template>
    <ConfirmDialogue ref="confirmDialogue" type="danger">
        <p>
            {{
                t(
                    "mechanic.If you delete the mechanics of {product}, all related assemblies will be irrevocably deleted",
                    { product: productId }
                )
            }}
        </p>
    </ConfirmDialogue>
</template>

<script setup lang="ts">
import { onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useDeleteMechanicsMutation } from "@/shared/services/graphql/generated/admin-graph-types";
import { DialogueOptions } from "@/shared/components/popup/confirm-dialogue.model";
import { functionText, keyText } from "@/shared/i18n/translation-types";
import { getStore } from "@/shared/store/store";
import ConfirmDialogue from "@/shared/components/popup/confirm-dialogue.vue";
import { useQuasar } from "quasar";

const props = defineProps<{
    productId: string;
    notifyAfterAction: (wasCanceled: boolean) => void;
}>();

const { notify } = useQuasar();
const { t } = useI18n({ useScope: "global" });

const {
    mutate: deleteMechanic,
    onDone: onMechanicDeleted,
    onError: onMechanicDeletedError,
} = useDeleteMechanicsMutation({ clientId: "admin" });

onMechanicDeleted(() => {
    notify({
        message: t("mechanic.Mechanic is deleted"),
        type: "positive",
        timeout: 1_000,
    });
    getStore().commit("notification/notifyMechanicDeleted");
});

onMechanicDeletedError((error) => {
    notify({ message: error.message });
});

const confirmDialogue = ref<{ show: (opts: DialogueOptions) => any } | null>(null);

async function openDeletionDialogue() {
    const ok = await confirmDialogue.value?.show({
        title: functionText((t) => {
            return t("mechanic.Do you really want to delete the mechanics of {product}?", {
                product: props.productId,
            });
        }),
        okButtonText: keyText("mechanic.Delete mechanics"),
        cancelButtonText: keyText("core.Cancel"),
        icon: "fa-regular fa-triangle-exclamation error-color fa-lg",
    });
    // If you throw an error, the method will terminate here unless you surround it with try/catch
    if (ok) {
        deleteMechanic({
            productIds: [props.productId],
        });
        notify({
            message: t("mechanic.Mechanic is being deleted"),
            timeout: 1_000,
        });
        props.notifyAfterAction(false);
    } else {
        props.notifyAfterAction(true);
    }
}

onMounted(() => {
    openDeletionDialogue();
});
</script>

<style lang="scss" scoped></style>
