import { ComputedRef, Ref, unref } from "vue";

import { I18TType } from "./i18-utils";
import { getI18n } from "@/shared/i18n/i18n";

export type TextObject = StaticTextObject | KeyTextObject | FunctionTextObject | ComputedTextObject;

export type TextObjectTypes = "static" | "key" | "function" | "computed";

interface BaseTextObject {
    __type: TextObjectTypes;
}

export interface StaticTextObject extends BaseTextObject {
    __type: "static";
    text: string;
}

export function staticText(text: string): StaticTextObject {
    return {
        __type: "static",
        text: text,
    };
}

export interface KeyTextObject extends BaseTextObject {
    __type: "key";
    key: string;
}

export function keyText(key: string): KeyTextObject {
    return {
        __type: "key",
        key: key,
    };
}

export interface FunctionTextObject extends BaseTextObject {
    __type: "function";
    callback: (t: I18TType) => string;
}

export function functionText(callback: (t: I18TType) => string): FunctionTextObject {
    return {
        __type: "function",
        callback: callback,
    };
}

export interface ComputedTextObject extends BaseTextObject {
    __type: "computed";
    computedText: Ref<string> | string;
}

export function computedText(computedText: ComputedRef<string>): ComputedTextObject {
    return {
        __type: "computed",
        computedText: computedText,
    };
}

export function useTextObjectTranslation() {
    const i18n = getI18n();

    const trans = (textObject: TextObject): string => {
        if (textObject.__type === "static") {
            return textObject.text;
        }
        if (textObject.__type === "key") {
            //@ts-ignore
            return i18n.global.t(textObject.key);
        }
        if (textObject.__type === "function") {
            return textObject.callback(i18n.global.t);
        }
        if (textObject.__type === "computed") {
            return unref(textObject.computedText);
        }
        return "";
    };

    return {
        trans,
    };
}
