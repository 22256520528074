<template>
    <MechanicArticlesList
        v-if="productSearchContext"
        :full-text-search-value="fullTextSearchValue"
        :product="productSearchContext"
        :asset="assetSearchContext"
    />
    <ArticlesList v-else :full-text-search-value="fullTextSearchValue" />
</template>

<script setup lang="ts">
/* Komponente, die bei der Suche nach Artikeln angezeigt wird. */
import ArticlesList from "./articles-list.vue";
import MechanicArticlesList from "./mechanic-articles-list.vue";
import { computed } from "vue";

const props = defineProps<{
    search: Record<string, string>;
}>();

const fullTextSearchValue = computed(() => {
    return props.search.text;
});

const productSearchContext = computed(() => {
    return props.search.product;
});

const assetSearchContext = computed(() => {
    return props.search.asset;
});
</script>
