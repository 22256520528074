import { Ability, NavigationMenuEntry, Route, RouteElementWithParam, Widget } from "@/shared/environment/ability.types";
import PlayGroundWidgetChild2 from "@/abilities/playground/pages/route-test/child2-widget.vue";
import PlayGroundWidgetChild1 from "@/abilities/playground/pages/route-test/child1-widget.vue";
import PlayGroundWidgetChild3 from "@/abilities/playground/pages/route-test/child3-widget.vue";
import PlayGroundWidgetChildArray from "@/abilities/playground/pages/route-test/child-array-widget.vue";
import PlayGroundWidgetChildQueryParams from "@/abilities/playground/pages/route-test/child-query-params-widget.vue";
import { createRouteFromRouteElements } from "@/shared/environment/ability-route-helper";
import { createArrayBreadCrumbs } from "@/abilities/playground/pages/route-test/child-array.model";
import { isPlayGroundVisible } from "@/shared/development/play-ground-easter-egg";
import { AccessFeature } from "@/shared/access-control/access-control";
import { staticText } from "@/shared/i18n/translation-types";

export class PlayGroundAbility implements Ability {
    alias = "playground";
    access = {
        accessFeature: AccessFeature.playground,
    };
    getWidgets = (): Array<Widget> => [
        {
            widgetContextAlias: [
                { userDefinedAlias: "playGroundParent" },
                { userDefinedAlias: "playGroundChildArray" },
                { userDefinedAlias: "playGroundChildQueryParams" },
            ],
            widgetComponent: PlayGroundWidgetChild1,
        },
        {
            widgetContextAlias: [{ userDefinedAlias: "playGroundParent" }, { userDefinedAlias: "playGroundChild1" }],
            widgetComponent: PlayGroundWidgetChild2,
        },
        {
            widgetContextAlias: [
                { userDefinedAlias: "playGroundParent" },
                { userDefinedAlias: "playGroundChild1" },
                { userDefinedAlias: "playGroundChild2" },
            ],
            widgetComponent: PlayGroundWidgetChild3,
        },
        {
            widgetContextAlias: [{ userDefinedAlias: "playGroundParent" }],
            widgetComponent: PlayGroundWidgetChildArray,
        },
        {
            widgetContextAlias: [{ userDefinedAlias: "playGroundParent" }],
            widgetComponent: PlayGroundWidgetChildQueryParams,
        },
    ];

    getNavigationMenuEntries = (): Array<NavigationMenuEntry> => [
        {
            icon: "fa-solid fa-paw",
            title: staticText("QTable Test"),
            isVisible: () => {
                return isPlayGroundVisible();
            },
            internalRoute: {
                alias: "qtabletest",
            },
        },
        {
            icon: "fa-solid fa-paw",
            title: staticText("Playground About Us"),
            isVisible: () => {
                return isPlayGroundVisible();
            },
            internalRoute: {
                alias: "about",
            },
        },
        {
            icon: "fa-solid fa-paw",
            title: staticText("Playground Parent"),
            isVisible: () => {
                return isPlayGroundVisible();
            },
            internalRoute: {
                alias: "playGroundParent",
            },
        },
        {
            icon: "fa-solid fa-paw",
            title: staticText("Playground Child2"),
            isVisible: () => {
                return isPlayGroundVisible();
            },
            internalRoute: {
                alias: "playGroundChild2",
            },
        },
        {
            icon: "fa-solid fa-paw",
            title: staticText("AbilityTypes-Filter-Test"),
            isVisible: () => {
                return isPlayGroundVisible();
            },
            internalRoute: {
                alias: "AbilityList",
            },
        },
        {
            icon: "fa-solid fa-paw",
            title: staticText("JsonEditor"),
            isVisible: () => {
                return isPlayGroundVisible();
            },
            internalRoute: {
                alias: "Json-Editor",
            },
        },
    ];

    getRoutes = (): Array<Route> => [
        {
            alias: "about",
            params: [],
            getComponent: () => import("@/abilities/playground/pages/playground-about.vue"),
            isRoot: true,
            getBreadCrumbs: (
                parentElements: Array<RouteElementWithParam>,
                currentElement: RouteElementWithParam,
                _translateMethod: (text: string) => string
            ) => {
                return [
                    {
                        content: {
                            simpleText: "Playground About Us",
                        },
                        getRouterTo: () => createRouteFromRouteElements(parentElements, currentElement),
                    },
                ];
            },
        },
        {
            alias: "playGroundParent",
            params: [],
            getComponent: () => import("@/abilities/playground/pages/route-test/parent.vue"),
            isRoot: true,
            getBreadCrumbs: (
                parentElements: Array<RouteElementWithParam>,
                currentElement: RouteElementWithParam,
                _translateMethod: (text: string) => string
            ) => {
                return [
                    {
                        content: {
                            simpleText: "Playground Parent",
                        },
                        getRouterTo: () => createRouteFromRouteElements(parentElements, currentElement),
                    },
                ];
            },
        },
        {
            alias: "qtabletest",
            params: [],
            isRoot: true,
            getComponent: () => import("@/abilities/playground/pages/qtable-test/q-table-test.vue"),
        },
        {
            alias: "playGroundChild1",
            params: ["playGroundChild1"],
            getComponent: () => import("@/abilities/playground/pages/route-test/child1.vue"),
            parentAliases: ["playGroundParent", "playGroundChildArray", "playGroundChildQueryParams"],
            getBreadCrumbs: (parentElements: Array<RouteElementWithParam>, currentElement: RouteElementWithParam) => {
                return [
                    {
                        content: {
                            simpleText: currentElement.namedParams[currentElement.alias] ?? "-",
                        },
                        getRouterTo: () => createRouteFromRouteElements(parentElements, currentElement),
                    },
                ];
            },
        },
        {
            alias: "playGroundChild2",
            params: ["playGroundChild2"],
            getComponent: () => import("@/abilities/playground/pages/route-test/child2.vue"),
            parentAliases: ["playGroundParent", "playGroundChild1"],
            isRoot: true,
            getBreadCrumbs: (parentElements: Array<RouteElementWithParam>, currentElement: RouteElementWithParam) => {
                return [
                    {
                        content: {
                            simpleText: currentElement.namedParams[currentElement.alias] ?? "-",
                        },
                        getRouterTo: () => createRouteFromRouteElements(parentElements, currentElement),
                    },
                ];
            },
        },
        {
            alias: "playGroundChild3",
            params: ["playGroundChild3"],
            getComponent: () => import("@/abilities/playground/pages/route-test/child3.vue"),
            parentAliases: ["playGroundParent", "playGroundChild1", "playGroundChild2"],
            isRoot: true,
            getBreadCrumbs: (parentElements: Array<RouteElementWithParam>, currentElement: RouteElementWithParam) => {
                return [
                    {
                        content: {
                            simpleText: currentElement.namedParams[currentElement.alias] ?? "-",
                        },
                        getRouterTo: () => createRouteFromRouteElements(parentElements, currentElement),
                    },
                ];
            },
        },
        {
            alias: "playGroundChildArray",
            params: ["playGroundChildArray"],
            getComponent: () => import("@/abilities/playground/pages/route-test/child-array.vue"),
            parentAliases: ["playGroundParent"],
            getBreadCrumbs: (parentElements: Array<RouteElementWithParam>, currentElement: RouteElementWithParam) => {
                return createArrayBreadCrumbs(parentElements, currentElement);
            },
        },
        {
            alias: "playGroundChildQueryParams",
            params: ["playGroundChildQueryParams"],
            getComponent: () => import("@/abilities/playground/pages/route-test/child-query-params.vue"),
            parentAliases: ["playGroundParent"],
            getBreadCrumbs: (parentElements: Array<RouteElementWithParam>, currentElement: RouteElementWithParam) => {
                return [
                    {
                        content: {
                            simpleText: currentElement.namedParams[currentElement.alias] ?? "-",
                        },
                        getRouterTo: () => createRouteFromRouteElements(parentElements, currentElement),
                    },
                ];
            },
        },
        {
            alias: "AbilityList",
            params: [],
            isRoot: true,
            getComponent: () => import("@/abilities/playground/pages/ability-test/abilitiy-filter.vue"),
        },
        {
            alias: "Json-Editor",
            params: [],
            isRoot: true,
            getComponent: () => import("@/abilities/playground/pages/json-editor-test/json-editor.vue"),
        },
    ];
}
