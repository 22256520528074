import { Aggregations, Facet } from "../facets.model";

export function generateAggregationsFromFacet(facet: Facet, keywordSearchValue: string = "") {
    const keywordAggregation = facet.facetType == "keyword";
    const metaDataAggregation = facet.type === "metadata" && facet.facetType != "keyword";
    const fieldAggregation = facet.type === "field" && facet.facetType != "keyword";
    let aggregations = "";

    if (metaDataAggregation || fieldAggregation || keywordAggregation) {
        const more = `(after: "", first: 10000){
            count
            value
        }`;

        const keywordFilterMore = `(first: 10000, prefix: "${keywordSearchValue}"){
            count
            value
        }`;

        let fieldQuery = "";
        if (fieldAggregation) {
            fieldQuery += facet.id;
            fieldQuery += more;
        }
        let metaDataQuery = "";

        if (metaDataAggregation) {
            metaDataQuery = "composite {";
            metaDataQuery += facet.id;
            metaDataQuery += more;
            metaDataQuery += "}";
        }

        let keywordQuery = "";
        if (keywordAggregation) {
            keywordQuery = "terms {";
            keywordQuery += facet.id;
            keywordQuery += keywordFilterMore;
            keywordQuery += "}";
        }

        aggregations = `{
            aggregations {
                ${fieldQuery}
                ${metaDataQuery}
                ${keywordQuery}
            } 
        }`;
    }

    return aggregations;
}

export function getAggregationsForFacet(facet: Facet, aggregations: any): Aggregations {
    let aggregationsForFacet;
    if (facet.type === "metadata") {
        if (aggregations?.composite) {
            aggregationsForFacet = aggregations?.composite[facet.id];
        } else if (aggregations?.terms) {
            aggregationsForFacet = aggregations?.terms[facet.id];
        }
    } else if (facet.type === "field") {
        aggregationsForFacet = aggregations[facet.id];
    }

    const aggregationsObject: Aggregations = {};

    if (aggregationsForFacet) {
        const aggregationsForFacetSelection: Record<string, number> = {};

        for (const value of Object.values(aggregationsForFacet)) {
            aggregationsForFacetSelection[(value as any).value] = (value as any).count;
        }

        aggregationsObject[facet.id] = aggregationsForFacetSelection;
        return aggregationsObject;
    }
    return aggregationsObject;
}
