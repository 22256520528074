import {
    LanguageWithWildcard,
    MechanicPath,
    useMechanicsLocationsQuery,
} from "@/shared/services/graphql/generated/consumer-graph-types";
import { computed, Ref } from "vue";

export interface useMechanicLocationParams {
    first?: number;
    acceptedLanguages?: Array<LanguageWithWildcard>;
    articleId: string;
    product: string;
    asset?: string;
}

export function useMechanicLocation(params: Ref<useMechanicLocationParams>) {
    const queryParams = computed(() => {
        return {
            first: params.value.first,
            filter: {
                andGroup: [
                    {
                        equals: { articleId: params.value.articleId },
                    },
                ],
            },
            assetId: params.value.asset,
            productId: params.value.product,
            acceptedLanguages: params.value.acceptedLanguages,
        };
    });

    const { loading, error, result, fetchMore } = useMechanicsLocationsQuery(
        queryParams,
        // TODO: mit Cache werden das immer mehr Daten alles was schon mal abgefragt wurde ist dann doppelt, siehe QIT-1685
        { fetchPolicy: "no-cache" }
    );

    const paths = computed(() => {
        const result: Array<MechanicPath> = [];
        mechanics.value?.mechanics.forEach((mechanic) => {
            mechanic?.node.pathsToRoot?.forEach((path) => {
                result.push(path as MechanicPath);
            });
        });
        return result;
    });

    const mechanics = computed(() => {
        return result.value?.mechanics;
    });

    return {
        loading,
        error,
        mechanics,
        paths,
        fetchMore,
    };
}
