<template>
    <AuthenticatorDialog
        :show="showAuthenticatorDialog"
        :authenticators="authenticators"
        @on-authenticator-selected="onLogin"
    />
    <div v-if="!initialized && !errorMessage" class="fixed-center">
        <q-spinner-ios size="2em" class="col" />
    </div>
    <ErrorMessage v-else-if="errorMessage" :error-message="errorMessage" />
    <Shell v-else :authenticated="authenticated" />
</template>

<script setup lang="ts">
import Shell from "@/shell/pages/shell.vue";
import { addI18nWatcher, initLanguage } from "@/shared/services/providers/language-provider";
import {
    forceLogin,
    getAnonymousAuthenticator,
    getAuthenticators,
    initAuthentication,
    isAnonymousMode,
    isMixedMode,
    tryGetToken,
} from "@/shared/authentication/authentication";
import { computed, onMounted, onUnmounted, ref, watchEffect } from "vue";
import { Authenticator } from "@/shared/services/graphql/generated/public-graph-types";
import AuthenticatorDialog from "@/shared/authentication/components/authenticator-dialog.vue";
import { useTheme } from "@/shell/helpers/theme-definition";
import { useRouter } from "vue-router";
import { setCssVar, useQuasar } from "quasar";
import { initAbilities } from "@/app/app.ts";
import { useTenant } from "@/shared/composables/tenant";
import ErrorMessage from "@/shared/components/placeholder/error-message.vue";
import { isMaintenance } from "@/shared/services/apollo-clients/links/error-link";

const router = useRouter();
const { screen } = useQuasar();
const { tenant, error } = useTenant();
const { colors, themeDefinitions, receiveMessage } = useTheme();

const authenticators = ref<Authenticator[]>([]);
const showAuthenticatorDialog = ref(false);
const initialized = ref(false);
const authenticated = ref(false);
const loginError = ref<string>();

function camelCaseToHyphen(str: string) {
    return str.replace(/[A-Z]/g, (match) => `-${match.toLowerCase()}`);
}

watchEffect(() => {
    if (!colors.value) return;

    for (const [key, value] of Object.entries(colors.value)) {
        setCssVar(`${camelCaseToHyphen(key)}`, value);
    }
    setCssVar("secondary", "#F2F2F2"); // $grey-95
});

/*
    i18n watcher hinzufügen, damit Translation-Funtkion t immer die aktuelle Oberflächensprache nutzt.
*/
addI18nWatcher();

onMounted(() => {
    screen.setSizes({ sm: 628, md: 960, lg: 1280 });
    //TODO Refactoring
    /*  Ideally, we should start the Vue app as soon as all necessary data (login, theming, ...) are available.*/

    window.addEventListener("message", receiveMessage, false);
});

const errorMessage = computed(() => {
    if (isMaintenance.value) return "Tenant is in maintenance mode";
    return loginError.value || error.value?.message;
});

const called = watchEffect(async () => {
    if (!tenant.value || !themeDefinitions.value) return;

    try {
        called();
        /*
        We need to use window.location instead of router from vue router to get the initial full path.
        Router does not return the initial url but the redirect url in certain situations.
        */
        const initPath = window.location.href.replace(window.location.origin, "");

        initLanguage(tenant.value.defaultLanguage, tenant.value.availableLanguages);

        initAuthentication(tenant.value?.authConfig);
        const successfulLogin = await tryGetToken();

        if (successfulLogin) {
            authenticated.value = true;

            //Ability initializing
            const { defaultPath } = await initAbilities(router, tenant.value);
            const newRoutePath = initPath === "/" ? defaultPath : initPath;
            await router.replace(newRoutePath);

            return;
        }

        if (isMixedMode() || isAnonymousMode()) {
            await forceLogin(getAnonymousAuthenticator());
        } else {
            authenticators.value = getAuthenticators();
            if (authenticators.value.length === 1) {
                await forceLogin(authenticators.value[0]);
            } else {
                showAuthenticatorDialog.value = true;
            }
        }
    } catch (e: any) {
        authenticated.value = false;
        loginError.value = e.message;
        console.error(loginError.value);
    } finally {
        initialized.value = true;
    }
});

onUnmounted(() => {
    window.removeEventListener("message", receiveMessage);
});

const onLogin = async (authenticatorEdge: Authenticator) => {
    await forceLogin(authenticatorEdge);
};
</script>

<style lang="scss">
html {
    font-size: 14px;
}

html,
body {
    margin: 0px;
    min-height: 100%;
    height: 100%;
    font-family: $base-font-family;
}
</style>
