<template>
    <div class="notifications-menu">
        <q-btn
            v-if="hasAccess"
            unelevated
            :ripple="false"
            padding="sm"
            :icon="isXSmall ? mobileIcon : icon"
            size="13px"
            :disabled="notifications.length === 0"
            class="grey-hover"
            data-qs="show-notifications-button"
        >
            <q-menu @hide="onHide" @before-show="onBeforeShow">
                <q-list dense>
                    <q-item>
                        <q-item-section>
                            <div class="flex-container flex-column flex-start">
                                <div class="notifications-menu-title">{{ $t("core.Notifications") }}</div>
                                <div ref="notificationsScrollAreaRef" style="max-height: 200px">
                                    <q-scroll-area v-if="notifications.length > 0" class="fit">
                                        <div class="notifications-card-wrapper">
                                            <NotificationsCard
                                                v-for="notification in notifications"
                                                :key="notification.id"
                                                :date="notification.creationDate"
                                                :notification="notification"
                                                :action="hasAction(notification)"
                                                @on-mark-as-read="onMarkAsRead"
                                                @on-action="onAction"
                                                @notifications-card-height="addNotificationsCardHeight"
                                                @set-notifications-card-width="setNotificationsCardWidth"
                                            />
                                        </div>
                                    </q-scroll-area>
                                </div>
                            </div>
                        </q-item-section>
                    </q-item>
                </q-list>
            </q-menu>
            <q-tooltip>{{ $t("core.Notifications") }}</q-tooltip>
        </q-btn>
    </div>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { getDataQueryLanguages } from "@/shared/services/providers/language-provider";
import { useI18n } from "vue-i18n";
import NotificationsCard from "./notifications-card.vue";
import { Notifications } from "../models/notifications.model";
import {
    GetContentByNoteIdDocument,
    GetContentByNoteIdQuery,
    useMarkNotificationsAsReadMutation,
    useNotificationsQuery,
    useNotificationsTopBarQuery,
    useUserQuery,
} from "@/shared/services/graphql/generated/consumer-graph-types";
import { consumerClient } from "@/shared/services/apollo-clients/consumer-client";
import { ContentNode } from "@/shared/router/document-route-helper";
import { useRouter } from "vue-router";
import { useHasAccess } from "@/shared/access-control/composables/use-has-access";
import { AccessAction, AccessResource } from "@/shared/access-control/access-control";
import { useScreenSize } from "@/shared/screen/composables/screen-size";
import { useQuasar } from "quasar";
import { useDocumentRoute } from "@/shared/router/document-route";

const props = defineProps<{
    menuTitleTranslationKey: string;
    icon: string;
    mobileIcon: string;
}>();

const { hasAccess } = useHasAccess({
    action: AccessAction.query,
    resource: AccessResource.notifications,
    ignoreConditions: true,
});

const NOTIFICATION_POLL_INTERVAL = 60000;

const show = ref(false);
const router = useRouter();
const { isXSmall } = useScreenSize();
const { t } = useI18n();
const { notify } = useQuasar();
const { getContentPath } = useDocumentRoute();
const notificationsScrollAreaRef = ref<HTMLElement>();

const { result: user, loading: userLoading } = useUserQuery();

const { mutate: markNotificationsAsReadMutation } = useMarkNotificationsAsReadMutation({});

const { result: notificationsData } = useNotificationsTopBarQuery(
    computed(() => ({
        userId: user?.value?.me.id!,
    })),
    computed(() => ({
        enabled: !userLoading.value && hasAccess.value && !!user.value?.me.id,
        pollInterval: NOTIFICATION_POLL_INTERVAL,
    }))
);

const scrollAreaHeight = ref(0);

function addNotificationsCardHeight(height: number) {
    if (!notificationsScrollAreaRef.value || notifications.value.length <= 0) {
        return;
    }
    scrollAreaHeight.value += height;
    notificationsScrollAreaRef.value.style.height = `${scrollAreaHeight.value}px`;
}

function setNotificationsCardWidth(width: number) {
    if (!notificationsScrollAreaRef.value) {
        return;
    }
    notificationsScrollAreaRef.value.style.width = `${width + 1}px`; // + 1 because of border (.notifications-menu)
}

const icon = computed(() => {
    let _icon = isXSmall.value ? props.mobileIcon : props.icon;
    if (notificationsData.value?.notifications?.total.count > 0) _icon += "-red";
    return _icon;
});

const { result } = useNotificationsQuery(
    computed(() => ({
        first: 100,
        userId: user.value?.me.id!,
        acceptedLanguages: getDataQueryLanguages(),
    })),
    computed(() => ({
        enabled: show.value && !userLoading.value,
    }))
);

const hasAction = (notification: Notifications) => {
    return !!actionsForNotificationType[notification.notificationType ?? ""];
};

const notifications = computed(() => {
    return (
        result.value?.notifications?.notifications.map((notification) => {
            return {
                text: notification?.node.teasers?.text!,
                creationDate: notification?.node.creationDate!,
                id: notification?.node.id!,
                notificationType: notification?.node.notificationType ?? "",
                objectId: notification?.node.objectId!,
            };
        }) ?? []
    );
});

const onHide = () => {
    scrollAreaHeight.value = 0;
    show.value = false;
};

const onBeforeShow = () => {
    show.value = true;
};

const actionsForNotificationType: Record<string, (notification: Notifications) => Promise<void>> = {
    newPublicNote: async (notification: Notifications) => {
        const objectId = notification.objectId as string;
        const { data: content, error } = await consumerClient.query<GetContentByNoteIdQuery>({
            query: GetContentByNoteIdDocument,
            variables: { id: objectId },
        });

        if (error) {
            notify({ message: error?.message, type: "negative" });
            return;
        }

        if (!content) {
            notify({ message: t("Content not available"), type: "negative" });
            return;
        }

        const path = getContentPath(content.note?.content as ContentNode, []);
        if (path) router.push(path);
    },
};

const onAction = async (notification: Notifications) => {
    await onMarkAsRead(notification);
    const action = actionsForNotificationType[notification.notificationType];
    if (action) await action(notification);
};

const onMarkAsRead = async (notification: Notifications) => {
    await markAsRead([notification.id]);
};

const markAsRead = async (notificationIds: string[]) => {
    try {
        await markNotificationsAsReadMutation(
            { ids: notificationIds },
            {
                awaitRefetchQueries: true,
            }
        );
    } catch (e: any) {
        notify({ message: typeof e === "string" ? e : e.message, type: "negative" });
    }
};
</script>

<style lang="scss" scoped>
.notifications-menu {
    &:deep(.q-btn:disabled) {
        background: transparent !important;
    }

    .notifications-menu-title {
        font-weight: $bold;
        padding: $spacing-m;
        border-bottom: 1px solid;
        border-color: $light-background-color;
    }

    .notifications-card-wrapper {
        border: 1px solid;
        border-color: $light-background-color;
    }
}
</style>
