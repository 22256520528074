<template>
    <Card
        title-icon="fa-regular fa-book light-border-color"
        :title="title"
        :show-footer="false"
        v-if="entries && entries.length > 0"
    >
        <CollectionEntry v-for="(item, index) in entries" :key="index" :item="item" />
        <template #close></template>
    </Card>
</template>

<script setup lang="ts">
import Card from "@/shared/components/card/card.vue";
import { useDashboardCollection } from "../composables/dashboard-collection";
import CollectionEntry from "@/abilities/collections/components/collection-entry.vue";

const { title, entries } = useDashboardCollection();
</script>

<style lang="scss" scoped>
.dashboard-collection-grid {
    padding: 0;
}
</style>
