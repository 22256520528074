import { computed, ComputedRef, Ref } from "vue";
import { useI18n } from "vue-i18n";
import {
    LanguageWithWildcard,
    useMechanicsAssemblyTitleQuery,
} from "@/shared/services/graphql/generated/consumer-graph-types";

function createAssemblyTitleQueryParam(
    assemblyId: ComputedRef<string>,
    productId: Ref<string>,
    assetId: Ref<string | undefined>,
    languages: ComputedRef<LanguageWithWildcard[]>
) {
    return computed(() => {
        return {
            filter: {
                andGroup: [
                    {
                        equals: {
                            product: productId.value,
                            assemblyId: assemblyId.value,
                        },
                    },
                ],
            },
            assetId: assetId.value,
            productId: productId.value,
            acceptedLanguages: languages.value,
            first: 1,
        };
    });
}

export function useAssemblyTitleResult(
    assemblyId: ComputedRef<string>,
    productId: Ref<string>,
    assetId: Ref<string | undefined>,
    languages: ComputedRef<LanguageWithWildcard[]>
) {
    const { t } = useI18n();
    const graphqlVariables = createAssemblyTitleQueryParam(assemblyId, productId, assetId, languages);
    const { loading, error, result } = useMechanicsAssemblyTitleQuery(graphqlVariables, { fetchPolicy: "cache-first" });

    const rawResult = computed(() => {
        return result.value?.mechanics.mechanics[0] ?? undefined;
    });

    const title = computed(() => {
        if ((assemblyId.value ?? "") === "") {
            return t("mechanic.Mechanics");
        }
        if (rawResult.value) {
            return rawResult.value.node?.localizations?.title && rawResult.value.node?.localizations?.title !== ""
                ? rawResult.value.node?.localizations?.title
                : rawResult.value.node?.articleId;
        } else {
            return undefined;
        }
    });

    const articleNumber = computed(() => {
        if (rawResult.value && rawResult.value.node?.articleNumber) {
            return rawResult.value.node?.articleNumber;
        }
        return "";
    });

    const exists = computed(() => {
        return !!rawResult.value?.node;
    });

    return {
        loading,
        error,
        title,
        articleNumber,
        exists,
    };
}
