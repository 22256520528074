import { QTableProps } from "quasar";

export const QIT_PREVIEW_IMAGE_COLUMN = "qit-preview-image";
export const QIT_PREVIEW_IMAGE_ROW_FIELD = "qitPreviewImageUrl";

export type QTableRowType = {
    id: string;
    previewImageUrl?: string; // url to preview image, when provided by backend
    title?: string;
} & {
    [key: string]: any; // TODO: could maybe narrowed down to specific types like string | number | ComponentX | ...
};

export interface QDataSource {
    columns: QTableProps["columns"];
    mobileColumns?: QTableProps["columns"];
    rows?: QTableRowType[];
    visibleColumns?: string[];
}
