import { FieldDefinitionTypes } from "@/shared/services/graphql/generated/consumer-graph-types";

export interface Facet {
    id: string;
    title: string;
    type: "metadata" | "field";
    displayOptions: FacetDisplayOptions;
    facetType: FieldDefinitionTypes;
    referencedId: string;
}

export interface FacetValue {
    id: string;
    text: string;
    pathKeys?: string[];
    approveUnknownValue?: boolean;
}

export interface FacetDisplayOptions {
    isModal?: boolean;
    isMultiSelect?: boolean;
    isTree?: boolean;
    alwaysVisible?: boolean;
}

export type Aggregations = Record<string, Record<string, number>>;

export type FacetSelected = {
    id: string;
    referencedId: string;
    type: FieldDefinitionTypes;
    values: string[];
};

export enum FacetCollection {
    assetSelection = "asset-selection",
    asset = "asset",
    productSelection = "product-selection",
    product = "product",
    parts = "parts",
    details = "part-details",
    article = "article",
    articleSelection = "article-selection",
    documentSelection = "document-selection",
    documentGo = "document-go",
    activitiesSelection = "activities-selection",
}

export enum DisplayType {
    flat = "flat",
    tree = "tree",
    multiSelect = "multiSelect",
}
