import {
    CollectionEntryEdge,
    ContentCollectionEntryNode,
} from "@/shared/services/graphql/generated/consumer-graph-types.ts";
import { PropertyItem } from "@/shared/components/views/property-item.model.ts";
import { useDocumentRoute } from "@/shared/router/document-route.ts";
import { DocumentCollectionEntry } from "@/abilities/collections/components/collection-entry.types.ts";

export const mapToDocumentCollectionEntry = (
    collectionEntryEdge: CollectionEntryEdge,
    getDataDisplayPropertiesByConfig: (graphqlResultObject: any) => PropertyItem[]
): DocumentCollectionEntry => {
    const { getPathToDocumentationDetailPageForSearch } = useDocumentRoute();
    let isTopic = false;
    let topicId: string | undefined;
    let contentMapId: string,
        title,
        rootContentMapTitle: string = "";

    const node = collectionEntryEdge.node as ContentCollectionEntryNode;

    if (node.content.contentId) {
        contentMapId = node.content.contentId;
        title = node.content.teasers?.title ?? "";
    } else {
        isTopic = true;
        topicId = node.content.id.split("@")![0];
        contentMapId = node.content.id.split("@")![1];
        rootContentMapTitle = node.content.useContext?.rootContentMap?.teasers?.title || "";
        title = node.content.teasers?.title ?? "";
    }

    let subheading = node.navContext?.product?.localizations?.title || "";
    if (node.navContext?.asset?.assetId) subheading += ` / ${node.navContext?.asset.assetId}`;

    const properties = getDataDisplayPropertiesByConfig(node.content);

    let useContext, searchContext;
    if (node.navContext?.asset?.assetId || node.navContext?.product?.id) {
        useContext = {
            assetId: node.navContext?.asset?.assetId || undefined,
            productId: node.navContext?.product?.id || undefined,
        };
        searchContext = {
            asset: node.navContext?.asset?.assetId || undefined,
            product: node.navContext?.product?.id || undefined,
        };
    }
    const routeLocation = getPathToDocumentationDetailPageForSearch(contentMapId, topicId, [], searchContext);

    return {
        cursor: collectionEntryEdge.cursor,
        isTopic,
        topicId,
        contentMapId,
        rootContentMapTitle,
        title,
        subheading,
        type: (collectionEntryEdge.node as any).type,
        properties,
        routeLocation,
        useContext,
        __typename: "Documentation",
    };
};
