<template>
    <Card
        :title="$t(title)"
        @on-close-button-clicked="$emit('onCloseButtonClicked')"
        data-qs="file-import-wizard"
        class="small-content-container"
        white-background
        container
    >
        <div v-show="processesLoading" class="loading">
            <q-spinner-ios size="2em" />
        </div>
        <ConfigurationFormWrapper>
            <FileImportForm @on-form-validation="setActiveFormValid" v-model="formFields" :entry-id="entryId" />
        </ConfigurationFormWrapper>
        <template #footer>
            <ConfigurationWizardFooter
                process-type="file"
                :continue-text="entryId ? 'core.Update Process' : 'core.Create Process'"
                :continue-disabled="!formValid || onProcessCreateLoading || onProcessUpdating"
                @go-to-next-step="createFileProcess"
            />
        </template>
    </Card>
</template>

<script setup lang="ts">
import { onMounted, Ref, ref, watch } from "vue";
import Card from "@/shared/components/card/card.vue";
import ConfigurationFormWrapper from "@/shared/components/data-process/configuration-form-wrapper.vue";
import FileImportForm from "./file-import-form.vue";
import ConfigurationWizardFooter from "@/shared/components/data-process/configuration-wizard-footer.vue";
import {
    ProcessType,
    useCreateDataProcessMutation,
    useUpdateDataProcessMutation,
} from "@/shared/services/graphql/generated/admin-graph-types";
import { ApolloError } from "@apollo/client/core";
import { useI18n } from "vue-i18n";
import { useProcessLazyQuery } from "@/shared/services/graphql/generated/consumer-graph-types";
import { FileImportFormModel, getFileImportModel } from "./file-import-form.model";
import { useQuasar } from "quasar";

const props = defineProps({
    /**
     * The Title of the Card.
     * @example Neuen Prozess anlegen (Dateien)
     */
    title: {
        type: String,
        default: "",
    },
    /**
     * When editing the process, an Id should be available from this prop, which prevents
     * invalidating the input name when the process already has a name and only makes a CRUD change.
     * Gets passed onto formComponent.
     * @example '_LYfvjuMw85qP9'
     */
    entryId: {
        type: String,
        default: "",
    },
});

/**
 *  @onCloseButtonClicked Close Button has been clicked.
 *  @onCreateProcess Create Process clicked
 */
const emit = defineEmits(["onCreateProcess", "onCloseButtonClicked"]);

const { notify } = useQuasar();
const { t } = useI18n({ useScope: "global" });

const formFields: Ref<FileImportFormModel> = getFileImportModel();

const formValid = ref(false);

function setActiveFormValid(valid: boolean) {
    formValid.value = valid;
}

const {
    mutate: updateProcess,
    onDone: onProcessUpdated,
    onError: onProcessUpdateError,
    loading: onProcessUpdating,
} = useUpdateDataProcessMutation({ clientId: "admin" });

const {
    mutate: createProcess,
    onDone: onProcessCreated,
    onError: onProcessCreateError,
    loading: onProcessCreateLoading,
} = useCreateDataProcessMutation({ clientId: "admin" });

const {
    load: loadProcesses,
    result: process,
    loading: processesLoading,
} = useProcessLazyQuery({
    processId: props.entryId,
});

onMounted(() => {
    if (props.entryId) {
        loadProcesses();
    }
});

/**
 * Creates file process by graphql-mutation
 */
function createFileProcess() {
    if (props.entryId) {
        updateProcess({
            id: props.entryId,
            name: formFields.value.name.value,
            filenameSchemaName: formFields.value.fileNameScheme.value,
            mappingOptions: formFields.value.mappingOptions.value
                .filter((option) => option.value)
                .map((option) => {
                    return {
                        type: option.type,
                        upToChar: option.upToChar,
                    };
                }),
        });
    } else {
        t;
        createProcess({
            spec: {
                name: formFields.value.name.value,
                processType: ProcessType.file,
            },
            filenameSchemaName: formFields.value.fileNameScheme.value,
            mappingOptions: formFields.value.mappingOptions.value
                .filter((option) => option.value)
                .map((option) => {
                    return {
                        type: option.type,
                        upToChar: option.upToChar,
                    };
                }),
        });
    }
}

onProcessCreated((data) => {
    notify({ message: `${t("core.Process")} '${formFields.value.name.value}' ${t("core.was created")}.` });
    emit("onCloseButtonClicked");
    emit("onCreateProcess", { id: data.data?.createProcess });
});

onProcessUpdated(() => {
    notify({ message: `${t("core.Process")} '${formFields.value.name.value}' ${t("core.was updated")}.` });
    emit("onCloseButtonClicked");
});

onProcessCreateError((e: ApolloError) => {
    console.error(e.message);
});

onProcessUpdateError((e: ApolloError) => {
    console.error(e.message);
});

// sets form values from process query when in editing mode
watch([process], () => {
    if (process.value) {
        formFields.value.name.value = process.value.process?.name ?? "";
        formFields.value.fileNameScheme.value = process.value.process?.filenameSchemaName ?? "";
        formFields.value.mappingOptions.value.forEach((option) => {
            const processMapping = process.value?.process?.mappingOptions?.find(
                (mapping) => mapping?.type === option.type
            );
            if (processMapping) {
                option.upToChar = processMapping.upToChar ?? "";
                option.value = true;
            } else {
                option.value = false;
            }
        });
        formFields.value.mappingOptions.value.sort((a, b) => {
            if (process.value?.process?.mappingOptions && process.value?.process?.mappingOptions?.length > 0) {
                const aIndex = process.value?.process?.mappingOptions?.findIndex(
                    (mappingOption) => mappingOption?.type === a.type
                );
                const bIndex = process.value?.process?.mappingOptions?.findIndex(
                    (mappingOption) => mappingOption?.type === b.type
                );
                if (aIndex === -1 && bIndex === -1) {
                    return 0;
                } else if (aIndex === -1) {
                    return 1;
                } else if (bIndex === -1) {
                    return -1;
                } else return aIndex - bIndex;
            }
            return 0;
        });
    }
});
</script>

<style lang="scss" scoped>
.loading {
    background: white;
    display: flex;
    width: 100%;
    height: 100%;
    z-index: 99;
    justify-content: center;
    align-items: center;
}
</style>
