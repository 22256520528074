import { computed } from "vue";
import { useGetConfigFdiShopQuery } from "@/shared/services/graphql/generated/consumer-graph-types";

export function useLoadConfigFdiShop() {
    const { loading, result, refetch } = useGetConfigFdiShopQuery({
        fetchPolicy: "cache-first",
    });

    const configFdiShop = computed(() => {
        if (!loading.value) {
            return result.value?.config?.configFdiShop ?? { baseUrl: "" };
        } else {
            return undefined;
        }
    });

    return {
        loading,
        configFdiShop,
        refetch,
    };
}

// länderkürzel die sich zwischen infotwin und fdi unterscheiden
// key: infotwin | value: fdi
export const FDI_LANGUAGE_ALIAS: Record<string, string> = {
    ja: "jp",
};
