import {
    FileType,
    FileSubType,
    usePartslistFilesQuery,
} from "@/shared/services/graphql/generated/consumer-graph-types";
import { OperationVariables } from "@apollo/client/core";
import { computed, Ref } from "vue";
import { ExplosionImage } from "@/shared/components/viewers/2d-viewers/explosion-viewer/explosion-viewer.model";
import { Hotspot } from "@/shared/components/viewers/2d-viewers/explosion-viewer/hotspot.model";
import { Model3D } from "@/shared/components/viewers/3d-viewers/3d-viewer.model";

export function usePartslistFiles(assemblyId: Ref<string>, productId: Ref<string>, assetId: Ref<string | undefined>) {
    const graphqlVariables = computed<OperationVariables>(() => {
        return {
            filter: {
                andGroup: {
                    equals: {
                        assemblyId: assemblyId.value,
                    },
                },
            },
            assetId: assetId.value,
            productId: productId.value,
            first: 1,
        };
    });

    const { loading, error, result } = usePartslistFilesQuery(graphqlVariables, {
        fetchPolicy: "cache-first",
    });

    const image2D = computed(() => {
        const resultImages = new Array<ExplosionImage>();
        result?.value?.mechanics.mechanics[0]?.node.files?.forEach((file) => {
            if (file.objectType === FileType.image_2D) {
                const hotspots: Hotspot[] = [];
                if (file.__typename === "FileSvgWithHotspots") {
                    file.hotspots?.forEach((hotspot) => {
                        hotspots.push({
                            x1: 0,
                            x2: 0,
                            y1: 0,
                            y2: 0,
                            linkType: hotspot?.linkType?.toString() ?? "",
                            linkValue: hotspot?.linkValue ?? "",
                            linkText: hotspot?.linkText ?? "",
                        });
                    });
                } else if (file.__typename === "FileWithHotspots") {
                    file.hotspots?.forEach((hotspot) => {
                        hotspots.push({
                            x1: hotspot?.x1 ?? "",
                            x2: hotspot?.x2 ?? "",
                            y1: hotspot?.y1 ?? "",
                            y2: hotspot?.y2 ?? "",
                            linkType: hotspot?.linkType?.toString() ?? "",
                            linkValue: hotspot?.linkValue ?? "",
                            linkText: "",
                        });
                    });
                }
                resultImages.push({
                    url: file.file.url,
                    hotspots: hotspots,
                    type: file.objectSubType === FileSubType.svg ? "SVG" : "PIXEL",
                });
            }
        });
        return resultImages;
    });

    const image3D = computed(() => {
        const resultImages = new Array<Model3D>();
        result?.value?.mechanics.mechanics[0]?.node.files?.forEach((file) => {
            if (
                file.objectType === FileType.image_3D &&
                (file.objectSubType === FileSubType.hoopsScs || file.objectSubType === FileSubType.latticeXvl)
            ) {
                resultImages.push({
                    url: file.file.url as string,
                    type: file.objectSubType,
                });
            }
        });
        return resultImages;
    });

    const has3DImages = computed(() => {
        return image3D.value.length > 0;
    });

    const has2DImages = computed(() => {
        return image2D.value.length > 0;
    });

    return {
        loading,
        error,
        image2D,
        has2DImages,
        image3D,
        has3DImages,
    };
}
