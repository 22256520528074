<template>
    <div class="breadcrumb-asset">
        {{ asset }}
    </div>
</template>

<script setup lang="ts">
import { onMounted } from "vue";

defineProps<{
    asset: string;
}>();

const emit = defineEmits<{
    (e: "breadcrumbLoaded"): void;
}>();

onMounted(async () => {
    // nothing is to load -> breadcrumb is ready at start
    emit("breadcrumbLoaded");
});
</script>

<style lang="scss" scoped>
.breadcrumb-asset {
    font-weight: bold;

    &:hover {
        text-decoration: underline;
        cursor: pointer;
    }
}
</style>
