import { useProductQuery } from "@/shared/services/graphql/generated/consumer-graph-types.ts";
import { getDataQueryLanguages } from "@/shared/services/providers/language-provider.ts";
import { computed, Ref } from "vue";
import { AdvProduct, advProduct } from "@/abilities/product-and-asset/data-objects/advanced-product.ts";

export const useProductAssetTitle = (productId: Ref<string | undefined>) => {
    const { result } = useProductQuery(
        computed(() => {
            return {
                id: productId.value as string,
                acceptedLanguages: getDataQueryLanguages(),
            };
        }),
        {
            enabled: !!productId.value,
            fetchPolicy: "cache-first",
        }
    );

    const productTitle = computed(() => {
        return result.value?.product ? advProduct(result.value?.product as AdvProduct).getTitle() : null;
    });

    return {
        productTitle,
    };
};
