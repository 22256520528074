<template>
    <div class="shopping-cart-menu">
        <QitBtn
            padding="sm"
            :flat="!isXSmall"
            :icon="isXSmall ? mobileIcon : icon"
            size="13px"
            class="grey-hover"
            color="default"
            data-qs="open-shopping-cart-button"
            @click="showBasket"
            :disabled="!isShopOnline"
        >
            <q-badge v-if="basketItemCount > 0" color="negative" floating style="z-index: 9999">
                {{ basketItemCount }}
            </q-badge>
            <q-tooltip self="top right">{{ $t(shoppingCartTooltip) }}</q-tooltip>
        </QitBtn>
    </div>
</template>

<script setup lang="ts">
import { computed, onMounted, onUnmounted } from "vue";
import QitBtn from "@/shared/components/buttons/qit-button.vue";
import { useFDIShopBasket } from "../../../composable/shop-basket";
import {
    basketIdForCurrentUser,
    basketUpdateKey,
    getFdiShopBaseUrl,
    updateBasket,
    useShopClient,
} from "../../../graphql/fdi-shop-client";
import { getUiLanguage } from "@/shared/services/providers/language-provider";
import { FDI_LANGUAGE_ALIAS } from "../../../settings/fdi-shop-config-settings";
import { useScreenSize } from "@/shared/screen/composables/screen-size";
import { useShopStatusStore } from "@/abilities/fdi-shop/stores/shop-status";

withDefaults(
    defineProps<{
        icon?: string;
        mobileIcon?: string;
    }>(),
    {
        icon: "fa-regular fa-cart-shopping icon-color",
        mobileIcon: "fa-regular fa-cart-shopping text-on-primary-light-color",
    }
);

const { isXSmall } = useScreenSize();

const shopClient = useShopClient();

const { basket } = useFDIShopBasket(
    computed(() => {
        return {
            client: shopClient.value,
            basketId: basketIdForCurrentUser.value,
            updateKey: basketUpdateKey.value,
        };
    })
);

const { isShopOnline } = useShopStatusStore();

const basketItemCount = computed(() => {
    let count = 0;
    basket.value?.basket?.items?.forEach((item: any) => {
        count += item.amount;
    });
    return count;
});

const shoppingCartTooltip = computed(() => {
    if (isShopOnline) {
        return "fdi-shop.Shopping cart";
    } else {
        return "fdi-shop.The store is currently unavailable due to maintenance work or errors";
    }
});

const languageFlag = computed(() => {
    const uiLanguage = getUiLanguage();
    const fdiAlias = FDI_LANGUAGE_ALIAS[uiLanguage];
    if (fdiAlias) {
        return fdiAlias;
    } else {
        return uiLanguage;
    }
});

async function showBasket() {
    const shopBaseUrl = await getFdiShopBaseUrl();
    if (shopBaseUrl) {
        const basketUrl = new URL("genie/basket", shopBaseUrl);
        basketUrl.searchParams.set("lang", languageFlag.value);
        const callbackURL = new URL(window.location.href);
        basketUrl.searchParams.set("callback", callbackURL.href);
        window.location.href = basketUrl.href;
    }
}

let pollingActive = false;

function startPolling() {
    setTimeout(() => {
        if (pollingActive) {
            updateBasket();
            startPolling();
        }
    }, 10000);
}

onMounted(() => {
    pollingActive = true;
    startPolling();
});

onUnmounted(() => {
    pollingActive = false;
});
</script>

<style lang="scss" scoped>
.shopping-cart-menu {
    &:deep(.q-btn:disabled) {
        background: transparent !important;
    }

    &:deep(.q-btn--flat:not([disabled]):hover .q-btn__content) {
        text-decoration: none;
    }
}
</style>
