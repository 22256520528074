import { ContentSortFields, SortOrder } from "@/shared/services/graphql/generated/consumer-graph-types";
import { computed } from "vue";
import { useI18n } from "vue-i18n";

export function useSortOrderItems() {
    const { t } = useI18n();

    const sortOrderItems = computed(() => {
        return [
            {
                id: "Ranking",
                title: t("core.Ranking"),
                value: {
                    field: ContentSortFields.score,
                    order: SortOrder.desc,
                },
            },
            {
                id: "Title ascending",
                title: t("core.Title ascending"),
                value: {
                    field: ContentSortFields.title,
                    order: SortOrder.asc,
                },
            },
            {
                id: "Title descending",
                title: t("core.Title descending"),
                value: {
                    field: ContentSortFields.title,
                    order: SortOrder.desc,
                },
            },
            {
                id: "Locale ascending",
                title: t("core.Locale ascending"),
                value: {
                    field: ContentSortFields.locale,
                    order: SortOrder.asc,
                },
            },
            {
                id: "Locale descending",
                title: t("core.Locale descending"),
                value: {
                    field: ContentSortFields.locale,
                    order: SortOrder.desc,
                },
            },
            {
                id: "Upload date ascending",
                title: t("core.Upload date ascending"),
                value: {
                    field: ContentSortFields.uploadDate,
                    order: SortOrder.asc,
                },
            },
            {
                id: "Upload date descending",
                title: t("core.Upload date descending"),
                value: {
                    field: ContentSortFields.uploadDate,
                    order: SortOrder.desc,
                },
            },
            {
                id: "Modification date ascending",
                title: t("core.Modification date ascending"),
                value: {
                    field: ContentSortFields.modificationDate,
                    order: SortOrder.asc,
                },
            },
            {
                id: "Modification date descending",
                title: t("core.Modification date descending"),
                value: {
                    field: ContentSortFields.modificationDate,
                    order: SortOrder.desc,
                },
            },
        ];
    });

    return { sortOrderItems };
}
