<template>
    <PopupModal :show="showPopUp" :type="type" class="confirm-dialogue">
        <div v-if="title" class="dialogue-header flex-container">
            <i :class="`${icon} qss-custom-icon-standard`" />
            <h2>{{ trans(title) }}</h2>
        </div>
        <slot />
        <p v-if="message">{{ message }}</p>
        <p v-if="switchLabelText">
            <q-toggle
                v-model="switchValue"
                :label="trans(switchLabelText)"
                :color="type === 'primary' ? 'primary' : 'red-10'"
            />
        </p>
        <div class="buttons">
            <QitBtn
                data-qs="confirm-dialog-confirm-button"
                class="bg-destructive"
                :disabled="okButtonDisabledState"
                @click="_confirm"
                :label="trans(okButtonText)"
            />
            <QitBtn
                data-qs="confirm-dialog-cancel-button"
                color="secondary"
                @click="_cancel"
                :label="trans(cancelButtonText)"
            />
        </div>
    </PopupModal>
</template>

<script setup lang="ts">
import { computed, ref, shallowRef } from "vue";
import { DialogueOptions } from "./confirm-dialogue.model";
import PopupModal from "./popup-modal.vue";
import { keyText, TextObject, useTextObjectTranslation } from "@/shared/i18n/translation-types";
import QitBtn from "@/shared/components/buttons/qit-button.vue";

withDefaults(
    defineProps<{
        type?: "primary" | "danger";
    }>(),
    {
        type: "primary",
    }
);

const { trans } = useTextObjectTranslation();

const showPopUp = ref(false);
const title = shallowRef<TextObject | undefined>(undefined);
const message = ref("");
const okButtonText = shallowRef<TextObject>(keyText("core.Yes"));
const cancelButtonText = shallowRef<TextObject>(keyText("core.No"));
const switchLabelText = shallowRef<TextObject | undefined>(undefined);
const icon = ref("");

const resolvePromise = ref<((value: unknown) => void) | undefined>(undefined);
const rejectPromise = ref<((value: unknown) => void) | undefined>(undefined);

const switchValue = ref(false);

const okButtonDisabledState = computed((): boolean => {
    return switchLabelText.value ? !switchValue.value : false;
});

function show(opts: DialogueOptions = {}) {
    if (opts.title) {
        title.value = opts.title;
    }
    if (opts.message) {
        message.value = opts.message;
    }
    if (opts.okButtonText) {
        okButtonText.value = opts.okButtonText;
    }
    if (opts.cancelButtonText) {
        cancelButtonText.value = opts.cancelButtonText;
    }
    if (opts.switchLabelText) {
        switchLabelText.value = opts.switchLabelText;
    }
    if (opts.icon) {
        icon.value = opts.icon;
    }

    showPopUp.value = true;

    return new Promise((resolve, reject) => {
        resolvePromise.value = resolve;
        rejectPromise.value = reject;
    });
}

function _confirm() {
    showPopUp.value = false;

    if (switchLabelText.value) {
        if (switchValue.value) {
            resolvePromise.value ? resolvePromise.value(true) : () => {};
        }
    } else {
        resolvePromise.value ? resolvePromise.value(true) : () => {};
    }
    resetValues();
}

function _cancel() {
    showPopUp.value = false;

    resolvePromise.value ? resolvePromise.value(false) : () => {};
    resetValues();
    // Can throw an error
    // rejectPromise.value ? rejectPromise.value(new Error('User cancelled the dialogue') : () => {};
}

function resetValues() {
    title.value = undefined;
    message.value = "";
    okButtonText.value = keyText("core.Yes");
    cancelButtonText.value = keyText("core.No");
    switchLabelText.value = undefined;
    icon.value = "";
    switchValue.value = false;
}

defineExpose({ title, message, okButtonText, cancelButtonText, show });
</script>

<style lang="scss" scoped>
.confirm-dialogue {
    .dialogue-header {
        gap: $spacing-s;
        h2 {
            margin-top: 0;
            margin-bottom: 0;
            word-wrap: break-word;
            word-break: break-word;
        }
        i {
            display: flex;
            align-items: center;
            margin-top: $spacing-s;
        }
    }

    .buttons {
        display: flex;
        justify-content: flex-end;
        gap: $spacing-m;
    }

    :deep(p) {
        white-space: break-spaces;
    }
}
</style>
