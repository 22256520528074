import { computed, ComputedRef, onMounted, Ref, watch } from "vue";
import { useLazyQuery } from "@vue/apollo-composable";
import { useDataDisplayConfig } from "@/shared/data-display-config/composable/data-display-config";
import {
    InputMaybe,
    LanguageWithWildcard,
    AssetFilter,
} from "@/shared/services/graphql/generated/consumer-graph-types";
import { AdvAsset, advAsset } from "../../data-objects/advanced-asset";
import { createAssetsQuery } from "../../graphql/assets.query";
import { DataDisplayConfigId } from "@/shared/data-display-config/composable/data-display.model";

interface AssetsQueryParams {
    assetIds: string[];
    acceptedLanguages: LanguageWithWildcard[];
}

export function useAssetsResultWithDisplayConfig(params: Ref<AssetsQueryParams>) {
    const filter = computed<{ filter: InputMaybe<AssetFilter> } | undefined>(() => {
        return {
            filter: {
                ids: params.value.assetIds,
            },
        };
    });

    const {
        result: dataDisplayResult,
        loading: dataConfigLoading,
        error: dataError,
    } = useDataDisplayConfig(DataDisplayConfigId.asset);

    const loadAssets = () => {
        if (!dataDisplayResult.value) return;

        load(
            createAssetsQuery(
                dataDisplayResult.value.datafieldsFragment.datafields,
                dataDisplayResult.value.datafieldsFragment.localizationDatafields
            )
        );
    };

    onMounted(() => {
        loadAssets();
    });

    watch(dataDisplayResult, () => {
        loadAssets();
    });

    const {
        error: assetError,
        result,
        loading: assetLoading,
        load,
    } = useLazyQuery(
        createAssetsQuery({}, {}),
        computed(() => {
            return {
                ...filter.value,
                acceptedLanguages: params.value.acceptedLanguages,
            };
        })
    );

    const loading = computed(() => {
        return assetLoading.value || dataConfigLoading.value;
    });

    const error = computed(() => {
        return dataError.value || assetError.value;
    });

    const assets: ComputedRef<AdvAsset[]> = computed(() => {
        if (result.value?.assets?.assets) {
            return result.value.assets.assets.map((asset: any) => {
                return advAsset(asset.node as AdvAsset);
            });
        }
        return [];
    });

    return {
        error,
        loading,
        assets,
    };
}
