import { Aggregations, Facet } from "@/shared/facets/facets.model";

export const convertAggregationsResult = (facet: Facet, aggregations: any) => {
    let aggregationsForFacet;

    if (facet.type === "metadata") {
        if (aggregations?.composite) {
            aggregationsForFacet = aggregations?.composite[facet.id];
        } else if (aggregations?.useContext?.composite) {
            aggregationsForFacet = aggregations?.useContext?.composite[facet.id];
        } else if (aggregations?.terms) {
            aggregationsForFacet = aggregations?.terms[facet.id];
        } else if (aggregations?.useContext?.terms) {
            aggregationsForFacet = aggregations?.useContext?.terms[facet.id];
        }
    } else if (facet.type === "field") {
        aggregationsForFacet = aggregations[facet.id];
    }

    const aggregationsObject: Aggregations = {};

    if (aggregationsForFacet) {
        const aggregationsForFacetSelection: Record<string, number> = {};

        for (const value of Object.values(aggregationsForFacet)) {
            aggregationsForFacetSelection[(value as any).value] = (value as any).count;
        }

        aggregationsObject[facet.id] = aggregationsForFacetSelection;
        return aggregationsObject;
    }

    return aggregationsObject;
};
