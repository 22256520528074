import { computed } from "vue";
import { Facet } from "@/shared/facets/facets.model";
import { generateQueryFilterForSearch } from "@/shared/facets/graphql/content-filter-generator";
import gql from "graphql-tag";
import { consumerClient } from "@/shared/services/apollo-clients/consumer-client";
import { convertAggregationsResult } from "@/shared/aggregations/aggregations-resolver";
import { useHasAccess } from "@/shared/access-control/composables/use-has-access.ts";
import { AccessFeature } from "@/shared/access-control/access-control.ts";
import { AggregationParams } from "@/shared/aggregations/aggregations.types.ts";

export const useDocumentationsAggregations = ({
    selectedFacets,
    facet,
    searchFields,
    searchContext,
    searchPhrase,
}: AggregationParams) => {
    const { hasAccess: fuzzy } = useHasAccess({ featureID: AccessFeature.fuzzy_search });

    const aggregationVariables = computed(() => {
        //remove own facet from selected
        const selectedCopy = [...selectedFacets.value.filter((x) => x.referencedId !== facet.value?.referencedId)];

        const { contentFilter, assetFilter, productId } = generateQueryFilterForSearch(
            selectedCopy,
            fuzzy.value,
            {
                product: searchContext?.value.product,
                asset: searchContext?.value.asset,
            },
            searchFields?.value,
            searchPhrase?.value
        );

        return { contentFilter, assetFilter, productId };
    });

    const generateDynamicSearchContentsOnlyAggregationsQuery = (facet: Facet, keywordSearchValue: string = "") => {
        const keywordAggregation = facet.facetType == "keyword";
        const metaDataAggregation = facet.type === "metadata" && facet.facetType != "keyword";
        const fieldAggregation = facet.type === "field" && facet.facetType != "keyword";

        let aggregations = "";

        if (metaDataAggregation || fieldAggregation || keywordAggregation) {
            const more = `(after: "", first: 1000){
            count
            value
        }`;

            const keywordFilterMore = `(first: 100, prefix: "${keywordSearchValue}"){
            count
            value
        }`;

            let fieldQuery = "";
            if (fieldAggregation) {
                fieldQuery += facet.id;
                fieldQuery += more;
            }
            let metaDataQuery = "";

            if (metaDataAggregation) {
                metaDataQuery = "useContext { composite {";
                metaDataQuery += facet.id;
                metaDataQuery += more;

                metaDataQuery += "}}";
            }

            let keywordQuery = "";
            if (keywordAggregation) {
                keywordQuery = "useContext { terms {";
                keywordQuery += facet.id;
                keywordQuery += keywordFilterMore;
                keywordQuery += "}}";
            }

            aggregations = `{
            aggregations {
                ${fieldQuery}
                ${metaDataQuery}
                ${keywordQuery}
            } 
        }`;
        }
        if (searchContext?.value.asset && searchContext?.value.product) {
            return gql`query searchDocumentsForAssetWithProductOnlyAggregations($assetFilter: AssetFilter!, $contentFilter: ContentFilter!) {
            assets(filter: $assetFilter) {
                assets(first:1) {
                    cursor
                    node {
                        id
                        relatedContents(filter: $contentFilter) 
                         ${aggregations}
                        
                    }
                }
            }
        } `;
        }
        if (searchContext?.value.product) {
            return gql`
            query searchRelatedContentsProductOnlyAggregations($productId: String!, $contentFilter: ContentFilter!) {
                product(id: $productId) {
                    relatedContents(filter: $contentFilter)                   
                    ${aggregations}    
                }
            } `;
        }
        return gql`
        query searchContentsOnlyAggregations($contentFilter: ContentFilter) {
            contents(filter: $contentFilter)
                ${aggregations}
        }
    `;
    };

    const fetchAggregation = async (filterPhrase: string) => {
        if (!facet.value) return {};

        const aggregationResult = await consumerClient.query({
            query: generateDynamicSearchContentsOnlyAggregationsQuery(facet.value, filterPhrase),
            variables: aggregationVariables.value,
        });

        const convertedAggregations = convertAggregationsResult(
            facet.value,
            aggregationResult.data?.contents?.aggregations ??
                aggregationResult.data?.product?.relatedContents?.aggregations ??
                aggregationResult.data?.assets?.assets[0]?.node?.relatedContents?.aggregations
        );

        return convertedAggregations[facet.value.id];
    };

    return {
        fetchAggregation,
        aggregationVariables,
    };
};
