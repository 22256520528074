<template>
    <ListFilterRenderer
        v-model:selected="facetsSelected"
        :facet-collection-id="facetCollectionId"
        :is-mobile-view="isMobileView"
        :preselect="preselected"
        :fetch-aggregation="fetchAggregation"
        :initial-selected-sort-entry="initialSelectedSortEntry"
        :sort-order-entries="sortOrderEntries"
        :more-menu-entries="moreMenuEntries"
        :product="product"
        @on-sort-selection-changed="emitOnSortSelectionChanged"
        @on-open-filter="onOpenFilterHandler"
        :sort-save-key="sortSaveKey"
    />
</template>

<script setup lang="ts">
import { Facet, FacetSelected } from "../facets/facets.model";
import ListFilterRenderer from "@/shared/list-filter/list-filter-renderer.vue";
import { ref, toRefs, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { SortEntry, SortOrderEntry } from "@/shared/components/sort-dropdown/sort-dropdown.model";
import { MenuComponentEntry, MenuTextEntry } from "@/shared/components/menu/menu.model";
import { PRODUCT_REFERENCED_ID, facetValuesToUrlParam, urlParamToFacetValues } from "@/shared/facets/facet-utils";
import { isEqual } from "lodash";
import { SortSaveKey } from "@/shared/components/sort-dropdown/composables/sort-settings";

const props = defineProps<{
    facetCollectionId: string;
    sortSaveKey: SortSaveKey;
    fetchAggregation?: (searchPhrase: string) => Promise<Record<string, number>>;
    isMobileView?: boolean;
    product?: string;
    sortOrderEntries?: SortOrderEntry[];
    initialSelectedSortEntry?: SortEntry;
    moreMenuEntries?: (MenuTextEntry | MenuComponentEntry)[];
}>();

const emits = defineEmits<{
    "update:selected": [facetsSelected: FacetSelected[]];
    onOpenFilter: [facet: Facet];
    onSortSelectionChanged: [sortEntry: SortEntry];
}>();

const router = useRouter();
const route = useRoute();

const { product, moreMenuEntries } = toRefs(props);
const facetsSelected = ref<FacetSelected[]>([]);
const preselected = ref<Record<string, string[]>>({});

watch(
    facetsSelected,
    (value, oldValue) => {
        if (oldValue.length > 0 && value.length === 0) resetSelection(oldValue);
        else selectionChanged(facetsSelected.value);
    },
    { deep: true }
);

const onHistoryChange = () => {
    const urlParams = new URLSearchParams(window.location.search);
    let result: Record<string, string[]> = {};

    urlParams.forEach((val, key) => {
        //don`t add search tab param as selected facets
        //refactoring necessary handle search tab as route param
        if (key !== "tab") result[key] = urlParamToFacetValues(val);
    });
    if (!isEqual(preselected.value, result)) preselected.value = result;
};

watch(
    () => route,
    () => {
        onHistoryChange();
    },
    { immediate: true, deep: true }
);

const onOpenFilterHandler = (facet: Facet) => {
    emits("onOpenFilter", facet);
};

const selectionChanged = (newFacetsSelected: FacetSelected[]) => {
    const newSelected: Record<string, string[]> = {};
    const urlParams = new URLSearchParams(window.location.search);

    const updatedSelected = product?.value
        ? newFacetsSelected.filter((x) => x.referencedId !== PRODUCT_REFERENCED_ID)
        : newFacetsSelected;

    updatedSelected.forEach((x) => {
        if (x.values.length < 1) {
            urlParams.delete(x.id);
        } else {
            urlParams.set(x.id, facetValuesToUrlParam(x.values));
            newSelected[x.id] = x.values;
        }
    });

    emits("update:selected", updatedSelected);
    updateHistory(urlParams);
};

const resetSelection = (facetsToReset: FacetSelected[]) => {
    const urlParams = new URLSearchParams(window.location.search);

    facetsToReset.forEach((x) => {
        urlParams.delete(x.referencedId);
    });

    updateHistory(urlParams);
    emits("update:selected", []);
};

const updateHistory = (urlParams: URLSearchParams) => {
    let route = window.location.pathname;

    if (urlParams.toString() !== "") {
        route = `${route}?${urlParams.toString()}`;
    }

    if (`${window.location.pathname}${window.location.search}` !== route) {
        router.push(route);
    }
};

const emitOnSortSelectionChanged = (sortEntry: SortEntry) => {
    emits("onSortSelectionChanged", sortEntry);
};
</script>
