import { Ability, NavigationMenuEntry, Route, RouteElementWithParam } from "@/shared/environment/ability.types";
import { CommonRouteAliases } from "@/shared/environment/common-route-aliases";
import { keyText } from "@/shared/i18n/translation-types";
import { AccessFeature } from "@/shared/access-control/access-control";
import { createRouteFromRouteElements } from "@/shared/environment/ability-route-helper";

export const DASHBOARD_ALIAS = "dashboard";
export const DASHBOARD_PATH = DASHBOARD_ALIAS;

export class DashboardAbility implements Ability {
    alias = DASHBOARD_ALIAS;

    getNavigationMenuEntries = (): Array<NavigationMenuEntry> => [
        {
            icon: "fa-regular fa-house-blank",
            title: keyText("dashboard.Home"),
            internalRoute: {
                alias: CommonRouteAliases.dashboard,
            },
        },
    ];

    getRoutes = (): Array<Route> => [
        {
            alias: CommonRouteAliases.dashboard,
            params: [],
            getComponent: () => import("@/abilities/dashboard/pages/dashboard-main.vue"),
            isRoot: true,
            getBreadCrumbs: (
                parentElements: Array<RouteElementWithParam>,
                currentElement: RouteElementWithParam,
                translateMethod: (text: string) => string
            ) => {
                return [
                    {
                        content: {
                            simpleText: translateMethod("dashboard.Home"),
                        },
                        getRouterTo: () => createRouteFromRouteElements(parentElements, currentElement),
                    },
                ];
            },
        },
    ];

    access = {
        accessFeature: AccessFeature.dashboard,
    };
}
