<template>
    <div class="need-qualification-wizard-tabbed-level fit column">
        <q-tabs v-model="selectedIndex" active-color="primary" indicator-color="primary" :no-caps="true">
            <q-tab v-for="tab in tabItems" :name="tab.index" :label="tab.text" :key="tab.text" :ripple="false" />
        </q-tabs>
        <component
            v-if="currentTabContent"
            class="tab-content col fit"
            :is="currentTabContentComponent"
            v-bind="currentTabContentProps"
            :selection-data="selectionData"
            :is-last-level="isLastLevel"
            :emit-last-level-selection="emitLastLevelSelection"
            @select="newSelection"
            @back="goBack"
            @link-clicked="linkClicked"
        />
    </div>
</template>

<script setup lang="ts">
import { LevelComponent } from "./need-qualification-wizard.model";
import { computed, ref, unref, watch, toRefs } from "vue";
import { QualificationLevelSelectionResult } from "@/shared/environment/ability.types";

interface TabElement {
    index: number;
    text: string;
}

const props = defineProps<{
    levelComponents: LevelComponent[];
    selectionData: QualificationLevelSelectionResult;
    isLastLevel: boolean;
    hasLevelTitle: boolean;
    emitLastLevelSelection?: boolean;
}>();

const { levelComponents, hasLevelTitle } = toRefs(props);

const selectedIndex = ref<number>(0);

const tabItems = computed(() => {
    const result: TabElement[] = [];

    levelComponents.value.forEach((element) => {
        result.push({
            text: unref(element.title),
            index: result.length,
        });
    });

    return result;
});

watch(tabItems, async () => {
    selectedIndex.value = 0;
});

const currentTabContent = computed(() => {
    if (levelComponents.value[selectedIndex.value]) {
        return levelComponents.value[selectedIndex.value].componentDefinition;
    } else {
        return undefined;
    }
});

const currentTabContentComponent = computed(() => {
    if (currentTabContent.value) {
        return currentTabContent.value.getComponent();
    } else {
        return undefined;
    }
});

const currentTabContentProps = computed(() => {
    if (currentTabContent.value) {
        return {
            ...currentTabContent.value.props,
            ...{ multiLevel: true, hasLevelTitle: hasLevelTitle.value },
        };
    } else {
        return undefined;
    }
});

const emit = defineEmits<{
    (e: "select", selectionResult: QualificationLevelSelectionResult): void;
    (e: "back"): void;
    (e: "linkClicked"): void;
}>();

const newSelection = (newSelection: QualificationLevelSelectionResult) => {
    emit("select", newSelection);
};

const goBack = () => {
    emit("back");
};

const linkClicked = () => {
    emit("linkClicked");
};
</script>

<style lang="scss" scoped>
.tabs-list {
    justify-content: space-around;
}

.tab-content {
    position: relative;
    &.something-went-wrong {
        margin-top: $spacing-xxl !important;
    }
}
.tab-content:not(.something-went-wrong) {
    background: $light-background-color;
}
</style>

<style lang="scss">
.need-qualification-wizard-tabbed-level {
    background-color: $white;
}
</style>
