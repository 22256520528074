import {
    Ability,
    DataProcessType,
    NavigationMenuEntry,
    Route,
    RouteElementWithParam,
} from "@/shared/environment/ability.types";
import { createRouteFromRouteElements } from "@/shared/environment/ability-route-helper";
import FileImportWizard from "../pages/components/data-process/file/file-import-wizard.vue";
import { ProcessType } from "@/shared/services/graphql/generated/consumer-graph-types";
import { AccessAction, AccessResource } from "@/shared/access-control/access-control";
import { keyText } from "@/shared/i18n/translation-types";

export const DATA_PROCESS_ALIAS = "data-process";

export class DataProcessAbility implements Ability {
    alias = DATA_PROCESS_ALIAS;

    getNavigationMenuEntries = (): Array<NavigationMenuEntry> => [
        {
            icon: "fa-regular fa-cloud-arrow-up",
            title: keyText("data-process.Imports"),
            internalRoute: {
                alias: DATA_PROCESS_ALIAS,
            },
        },
    ];

    getRoutes = (): Array<Route> => [
        {
            alias: DATA_PROCESS_ALIAS,
            params: [],
            isRoot: true,
            getComponent: () => import("../pages/data-process-main.vue"),
            access: {
                accessAction: AccessAction.write,
                accessResource: AccessResource.data_ingest_data,
            },
            getBreadCrumbs: (
                parentElements: Array<RouteElementWithParam>,
                currentElement: RouteElementWithParam,
                translateMethod: (text: string) => string
            ) => {
                return [
                    {
                        content: {
                            simpleText: translateMethod("data-process.Data processes"),
                        },
                        getRouterTo: () => createRouteFromRouteElements(parentElements, currentElement),
                    },
                ];
            },
        },
    ];
    getDataProcessTypes = (): Array<DataProcessType> => [
        {
            type: ProcessType.file,
            tileSettings: {
                title: "data-process.Files",
                text: "data-process.Configure an import for additional files, like graphics",
                icon: "fa-solid fa-image file-color",
                cssClass: "file-process-tile",
            },
            dialogFormComponent: {
                getComponent: () => FileImportWizard,
                props: {
                    title: "data-process.Create New Process (Files)",
                },
            },
        },
    ];
}
