import { InMemoryCache } from "@apollo/client/core";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function offsetFromCursor(items: any, cursor: string, _readField: any) {
    // Search from the back of the list because the cursor we're
    // looking for is typically the ID of the last item.
    for (let i = items.length - 1; i >= 0; --i) {
        const item = items[i];
        // Using readField works for both non-normalized objects
        // (returning item.id) and normalized references (returning
        // the id field from the referenced entity object), so it's
        // a good idea to use readField when you're not sure what
        // kind of elements you're dealing with.
        if (item.cursor === cursor) {
            // Add one because the cursor identifies the item just
            // before the first item in the page we care about.
            return i + 1;
        }
    }
    // Report that the cursor could not be found.
    return -1;
}

function merge(existing: any, incoming: any, args: any) {
    const merged = existing?.length ? existing.slice(0) : [];
    let offset = offsetFromCursor(merged, args?.args?.after, args.readField);
    // If we couldn't find the cursor, we check for after variable
    // if exists appending incoming data to the end of the list
    // else return incoming data
    if (offset < 0) {
        if (!args?.variables?.after) return incoming;
        else offset = merged.length;
    }

    for (let i = 0; i < incoming.length; ++i) {
        merged[offset + i] = incoming[i];
    }
    return merged;
}

export const clientCache = new InMemoryCache({
    typePolicies: {
        Query: {
            fields: {
                theme: {
                    keyArgs: false,
                    merge,
                },
                contents: {
                    keyArgs: false,
                    merge: true,
                },
                facetsCollection: {
                    keyArgs: false,
                    merge,
                },
                tenant: {
                    keyArgs: false,
                    merge: true,
                },
            },
        },
        AccessFilterQueryResult: {
            fields: {
                accessFilters: {
                    keyArgs: false,
                    merge,
                },
            },
        },
        ContentMapQueryResult: {
            fields: {
                matchedFacets: {
                    keyArgs: false,
                    merge,
                },
            },
        },
        NoteQueryResult: {
            fields: {
                notes: {
                    keyArgs: false,
                    merge,
                },
            },
        },
        NotificationQueryResult: {
            fields: {
                notifications: {
                    keyArgs: false,
                    merge,
                },
            },
        },
        UserQueryResult: {
            fields: {
                users: {
                    keyArgs: false,
                    merge,
                },
            },
        },
        WebhookConfigQueryResult: {
            fields: {
                webhookConfigs: {
                    keyArgs: false,
                    merge,
                },
            },
        },
        TenantQueryResult: {
            fields: {
                tenants: {
                    keyArgs: false,
                    merge,
                },
            },
        },
        ContentQueryResult: {
            fields: {
                contents: {
                    keyArgs: false,
                    merge,
                },
            },
        },
        MetadataDefinitionQueryResult: {
            fields: {
                metadataDefinitions: {
                    keyArgs: false,
                    merge,
                },
            },
        },
        DateTimeRangeMetadataDefinition: {
            fields: {
                localizations: {
                    keyArgs: false,
                    merge: true,
                },
            },
        },
        NumberRangeMetadataDefinition: {
            fields: {
                localizations: {
                    keyArgs: false,
                    merge: true,
                },
            },
        },
        KeywordMetadataDefinition: {
            fields: {
                localizations: {
                    keyArgs: false,
                    merge: true,
                },
            },
        },
        StaticPageQueryResult: {
            fields: {
                staticPages: {
                    keyArgs: false,
                    merge,
                },
            },
        },
        ProductQueryResult: {
            fields: {
                products: {
                    keyArgs: false,
                    merge,
                },
                localizations: {
                    keyArgs: false,
                    merge: true,
                },
            },
        },
        Product: {
            fields: {
                localizations: {
                    keyArgs: false,
                    merge: true,
                },
            },
        },
        NumberMetadataDefinition: {
            fields: {
                localizations: {
                    keyArgs: false,
                    merge: true,
                },
            },
        },
        DateTimeMetadataDefinition: {
            fields: {
                localizations: {
                    keyArgs: false,
                    merge: true,
                },
            },
        },
        TaxonomyMetadataDefinition: {
            fields: {
                localizations: {
                    keyArgs: false,
                    merge: true,
                },
            },
        },
        TextMetadataDefinition: {
            fields: {
                localizations: {
                    keyArgs: false,
                    merge: true,
                },
            },
        },
        Asset: {
            fields: {
                localizations: {
                    keyArgs: false,
                    merge: true,
                },
            },
        },
        MechanicQueryResult: {
            fields: {
                mechanics: {
                    keyArgs: false,
                    merge,
                },
            },
        },
        Mechanic: {
            fields: {
                localizations: {
                    keyArgs: false,
                    merge: true,
                },
            },
        },
        Documentation: {
            fields: {
                localizations: {
                    keyArgs: false,
                    merge: true,
                },
            },
        },
        Article: {
            fields: {
                localizations: {
                    keyArgs: false,
                    merge: true,
                },
            },
        },
        EnumMetadataDefinition: {
            fields: {
                localizations: {
                    keyArgs: false,
                    merge: true,
                },
            },
        },
        ArticleQueryResult: {
            fields: {
                articles: {
                    keyArgs: false,
                    merge,
                },
            },
        },
        FieldFacet: {
            fields: {
                definition: {
                    keyArgs: false,
                    merge: true,
                },
            },
        },
        MetadataFacet: {
            fields: {
                definition: {
                    keyArgs: false,
                    merge: true,
                },
            },
        },
        Topic: {
            fields: {
                teasers: {
                    keyArgs: false,
                    merge: true,
                },
            },
        },
        TocResult: {
            fields: {
                tocEntries: {
                    keyArgs: ["path"],
                    merge,
                },
            },
        },
        AssetQueryResult: {
            fields: {
                assets: {
                    keyArgs: ["id"],
                    merge,
                },
            },
        },
        CollectionEntryQueryResult: {
            fields: {
                entries: {
                    keyArgs: ["cursor"],
                    merge,
                },
            },
        },
        CollectionQueryResult: {
            fields: {
                collections: {
                    keyArgs: false,
                    merge,
                },
            },
        },
        ActivityQueryResult: {
            fields: {
                activities: {
                    keyArgs: false,
                    merge,
                },
            },
        },
    },
});
