import { Ability, NavigationMenuEntry, Route, Widget } from "@/shared/environment/ability.types";
import { CommonRouteAliases } from "@/shared/environment/common-route-aliases.ts";
import { keyText } from "@/shared/i18n/translation-types.ts";
import AiAssistentContextWidget from "../widgets/ai-assistent-context.widget.vue";
import { AccessFeature } from "@/shared/access-control/access-control.ts";

export const AI_ASSISTANT_ALIAS = "ai-assistant";

export class AiAssistantAbility implements Ability {
    alias = AI_ASSISTANT_ALIAS;
    access = {
        accessFeature: AccessFeature.rag_gui,
    };
    getNavigationMenuEntries = (): Array<NavigationMenuEntry> => [
        {
            title: keyText("ai-assistant.AI Assistant"),
            icon: "fak fa-regular fa-qit-ai-assistant-regular",
            internalRoute: {
                alias: CommonRouteAliases.aiAssistant,
            },
        },
    ];

    getRoutes = (): Array<Route> => [
        {
            alias: CommonRouteAliases.aiAssistant,
            params: ["product?", "asset?"],
            getComponent: () => import("../pages/ai-assistant.vue"),
            isRoot: true,
        },
    ];

    getWidgets = (): Array<Widget> => [
        {
            widgetContextAlias: ["product", "asset"],
            widgetComponent: AiAssistentContextWidget,
        },
    ];
}
