<template>
    <div class="form-container">
        <div class="form-row">
            <div class="row">
                <div class="col">
                    {{ $t("data-process.Process name") }}
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <q-input
                        v-if="formFields"
                        outlined
                        v-model="formFields.name.value"
                        :dense="true"
                        :rules="[() => !!formFields?.name.valid]"
                        data-qs="file-import-process-name-textbox"
                        @focus="validateProcessName"
                        @blur="validateProcessName"
                    />
                    <div class="message-wrapper" v-if="formFields.name.messageKey">
                        <span class="message" :class="{ 'error-message': !formFields.name.valid }">{{
                            $t(formFields.name.messageKey)
                        }}</span>
                    </div>
                </div>
                <div class="col">
                    <div class="description-field" :class="{ 'description-field-mobile': isXSmall }">
                        {{ $t("data-process.Enter a name for the process") }}
                    </div>
                </div>
            </div>
        </div>
        <div class="form-row">
            <div class="row">
                <div class="col">
                    {{ $t("data-process.Name of the ZIP file") }}
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <q-input
                        v-if="formFields"
                        outlined
                        v-model="formFields.fileNameScheme.value"
                        :dense="true"
                        :rules="[() => formFields.fileNameScheme.valid]"
                        data-qs="file-import-file-name-textbox"
                        @focus="validateFileNameScheme"
                        @blur="validateFileNameScheme"
                    />
                    <div class="message-wrapper" v-if="formFields.fileNameScheme.messageKey">
                        <span class="message" :class="{ 'error-message': !formFields.fileNameScheme.valid }">{{
                            $t(formFields.fileNameScheme.messageKey)
                        }}</span>
                    </div>
                </div>
                <div class="col">
                    <div class="description-field" :class="{ 'description-field-mobile': isXSmall }">
                        {{ $t("data-process.Specify how the ZIP file containing the files is named") }}
                    </div>
                </div>
            </div>
        </div>
        <div class="form-row">
            <div class="row">
                <div class="col">
                    {{ $t("data-process.Mapping via") }}
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <div class="sortable-container">
                        <template v-for="(element, index) in formFields.mappingOptions.value" :key="element.type">
                            <div
                                class="row items-center sortable-item q-ma-xs"
                                draggable="true"
                                @dragstart="onDragStart(index)"
                                @dragover="onDragOver(index)"
                                @drop="onDrop"
                            >
                                <q-checkbox
                                    :class="{ 'form-field-not-valid': !formFields.mappingOptions.valid }"
                                    v-model="element.value"
                                    @update:model-value="validateMappingVia"
                                    :label="t(element.title)"
                                />
                                <q-input
                                    outlined
                                    dense
                                    hide-bottom-space
                                    v-if="element.type === 'FILENAME_UP_TO_CHAR'"
                                    v-model="element.upToChar"
                                    @update:model-value="validateUpToChar"
                                    class="q-ml-sm option-text-box"
                                    input-class="option-text-input"
                                    maxlength="1"
                                    :error="!formFields.upToChar.valid"
                                />
                                <i class="option-handle fa-regular fa-grip-dots-vertical grey-60-color" />
                            </div>
                        </template>
                    </div>
                    <q-checkbox
                        :class="{ 'form-field-not-valid': !formFields.mappingOptions.valid }"
                        v-model="mappingViaAll"
                        @update:model-value="
                            toggleMappingViaAll();
                            validateMappingVia();
                        "
                        :label="t('data-process.All')"
                    />
                    <div class="message-wrapper" v-if="formFields.mappingOptions.messageKey">
                        <span class="message" :class="{ 'error-message': !formFields.mappingOptions.valid }">{{
                            $t(formFields.mappingOptions.messageKey)
                        }}</span>
                    </div>
                    <div class="message-wrapper" v-if="formFields.upToChar.messageKey">
                        <span class="message" :class="{ 'error-message': !formFields.upToChar.valid }">{{
                            $t(formFields.upToChar.messageKey)
                        }}</span>
                    </div>
                </div>
                <div class="col">
                    <div class="description-field" :class="{ 'description-field-mobile': isXSmall }">
                        <span
                            >{{
                                $t(
                                    "data-process.Select which criterion is used to assign an imported file to the corresponding product/asset"
                                )
                            }}
                        </span>
                        <span>
                            {{ $t("data-process.The criteria are prioritized in the order given") }}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { onMounted, ref, Ref, watch } from "vue";
import { FileImportFormModel, useFileImportFormValidation } from "./file-import-form.model";
import { useI18n } from "vue-i18n";
import { useScreenSize } from "@/shared/screen/composables/screen-size";

const props = withDefaults(
    defineProps<{
        /**
         * File import model as two way binded prop.
         */
        modelValue: FileImportFormModel;
        /**
         * When editing the process, an Id should be available from this prop, which prevents
         * invalidating the input name when the process already has a name and only makes a CRUD change.
         * @example '_LYfvjuMw85qP9'
         */
        entryId?: string;
    }>(),
    {
        entryId: "",
    }
);

/**
 *  @onFormValidation Form valid or not valid
 */
const emit = defineEmits(["onFormValidation", "update:modelValue"]);

const { t } = useI18n();
const { isXSmall } = useScreenSize();

const formFields: Ref<FileImportFormModel> = ref(props.modelValue);

const { mappingViaAll, validateProcessName, validateFileNameScheme, validateMappingVia, validateUpToChar } =
    useFileImportFormValidation(formFields, props.entryId);

onMounted(() => {
    validateMappingVia();
});

/**
 * Validate Form initially
 */
const formValid = ref(isFormValid());
setFormValid();

/**
 * Evaluation if overall form is valid
 */
function isFormValid(): boolean {
    validateProcessName();
    return Object.values(formFields.value).every((field) => field.valid);
}

/**
 * If the formFields change, the overall form has to be validated
 */
watch(formFields.value, () => {
    setFormValid();
});

/**
 * Form validation and emit of onFormValidation
 */
function setFormValid() {
    formValid.value = isFormValid();
    emit("onFormValidation", formValid.value);
}

/**
 * If mapping options change, mappingViaAll has to be aligned and upToChar has to be reevaluated
 */
watch(formFields.value.mappingOptions, () => {
    validateUpToChar();
    mappingViaAll.value = formFields.value.mappingOptions.value.every((option) => option.value);
});

/**
 * If mappingViaAll is true, all mapping options must be set to true (checked).
 */
function toggleMappingViaAll() {
    formFields.value.mappingOptions.value.forEach((mappingOption) => (mappingOption.value = mappingViaAll.value));
}

const draggedIndex = ref<number | null>(null);

const onDragStart = (index: number) => {
    draggedIndex.value = index;
};

const onDragOver = (hoverIndex: number) => {
    if (draggedIndex.value !== null && draggedIndex.value !== hoverIndex) {
        const temp = formFields.value.mappingOptions.value[draggedIndex.value];
        formFields.value.mappingOptions.value.splice(draggedIndex.value, 1);
        formFields.value.mappingOptions.value.splice(hoverIndex, 0, temp);
        draggedIndex.value = hoverIndex;
    }
};

const onDrop = () => {
    draggedIndex.value = null;
};
</script>

<style lang="scss" scoped>
.sortable-container {
    & > .sortable-item {
        cursor: grab;
        border: 1px solid #d9d9d9;
        border-radius: $default-border-radius;
    }

    .form-field-not-valid {
        &:deep(.q-checkbox__inner--falsy) {
            color: $error-color;
        }
    }
}

.form-container {
    padding: $spacing-xl;
    display: flex;
    flex-direction: column;
    gap: $spacing-xxl;
}

.form-row {
    .form-label {
        margin-bottom: $spacing-s;
        font-weight: $semi-bold;
    }

    .description-field {
        color: $light-text-color;
        margin-left: $spacing-xxl;

        &.description-field-mobile {
            margin-left: 0;
        }

        span:last-child {
            margin-top: $spacing-l;
        }
    }

    .message-wrapper {
        display: flex;
        margin-top: $spacing-s;

        .message {
            padding: $spacing-s;
            &.error-message {
                color: $error-color;
            }
        }
    }
}

.option-handle {
    padding: $spacing-m;
    margin-left: auto;
}

.option-text-box {
    max-width: 55px;
    :deep(.q-field__control) {
        height: 34px;
    }
}
</style>
