<template>
    <div v-for="(item, i) in userMenuItems" :key="i">
        <component
            v-if="!('text' in item) && (item.visible ?? true)"
            :is="item.component.getComponent()"
            v-bind="item.component.props"
        />
    </div>
    <component v-if="dialogComponent" :is="dialogComponent.getComponent()" v-bind="dialogComponent.props" />
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { MenuEntry } from "@/shared/components/menu/menu.model";
import { useMenuItems } from "@/shell/composables/menu-items";
import { useScreenSize } from "@/shared/screen/composables/screen-size";
import { ComponentDefinition } from "@/shared/components/component-definition.model";
import { useMenuEntriesFromAbilities } from "@/shared/environment/menu/menu-entries-from-abilities";
const menuItems = useMenuItems();
const { isXSmall } = useScreenSize();
const dialogComponent = ref<ComponentDefinition | undefined>(undefined);

const { menuEntries: toolbarEntriesFromAbility } = useMenuEntriesFromAbilities(
    computed(() => {
        return {
            __type: "toolbarMenuDetail",
            callerContext: {
                notifyDone: () => {
                    dialogComponent.value = undefined;
                },
                showDialogComponent: (component: ComponentDefinition) => {
                    dialogComponent.value = component;
                },
            },
        };
    })
);

const userMenuItems = computed<MenuEntry[]>(() => {
    if (isXSmall.value) {
        return [];
    }
    return [...menuItems.value, ...toolbarEntriesFromAbility.value];
});
</script>

<style scoped lang="scss"></style>
