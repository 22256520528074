import { useI18n } from "vue-i18n";
import CommonStep from "./steps/common-step.vue";
import CommonStepInformation from "./steps/common-step.information.vue";
import AssignStep from "@/shared/components/data-process/assign-step.vue";
import AssignStepInformation from "./steps/assign-step.information.vue";
import ConfigurationWizardFooter from "@/shared/components/data-process/configuration-wizard-footer.vue";
import { reactive } from "vue";
import { abilityAlias } from "../../../../config/product-and-asset.ability";
import { CommonStepModel, WizardStep } from "@/shared/components/data-process/form-types";
import { ProcessType } from "@/shared/services/graphql/generated/consumer-graph-types";
import { DEFAULT_DELIMITER, DEFAULT_ENCODING, DEFAULT_ESCAPE } from "@/shared/components/data-process/form-constants";

export interface AssignStepModel {}

export function useWizardSteps() {
    const { t } = useI18n({ useScope: "global" });

    const commonStepFormModel = reactive<CommonStepModel>({
        name: {
            value: t("product-and-asset.Asset options"),
            valid: true,
            messageKey: "",
        },
        headers: {
            value: [],
            valid: false,
            messageKey: "",
        },
        file: {
            name: "",
            value: [],
            valid: true,
            messageKey: "",
        },
        delimiter: {
            value: DEFAULT_DELIMITER,
            valid: true,
            messageKey: "",
        },
        escape: {
            value: DEFAULT_ESCAPE,
            valid: true,
            messageKey: "",
        },
        encoding: {
            value: DEFAULT_ENCODING,
            valid: true,
            messageKey: "",
        },
    });

    const assignStepFormModel = reactive<any>({
        // TODO: wenn validation für assign step relevant ist hier rein
    });

    const wizardSteps: WizardStep<CommonStepModel & AssignStepModel>[] = [
        {
            id: "commonStep",
            name: `${abilityAlias}.General`,
            active: true,
            valid: false,
            model: commonStepFormModel,
            formComponentDefinition: {
                getComponent: () => CommonStep,
            },
            footerComponentDefinition: {
                getComponent: () => ConfigurationWizardFooter,
                props: {
                    processType: "asset-option",
                    showBackButton: false,
                },
            },
            informationComponentDefinition: {
                getComponent: () => CommonStepInformation,
            },
        },
        {
            id: "assignStep",
            name: `${abilityAlias}.Assign`,
            active: false,
            valid: true,
            model: assignStepFormModel,
            formComponentDefinition: {
                getComponent: () => AssignStep,
                props: {
                    processType: ProcessType.assetOption,
                    showOnlySystemFields: true,
                },
            },
            footerComponentDefinition: {
                getComponent: () => ConfigurationWizardFooter,
                props: {
                    continueText: "core.Create Process",
                    showBackButton: true,
                    processType: "asset-option",
                },
            },
            informationComponentDefinition: {
                getComponent: () => AssignStepInformation,
            },
        },
    ];

    return {
        wizardSteps,
    };
}
