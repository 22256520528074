<template>
    <q-dialog v-model="visible" :maximized="isXSmall" no-route-dismiss no-backdrop-dismiss no-shake>
        <q-card class="dialog-card">
            <div class="q-mx-md q-mt-md">
                <slot name="header" />
                <div v-if="!$slots.header" class="row no-wrap align-center">
                    <div class="flex-grow text-weight-bold text-h6">
                        {{ title }}
                    </div>
                    <div>
                        <q-btn
                            round
                            size="sm"
                            icon="fa fa-solid fa-xmark icon-color"
                            @click="() => emits('onClose')"
                            data-qs="dialog-close-btn"
                        />
                    </div>
                </div>
            </div>
            <q-card-section>
                <slot name="content" />
            </q-card-section>
        </q-card>
    </q-dialog>
</template>

<script setup lang="ts">
import { useScreenSize } from "@/shared/screen/composables/screen-size.ts";
import { toRefs } from "vue";

const props = defineProps<{ visible: boolean; title?: string }>();
const emits = defineEmits<{ onClose: [] }>();

const { visible } = toRefs(props);
const { isXSmall } = useScreenSize();
</script>

<style scoped>
.dialog-card {
    .screen--md &,
    .screen--xl &,
    .screen--lg & {
        min-width: 500px;
    }
}
</style>
