import { FieldDefinitionTypes, FullTextSearchType, SearchField } from "@/shared/search/search.types";
import { ParseNode } from "../lucene-query-parser/lucene-query-parser-types";
import { parseQuery } from "../lucene-query-parser/lucene-query-parser-wrapper";

const replaceToken = "_#--!§";

export const generateSearchFieldsFilterClause = (searchPhrase: string, entries: SearchField[], fuzzy: boolean) => {
    if (!searchPhrase) return { orGroup: undefined };

    const textEntries =
        entries?.filter((x) => x.fieldType === FieldDefinitionTypes.text).map((x) => x.referencedId) ?? [];
    const numberEntries =
        entries?.filter((x) => x.fieldType === FieldDefinitionTypes.number).map((x) => x.referencedId) ?? [];
    const enumEntries =
        entries?.filter((x) => x.fieldType === FieldDefinitionTypes.enum).map((x) => x.referencedId) ?? [];
    const keywordEntries =
        entries?.filter((x) => x.fieldType === FieldDefinitionTypes.keyword).map((x) => x.referencedId) ?? [];
    const searchPhraseIsNumber = !isNaN(Number(searchPhrase));

    let fullTextFields: Record<string, number> = {};

    //fulltext
    if (textEntries.length > 0) {
        for (const textEntry of textEntries) {
            fullTextFields[textEntry] = 1;
        }
    } else {
        //default
        fullTextFields = { text: 1, title: 1 };
    }

    const fullTextQuery = fuzzy
        ? createBooleanFullTextQuery(searchPhrase, fullTextFields, true, undefined)
        : createBooleanFullTextQuery(searchPhrase, fullTextFields, false, FullTextSearchType.prefix);

    const orGroup: any[] = [];
    orGroup.push(fullTextQuery);

    //numbers
    if (searchPhraseIsNumber) {
        const searchPhraseAsNumber = Number(searchPhrase);
        for (const numberEntry of numberEntries) {
            const equals: any = {};
            equals[numberEntry] = searchPhraseAsNumber;
            orGroup.push({ equals });
        }
    }

    //keywords and enums
    for (const keywordEntry of [...keywordEntries, ...enumEntries]) {
        const equals: any = {};
        equals[keywordEntry] = searchPhrase;
        orGroup.push({ equals });
    }

    return { orGroup };
};

export const createBooleanFullTextQuery = (
    search: string,
    contentFullTextFields: Record<string, number>,
    fuzzy: boolean,
    fullTextSearchType: FullTextSearchType | undefined
) => {
    if (fullTextSearchType && fuzzy) throw new Error("Fuzzy can't be used with fullTextSearchType");

    try {
        const _search = escape(search);
        const parseTree = parseQuery(_search);

        //single term
        if (parseTree.left && !parseTree.right) {
            let term = parseTree.left.term;
            let leftTree = parseTree.left;
            while (!term && leftTree.left) {
                term = leftTree.left.term;
                leftTree = leftTree.left;
            }
            if (term === undefined) term = "";
            const _term = unescape(term);
            return {
                fullText: {
                    query: _term,
                    searchType: fullTextSearchType,
                    fields: contentFullTextFields,
                    fuzzy,
                },
            };
        }

        const currentTerms: ParseNode[] = [];

        //pre-order
        const buildFilterFromParseTree = (node: ParseNode) => {
            if (!node) return;

            currentTerms.push(node);

            buildFilterFromParseTree(node.left);
            buildFilterFromParseTree(node.right);
        };

        buildFilterFromParseTree(parseTree);

        currentTerms.reverse();
        const queryParts: any = [];
        const stackOfTerms: string[] = [];
        for (const part of currentTerms) {
            if (part.operator) {
                const terms: any[] = [];

                let i = 0;
                let term;
                while (i < 2 && (term = stackOfTerms.pop())) {
                    terms.push({
                        fullText: {
                            query: term,
                            fields: contentFullTextFields,
                            searchType: fullTextSearchType,
                            fuzzy,
                        },
                    });
                    i++;
                }

                let queryPart;
                while ((queryPart = queryParts.pop())) {
                    terms.push(queryPart);
                }

                let groupPart;
                if (part.operator === "AND" || part.operator === "<implicit>") {
                    groupPart = { andGroup: terms };
                } else if (part.operator === "OR") {
                    groupPart = { orGroup: terms };
                } else if (part.operator.endsWith("NOT")) {
                    /*  parse tree is wrong
                     *
                     *  query: a NOT b
                     *
                     *  is:   NOT      should be:     a
                     *       /   \                     \
                     *      a     b                    NOT
                     *                                   \
                     *                                    b
                     */

                    //pop term and put in NOT clause
                    const NOT_Term = terms.pop();
                    const NOT_Clause = {
                        not: NOT_Term,
                    };

                    terms.push(NOT_Clause);

                    //Push rest in AND or OR clause
                    if (part.operator === "NOT" || part.operator === "AND NOT") {
                        groupPart = { andGroup: terms };
                    } else {
                        groupPart = { orGroup: terms };
                    }
                }

                queryParts.push(groupPart);
            } else if (part.term) {
                const _term = unescape(part.term);
                stackOfTerms.push(_term);
            }
        }
        return queryParts[0];
    } catch (e: any) {
        throw new Error(e.message);
    }
};

const unescape = (word: string) => {
    return word.replaceAll(replaceToken, " ").trim();
};

const escape = (word: string) => {
    let escapedWord = word.replace(/\s/g, replaceToken);
    escapedWord = escapedWord.replace(/NOT/g, " NOT ");
    escapedWord = escapedWord.replace(/AND/g, " AND ");
    escapedWord = escapedWord.replace(/OR/g, " OR ");
    escapedWord = escapedWord.replace(/AND _#--!§ NOT/g, "AND NOT");
    escapedWord = escapedWord.replace(/OR _#--!§ NOT/g, "OR NOT");
    return escapedWord;
};
