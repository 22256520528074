<template>
    <div class="fit">
        <MobileView v-if="isMobileView">
            <template #panel>
                <div class="row fit flex-grow items-center">
                    <slot name="filter" :is-mobile="isMobileView" />
                </div>
            </template>
            <template v-if="slots.banner" #banner>
                <slot name="banner" />
            </template>
            <template v-if="slots.count" #count>
                <slot name="count" />
            </template>
            <template #content>
                <ErrorAndLoadingFragment
                    :loading="loading"
                    :error="error"
                    :no-results="noResults"
                    :no-result-icon="noResultIcon"
                    :no-results-error-message="noResultsErrorMessage"
                />
                <template v-if="!loading && !error && !noResults">
                    <div class="fit">
                        <slot name="content" :is-mobile="isMobileView" />
                    </div>
                </template>
            </template>
        </MobileView>
        <!-- Desktop View -->
        <div
            v-else
            :class="[
                { 'small-content-container': !largeContentContainer, 'large-content-container': largeContentContainer },
                'desktop-container column full-height no-wrap',
            ]"
        >
            <div
                class="flex-container flex-row flex-space-between flex-align-center col-auto"
                :class="{ 'q-px-lg': isLargerThanMedium, 'q-px-md': !isLargerThanMedium }"
            >
                <slot name="filter" :is-mobile="isMobileView" />
            </div>
            <div v-if="slots.banner" class="q-mx-md q-mt-md" :class="{ 'q-mx-lg': isLargerThanMedium }">
                <slot name="banner" />
            </div>
            <div
                v-if="slots.count"
                class="q-mt-sm slot-count"
                :class="{ 'q-px-lg': isLargerThanMedium, 'q-px-md': !isLargerThanMedium }"
            >
                <slot name="count" />
            </div>
            <ErrorAndLoadingFragment
                :loading="loading"
                :error="error"
                :no-results="noResults"
                :no-result-icon="noResultIcon"
                :no-results-error-message="noResultsErrorMessage"
            />
            <div
                v-if="!loading && !error && !noResults"
                class="slot-content col fit q-pb-md q-pt-sm"
                :class="{ 'q-px-lg': isLargerThanMedium, 'q-px-md': !isLargerThanMedium }"
            >
                <slot name="content" :is-mobile="isMobileView" />
            </div>
        </div>
        <q-resize-observer @resize="onResize" />
    </div>
</template>

<script lang="ts" setup>
import { ref, toRefs, useSlots } from "vue";
import { ApolloError } from "@apollo/client/errors";
import MobileView from "./mobile-view.vue";
import ErrorAndLoadingFragment from "./error-and-loading-fragment.vue";
import { useQuasar } from "quasar";
import { useScreenSize } from "@/shared/screen/composables/screen-size";

const props = withDefaults(
    defineProps<{
        noResults?: boolean;
        noResultsErrorMessage?: string;
        loading?: boolean;
        error?: ApolloError | null;
        largeContentContainer?: boolean;
        noResultIcon?: string;
    }>(),
    {
        noResults: false,
        loading: false,
        largeContentContainer: false,
        noResultIcon: "fa-thin fa-cloud-question icon-light-color fa-4x",
    }
);

const slots = useSlots();

const { screen } = useQuasar();
const { isLargerThanMedium } = useScreenSize();

const { noResults, loading } = toRefs(props);
const isMobileView = ref(false);

const onResize = (value: { width: number }) => {
    isMobileView.value = value.width < screen.sizes.sm;
};
</script>

<style lang="scss" scoped>
.desktop-container {
    padding-top: $spacing-l;

    .non-content-element {
        padding: 0 $spacing-l;

        .screen--xs & {
            padding: 0 $spacing-m;
        }
    }
}
.slot-count,
:deep(.slot-count) {
    min-height: 17px;
    max-height: 17px;
    color: $grey-40;
    font-size: $smaller-font-size;
}
</style>
