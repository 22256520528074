import { useAbilityStore } from "@/shared/store/ability.store";
import { Ability } from "@/shared/environment/ability.types";
import { createAbilityList } from "@/shared/environment/ability-list";
import PageNotFound from "@/shared/404/pages/page-not-found.vue";
import { Router } from "vue-router";
import { Tenant } from "@/shared/composables/tenant";
import { useAbilityRoute } from "@/shared/environment/ability-route";
import { useHasAccessAsync } from "@/shared/access-control/composables/use-has-access-async.ts";

export const initAbilities = async (router: Router, tenant: Tenant) => {
    const { createRoutesFromAbilities } = useAbilityRoute();
    const accessibleAbilities = await getAccessibleAbilities();

    const abilitiesInitFailed: Ability[] = [];
    for (const ability of accessibleAbilities) {
        if (ability.init) {
            const initSuccessful = await ability.init();
            if (!initSuccessful) abilitiesInitFailed.push(ability);
        }
    }
    const abilities = accessibleAbilities.filter((ability) => !abilitiesInitFailed.includes(ability));

    const addAbilitiesRoutes = async (router: Router, abilities: Ability[], tenant: Tenant) => {
        const routes = await createRoutesFromAbilities(abilities);

        if (routes.length > 0) {
            const firstRoute = tenant.landingPage ?? routes[0].path;
            routes.push(
                {
                    path: "/",
                    redirect: firstRoute,
                },
                {
                    path: "/recovery",
                    redirect: firstRoute,
                },
                {
                    path: "/:pathMatch(.*)*",
                    component: PageNotFound,
                }
            );
        }

        for (const route of routes) {
            router.addRoute({ ...route });
        }

        return routes;
    };

    const routes = await addAbilitiesRoutes(router, abilities, tenant);

    const abilityStore = useAbilityStore();
    abilityStore.setAbilities(abilities);

    const defaultPath = tenant.landingPage ?? routes[0].path;
    return { defaultPath };
};

export const getAccessibleAbilities = async () => {
    const abilities: Ability[] = [];

    for (const ability of createAbilityList()) {
        if (ability.access) {
            const hasAccess = await useHasAccessAsync({
                resource: ability.access.accessResource,
                featureID: ability.access.accessFeature,
                action: ability.access.accessAction,
                ignoreConditions: true,
            });
            if (hasAccess) abilities.push(ability);
        } else {
            abilities.push(ability);
        }
    }

    return abilities;
};
