import { onMounted, ref } from "vue";
import { Ability, QualificationLevelSelectList } from "./ability.types";
import { useAbilityStore } from "@/shared/store/ability.store";

export const useQualificationLevelSelectListsFromAbilities = () => {
    const qualificationLevels = ref<QualificationLevelSelectList[]>([]);

    const abilityStore = useAbilityStore();

    const getQualificationLevels = (abilityList: Ability[]): QualificationLevelSelectList[] => {
        const unfilteredQualificationLevels: {
            abilityAlias: string;
            qualificationSelectList: QualificationLevelSelectList;
        }[] = [];

        abilityList.forEach((ability) => {
            ability.getQualificationSelectList?.().forEach((qualificationSelectList: QualificationLevelSelectList) => {
                unfilteredQualificationLevels.push({ abilityAlias: ability.alias, qualificationSelectList });
            });
        });

        return unfilteredQualificationLevels
            .filter((elem) => abilityList.find((ability) => ability.alias === elem.abilityAlias))
            .map((elem) => elem.qualificationSelectList);
    };

    onMounted(() => {
        //@ts-ignore
        qualificationLevels.value = getQualificationLevels(abilityStore.abilities);
    });

    return {
        qualificationLevels,
    };
};
