<template>
    <q-dialog
        v-if="isModal"
        v-model="isModalVisible"
        @hide="onDialogHide()"
        :maximized="isXSmall"
        :no-route-dismiss="true"
    >
        <q-card class="card">
            <div class="row justify-between align-center items-center q-mx-md q-my-sm">
                <div class="text-h6">{{ facet.title }}</div>
                <div class="q-gutter-sm align-center">
                    <q-btn
                        round
                        size="sm"
                        icon="fa fa-solid fa-xmark icon-color"
                        @click="closeModal()"
                        data-qs="facet-modal-close-btn"
                    />
                </div>
            </div>
            <q-separator />
            <div v-if="loading" class="q-pa-sm row justify-center">
                <q-spinner-ios size="2em" />
            </div>
            <slot v-else />
        </q-card>
    </q-dialog>
    <template v-else>
        <div v-if="loading" class="q-pa-sm row justify-center">
            <q-spinner-ios size="2em" />
        </div>
        <div v-else class="slot">
            <slot />
        </div>
    </template>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { Facet } from "../../facets.model";
import { useScreenSize } from "@/shared/screen/composables/screen-size";

const props = defineProps<{
    loading: boolean;
    facet: Facet;
}>();

const emit = defineEmits<{
    (e: "onClose"): void;
}>();

const { isXSmall } = useScreenSize();
const isModalVisible = ref<boolean>(true);

const isModal = computed(() => {
    return props.facet.displayOptions.isModal || isXSmall.value;
});

const onDialogHide = () => {
    emit("onClose");
};

const closeModal = () => {
    isModalVisible.value = false;
    emit("onClose");
};
</script>

<style lang="scss" scoped>
.card {
    min-height: 80%;
    min-width: 80%;
}
</style>
