<template>
    <div class="breadcrumb">
        <i :class="`fa-regular ${props.icon}`" />
        <span>
            {{ t(props.title) }}
        </span>
    </div>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { onMounted } from "vue";

const props = defineProps<{ title: string; icon: string }>();

const { t } = useI18n();

const emit = defineEmits<{
    (e: "breadcrumbLoaded"): void;
}>();

onMounted(async () => {
    emit("breadcrumbLoaded");
});
</script>

<style lang="scss" scoped>
.breadcrumb {
    font-weight: $semi-bold;
    color: var(--q-link);

    i {
        font-size: 1rem;
        margin-right: $spacing-s;
        margin-bottom: $spacing-s;
        margin-top: $spacing-s;
    }

    &:hover {
        text-decoration: underline;
        cursor: pointer;
    }
}
</style>
