<template>
    <Card
        :title="$t(title)"
        @on-close-button-clicked="$emit('onCloseButtonClicked')"
        :key="locale"
        data-qs="documentation-import-wizard"
        class="small-content-container"
        white-background
        container
    >
        <ConfigurationFormWrapper>
            <DocumentationImportForm />
        </ConfigurationFormWrapper>
        <template #footer>
            <ConfigurationWizardFooter
                process-type="documentation"
                continue-text="documentation.Close"
                :continue-disabled="!formValid"
                @go-to-next-step="closeForm"
            />
        </template>
    </Card>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import Card from "@/shared/components/card/card.vue";
import ConfigurationFormWrapper from "@/shared/components/data-process/configuration-form-wrapper.vue";
import ConfigurationWizardFooter from "@/shared/components/data-process/configuration-wizard-footer.vue";
import DocumentationImportForm from "./documentation-import-form.vue";
import { getUiLanguage } from "@/shared/services/providers/language-provider";

defineProps({
    /**
     * The Title of the Card.
     * @example Neuen Prozess anlegen (Dateien)
     */
    title: {
        type: String,
        default: "",
    },
    /**
     * When editing the process, an Id should be available from this prop, which prevents
     * invalidating the input name when the process already has a name and only makes a CRUD change.
     * Gets passed onto formComponent.
     * @example '_LYfvjuMw85qP9'
     */
    entryId: {
        type: String,
        default: "",
    },
});

/**
 *  @onCloseButtonClicked Close Button has been clicked.
 *  @onCreateProcess Create Process clicked
 */
const emit = defineEmits(["onCreateProcess", "onCloseButtonClicked"]);

const locale = computed(() => {
    return getUiLanguage();
});

const formValid = ref(true);

function closeForm() {
    emit("onCloseButtonClicked");
}
</script>
