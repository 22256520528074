/**
 * Umrechnung von bytes in ein lesbares Format
 * @param bytes number
 * @returns Ergebnis String
 */
export function bytesToSize(bytes: number) {
    const sizes: Array<string> = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes === 0) return "0 Byte";
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    // * 10 und / 10, damit auf 1 Dezimalstelle gerunded wird
    return Math.round((bytes / Math.pow(1024, i)) * 10) / 10 + " " + sizes[i];
}
