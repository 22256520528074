<template>
    <div class="no-last-element column q-pa-sm items-center">
        <q-icon :name="icon" class="col q-pa-md" size="40px" />
        <div class="col q-pa-md">
            {{ message }}
        </div>
        <q-btn no-caps class="q-ma-md" :label="buttonMessage" :to="routeToProductsAssetsTab" color="white" />
    </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { CommonSearchTabAliases } from "@/shared/environment/common-route-aliases";
import { useProductNames } from "@/abilities/product-and-asset/composables/product-names";
import { useAssetNames } from "@/abilities/product-and-asset/composables/asset-names";
import { getPathToProductSelection } from "../../helpers/product-selection-routing";

const props = defineProps<{
    isProduct: boolean;
}>();

const notViewedAnyProductsYet = useProductNames("notViewedAnyProductsYet");
const notViewedAnyAssetsYet = useAssetNames("notViewedAnyAssetsYet");

const goToProducts = useProductNames("goToProducts");
const goToAssets = useAssetNames("goToAssets");

const icon = computed(() => {
    return props.isProduct ? "fa-regular fa-box icon-light-color" : "fa-regular fa-tag icon-light-color";
});

const message = computed(() => {
    return props.isProduct ? notViewedAnyProductsYet.value : notViewedAnyAssetsYet.value;
});

const buttonMessage = computed(() => {
    return props.isProduct ? goToProducts.value : goToAssets.value;
});

const routeToProductsAssetsTab = computed(() => {
    return props.isProduct
        ? getPathToProductSelection(CommonSearchTabAliases.products)
        : getPathToProductSelection(CommonSearchTabAliases.assets);
});
</script>

<style lang="scss" scoped>
.no-last-element {
    background-color: $grey-97;
    $border-radius: $default-border-radius;
}
</style>
