import { SearchFilteredResultsParams } from "@/shared/search/search.types";
import { computed } from "vue";
import { useDocumentSearch } from "../composables/document-search/document-search";
import { mapContentQueryResultToContentResult } from "@/abilities/documentation/mapper/content-query-result.mapper.ts";
import { useDocumentationsAggregations } from "@/abilities/documentation/composables/document-search/documentations-aggregations.ts";

/**
 * A composable function to handle document search and converts the search result to content results.
 *
 * @param selected - A reference to the selected facets for filtering.
 * @param facet - A reference to the current opened facet.
 * @param searchContext - A reference to the search context.
 * @param searchPhrase - A reference to the search phrase.
 * @param sort - A reference to the sort entry for sorting the search results.
 * @returns An object containing the search configuration result, loading state, error state, total result, content results,
 *          function to fetch more content results and a function to fetch aggregations
 */
export const useFilteredDocumentationResults = ({
    selected,
    facet,
    searchContext,
    searchPhrase,
    sort,
}: SearchFilteredResultsParams) => {
    //search documents by given search params
    const { result, searchFields, loading, error, fetchMore, getDataDisplayPropertiesByConfig } = useDocumentSearch({
        searchContext,
        selected,
        sort,
        searchPhrase,
    });

    const { fetchAggregation } = useDocumentationsAggregations({
        selectedFacets: selected,
        facet,
        searchFields,
        searchContext,
        searchPhrase,
    });

    const total = computed(() => {
        return result.value?.contents?.total;
    });

    //map search results to content result
    const contents = computed(() => {
        if (result.value?.contents)
            return mapContentQueryResultToContentResult(result.value?.contents, getDataDisplayPropertiesByConfig);

        return undefined;
    });

    const loadMore = async (after: string, first: number) => {
        const fetchResult = await fetchMore({
            variables: {
                after: after,
                first: first,
            },
        });

        let content;

        if (searchContext?.value.asset && searchContext?.value.product)
            content = fetchResult?.data?.assets?.assets[0]?.node.relatedContents.contents;
        else if (searchContext?.value.product) content = fetchResult?.data?.product?.relatedContents.contents;
        else content = fetchResult?.data?.contents.contents;

        if (!content || content.length === 0) return undefined;

        return content[content.length - 1].cursor;
    };

    return {
        loading,
        contents,
        total,
        error,
        loadMore,
        fetchAggregation,
    };
};
