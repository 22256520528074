import { computed, ComputedRef, Ref, ref, watch, watchEffect } from "vue";
import { useLazyQuery } from "@vue/apollo-composable";
import { createMechanicPartsListQuery } from "../graphql/mechanics-partslist.query";
import { Mechanic, MechanicQueryResult } from "@/shared/services/graphql/generated/consumer-graph-types";
import { loadMore } from "@/shared/components/scroll/lazy-loading-fetch";
import { OperationVariables, WatchQueryFetchPolicy } from "@apollo/client/core";
import { useDataDisplayConfig } from "@/shared/data-display-config/composable/data-display-config";
import { DataDisplayConfigId } from "@/shared/data-display-config/composable/data-display.model";
import { SortEntry } from "@/shared/components/sort-dropdown/sort-dropdown.model";
import { getSortEntries } from "@/shared/environment/filter/filter-types";
import { getDataQueryLanguages } from "@/shared/services/providers/language-provider";
import { watchImmediate } from "@vueuse/core";

export interface MechanicsListQueryParams {
    assemblyId: string;
    productId: string;
    assetId: string | undefined;
    assemblyOnly?: boolean;
    withPreviewImages?: boolean;
    maxResultCount?: number;
    fetchPolicyCacheFirst?: boolean;
    sortEntry?: SortEntry;
}

const ROWS_PER_FETCH = 200;

export const useMechanicsListQuery = (params: Ref<MechanicsListQueryParams>) => {
    const {
        loading: dataConfigLoading,
        result: dataDisplayResult,
        error: dataConfigError,
        priorityDataDisplayFields,
    } = useDataDisplayConfig(DataDisplayConfigId.parts);

    const mechanicFilterVariables = computed<OperationVariables>(() => {
        return {
            filter: {
                andGroup: [
                    {
                        equals: {
                            parentAssemblyId: params.value.assemblyId,
                        },
                    },
                    {
                        equals: {
                            isAssembly: params.value.assemblyOnly,
                        },
                    },
                ],
            },
            assetId: params.value.assetId,
            productId: params.value.productId,
            acceptedLanguages: getDataQueryLanguages(),
            ...(params.value.sortEntry &&
                params.value.sortEntry.field &&
                params.value.sortEntry.order && {
                    sort: getSortEntries(params.value.sortEntry, getDataQueryLanguages()),
                }),
            // So viele Datensätze wie in maxResultCount, aber nicht mehr als 200. Und falls maxResultCount nicht angegeben wurde 200
            first: Math.min(params.value.maxResultCount ?? ROWS_PER_FETCH, ROWS_PER_FETCH),
        };
    });

    const data = ref<Array<Mechanic>>([]);

    const queryOptions = ref({
        ...(params.value.fetchPolicyCacheFirst && { fetchPolicy: <WatchQueryFetchPolicy>"cache-first" }),
    });
    const {
        result,
        error: queryError,
        fetchMore,
        load,
        loading: reloadBecauseOfSort,
    } = useLazyQuery(
        createMechanicPartsListQuery({ withPreviewImages: params.value.withPreviewImages }),
        mechanicFilterVariables,
        queryOptions
    );

    const startMechanicQuery = () => {
        const mechanicPartsListQuery = createMechanicPartsListQuery({
            withPreviewImages: params.value.withPreviewImages,
            datafieldsFragment: dataDisplayResult.value?.datafieldsFragment.datafields,
            teaserDatafieldsFragment: dataDisplayResult.value?.datafieldsFragment.localizationDatafields,
        });

        load(mechanicPartsListQuery);
    };

    watchEffect(() => {
        if (!dataDisplayResult.value || !params.value.sortEntry) return;
        startMechanicQuery();
    });

    const mechanics: ComputedRef<MechanicQueryResult> = computed(() => {
        return result.value?.mechanics ?? undefined;
    });

    const doneLoading = ref(false);

    const loadMoreResults = (): void => {
        // Teste, ob schon die gewünschte Maximalanzahl erreicht ist, falls ja, dann nicht weiter laden
        if (params.value.maxResultCount) {
            if (data.value.length >= params.value.maxResultCount) {
                doneLoading.value = true;
                return;
            }
        }
        const after = mechanics.value?.mechanics[mechanics.value?.mechanics.length - 1]?.cursor;
        const first = mechanicFilterVariables.value.first;
        loadMore(fetchMore, after, first).then((data) => {
            doneLoading.value =
                data?.data.mechanics.mechanics.length < first || data?.data.mechanics.mechanics.length == 0;
        });
    };

    const reload = () => {
        doneLoading.value = false;
        startMechanicQuery();
    };

    //Ändern sich die assemblyId, wird die graphqlQuery neu ausgeführt
    //Deshalb muss auch die data neu gesetzt werden, auch doneLoading muss neu gesetzt werden
    watch(
        () => params.value.assemblyId,
        () => {
            if (!dataDisplayResult.value || !params.value.sortEntry) return;
            reload();
        }
    );

    const processMechanicValues = () => {
        const newElements: Array<Mechanic> = [];
        mechanics.value?.mechanics.forEach((part) => {
            if (part?.node) {
                newElements.push(part.node);
            }
        });
        data.value = newElements;
        if (
            !doneLoading.value &&
            mechanics.value?.mechanics.length &&
            !(mechanics.value?.mechanics.length < ROWS_PER_FETCH)
        ) {
            loadMoreResults();
        }
    };

    watchImmediate(mechanics, () => {
        processMechanicValues();
    });

    const loading = computed(() => {
        return dataConfigLoading.value || !params.value.sortEntry || reloadBecauseOfSort.value;
    });

    const error = computed(() => {
        return dataConfigError.value || queryError.value;
    });

    const dataDisplayFields = computed(() => {
        return dataDisplayResult.value?.dataDisplayFields;
    });

    return {
        dataDisplayFields,
        priorityDataDisplayFields,
        data,
        loading,
        error,
    };
};
