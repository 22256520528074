<template>
    <q-input
        v-model="filterPhrase"
        @update:model-value="updateModelHandler"
        dense
        outlined
        class="q-ma-sm dropdown-search-field"
    >
        <template #prepend>
            <q-icon name="fa-regular fa-magnifying-glass" />
        </template>
    </q-input>
</template>

<script setup lang="ts">
import { ref } from "vue";

const emits = defineEmits<{
    (e: "onValueChanged", value: string): void;
}>();

const filterPhrase = ref<string>("");

const updateModelHandler = () => {
    emits("onValueChanged", filterPhrase.value);
};
</script>

<style lang="scss" scoped>
.items-center {
    padding: 0 16px;
}

.dropdown-search-field {
    min-height: 34px;
}
</style>
