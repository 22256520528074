import { Facet } from "@/shared/facets/facets.model";

export const generateAggregationsFragment = (facet: Facet, hasUseContext: boolean, keywordSearchValue: string = "") => {
    const more = `(after: "", first: 10000){
            count
            value
        }`;

    const keywordFilterMore = `(first: 10000, prefix: "${keywordSearchValue}"){
            count
            value
        }`;

    if (facet.type === "field") {
        return `aggregations {
                  ${facet.id}
                    ${more}
                }`;
    }

    const fragment =
        facet.facetType === "keyword"
            ? `terms {${facet.id} ${keywordFilterMore}}`
            : `composite { ${facet.id} ${more} }`;

    if (hasUseContext)
        return `aggregations {
                  useContext {
                    ${fragment}
                }
            }`;

    return `aggregations {
               ${fragment}
            }`;
};
