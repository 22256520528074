<template>
    <ProductsOverview
        :emit-selection-in-tiles="!isLastLevel || emitLastLevelSelection"
        :show-buttons="false"
        :selection-data="selectionData"
        @select="onSelect"
        dense
        :is-search="false"
    />
</template>

<script setup lang="ts">
import { QualificationLevelSelectionResult } from "@/shared/environment/ability.types";
import ProductsOverview from "./products-overview.vue";

const props = defineProps<{
    selectionData: QualificationLevelSelectionResult;
    isLastLevel: boolean;
    emitLastLevelSelection?: boolean;
}>();

const emit = defineEmits<{
    (e: "select", selectionResult: QualificationLevelSelectionResult): void;
}>();

const onSelect = (productId: string) => {
    const result: QualificationLevelSelectionResult = {
        ...props.selectionData,
        product: productId,
    };
    emit("select", result);
};
</script>

<style lang="scss" scoped>
:deep(.default-preview-image-container),
:deep(.preview-image) {
    height: 100px;
}
</style>
