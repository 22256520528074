import { defineStore } from "pinia";
import { Ability } from "@/shared/environment/ability.types";

export interface AbilityStoreState {
    abilities: Ability[];
}

export const useAbilityStore = defineStore("abilityStore", {
    state: (): AbilityStoreState => ({
        abilities: [],
    }),
    actions: {
        setAbilities(newAbilities: Ability[]) {
            this.abilities = newAbilities;
        },
        addAbilities(abilitiesToAdd: Ability[]) {
            this.abilities = this.abilities.concat(abilitiesToAdd);
        },
        removeAbilities(abilitiesToRemove: Ability[]) {
            const aliasesToRemove = abilitiesToRemove.map((ability) => ability.alias);
            this.abilities = this.abilities.filter((ability) => !aliasesToRemove.includes(ability.alias));
        },
    },
});
