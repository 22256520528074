import { computed, Ref } from "vue";
import { LazyLoadingDataSource } from "@/shared/components/scroll/lazy-loading-datasource";
import { ComponentDefinition } from "@/shared/components/component-definition.model";
import LocationTile from "./components/mechanic-installation-location-tile.vue";
import { Maybe, MechanicPath } from "@/shared/services/graphql/generated/consumer-graph-types";
import { useMechanicLocation, useMechanicLocationParams } from "../helpers/mechanic-location-helper";

function createDataSource(
    product: string,
    asset: string | undefined,
    emitSelection: boolean
): LazyLoadingDataSource<Maybe<MechanicPath>, ComponentDefinition> {
    const result = new LazyLoadingDataSource<Maybe<MechanicPath>, ComponentDefinition>((item) => {
        return item
            ? {
                  getComponent: () => LocationTile,
                  props: {
                      product: product,
                      asset: asset,
                      mechanicPath: item,
                      emitSelection: emitSelection,
                  },
              }
            : undefined;
    });
    return result;
}

function createTiles(
    dataSource: LazyLoadingDataSource<Maybe<MechanicPath>, ComponentDefinition>,
    paths: Array<Maybe<MechanicPath>>
): Array<ComponentDefinition> {
    dataSource.setSourceValues(paths ?? []);
    return dataSource.getResults();
}

export const useMechanicLocationDataSource = (
    params: Ref<useMechanicLocationParams>,
    emitSelectionInTiles: boolean
) => {
    const { loading, error, mechanics, paths, fetchMore } = useMechanicLocation(params);

    const dataSource = createDataSource(params.value.product, params.value.asset, emitSelectionInTiles);

    const tiles = computed(() => {
        return createTiles(dataSource, paths.value ? (paths.value as Array<MechanicPath>) : []);
    });

    return {
        loading,
        error,
        tiles,
        mechanics,
        paths,
        fetchMore,
    };
};
