<template>
    <div id="root" class="app" v-if="authenticated">
        <DefaultLayout>
            <div class="content">
                <router-view />
            </div>
        </DefaultLayout>
    </div>
    <div v-else class="fixed-center">
        <q-spinner-ios size="2em" />
    </div>
</template>

<script setup lang="ts">
import DefaultLayout from "@/shell/pages/layout/default-layout.vue";
import { waitForAuthentication } from "@/shared/authentication/authentication";
import { Ability, AccessOptions } from "@/shared/environment/ability.types";
import { useRouter } from "vue-router";
import { useAbilityStore } from "@/shared/store/ability.store";
import { useHasAccessAsync } from "@/shared/access-control/composables/use-has-access-async.ts";
import { buildRedirectToPageNotFound } from "@/shared/router/router.ts";
import { useUserQuery } from "@/shared/services/graphql/generated/consumer-graph-types.ts";
import { watch } from "vue";
import { useBrowserTitle } from "@/shared/composables/use-browser-title.ts";

defineProps<{
    authenticated: boolean;
}>();

const router = useRouter();
const abilityStore = useAbilityStore();
const { result } = useUserQuery();
const { setTitle } = useBrowserTitle();

watch(
    () => result.value,
    async () => {
        if (!("heap" in window) || !window.heap || !result.value) {
            return;
        }
        const heap = window.heap as { addUserProperties: (value: Record<string, string>) => void };

        if (!result.value.me.anonymous) {
            heap.addUserProperties({ Authenticated: "true" });
        }
    }
);

router.beforeEach(async (to) => {
    try {
        await waitForAuthentication();
        setTitle("");

        let hasAccess = true;
        if (to.meta.abilityAlias) {
            hasAccess = abilityStore.abilities.some(
                (ability: Ability) => ability.alias === (to.meta.abilityAlias as string)
            );
        }

        if (hasAccess && to.meta.access) {
            const accessInformation = to.meta.access as AccessOptions;

            hasAccess = await useHasAccessAsync({
                resource: accessInformation.accessResource,
                featureID: accessInformation.accessFeature,
                action: accessInformation.accessAction,
                ignoreConditions: true,
            });
        }

        if (!hasAccess) {
            return buildRedirectToPageNotFound(router, to);
        }
    } catch (error: any) {
        console.log(error.message);
        return buildRedirectToPageNotFound(router, to);
    }
});
</script>

<style lang="scss">
@import "@/shell/assets/scss/qit-style";
@import "@/shell/assets/scss/global-overwrites";
@import "@/shell/assets/scss/quasar-color-overwrites";
@import "@/shell/assets/scss/font-families";
@import "@/shell/assets/scss/abilities/data-process";
@import "@/shell/assets/scss/abilities/product-and-asset";
</style>
