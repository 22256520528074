<template>
    <Fragment v-if="selected.length > 0">
        <div class="selected-container">
            <Fragment v-for="(val, index) in selected" :key="val.id">
                <q-chip
                    color="primary"
                    text-color="white"
                    icon-remove="fa-solid fa-circle-xmark"
                    v-if="index < maxTags"
                    :removable="!disable"
                    @remove="onRemove(val.id)"
                    :data-qs="`${val.text}-chip`"
                >
                    <div class="ellipsis">
                        {{ val.text }}
                        <q-tooltip>{{ val.text }}</q-tooltip>
                    </div>
                </q-chip>
                <q-chip
                    color="primary"
                    text-color="white"
                    icon-remove="fa-solid fa-circle-xmark"
                    v-if="index === maxTags"
                    :removable="!disable"
                    @remove="onRemove(val.id)"
                >
                    {{ selected.length - maxTags }} {{ $t("core.More") }}
                </q-chip>
            </Fragment>
        </div>
    </Fragment>
</template>

<script setup lang="ts">
import { toRefs } from "vue";
import { FacetValue } from "../../facets.model";
import Fragment from "@/shared/components/fragment/fragment.vue";

const props = defineProps<{
    selected: FacetValue[];
    maxTags: number;
    onRemove: (id: string) => void;
    disable?: boolean;
}>();

const { selected } = toRefs(props);
</script>

<style lang="scss" scoped>
.selected-container {
    display: flex;
    flex-wrap: wrap;
    max-width: 400px;
}
.facet-dropdown-multi-field {
    display: flex;
    flex-wrap: wrap;
    min-height: 36px;
}
</style>
