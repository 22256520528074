<template>
    <ExpansionPanel
        icon="fa-regular fa-gear icon-light-color"
        :label="$t('mechanic.Identify Component')"
        v-if="isWidgetVisible"
    >
        <q-card class="container-shadow row q-pa-md">
            <div v-for="item in identifyPartsItems" :key="item.titleKey" class="col">
                <IdentifyPartBoxItem class="identify-part-item" :identify-parts-item="item" />
            </div>
        </q-card>
    </ExpansionPanel>
    <Dialog
        v-model="overlayVisible"
        position="left"
        :title="$t('mechanic.Select Assembly')"
        icon="fa-light fa-sitemap icon-light-color"
    >
        <AssemblyNav :asset="assetId" :product="productId ?? ''" @hide-overlay="hideOverlay"> </AssemblyNav>
    </Dialog>
</template>

<script setup lang="ts">
import { computed, ref, toRefs, watch } from "vue";
import { getIdentifyPartsItems, IDENTIFY_PART, IdentifyPartsItem } from "./identify-part.model";
import { WidgetContext } from "@/shared/environment/widgets/widget-context";
import {
    AvailablePartsListDocument,
    useAvailablePartsListLazyQuery,
    useAvailablePartsListQuery,
} from "@/shared/services/graphql/generated/consumer-graph-types";
import IdentifyPartBoxItem from "@/abilities/mechanic/pages/components/identify-part-item.vue";
import ExpansionPanel from "@/shared/components/expansion/expansion-panel.vue";
import AssemblyNav from "../components/assembly-navigation.vue";
import Dialog from "@/shared/components/dialog/title-content-footer-dialog.vue";
import { getStore } from "@/shared/store/store";
import { useRouter } from "vue-router";
import { useAbilityRoute } from "@/shared/environment/ability-route";
import { encodeParamArrayToString } from "@/shared/environment/ability-route-helper";
import { CommonRouteAliases } from "@/shared/environment/common-route-aliases";

const store = getStore();
const router = useRouter();

const { getPathToChild } = useAbilityRoute();

watch(
    () => store.state.notification.mechanicDeleted,
    (value) => {
        if (value > 0) {
            refetch();
        }
    }
);

const props = defineProps<{
    widgetContext: WidgetContext;
}>();

const { widgetContext } = toRefs(props);

const overlayVisible = ref(false);

function hideOverlay() {
    overlayVisible.value = false;
}

function showOverlay() {
    overlayVisible.value = true;
}

//one and only assembly of root "" has subassemblies
const hasSubAssemblies = ref(true);
// path to one and only assembly of root ""
const pathToRootAssembly = ref<string[] | undefined>(undefined);

function identifyPartButtonAction() {
    //if there is exactly one mechanic with no subassemblies in the root-assembly-list, jump directly to the partslist instead of opening the assembly navigation
    if (!hasSubAssemblies.value && productId.value && pathToRootAssembly.value) {
        router.push(
            getPathToChild(CommonRouteAliases.mechanic, {
                [CommonRouteAliases.mechanic]: encodeParamArrayToString(pathToRootAssembly.value),
            })
        );
    } else {
        showOverlay();
    }
}

const productId = computed(() => {
    if (widgetContext.value.__type === "product") {
        return widgetContext.value.productId;
    }
    if (widgetContext.value.__type === "asset") {
        return widgetContext.value.product;
    }
    return undefined;
});

const assetId = computed(() => {
    if (widgetContext.value.__type === "asset") {
        return widgetContext.value.asset;
    }
    return undefined;
});

const identifyPartsItems = getIdentifyPartsItems().map((item: IdentifyPartsItem) => {
    item.productId = productId.value ?? "";
    item.assetId = assetId.value;

    if (item.identifyPartsItemKey === IDENTIFY_PART) {
        item.buttonClickEvent = identifyPartButtonAction;
    }
    return { ...item };
});

const filterVariables = computed(() => {
    return {
        filter: {
            andGroup: [
                {
                    equals: {
                        parentAssemblyId: "",
                    },
                },
            ],
        },
        assetId: assetId.value,
        productId: productId.value,
        first: 2,
    };
});

const { loading, result, refetch, onResult } = useAvailablePartsListQuery(
    filterVariables.value,
    computed(() => ({
        fetchPolicy: "no-cache",
    }))
);

const { load: loadHasSubAssemblies, onResult: onHasSubAssembliesResult } = useAvailablePartsListLazyQuery(
    filterVariables.value
);

onResult(({ data }) => {
    //if there is exactly one assembly, check if it has subassemblies
    if (data.mechanics.mechanics.length === 1 && data.mechanics.mechanics[0]?.node.assemblyId) {
        loadHasSubAssemblies(AvailablePartsListDocument, {
            filter: {
                andGroup: [
                    {
                        equals: {
                            parentAssemblyId: data.mechanics.mechanics[0].node.assemblyId,
                        },
                    },
                    {
                        equals: {
                            isAssembly: true,
                        },
                    },
                ],
            },
            assetId: assetId.value,
            productId: productId.value,
            first: 1,
        });
        if (data.mechanics.mechanics[0]?.node?.assemblyId) {
            pathToRootAssembly.value = ["", data.mechanics.mechanics[0]?.node?.assemblyId];
        }
    }
});

onHasSubAssembliesResult(({ data }) => {
    if (data.mechanics.mechanics && data.mechanics.mechanics.length === 0) {
        hasSubAssemblies.value = false;
    }
});

const isWidgetVisible = computed(() => {
    return (
        result.value?.mechanics !== undefined &&
        !loading.value &&
        result.value.mechanics.mechanics &&
        result.value.mechanics.mechanics.length > 0
    );
});
</script>

<style lang="scss" scoped>
.identify-part-container {
    display: grid;
}

.identify-part-horizontally {
    grid-auto-columns: 1fr;
    grid-auto-flow: column;
    grid-gap: $spacing-xxl;
    padding: 0;

    > div {
        max-width: 50%;
    }
}

.identify-part-vertically {
    grid-template-columns: repeat(1, 1fr);

    .identify-part-item {
        border-bottom: 1px solid $default-border-color;
        padding: $spacing-l 0;
    }

    .identify-part-item:last-child {
        border-bottom: none;
    }
}
</style>
