import { gql } from "@apollo/client/core";
import { Ref, ref, watch } from "vue";

export function useFDIShopBasket(params: Ref<{ client: any; basketId: string | undefined; updateKey: number }>) {
    const basket = ref<any>(undefined);

    watch(params, async () => {
        if (params.value.basketId) {
            basket.value = await getBasket(params.value.basketId);
        } else {
            basket.value = undefined;
        }
    });

    async function getBasket(basketId: string) {
        const client = params.value.client;
        if (!client || !basketId) {
            return undefined;
        }
        try {
            try {
                const { data } = await client.query({
                    query: gql`
                        query basketForUser($basketId: ID!) {
                            basket(basketId: $basketId) {
                                items {
                                    product {
                                        id
                                        title
                                    }
                                    amount
                                }
                            }
                        }
                    `,
                    variables: {
                        basketId: basketId,
                    },
                });
                return data;
            } catch (e: any) {
                if (e.message?.includes("not found")) {
                    return undefined;
                } else {
                    throw e;
                }
            }
        } catch (error) {
            console.error(error);
            return undefined;
        }
    }

    async function addPartToBasket(basketId: string, productId: string, amount = 1) {
        const client = params.value.client;
        if (!client) {
            return undefined;
        }
        try {
            const result = await client.mutate({
                mutation: gql`
                    mutation ($BasketId: ID!, $Amount: Float!, $ProductId: ID!) {
                        basketAddItem(basketId: $BasketId, amount: $Amount, productId: $ProductId) {
                            id
                        }
                    }
                `,
                variables: {
                    BasketId: basketId,
                    Amount: amount,
                    ProductId: productId,
                },
            });
            return result;
        } catch (error) {
            console.error(error);
            return undefined;
        }
    }

    return {
        basket,
        addPartToBasket,
    };
}
