import { useLoadConfigProductAndAsset } from "@/shared/configurations/product-and-asset/load-product-and-asset-settings";
import { getI18n } from "@/shared/i18n/i18n";
import { computed } from "vue";
import { ProductNameTypes, productTextKeys } from "@/abilities/product-and-asset/helpers/product-asset-naming";

export const useProductNames = (subType: ProductNameTypes) => {
    const { loading, configProductAndAsset } = useLoadConfigProductAndAsset();
    const i18n = getI18n();

    return computed(() => {
        if (!loading.value && configProductAndAsset.value) {
            return i18n.global.t(
                "product-and-asset." + productTextKeys[configProductAndAsset.value.productName][subType]
            );
        }
        return "";
    });
};
