import { CommonRouteAliases } from "@/shared/environment/common-route-aliases";
import { computed } from "vue";
import { useStaticPageMenuQuery } from "@/shared/services/graphql/generated/consumer-graph-types";
import { getDataQueryLanguages } from "@/shared/services/providers/language-provider";
import { RouteLocationRaw } from "vue-router";
import { useAbilityRoute } from "@/shared/environment/ability-route";

export interface StaticPage {
    title: string;
    key: string;
    to: RouteLocationRaw;
}

export function useStaticPages() {
    const { getPathToChildWithoutParents } = useAbilityRoute();

    const { result, error, loading } = useStaticPageMenuQuery(computed(() => ({ languages: getDataQueryLanguages() })));

    const getPathToStaticPage = (pathType: string) => {
        return getPathToChildWithoutParents(CommonRouteAliases.staticPages, {
            [CommonRouteAliases.staticPages]: pathType,
        });
    };

    const pages = computed<StaticPage[]>(
        () =>
            // TODO Remove filter after bugfix QIT-1129
            result?.value?.staticPageMenu?.pages
                ?.filter((page) => {
                    return !!page?.teasers?.title;
                })
                ?.map((page) => ({
                    title: page?.teasers?.title!,
                    key: page?.pageType!,
                    to: getPathToStaticPage(page?.pageType!),
                })) ?? []
    );

    const privacyStatements = computed<StaticPage[]>(() => {
        return pages.value.filter((page) => page.key === "privacy-statement");
    });

    const staticPages = computed<StaticPage[]>(() => {
        return pages.value.filter((page) => page.key !== "privacy-statement");
    });

    return { staticPages, privacyStatements, loading, error };
}
