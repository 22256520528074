<template>
    <q-btn-dropdown
        v-if="dropDownButtonVisible"
        no-caps
        unelevated
        flat
        class="search-context-btn text-weight-regular"
        text-color="black"
        color="white"
        dropdown-icon="fa-solid fa-caret-down"
        :ripple="false"
        :icon="searchContextIcon"
    >
        <template #label>
            <div class="qit-text-ellipsis q-ml-sm">
                {{ searchContextLabel }}
            </div>
        </template>
        <q-list unelevated>
            <q-item
                dense
                clickable
                v-close-popup
                @click="onSearchContextChanged(item)"
                v-for="item in dropDownData"
                :key="item.id"
            >
                <div class="row no-wrap items-center">
                    <div class="q-mr-sm">
                        <q-icon :class="item.icon" />
                    </div>
                    <q-item-label>{{ item.title }}</q-item-label>
                </div>
            </q-item>
        </q-list>
    </q-btn-dropdown>
</template>

<script setup lang="ts">
import { computed, toRefs } from "vue";
import { useI18n } from "vue-i18n";
import {
    SearchContextForDropDown,
    SearchContextForDropDownDetails,
} from "@/shell/pages/header/search/header-search.types";
import { useScreenSize } from "@/shared/screen/composables/screen-size";

const props = defineProps<{
    selected: "all" | "product" | "asset";
    availableSearchContexts: SearchContextForDropDown;
}>();

const emits = defineEmits<{ onSelection: [context: SearchContextForDropDownDetails] }>();

const { availableSearchContexts, selected } = toRefs(props);

const { t } = useI18n();
const { isLessThanMedium } = useScreenSize();

const dropDownData = computed(() => {
    return [
        { id: "all", type: "all", title: t("core.All"), icon: "fa-regular fa-cloud default-grey-icon-color" },
        ...Object.values(availableSearchContexts.value),
    ];
});

const dropDownButtonVisible = computed(() => {
    return dropDownData.value.length > 1;
});

const searchContextLabel = computed(() => {
    if (isLessThanMedium.value) return "";

    const find = dropDownData.value.find((x) => x.type === selected.value);
    if (find) return find.title;

    return "all";
});

const searchContextIcon = computed(() => {
    const find = dropDownData.value.find((x) => x.type === selected.value);
    if (find) return find.icon;
    return "fa-regular fa-cloud default-grey-icon-color";
});

const onSearchContextChanged = (context: SearchContextForDropDownDetails) => {
    emits("onSelection", context);
};
</script>

<style lang="scss" scoped>
.search-context-btn {
    border-radius: 25px 0px 0px 25px;
    background-color: $white;
    height: 48px;
    padding: 0px 10px 0px 10px;
    &:deep(.q-btn__content) {
        font-weight: $light-font-weight !important;
    }
}
</style>
