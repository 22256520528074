import { ProcessEncodingType } from "@/shared/services/graphql/generated/consumer-graph-types";

export interface EncodingOptions {
    encoding: "utf8" | "utf16le" | "latin1";
    bom: boolean;
}

export function getEncodingOptionsByType(encoding: ProcessEncodingType): EncodingOptions {
    if (encoding === ProcessEncodingType.latin1) {
        return { encoding: "latin1", bom: false };
    }
    if (encoding === ProcessEncodingType.utf8) {
        return { encoding: "utf8", bom: true };
    }
    if (encoding === ProcessEncodingType.utf16Le) {
        return { encoding: "utf16le", bom: true };
    }
    return { encoding: "utf8", bom: false };
}

export function getTypeByEncodingOptions(options: EncodingOptions): ProcessEncodingType {
    if (options.encoding === "utf16le") {
        return ProcessEncodingType.utf16Le;
    }
    if (options.encoding === "latin1") {
        return ProcessEncodingType.latin1;
    }
    return ProcessEncodingType.utf8;
}

export async function detectBom(file: Blob): Promise<EncodingOptions> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = () => {
            if (typeof reader.result === "string") {
                if (reader.result.charCodeAt(0) === 0xff && reader.result.charCodeAt(1) === 0xfe) {
                    resolve({ encoding: "utf16le", bom: true });
                }
            }
            //Return default utf8_bom if utf16le is not detected
            resolve({ encoding: "utf8", bom: true });
        };

        reader.onerror = () => {
            reject(reader.error);
        };

        reader.readAsBinaryString(file);
    });
}
