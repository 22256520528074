import { Facet } from "@/shared/facets/facets.model";
import gql from "graphql-tag";
import { generateAggregationsFromFacet } from "@/shared/facets/composables/facets-aggregation";

export function generateDynamicSearchProductsOnlyAggregationsQuery(facet: Facet, keywordSearchValue: string = "") {
    return gql`
        query searchProductsOnlyAggregations($filter: ProductFilter) {
            products(filter: $filter)
                ${generateAggregationsFromFacet(facet, keywordSearchValue)}
        }
    `;
}

export function generateDynamicSearchAssetsOnlyAggregationsQuery(facet: Facet, keywordSearchValue: string = "") {
    return gql`
        query searchAssetsOnlyAggregations($filter: AssetFilter) {
            assets(filter: $filter)
                ${generateAggregationsFromFacet(facet, keywordSearchValue)}
        }
    `;
}
