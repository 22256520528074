<!-- 
    Testkomponente für das Routing
-->

<template>
    <div class="detail-card container">
        <div>child2-widget</div>
        <div class="field">
            <div class="label">Param for Link</div>
            <div class="value">
                <QInput outlined type="textarea" v-model="newParam" :height="80" />
            </div>
        </div>
        <router-link class="router-link" :to="getPathToChild2()">
            <QitButton label="Child2-Route" />
        </router-link>
    </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import QitButton from "@/shared/components/buttons/qit-button.vue";
import { QInput } from "quasar";
import { useAbilityRoute } from "@/shared/environment/ability-route";

const newParam = ref("<leer>");

const { getPathToChild } = useAbilityRoute();

function getPathToChild2() {
    return getPathToChild("playGroundChild2", { playGroundChild2: newParam.value });
}
</script>

<style lang="scss" scoped>
.container {
    display: flex;
    gap: $spacing-m;
    padding: $spacing-m;
}
</style>
