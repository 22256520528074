/**
 * Helper um den Playground normalerweise unsichtbar zu schalten.
 * Dreimal Klick auf das Logo schaltet den Playground ein
 */

import { ref } from "vue";

const showPlayGround = ref(false);

let logoClickedCounter = 0;

export function playgroundEasterEggClicked() {
    logoClickedCounter++;
    if (logoClickedCounter > 3) {
        logoClickedCounter = 0;
        showPlayGround.value = !showPlayGround.value;
    }
}

export function isPlayGroundVisible(): boolean {
    return showPlayGround.value;
}
