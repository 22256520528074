import { Theme, ThemeQuery } from "@/shared/services/graphql/generated/consumer-graph-types";
import { colors as quasarColors } from "quasar";
import { InfoTwinTheme, ThemeColors } from "@/shell/helpers/theme.types";

/**
 * Coerces values so ti is between 0 and 1.
 * @param color original hex color
 * @param opacity opacity from 0 - 1
 * @returns modified hex color with opacity f.e. #efefef to #efefef3B
 */
export const addAlpha = (color: string, opacity: number): string => {
    const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
    return color + _opacity.toString(16).toUpperCase();
};

export const isThemeObject = (obj: any): obj is Theme => {
    return (
        obj &&
        Object.prototype.toString.call(obj) !== "[object String]" &&
        "showTitle" in obj &&
        "title" in obj &&
        "theming" in obj
    );
};

/**
 * Convert the colors from the query to the Theme Colors object, that
 * additionally has darker, and lighter color variants, derived from f.e. primary color
 * @param colors: colors from the theme Query result
 * @returns ThemeColors Object, see Interface 'ThemeColors'
 */
export const convertToThemeColors = (colors: any | undefined): ThemeColors => {
    const defaultTheme = getDefaultThemeColors();
    if (!colors) return defaultTheme;

    const primaryDark = colors.primary ? quasarColors.lighten(colors.primary, -20) : undefined;
    const primaryDarker = colors.primary ? quasarColors.lighten(colors.primary, -40) : undefined;
    const textOnPrimaryLight = colors.textOnPrimary ? addAlpha(colors.textOnPrimary ?? "#000", 0.7) : undefined;
    const highlightHover = colors.highlight ? addAlpha(colors.highlight, 0.1) : undefined;
    const textOnNavbarColorLight = colors.textOnNavbarColor ? addAlpha(colors.textOnNavbarColor, 0.7) : undefined;
    const navBarColorDark = colors.navBarColor ? quasarColors.lighten(colors.navBarColor, -27) : undefined;

    return {
        primary: colors.primary ?? defaultTheme.primary,
        primaryDark: primaryDark ?? defaultTheme.primaryDark,
        primaryDarker: primaryDarker ?? defaultTheme.primaryDarker,
        textOnPrimary: colors.textOnPrimary ?? defaultTheme.textOnPrimary,
        textOnPrimaryLight: textOnPrimaryLight ?? defaultTheme.textOnPrimaryLight,
        highlight: colors.highlight ?? defaultTheme.highlight,
        highlightHover: highlightHover ?? defaultTheme.highlightHover,
        link: colors.link ?? defaultTheme.link,
        icon: colors.icon ?? defaultTheme.icon,
        navBarColor: colors.navBarColor ?? defaultTheme.navBarColor,
        navBarColorDark: navBarColorDark ?? defaultTheme.navBarColorDark,
        textOnNavbarColor: colors.textOnNavbarColor ?? defaultTheme.textOnNavbarColor,
        textOnNavbarColorLight: textOnNavbarColorLight ?? defaultTheme.textOnNavbarColorLight,
    };
};

export const createThemeObject = (themeResult: ThemeQuery, title: string): InfoTwinTheme => {
    const theme = themeResult.theme;

    return {
        logoURL: theme?.logo?.url ?? "",
        logoPath: theme?.logo?.path ?? "",
        showTitle: theme?.showTitle ?? false,
        colors: convertToThemeColors(theme?.theming?.colors),
        title,
    };
};

export const getDefaultThemeColors = (): ThemeColors => {
    const primary = "#00417d";
    const navBarColor = "#00417d";
    const primaryDark = quasarColors.lighten(primary, -20);
    const primaryDarker = quasarColors.lighten(primary, -40);
    const navBarColorDark = quasarColors.lighten(navBarColor, -27);

    return {
        primary,
        primaryDark,
        primaryDarker,
        textOnPrimary: "#ffffff",
        textOnPrimaryLight: "#ffffff8b",
        highlight: "#dbeeff",
        highlightHover: "#dbeeff24",
        link: "#00417d",
        icon: "#666666",
        navBarColor,
        navBarColorDark,
        textOnNavbarColor: "#ffffff",
        textOnNavbarColorLight: "#ffffff8b",
    };
};
