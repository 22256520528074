<template>
    <div class="enumerated-navigation">
        <div
            v-for="(element, i) in formFields"
            :key="element.name"
            class="navigation-row no-user-select"
            :class="{ 'hover-enabled': hoverActive[i] }"
        >
            <div class="row" width="100%" style="justify-content: space-around">
                <div class="col-auto">
                    <Badge :text="(i + 1).toString()" :active="element.active || hoverActive[i]" />
                </div>
                <div class="col">
                    <span class="navigation-item-name" :class="{ 'navigation-active': element.active }">{{
                        $t(element.name)
                    }}</span>
                </div>
            </div>
            <div v-if="i < formFields.length - 1" class="between-line" />
        </div>
    </div>
</template>

<script lang="ts" setup>
/**
 * This component shows a navigation with line connection in the form of [Badge<index>] <name of component> rows.
 * @displayName EnumeratedNavigation
 * @example `
 *  Can be used inside Card components ConfigurationFormWrapper component navigation slot.
 *  <Card>
 *      <ConfigurationFormWrapper :key="activeFormModel">
 *          ...
 *          <template #navigation>
 *              <EnumeratedNavigation v-model="formModels" />
 *          </template>
 *      </ConfigurationFormWrapper>
 *  </Card>
 * `
 */
import { ref } from "vue";
import Badge from "@/shared/components/badge/badge.vue";

const props = defineProps<{
    /**
     * Needs an Array of Objects with at least name, valid and active properties.
     * @example [
            {
                name: "commonStep",
                active: true,
                valid: false,
                ...
            },
            {
                name: "assignStep",
                active: false,
                valid: false,
                ...
            },
        ];
    */
    modelValue: Pick<any, "name" | "active" | "valid">[];
}>();

defineEmits(["update:modelValue"]);

const formFields = ref(props.modelValue);

const hoverActive = ref(Array(formFields.value.length).fill(false));
</script>

<style lang="scss" scoped>
.enumerated-navigation {
    padding: $spacing-l;

    .screen--xl &,
    .screen--lg & {
        padding: $spacing-xl;
    }

    .navigation-row {
        .navigation-item-name {
            margin-left: $spacing-m;
            font-weight: $bold;
            color: $grey-70;
        }

        .navigation-active {
            color: var(--q-link);
        }

        .between-line {
            border-left: 2px solid $grey-70;
            height: $spacing-l;
            margin-left: 9px;
        }
    }

    .hover-enabled {
        &:hover {
            cursor: pointer;
            .navigation-item-name {
                color: var(--q-link);
            }
        }
    }
}
</style>
