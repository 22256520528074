<template>
    <div class="user-menu">
        <QitBtn
            padding="sm"
            square
            :icon="isXSmall ? mobileIcon : icon"
            size="13px"
            class="grey-hover"
            data-qs="show-user-menu-button"
            color="default"
        >
            <q-menu v-model="show" style="width: fit-content">
                <q-list dense class="q-my-sm">
                    <q-item v-if="displayName" class="justify-center">
                        <q-icon color="grey-5" size="20px" name="fa-regular fa-circle-user" class="q-pr-sm" />
                        {{ displayName }}
                    </q-item>
                    <ShowIfHasAccess
                        :feature-i-d="AccessFeature.app"
                        :resource="AccessResource.user_app_key"
                        action="write"
                        :ignore-conditions="true"
                    >
                        <q-item>
                            <q-item-section>
                                <QitBtn
                                    color="secondary"
                                    class="full-width default-border-radius"
                                    icon="fa-thin fa-mobile-button"
                                    :label="$t('core.Link device')"
                                    data-qs="connect-device-button"
                                    @click="openQrDialog"
                                />
                            </q-item-section>
                        </q-item>
                    </ShowIfHasAccess>
                    <q-item>
                        <q-item-section>
                            <q-item-label>
                                <QitBtn
                                    v-if="showLogin"
                                    color="secondary"
                                    class="q-my-xs full-width default-border-radius"
                                    icon="fa-thin fa-plus"
                                    :label="$t('core.Login')"
                                    data-qs="user-menu-login-button"
                                    @click="login"
                                />
                                <QitBtn
                                    v-if="!isAnonymousUser"
                                    color="secondary"
                                    class="q-my-xs full-width default-border-radius"
                                    icon="fa-thin fa-arrow-right-from-bracket"
                                    :label="$t('core.Logout')"
                                    data-qs="static-pages-menu-logout-button"
                                    @click="logout"
                                />
                            </q-item-label>
                        </q-item-section>
                    </q-item>
                </q-list>
            </q-menu>
            <q-tooltip>{{ $t("core.User") }}</q-tooltip>
        </QitBtn>
    </div>
    <AuthenticatorDialog
        :show="visibleDialog === 'authenticator'"
        :authenticators="authenticators"
        @on-authenticator-selected="onSelectedAuthenticator"
    />
    <CreateQrCodeDialog :visible="visibleDialog === 'qrcode'" @on-close="closeQrDialog" />
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import QitBtn from "@/shared/components/buttons/qit-button.vue";
import { useUserQuery } from "@/shared/services/graphql/generated/consumer-graph-types";
import { forceLogin, getAuthenticators, getAuthHeader, isMixedMode } from "@/shared/authentication/authentication";
import { Authenticator } from "@/shared/services/graphql/generated/public-graph-types";
import AuthenticatorDialog from "@/shared/authentication/components/authenticator-dialog.vue";
import { useScreenSize } from "@/shared/screen/composables/screen-size";
import CreateQrCodeDialog from "@/shared/components/connect-device/create-qr-code-dialog.vue";
import { AccessFeature, AccessResource } from "@/shared/access-control/access-control.ts";
import ShowIfHasAccess from "@/shared/access-control/components/show-if-has-access.vue";

defineProps<{
    icon: string;
    mobileIcon: string;
}>();

const show = ref(false);
const visibleDialog = ref<undefined | "authenticator" | "qrcode">(undefined);
const authenticators = ref<Authenticator[]>([]);

const { isXSmall } = useScreenSize();
const { result } = useUserQuery(computed(() => ({ enabled: show.value })));

const displayName = computed(() => {
    if (!result.value) {
        return "";
    }
    return result.value.me.displayName;
});

const isAnonymousUser = computed(() => {
    return !!result.value?.me.anonymous;
});

const showLogin = computed(() => {
    return isAnonymousUser.value && isMixedMode();
});

const logout = async () => {
    try {
        await fetch("/auth/logout", {
            method: "POST",
            headers: {
                Authorization: getAuthHeader(),
            },
        });
    } catch (ignore) {
        //ignore
    } finally {
        window.location.href = result.value?.me.endSessionUrl ?? window.location.origin;
    }
};

const login = async () => {
    show.value = false;
    authenticators.value = getAuthenticators();
    if (authenticators.value.length === 1) {
        await forceLogin(authenticators.value[0]);
    } else {
        visibleDialog.value = "authenticator";
    }
};

const onSelectedAuthenticator = async (authenticatorEdge: Authenticator) => {
    await forceLogin(authenticatorEdge);
};

const openQrDialog = () => {
    show.value = false;
    visibleDialog.value = "qrcode";
};
const closeQrDialog = () => {
    visibleDialog.value = undefined;
};
</script>
