import {
    Ability,
    DataProcessType,
    NavigationMenuEntry,
    Route,
    RouteElementWithParam,
    SearchTab,
    Widget,
} from "@/shared/environment/ability.types";
import ActivityImportWizard from "@/abilities/activities/components/data-process/activity-import-wizard.vue";
import { ProcessType } from "@/shared/services/graphql/generated/consumer-graph-types";
import { AccessFeature } from "@/shared/access-control/access-control";
import { CommonRouteAliases, CommonSearchTabAliases } from "@/shared/environment/common-route-aliases";
import RelatedActivitiesWidget from "@/abilities/activities/widgets/related-activities/related-activities.widget.vue";
import { createRouteFromRouteElements } from "@/shared/environment/ability-route-helper";
import { useActivityBreadcrumb } from "@/abilities/activities/composeables/activity-breadcrumbs";
import BreadcrumbSpan from "@/shared/breadcrumbs/breadcrumb-span.vue";
import { keyText } from "@/shared/i18n/translation-types";
import ActivitiesSearch from "@/abilities/activities/pages/activity-search.vue";

export const SERVICE_ACTIVITIES_ALIAS = "activities";

export class ActivitiesAbility implements Ability {
    alias = SERVICE_ACTIVITIES_ALIAS;
    access = {
        accessFeature: AccessFeature.activities,
    };

    getNavigationMenuEntries = (): Array<NavigationMenuEntry> => [
        {
            title: keyText("activities.Activities"),
            icon: "fa-regular fa-wrench",
            internalRoute: {
                alias: CommonRouteAliases.activities,
                access: {
                    accessFeature: AccessFeature.activities_menu,
                },
            },
        },
    ];

    getRoutes = (): Array<Route> => [
        {
            alias: CommonRouteAliases.activities,
            params: [],
            parentAliases: [CommonRouteAliases.asset, CommonRouteAliases.commonArticle, CommonRouteAliases.product],
            isRoot: true,
            getComponent: () => import("@/abilities/activities/pages/activities.vue"),
            getBreadCrumbs: (parentElements: Array<RouteElementWithParam>, currentElement: RouteElementWithParam) => {
                return [
                    {
                        content: {
                            componentDefinition: {
                                getComponent: () => BreadcrumbSpan,
                                props: {
                                    title: "activities.Activities",
                                    icon: "fa-wrench",
                                },
                            },
                        },
                        getRouterTo: () => createRouteFromRouteElements(parentElements, currentElement),
                    },
                ];
            },
            getSearchParams: () => {
                return { searchTab: CommonSearchTabAliases.activities };
            },
        },
        {
            alias: CommonRouteAliases.activityOverview,
            params: ["activityId"],
            parentAliases: [
                CommonRouteAliases.activities,
                CommonRouteAliases.commonArticle,
                CommonRouteAliases.product,
            ],
            getComponent: () => import("@/abilities/activities/pages/activity-overview-page.vue"),
            getBreadCrumbs: (
                parentElements: Array<RouteElementWithParam>,
                currentElement: RouteElementWithParam,
                translateMethod: (text: string) => string
            ) => {
                return useActivityBreadcrumb(parentElements, currentElement, translateMethod);
            },
            getSearchParams: () => {
                return { searchTab: CommonSearchTabAliases.activities };
            },
        },
        {
            alias: CommonRouteAliases.activityDetail,
            params: ["activityId"],
            parentAliases: [
                CommonRouteAliases.activities,
                CommonRouteAliases.commonArticle,
                CommonRouteAliases.product,
            ],
            getComponent: () => import("@/abilities/activities/pages/activity-detail-page.vue"),
            getBreadCrumbs: (
                parentElements: Array<RouteElementWithParam>,
                currentElement: RouteElementWithParam,
                translateMethod: (text: string) => string
            ) => {
                return useActivityBreadcrumb(parentElements, currentElement, translateMethod);
            },
            getSearchParams: () => {
                return { searchTab: CommonSearchTabAliases.activities };
            },
        },
    ];

    getWidgets = (): Array<Widget> => [
        {
            widgetContextAlias: ["asset", "product", "commonArticle"],
            widgetComponent: RelatedActivitiesWidget,
        },
    ];

    getDataProcessTypes = (): Array<DataProcessType> => [
        {
            type: ProcessType.activity,
            tileSettings: {
                title: "activities.Activities",
                text: "activities.Configure an import for activities for your products",
                icon: "fa-solid fa-wrench activities-color",
                cssClass: "activities-process-tile",
            },
            dialogFormComponent: {
                getComponent: () => ActivityImportWizard,
                props: {
                    title: "activities.Create New Process (Activities)",
                },
            },
        },
    ];

    getSearchTabs: () => Array<SearchTab> = () => [
        {
            alias: CommonSearchTabAliases.activities,
            title: keyText("activities.Activities"),
            searchComponent: {
                getComponent: () => ActivitiesSearch,
            },
        },
    ];
}
