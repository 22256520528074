import { SearchContext, SearchField } from "@/shared/search/search.types";
import { FacetSelected } from "@/shared/facets/facets.model";
import {
    AssetFilter,
    ContentFilter,
    FieldDefinitionTypes,
} from "@/shared/services/graphql/generated/consumer-graph-types";
import { generateSearchFieldsFilterClause } from "@/shared/utils/search/search-fields-filter-clause";

export const generateOrGroupsByFacetsSelected = (selected: FacetSelected[]) => {
    const generateOrQueryParts = (val: FacetSelected) => {
        return val.values.map((value) => {
            const filters: Record<string, string> = {};
            filters[val.referencedId] = value;
            if (val.type === FieldDefinitionTypes.taxonomy)
                return {
                    taxonomy: filters,
                };
            else
                return {
                    equals: filters,
                };
        });
    };

    const selectedWithValues = selected.length > 0 ? selected.filter((x) => x.values.length > 0) : [];

    return selectedWithValues.map((x) => ({
        orGroup: generateOrQueryParts(x),
    }));
};

export const generateQueryFilterForSearch = (
    selected: FacetSelected[],
    fuzzy: boolean,
    searchContext?: SearchContext,
    searchFields?: SearchField[],
    searchPhrase?: string
): { contentFilter: ContentFilter; assetFilter: AssetFilter | undefined; productId: string | undefined } => {
    const copySelected = [...selected];
    const contentFilter: ContentFilter = {};
    let assetFilter: AssetFilter | undefined;
    let productId: string | undefined;

    if (searchContext?.product && searchContext?.asset) {
        assetFilter = { equals: { productId: searchContext.product, assetId: searchContext.asset } };
    } else if (searchContext?.product) {
        productId = searchContext.product;
    }

    if (searchPhrase) {
        //get filter clause for search fields and add to content filter
        const searchFilterClause = generateSearchFieldsFilterClause(searchPhrase, searchFields ?? [], fuzzy);
        contentFilter.orGroup = searchFilterClause.orGroup;
    }

    //get filter clause for selected facets and add to content filter
    const orGroups = generateOrGroupsByFacetsSelected(copySelected);
    if (orGroups.length > 0) contentFilter.andGroup = orGroups;

    return { contentFilter, assetFilter, productId };
};
