import { ApolloClient, createHttpLink } from "@apollo/client/core";
import { clientCache } from "./client-cache";
import { errorLink } from "@/shared/services/apollo-clients/links/error-link";

const httpLink = createHttpLink({
    uri: "/graphql/public",
});

export const publicClient = new ApolloClient({
    link: errorLink.concat(httpLink),
    connectToDevTools: false,
    cache: clientCache,
    defaultOptions: {
        watchQuery: {
            fetchPolicy: "network-only",
        },
        query: {
            fetchPolicy: "network-only",
        },
    },
});
