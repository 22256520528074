<template>
    <AdvancedRouterLink :to="linkTo" :click-handler="clickHandler">
        <template #content>
            <component
                :is="currentComponent"
                :title="product.getTitle()"
                :id="product.id"
                :image-separator="!!product.getPreviewImage()"
                :data-qs="`product-card-${product.id}`"
                :image-src="product.getPreviewImage()"
                fallback-icon="fa-box"
            >
                <template #content>
                    <div :class="{ 'row properties': isMobile }">
                        <PropertyView
                            v-if="propertyItems && dataDisplayConfig"
                            :items="propertyItems"
                            :multiline="true"
                        />
                    </div>
                    <div v-if="showButtons && assetsActivated" class="row flex justify-center q-mt-md">
                        <AdvancedRouterLink
                            :to="getRouteToFilteredAssets(product.id)"
                            :disabled="!hasAssets"
                            class="go-to-assets-button"
                        >
                            <template #content>
                                <q-btn
                                    class="col"
                                    no-caps
                                    flat
                                    color="primary"
                                    :label="
                                        $t('product-and-asset.Show {assetsName}', {
                                            assetsName: assetName,
                                            assetCount: assetCount,
                                        })
                                    "
                                    :disable="!hasAssets"
                                />
                                <q-tooltip v-if="!hasAssets">{{ noAssetsAvailable }}</q-tooltip>
                            </template>
                        </AdvancedRouterLink>
                    </div>
                </template>
            </component>
        </template>
    </AdvancedRouterLink>
</template>

<script setup lang="ts">
import { computed, toRefs } from "vue";
import PropertyView from "@/shared/components/views/property-view.vue";
import { AdvProduct } from "../../../data-objects/advanced-product";
import { CommonRouteAliases } from "@/shared/environment/common-route-aliases";
import { useDataDisplayConfig } from "@/shared/data-display-config/composable/data-display-config";
import { RouteLocationRaw } from "vue-router";
import AdvancedRouterLink from "@/shared/components/router-link/advanced-router-link.vue";
import { DataDisplayConfigId } from "@/shared/data-display-config/composable/data-display.model";
import { CURRENT_TAB_PARAM_NAME, TAB_ASSETS_ALIAS } from "../../../helpers/product-selection-routing";
import { useAbilityRoute } from "@/shared/environment/ability-route";
import { useAssetNames } from "@/abilities/product-and-asset/composables/asset-names";
import ProductCard from "@/abilities/product-and-asset/pages/components/product-selection/product-card.vue";
import { useScreenSize } from "@/shared/screen/composables/screen-size.ts";
import ListCard from "@/shared/components/card/list-card.vue";
import { useLoadConfigProductAndAsset } from "@/shared/configurations/product-and-asset/load-product-and-asset-settings";

const props = withDefaults(
    defineProps<{
        product: AdvProduct;
        emitSelection?: boolean;
        showButtons?: boolean;
        isMobile?: boolean;
    }>(),
    {
        showButtons: true,
    }
);

const emit = defineEmits<{
    select: [productId: string];
}>();

const { isMobile, product } = toRefs(props);

const { isLessThanMedium } = useScreenSize();
const assetName = useAssetNames("pluralLower");
const noAssetsAvailable = useAssetNames("noAssetsAvailable");
const { getPathToChildrenWithoutParents, getPathToChildWithoutParents } = useAbilityRoute();

const { result: dataDisplayConfig, getDataDisplayPropertiesByConfig } = useDataDisplayConfig(
    DataDisplayConfigId.productSelection
);

const { assetsActivated } = useLoadConfigProductAndAsset();

const compactView = computed(() => {
    return isLessThanMedium.value || isMobile.value;
});

const currentComponent = computed(() => {
    return compactView.value ? ListCard : ProductCard;
});

const getRouteToFilteredAssets = (productId: string): RouteLocationRaw | undefined => {
    if (hasAssets.value) {
        return (
            getPathToChildWithoutParents(
                CommonRouteAliases.productSelection,
                {},
                { custom_products: productId, [CURRENT_TAB_PARAM_NAME]: TAB_ASSETS_ALIAS }
            ) ?? undefined
        );
    } else {
        return undefined;
    }
};

/**
 * Asset Pfad erzeugen
 * @param productId Product ID
 */
const getPathToProduct = (productId: string) => {
    return getPathToChildrenWithoutParents([
        {
            alias: CommonRouteAliases.product,
            namedParams: { [CommonRouteAliases.product]: productId },
        },
    ]);
};

const linkTo = computed(() => {
    if (product.value.id) {
        if (!props.emitSelection) {
            return getPathToProduct(props.product.id);
        }
    }
    return undefined;
});

const clickHandler = computed(() => {
    if (props.emitSelection) {
        return () => {
            if (product.value.id) {
                emit("select", product.value.id);
            }
        };
    }
    return undefined;
});

const hasAssets = computed(() => {
    return assetCount.value > 0;
});

const assetCount = computed(() => {
    return props.product?.assets?.total.count ?? 0;
});

const propertyItems = computed(() => {
    return getDataDisplayPropertiesByConfig(product.value);
});
</script>

<style lang="scss" scoped>
.properties {
    column-gap: $spacing-xxl;
}

.go-to-assets-button {
    &:deep(.q-btn:disabled) {
        background: transparent !important;
    }
}
</style>
