import { computed, ComputedRef, ref, Ref, watch } from "vue";
import { MechanicsListQueryParams, useMechanicsListQuery } from "../helpers/mechanics-list-result";
import { usePartslistFiles } from "../helpers/assembly-files-helper";
import { OperationVariables } from "@apollo/client/core";
import { Product } from "@/shared/services/graphql/generated/consumer-graph-types";
import { PRODUCT_TITLE_QUERY } from "@/shared/graphql/product-title.query";
import { useQuery } from "@vue/apollo-composable";
import { QTableProps } from "quasar";
import { getDataDisplayValue } from "@/shared/data-display-config/composable/data-display.helper";
import {
    QDataSource,
    QIT_PREVIEW_IMAGE_COLUMN,
    QIT_PREVIEW_IMAGE_ROW_FIELD,
} from "@/shared/components/table/q-data-source";

export const MECHANIC_OBJECT_KEY = "mechanicObject";

//TODO diese Query hat in der Mechanik eigentlich nix zu suchen. Ist Teil der Product-Ability
export function useProductDescriptionQuery(productFilterVariables: OperationVariables) {
    const {
        result,
        loading: productDescriptionLoading,
        onError,
    } = useQuery(PRODUCT_TITLE_QUERY, productFilterVariables);
    const product: ComputedRef<Product> = computed(() => {
        return result.value?.product ?? undefined;
    });

    onError((error) => {
        console.error(error);
    });

    const productTitle = computed(() => {
        return product.value?.localizations?.title ?? "";
    });

    return {
        productTitle,
        productDescriptionLoading,
    };
}

export function useMechanicModel(params: Ref<MechanicsListQueryParams>) {
    const {
        dataDisplayFields,
        priorityDataDisplayFields,
        error: partListError,
        data,
        loading: partListLoading,
    } = useMechanicsListQuery(params);

    const columns = computed(() => {
        if (dataDisplayFields.value) {
            const result = dataDisplayFields.value?.map((displayField) => {
                return {
                    name: displayField.key,
                    field: displayField.key,
                    label: displayField.title,
                    align: "left",
                };
            });

            return [
                {
                    name: QIT_PREVIEW_IMAGE_COLUMN,
                    field: QIT_PREVIEW_IMAGE_ROW_FIELD,
                    label: "",
                },
                ...result,
                {
                    name: "click-arrow",
                    field: "click-arrow",
                    label: "",
                },
            ];
        }

        return undefined;
    });

    const mobileColumns = computed(() => {
        const result = priorityDataDisplayFields.value.map((displayField) => {
            return {
                name: displayField.key,
                field: displayField.key,
                label: displayField.title,
                align: "left",
            };
        });

        return [
            ...result,
            {
                name: "click-arrow",
                field: "click-arrow",
                label: "",
                align: "center",
            },
        ];
    });

    const rows = ref<undefined | any[]>(undefined);
    const visibleColumns = ref<string[]>(["click-arrow", QIT_PREVIEW_IMAGE_COLUMN]);

    watch([dataDisplayFields, data], () => {
        if (data.value && dataDisplayFields.value) {
            rows.value = data.value.map((node) => {
                if (!node) {
                    return {};
                }
                const result: any = {};
                dataDisplayFields.value?.forEach((dataDisplayField) => {
                    const dataValue = getDataDisplayValue(dataDisplayField, node);
                    result[dataDisplayField.key] = dataValue.formattedValue;
                    if (dataValue.exists && !visibleColumns.value.includes(dataDisplayField.key)) {
                        visibleColumns.value.push(dataDisplayField.key);
                    }
                });
                const dataDisplayFieldId = dataDisplayFields.value?.find((field) => field.key === "id");
                const dataDisplayFieldAssemblyId = dataDisplayFields.value?.find((field) => field.key === "assemblyId");
                const dataDisplayFieldIsAssembly = dataDisplayFields.value?.find((field) => field.key === "isAssembly");
                if (!dataDisplayFieldId) {
                    result["id"] = node.id;
                }
                if (!dataDisplayFieldAssemblyId) {
                    result["assemblyId"] = node.assemblyId;
                }
                if (!dataDisplayFieldIsAssembly) {
                    result["isAssembly"] = node.isAssembly;
                }
                result[MECHANIC_OBJECT_KEY] = node;
                result[QIT_PREVIEW_IMAGE_ROW_FIELD] = node.previewImage?.url;
                return result;
            });
        }
    });

    const qDataSource: ComputedRef<QDataSource> = computed(() => {
        return {
            columns: columns.value as QTableProps["columns"],
            mobileColumns: mobileColumns.value as QTableProps["columns"],
            rows: rows.value,
            visibleColumns: visibleColumns.value,
        };
    });

    const {
        loading: imageLoading,
        image2D: images2D,
        has2DImages,
        image3D: images3D,
        has3DImages,
        error: imageError,
    } = usePartslistFiles(
        computed(() => params.value.assemblyId),
        computed(() => params.value.productId),
        computed(() => params.value.assetId)
    );

    const loading = computed(() => {
        return partListLoading.value || imageLoading.value;
    });

    const error = computed(() => {
        return partListError.value || imageError.value;
    });

    return {
        loading,
        images2D,
        has2DImages,
        images3D,
        has3DImages,
        error,
        qDataSource,
    };
}
