import { computed } from "vue";
import { useTenant } from "@/shared/composables/tenant.ts";
import { getUiLanguage } from "@/shared/services/providers/language-provider.ts";

export const useDocQueryParams = () => {
    const { tenant, loading } = useTenant();
    const guiLanguage = computed(() => getUiLanguage());

    const queryParams = computed(() => {
        const docsRouteParams = tenant.value?.docsRouteParams;
        if (!docsRouteParams) {
            return { type: "contentMap" };
        }

        const docQueryParamsCopy = { ...docsRouteParams };

        for (const key of Object.keys(docQueryParamsCopy)) {
            if (docQueryParamsCopy[key] === "{gui-language}") {
                docQueryParamsCopy[key] = docQueryParamsCopy[key].replace("{gui-language}", guiLanguage.value);
            }
        }

        return docQueryParamsCopy;
    });

    return {
        queryParams,
        loading,
    };
};
