export type AssetNameTypes =
    | "singular"
    | "plural"
    | "singularLower"
    | "pluralLower"
    | "moreSpecificInformation"
    | "selectAsset"
    | "noAssetsAvailable"
    | "ifDeleteAllAssetsOptionsMechanicWillDeleted"
    | "noAssetsWithSetFilterAvailable"
    | "goToAssets"
    | "notViewedAnyAssetsYet"
    | "assetIsBeingDeleted"
    | "assetIsDeleted";

export type ProductNameTypes =
    | "singular"
    | "plural"
    | "singularLower"
    | "pluralLower"
    | "noProductsAvailable"
    | "noProductsWithSetFilterAvailable"
    | "notViewedAnyProductsYet"
    | "goToProducts"
    | "productIsBeingDeleted"
    | "productIsDeleted";

interface AssetTextKeys {
    singular: string;
    plural: string;
    singularLower: string;
    pluralLower: string;
    moreSpecificInformation: string;
    selectAsset: string;
    noAssetsAvailable: string;
    ifDeleteAllAssetsOptionsMechanicWillDeleted: string;
    noAssetsWithSetFilterAvailable: string;
    notViewedAnyAssetsYet: string;
    goToAssets: string;
    assetIsBeingDeleted: string;
    assetIsDeleted: string;
}

interface ProductTextKeys {
    singular: string;
    plural: string;
    singularLower: string;
    pluralLower: string;
    noProductsAvailable: string;
    noProductsWithSetFilterAvailable: string;
    notViewedAnyProductsYet: string;
    goToProducts: string;
    productIsBeingDeleted: string;
    productIsDeleted: string;
}

export const assetTextKeys: Record<string, AssetTextKeys> = {
    ASSETS: {
        plural: "Assets",
        singular: "Asset",
        pluralLower: "assets",
        singularLower: "asset",
        moreSpecificInformation: "To get more specific information, please select an asset",
        selectAsset: "Select an asset",
        noAssetsAvailable: "No assets are available",
        ifDeleteAllAssetsOptionsMechanicWillDeleted:
            "If you delete {product}, all related assets, options, and mechanics will be irrevocably deleted",
        noAssetsWithSetFilterAvailable:
            "No assets found with the set filters Adjust the filter criteria to get more results",
        notViewedAnyAssetsYet: "You have not viewed any assets yet",
        goToAssets: "Go to assets",
        assetIsBeingDeleted: "Asset is being deleted",
        assetIsDeleted: "Asset is deleted",
    },
    PRODUCTS: {
        plural: "Products",
        singular: "Product",
        pluralLower: "products",
        singularLower: "product",
        moreSpecificInformation: "To get more specific information, please select a product",
        selectAsset: "Select a product",
        noAssetsAvailable: "No products are available",
        ifDeleteAllAssetsOptionsMechanicWillDeleted:
            "If you delete {product}, all related products, options, and mechanics will be irrevocably deleted",
        noAssetsWithSetFilterAvailable:
            "No products found with the set filters Adjust the filter criteria to get more results",
        notViewedAnyAssetsYet: "You have not viewed any products yet",
        goToAssets: "Go to products",
        assetIsBeingDeleted: "Product is being deleted",
        assetIsDeleted: "Product is deleted",
    },
    MACHINES: {
        plural: "Machines",
        singular: "Machine",
        pluralLower: "machines",
        singularLower: "machine",
        moreSpecificInformation: "To get more specific information, please select a machine",
        selectAsset: "Select a machine",
        noAssetsAvailable: "No machines are available",
        ifDeleteAllAssetsOptionsMechanicWillDeleted:
            "If you delete {product}, all related machines, options, and mechanics will be irrevocably deleted",
        noAssetsWithSetFilterAvailable:
            "No machines found with the set filters Adjust the filter criteria to get more results",
        notViewedAnyAssetsYet: "You have not viewed any machines yet",
        goToAssets: "Go to machines",
        assetIsBeingDeleted: "Machine is being deleted",
        assetIsDeleted: "Machine is deleted",
    },
    EQUIPMENTS: {
        plural: "Equipments",
        singular: "Equipment",
        pluralLower: "equipments",
        singularLower: "equipment",
        moreSpecificInformation: "To get more specific information, please select an equipment",
        selectAsset: "Select an equipment",
        noAssetsAvailable: "No equipments are available",
        ifDeleteAllAssetsOptionsMechanicWillDeleted:
            "If you delete {product}, all related equipments, options, and mechanics will be irrevocably deleted",
        noAssetsWithSetFilterAvailable:
            "No equipments found with the set filters Adjust the filter criteria to get more results",
        notViewedAnyAssetsYet: "You have not viewed any equipments yet",
        goToAssets: "Go to equipments",
        assetIsBeingDeleted: "Equipment is being deleted",
        assetIsDeleted: "Equipment is deleted",
    },
    PLANTS: {
        plural: "Plants",
        singular: "Plant",
        pluralLower: "plants",
        singularLower: "plant",
        moreSpecificInformation: "To get more specific information, please select a plant",
        selectAsset: "Select a plant",
        noAssetsAvailable: "No plants are available",
        ifDeleteAllAssetsOptionsMechanicWillDeleted:
            "If you delete {product}, all related plants, options, and mechanics will be irrevocably deleted",
        noAssetsWithSetFilterAvailable:
            "No plants found with the set filters Adjust the filter criteria to get more results",
        notViewedAnyAssetsYet: "You have not viewed any plants yet",
        goToAssets: "Go to plants",
        assetIsBeingDeleted: "Plant is being deleted",
        assetIsDeleted: "Plant is deleted",
    },
    DEVICES: {
        plural: "Devices",
        singular: "Device",
        pluralLower: "devices",
        singularLower: "device",
        moreSpecificInformation: "To get more specific information, please select a device",
        selectAsset: "Select a device",
        noAssetsAvailable: "No devices are available",
        ifDeleteAllAssetsOptionsMechanicWillDeleted:
            "If you delete {product}, all related devices, options, and mechanics will be irrevocably deleted",
        noAssetsWithSetFilterAvailable:
            "No devices found with the set filters Adjust the filter criteria to get more results",
        notViewedAnyAssetsYet: "You have not viewed any devices yet",
        goToAssets: "Go to devices",
        assetIsBeingDeleted: "Device is being deleted",
        assetIsDeleted: "Device is deleted",
    },
    VEHICLES: {
        plural: "Vehicles",
        singular: "Vehicle",
        pluralLower: "vehicles",
        singularLower: "vehicle",
        moreSpecificInformation: "To get more specific information, please select a vehicle",
        selectAsset: "Select a vehicle",
        noAssetsAvailable: "No vehicles are available",
        ifDeleteAllAssetsOptionsMechanicWillDeleted:
            "If you delete {product}, all related vehicles, options, and mechanics will be irrevocably deleted",
        noAssetsWithSetFilterAvailable:
            "No vehicles found with the set filters Adjust the filter criteria to get more results",
        notViewedAnyAssetsYet: "You have not viewed any vehicles yet",
        goToAssets: "Go to vehicles",
        assetIsBeingDeleted: "Vehicle is being deleted",
        assetIsDeleted: "Vehicle is deleted",
    },
    VERSIONS: {
        plural: "Versions",
        singular: "Version",
        pluralLower: "versions",
        singularLower: "version",
        moreSpecificInformation: "To get more specific information, please select a version",
        selectAsset: "Select a version",
        noAssetsAvailable: "No versions are available",
        ifDeleteAllAssetsOptionsMechanicWillDeleted:
            "If you delete {product}, all related versions, options, and mechanics will be irrevocably deleted",
        noAssetsWithSetFilterAvailable:
            "No versions found with the set filters Adjust the filter criteria to get more results",
        goToAssets: "Go to versions",
        notViewedAnyAssetsYet: "You have not viewed any versions yet",
        assetIsBeingDeleted: "Version is being deleted",
        assetIsDeleted: "Version is deleted",
    },
};

export const productTextKeys: Record<string, ProductTextKeys> = {
    PRODUCTS: {
        plural: "Products",
        singular: "Product",
        pluralLower: "products",
        singularLower: "product",
        noProductsAvailable: "No products are available",
        noProductsWithSetFilterAvailable:
            "No products found with the set filters Adjust the filter criteria to get more results",
        notViewedAnyProductsYet: "You have not viewed any products yet",
        goToProducts: "Go to products",
        productIsBeingDeleted: "Product is being deleted",
        productIsDeleted: "Product is deleted",
    },
    DEVICES: {
        plural: "Devices",
        singular: "Device",
        pluralLower: "devices",
        singularLower: "device",
        noProductsAvailable: "No devices are available",
        noProductsWithSetFilterAvailable:
            "No devices found with the set filters Adjust the filter criteria to get more results",
        notViewedAnyProductsYet: "You have not viewed any devices yet",
        goToProducts: "Go to devices",
        productIsBeingDeleted: "Device is being deleted",
        productIsDeleted: "Device is deleted",
    },
    MODELS: {
        plural: "Models",
        singular: "Model",
        pluralLower: "models",
        singularLower: "model",
        noProductsAvailable: "No models are available",
        noProductsWithSetFilterAvailable:
            "No models found with the set filters Adjust the filter criteria to get more results",
        notViewedAnyProductsYet: "You have not viewed any models yet",
        goToProducts: "Go to models",
        productIsBeingDeleted: "Model is being deleted",
        productIsDeleted: "Model is deleted",
    },
    TYPES: {
        plural: "Types",
        singular: "Type",
        pluralLower: "types",
        singularLower: "type",
        noProductsAvailable: "No types are available",
        noProductsWithSetFilterAvailable:
            "No types found with the set filters Adjust the filter criteria to get more results",
        notViewedAnyProductsYet: "You have not viewed any types yet",
        goToProducts: "Go to types",
        productIsBeingDeleted: "Type is being deleted",
        productIsDeleted: "Type is deleted",
    },
    MACHINES: {
        plural: "Machines",
        singular: "Machine",
        pluralLower: "machines",
        singularLower: "machine",
        noProductsAvailable: "No machines are available",
        noProductsWithSetFilterAvailable:
            "No machines found with the set filters Adjust the filter criteria to get more results",
        notViewedAnyProductsYet: "You have not viewed any machines yet",
        goToProducts: "Go to machines",
        productIsBeingDeleted: "Machine is being deleted",
        productIsDeleted: "Machine is deleted",
    },
    PLANTS: {
        plural: "Plants",
        singular: "Plant",
        pluralLower: "plants",
        singularLower: "plant",
        noProductsAvailable: "No plants are available",
        noProductsWithSetFilterAvailable:
            "No plants found with the set filters Adjust the filter criteria to get more results",
        notViewedAnyProductsYet: "You have not viewed any plants yet",
        goToProducts: "Go to plants",
        productIsBeingDeleted: "Plant is being deleted",
        productIsDeleted: "Plant is deleted",
    },
};
