import { RouteLocationNormalized, Router } from "vue-router";
import PageNotFound from "@/shared/404/pages/page-not-found.vue";

/**
 * If an error occurs or access is forbidden,
 * a route with the same url but different component and name is added to the router
 *
 * @param to
 * @param router
 * @returns redirect to same page URL with PageNotFound-Component
 */
export function buildRedirectToPageNotFound(router: Router, to: RouteLocationNormalized) {
    const routeName = to.name?.toString() + " forbidden ";
    const forbiddenRoute = {
        name: routeName,
        path: to.path,
        component: PageNotFound,
    };
    router.addRoute(forbiddenRoute);
    return { name: forbiddenRoute.name };
}
