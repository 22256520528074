<template>
    <div class="collections-form">
        <div class="input-label">{{ $t("core.Name") }}</div>
        <q-input
            outlined
            v-model="displayName"
            :placeholder="$t('collections.Collection title')"
            class="collection-form-input q-mb-md"
            :dense="true"
            data-qs="collections-display-name-input"
        />
        <div class="input-label">{{ $t("core.Description") }}</div>
        <q-input
            outlined
            v-model="description"
            :placeholder="$t('collections.Description of the collection (optional)')"
            class="collection-form-input"
            :dense="true"
            type="textarea"
            rows="3"
            data-qs="collections-description-input"
        />
        <ShowIfHasAccess
            :resource="AccessResource.collections"
            :action="AccessAction.write"
            :external-context="{ visibility: CollectionVisibilityTypes.public }"
        >
            <q-toggle
                v-if="!collection"
                v-model="isPublic"
                :label="$t('collections.Collection is public')"
                class="collection-form-switch"
            />
        </ShowIfHasAccess>
        <div :class="{ btnContainer: isXSmall }" class="collection-form-buttons">
            <QitBtn
                :disable="!isValid || loading"
                @click="!!collection ? onEdit() : onCreate()"
                :label="!!collection ? $t('collections.Save') : $t('collections.Create collection')"
                :data-qs="!!collection ? 'collections-save-button' : 'collections-create-button'"
            />
        </div>
    </div>
</template>

<script setup lang="ts">
import QitBtn from "@/shared/components/buttons/qit-button.vue";
import { AccessAction, AccessResource } from "@/shared/access-control/access-control.ts";
import ShowIfHasAccess from "@/shared/access-control/components/show-if-has-access.vue";
import { computed, ref, toRefs, watchEffect } from "vue";
import { CollectionData, CollectionEditData, CollectionVisibilityTypes } from "../models/collections.models.ts";
import { useScreenSize } from "@/shared/screen/composables/screen-size.ts";

const props = defineProps<{
    collection?: CollectionEditData;
    loading?: boolean;
}>();

const emit = defineEmits<{
    onCreate: [newCollection: CollectionData];
    onEdit: [editedCollection: CollectionEditData];
}>();

const { collection, loading } = toRefs(props);
const displayName = ref<string>("");
const description = ref<string | undefined>();
const isPublic = ref<boolean>(false);

watchEffect(() => {
    displayName.value = collection.value?.displayName! ?? "";
    description.value = collection.value?.description;
    isPublic.value = collection.value?.visibility === CollectionVisibilityTypes.public;
});

const { isXSmall } = useScreenSize();

const visibility = computed(() => {
    return isPublic.value ? CollectionVisibilityTypes.public : CollectionVisibilityTypes.personal;
});

const isValid = computed(() => {
    return displayName.value.length > 0;
});

const onCreate = () => {
    emit("onCreate", {
        displayName: displayName.value,
        description: description.value,
        visibility: visibility.value,
    });
};

const onEdit = () => {
    if (!collection.value) return;

    emit("onEdit", {
        id: collection.value.id,
        displayName: displayName.value,
        description: description.value,
        visibility: visibility.value,
    });
};
</script>

<style lang="scss" scoped>
.collections-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: $spacing-s;

    & .btnContainer {
        display: flex;
        align-items: flex-end;
        flex: 1;
    }

    .collection-form-switch {
        margin-top: 12px;
    }

    .collection-form-buttons {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        margin-top: 12px;
    }
}
</style>
