import { gql } from "graphql-tag";

gql`
    query toc($filter: ContentMapFilter, $paths: [String!], $first: NonNegativeInt!, $after: String!) {
        contentMaps(filter: $filter) {
            contentMaps(first: 1) {
                node {
                    id
                    contentId
                    toc(paths: $paths) {
                        total {
                            count
                            countRelation
                        }
                        tocEntries(first: $first, after: $after) {
                            id
                            path
                            cursor
                            childPath
                            hasChildren
                            node {
                                id
                                teasers {
                                    title
                                }
                                ... on Topic {
                                    url
                                    mimeType
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

gql`
    query tocPathAndTitle($rootContentId: ID, $contentId: ID) {
        contentInContext(rootContentId: $rootContentId, contentId: $contentId) {
            path
            id
            childPath
            hasChildren
            node {
                id
                ... on Topic {
                    url
                    mimeType
                    teasers {
                        title(length: 200)
                    }
                }
            }
        }
    }
`;

export const getQueryTopicIsAllowedInContext = (productId: string | undefined, assetId: string | undefined) => {
    const contentFragment = `relatedContents(filter: $filter) {
        contents {
            node {
                id
            }
        }
    }`;

    if (assetId) {
        const query = `query topicIsAllowedInContext($assetId: String!, $filter: ContentFilter!) {
            asset(id: $assetId) {
                ${contentFragment}
            }
        }`;
        return gql(query);
    }

    if (productId) {
        const query = `query topicIsAllowedInContext($productId: String!, $filter: ContentFilter!) {
            product(id: $productId) {
                ${contentFragment}
            }
        }`;
        return gql(query);
    }

    return undefined;
};

gql`
    query topicAtPath($filter: ContentMapFilter, $paths: [String!]) {
        contentMaps(filter: $filter) {
            contentMaps {
                node {
                    id
                    toc(paths: $paths) {
                        tocEntries {
                            id
                            path
                            node {
                                id
                                ... on Topic {
                                    contentId
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;
