import { ContentEdge, useRelatedContentsForAssetQuery } from "@/shared/services/graphql/generated/consumer-graph-types";
import { computed } from "vue";
import { mapContentEdgesToDocuments } from "../../mapper/content-edges.mapper.ts";
import { RelatedDocumentsQuery } from "./related-documents-query-factory";

export function useRelatedDocumentsForAsset(assetId: string, productId: string, count: number): RelatedDocumentsQuery {
    const assetFilter = { filter: { equals: { productId: productId, assetId: assetId } }, first: count };

    const { loading, error, result, fetchMore: fetchMoreContent } = useRelatedContentsForAssetQuery(assetFilter);

    const docs = computed(() => {
        if (!result?.value?.assets?.assets[0]?.node?.relatedContents.contents) return undefined;

        return mapContentEdgesToDocuments(
            result?.value?.assets?.assets[0]?.node?.relatedContents.contents as ContentEdge[]
        );
    });

    const total = computed(() => {
        if (!result?.value?.assets?.assets[0]?.node?.relatedContents) return undefined;

        return result?.value.assets?.assets[0].node.relatedContents.total?.count;
    });

    const fetchMore = (cursor: string, moreCount: number) => {
        return fetchMoreContent({ variables: { ...assetFilter, first: moreCount, after: cursor } });
    };

    return {
        loading,
        error,
        docs,
        total,
        fetchMore,
    };
}
