<!-- 

    Einfaches Div, welches bei Änderungen im Dom-Baum seiner Elemente  ein Event feuert.

    Verwendung:
    <MutationObserverDiv
             class="flex-container flex-row flex-space-between flex-align-center drawer-content"
             @on-change="onMutation"
    >

    // onChange-Methode werden die aktuellen Children und die attribute übergeben
    function onMutation(children: HTMLCollection, attributes: NamedNodeMap) {
        mache etwas
    }
 -->

<template>
    <div ref="mainHtmlElement">
        <slot />
    </div>
</template>

<script setup lang="ts">
import { onMounted, onBeforeUnmount, ref, nextTick } from "vue";

const emit = defineEmits<{
    (e: "onChange", children: HTMLCollection, attributes: NamedNodeMap): void;
}>();

const mainHtmlElement = ref<HTMLElement>();

let observer: MutationObserver | undefined = undefined;

async function fireOnMutation() {
    await nextTick();

    if (mainHtmlElement.value) {
        emit("onChange", mainHtmlElement.value.children, mainHtmlElement.value.attributes);
    }
}

onMounted(() => {
    if (mainHtmlElement.value) {
        // Options for the observer (which mutations to observe)
        const config = { attributes: true, childList: true, subtree: true };

        observer = new MutationObserver(fireOnMutation);
        observer.observe(mainHtmlElement.value, config);
    }
});

onBeforeUnmount(() => {
    if (observer) {
        observer.disconnect();
    }
});
</script>
