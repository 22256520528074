import {
    ActivityCollectionEntryNode,
    CollectionEntryEdge,
} from "@/shared/services/graphql/generated/consumer-graph-types.ts";
import { CommonRouteAliases } from "@/shared/environment/common-route-aliases.ts";
import { ActivityCollectionEntry } from "@/abilities/collections/components/collection-entry.types.ts";
import { PropertyItem } from "@/shared/components/views/property-item.model.ts";
import { useActivityRoute } from "@/shared/router/activity-route.ts";
import { SearchContext } from "@/shared/search/search.types.ts";

export const mapToActivityCollectionEntry = (
    entry: CollectionEntryEdge,
    getDataDisplayPropertiesByConfig: (graphqlResultObject: any) => PropertyItem[]
): ActivityCollectionEntry => {
    const { calculatePathForActivitiesSubalias } = useActivityRoute();

    const activity = (entry.node as ActivityCollectionEntryNode).activity;
    let subheading = entry.node.navContext?.product?.localizations?.title || "";
    if (entry.node.navContext?.asset?.assetId) subheading += ` / ${entry.node.navContext.asset.assetId}`;

    const searchContext: SearchContext = {};
    if (entry.node.navContext?.product?.id) searchContext.product = entry.node.navContext.product.id;
    if (entry.node.navContext?.asset?.assetId) searchContext.asset = entry.node.navContext?.asset.assetId;

    const returnActivity: ActivityCollectionEntry = {
        activityId: activity.id,
        language: activity.language,
        title: activity.localizations?.title || activity.id,
        subheading,
        cursor: entry.cursor,
        routeLocation: calculatePathForActivitiesSubalias(
            CommonRouteAliases.activityOverview,
            activity.id,
            searchContext
        ),
        properties: getDataDisplayPropertiesByConfig(activity),
        __typename: "Activity",
    };

    if (entry.node.navContext?.asset?.assetId || entry.node.navContext?.product?.id) {
        returnActivity.useContext = {
            assetId: entry.node.navContext?.asset?.assetId || undefined,
            productId: entry.node.navContext?.product?.id || undefined,
        };
    }

    return returnActivity;
};
