import { useAbilityStore } from "@/shared/store/ability.store";
import { storeToRefs } from "pinia";
import { Ability, SearchTab } from "@/shared/environment/ability.types";
import { computed, ComputedRef, ref } from "vue";
import { watchImmediate } from "@vueuse/core";
import { useRoute } from "vue-router";
import { CommonSearchTabAliases } from "@/shared/environment/common-route-aliases";
import { useAbilityRoute } from "@/shared/environment/ability-route";
import { useLoadConfigProductAndAsset } from "@/shared/configurations/product-and-asset/load-product-and-asset-settings";

export const useSearchTabsFromAbilities = () => {
    const abilityStore = useAbilityStore();
    const { abilities } = storeToRefs(abilityStore);
    const route = useRoute();

    const searchTabs = ref<SearchTab[]>([]);

    const { assetsActivated } = useLoadConfigProductAndAsset();

    const { getCurrentSearchParams } = useAbilityRoute();

    const searchTabsAll: ComputedRef<SearchTab[]> = computed(() => {
        const tabs: SearchTab[] = abilities.value
            .filter((ability: Ability) => {
                return !!ability.getSearchTabs;
            })
            .flatMap((x: Ability) => x.getSearchTabs!());
        return tabs;
    });

    watchImmediate(
        () => [assetsActivated.value, route.query, route.fullPath],
        async () => {
            const { searchParam } = await getCurrentSearchParams();
            const { asset, product } = searchParam;
            //we have to convert to any because of a typescript bug with Type SearchTab
            //check if assets are activated, if not, remove assets-searchtab
            const filteredTabs = (searchTabsAll.value.map((x: SearchTab) => x) as any).filter((tab: SearchTab) => {
                if (tab.alias === CommonSearchTabAliases.assets && !assetsActivated.value) {
                    return false;
                } else {
                    return true;
                }
            });

            if (!asset && !product) {
                searchTabs.value = filteredTabs;
                return;
            }

            if (asset)
                searchTabs.value = [...filteredTabs].filter(
                    (x) => x.alias !== CommonSearchTabAliases.products && x.alias !== CommonSearchTabAliases.assets
                );
            else if (product)
                searchTabs.value = [...filteredTabs].filter((x) => x.alias !== CommonSearchTabAliases.products);
            else searchTabs.value = [...filteredTabs];
        }
    );

    return {
        searchTabs,
    };
};
