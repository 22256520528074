<template>
    <ProductsOverview ref="productsOverviewRef" :full-text-search-value="fullTextSearchValue" :is-search="true" />
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import ProductsOverview from "@/abilities/product-and-asset/pages/products-overview.vue";

const props = defineProps<{
    search: Record<string, string>;
}>();

const productsOverviewRef = ref<typeof ProductsOverview | null>(null);

const fullTextSearchValue = computed(() => {
    return props.search.text;
});
</script>
