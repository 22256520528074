<template>
    <div class="row flex-grow">
        <div>
            <QitBtn
                color="white"
                size="13px"
                padding="sm"
                class="q-ml-sm"
                @click="showPopup"
                :icon="
                    selectedFacetsCount > 0
                        ? 'fa-regular fa-filter primary-color'
                        : 'fa-regular fa-filter default-text-color'
                "
                :label="
                    $t('core.Filter{activeFilterCount}', {
                        activeFilterCount: selectedFacetsCount > 0 ? ` (${selectedFacetsCount})` : '',
                    })
                "
                data-qs="facet-modal-open-btn-mobile"
            />
        </div>
        <div class="sorting-container row flex-grow justify-end items-center">
            <ListActions
                :more-menu-entries="moreMenuEntries"
                :sort-order-entries="sortOrderEntries"
                :initial-selected-sort-entry="initialSelectedSortEntry"
                @on-sort-selection-changed="onSortSelectionChanged"
                :sort-save-key="sortSaveKey"
            />
        </div>
        <q-dialog v-model="popupVisible" @update:model-value="popupVisibleUpdate" :maximized="isXSmall">
            <q-card :class="['filter-dialog-card column fit q-pa-md', isXSmall ? '' : 'card-Large']">
                <div class="align-center col-auto">
                    <div class="q-py-sm q-pl-sm q-pr-md row">
                        <div class="col-grow text-weight-bold text-h6">
                            <i class="fa-regular fa-filter default-text-color" />
                            {{ $t("core.Filter search results") }}
                        </div>
                        <q-btn
                            class="col-auto"
                            round
                            size="sm"
                            icon="fa fa-solid fa-xmark icon-color"
                            @click="hidePopup()"
                            data-qs="facet-modal-close-btn-mobile"
                        />
                    </div>
                    <q-separator />
                </div>
                <q-scroll-area class="col-grow">
                    <div v-for="facet in facets" :key="facet.id" class="q-ma-sm">
                        <FacetDropdown
                            v-bind="getFacetDropdownProps(facet)"
                            @on-value-changed="onValueChangedHandler"
                            @on-open-filter="onOpenFilterHandler"
                        />
                    </div>
                </q-scroll-area>
                <div class="col-auto">
                    <q-separator />
                    <div class="row justify-between q-pa-sm q-mt-md full-width">
                        <q-btn
                            no-caps
                            unelevated
                            color="grey-2"
                            text-color="on-link-color"
                            :label="$t('core.Reset filter')"
                            @click="deleteFilterSelection"
                            :disabled="disableDeleteButton"
                        />
                        <q-btn
                            no-caps
                            unelevated
                            color="primary"
                            :label="$t('core.Show results')"
                            @click="hidePopup"
                            data-qs="show-result-btn"
                        />
                    </div>
                </div>
            </q-card>
        </q-dialog>
    </div>
</template>

<script lang="ts" setup>
import { computed, ref, toRefs, watch } from "vue";
import { Facet, FacetSelected } from "@/shared/facets/facets.model";
import FacetDropdown, { FacetDropdownProps } from "@/shared/facets/dropdown/facet-dropdown.vue";
import QitBtn from "@/shared/components/buttons/qit-button.vue";
import { useScreenSize } from "@/shared/screen/composables/screen-size";
import { SortEntry, SortOrderEntry } from "@/shared/components/sort-dropdown/sort-dropdown.model";
import ListActions from "@/shared/list-filter/list-actions.vue";
import { MenuComponentEntry, MenuTextEntry } from "@/shared/components/menu/menu.model";
import { SortSaveKey } from "@/shared/components/sort-dropdown/composables/sort-settings";

const props = defineProps<{
    facets: Facet[];
    selected: FacetSelected[];
    disableDeleteButton: boolean;
    getFacetDropdownProps: (facet: Facet) => FacetDropdownProps;
    isMobileView: boolean;
    sortOrderEntries?: SortOrderEntry[];
    initialSelectedSortEntry?: SortEntry;
    moreMenuEntries?: (MenuTextEntry | MenuComponentEntry)[];
    sortSaveKey: SortSaveKey;
}>();

const emits = defineEmits<{
    onResetSelection: [];
    onValueChanged: [facetSelected: FacetSelected];
    onSortSelectionChanged: [sortEntry: SortEntry];
    onOpenFilter: [facet: Facet];
}>();

const { isXSmall } = useScreenSize();

const { disableDeleteButton, selected, sortOrderEntries, moreMenuEntries } = toRefs(props);
const popupVisible = ref<boolean>(false);

const onValueChangedHandler = (facetSelected: FacetSelected) => {
    emits("onValueChanged", facetSelected);
};

const onOpenFilterHandler = (facet: Facet) => {
    emits("onOpenFilter", facet);
};

//TODO remove isMobileView as prop and use useScreenSize instead
//close popup if screen sizes isn't xs anymore
watch(
    () => props.isMobileView,
    (val) => {
        if (!val) popupVisible.value = false;
    }
);

const popupVisibleUpdate = () => {
    showPopup();
};

const selectedFacetsCount = computed(() => {
    return selected.value.reduce((accumulator, val) => accumulator + val.values.length, 0);
});

const showPopup = () => {
    popupVisible.value = true;
};

const hidePopup = () => {
    popupVisible.value = false;
};

const deleteFilterSelection = () => {
    emits("onResetSelection");
};

const onSortSelectionChanged = (sortEntry: SortEntry) => {
    emits("onSortSelectionChanged", sortEntry);
};
</script>

<style lang="scss" scoped>
.filter-dialog-card {
    height: 95% !important;

    &:deep(.facet-dropdown) {
        width: 100%;
        max-width: 100%;
    }
}

.card-Large {
    min-width: 498px;
}
</style>
