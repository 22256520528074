import { provideApolloClient } from "@vue/apollo-composable";
import { consumerClient } from "@/shared/services/apollo-clients/consumer-client.ts";
import { AccessComposableDocument } from "@/shared/services/graphql/generated/consumer-graph-types.ts";
import {
    hasActionAndResourceAccess,
    hasFeatureAccess,
    useHasAccessParams,
} from "@/shared/access-control/composables/use-has-access.ts";

/**
 * Async function evaluating if a user has access rights
 *
 * @returns a Promise with a boolean result (if the user has access) that can be awaited
 */
export async function useHasAccessAsync({
    resource,
    action,
    featureID,
    externalContext,
    ignoreConditions,
}: useHasAccessParams): Promise<boolean> {
    provideApolloClient(consumerClient);
    const result = await consumerClient.query({
        query: AccessComposableDocument,
        fetchPolicy: "cache-first",
    });

    if (featureID) {
        const hasFeature = hasFeatureAccess(featureID, result.data);
        if (!hasFeature) return false;

        if (!action && !resource) return hasFeature;
    }

    //@ts-ignore
    return hasActionAndResourceAccess(result.data, result.data, action, resource, externalContext, ignoreConditions);
}
