<template>
    <template v-if="!loading">
        <ListFilter
            v-if="!isMobileView"
            :facets="facets"
            :selected="selected"
            :get-facet-dropdown-props="getFacetDropdownProps"
            :disable-delete-button="disableDeleteButton"
            :initial-selected-sort-entry="initialSelectedSortEntry"
            :sort-order-entries="sortOrderEntries"
            :more-menu-entries="moreMenuEntries"
            :sort-save-key="sortSaveKey"
            @on-reset-filter-selection="deleteFilterSelection"
            @on-sort-selection-changed="emitOnSortSelectionChanged"
            @on-open-filter="onOpenFilterHandler"
            @on-value-changed="valueChangedHandler"
        />
        <ListFilterAsDialog
            v-else
            :facets="facets"
            :selected="selected"
            :get-facet-dropdown-props="getFacetDropdownProps"
            :disable-delete-button="disableDeleteButton"
            :is-mobile-view="isMobileView"
            :initial-selected-sort-entry="initialSelectedSortEntry"
            :sort-order-entries="sortOrderEntries"
            :more-menu-entries="moreMenuEntries"
            :sort-save-key="sortSaveKey"
            @on-reset-selection="deleteFilterSelection"
            @on-sort-selection-changed="emitOnSortSelectionChanged"
            @on-open-filter="onOpenFilterHandler"
            @on-value-changed="valueChangedHandler"
        />
    </template>
</template>

<script setup lang="ts">
import { computed, ref, toRefs, watch } from "vue";
import { Facet, FacetSelected } from "../facets/facets.model";
import { useFacets } from "@/shared/facets/facets";
import ListFilterAsDialog from "@/shared/list-filter/list-filter-as-dialog.vue";
import { isEqual } from "lodash";
import ListFilter from "@/shared/list-filter/list-filter.vue";
import { SortEntry, SortOrderEntry } from "@/shared/components/sort-dropdown/sort-dropdown.model";
import { MenuComponentEntry, MenuTextEntry } from "@/shared/components/menu/menu.model";
import { PRODUCT_REFERENCED_ID } from "@/shared/facets/facet-utils";
import { SortSaveKey } from "@/shared/components/sort-dropdown/composables/sort-settings";
import { FacetDropdownProps } from "@/shared/facets/dropdown/facet-dropdown.vue";

export interface ListFilterRendererProps {
    selected: FacetSelected[];
    facetCollectionId: string;
    fetchAggregation?: (facetID: string) => Promise<Record<string, number>>;
    isMobileView?: boolean;
    preselect?: Record<string, string[]>;
    moreMenuEntries?: (MenuTextEntry | MenuComponentEntry)[];
    sortOrderEntries?: SortOrderEntry[];
    initialSelectedSortEntry?: SortEntry;
    product?: string;
    sortSaveKey: SortSaveKey;
}

const props = defineProps<ListFilterRendererProps>();

const emits = defineEmits<{
    "update:selected": [facetsSelected: FacetSelected[]];
    onOpenFilter: [facet: Facet];
    onSortSelectionChanged: [sortEntry: SortEntry];
}>();

const facetsSelected = ref<FacetSelected[]>([]);
const currentSelected = ref<Record<string, string[]>>({});
const disableDeleteButton = ref<boolean>(true);
const { preselect, selected, moreMenuEntries, product } = toRefs(props);

const onOpenFilterHandler = (facet: Facet) => {
    emits("onOpenFilter", facet);
};

const { facets, loading } = useFacets(props.facetCollectionId);

const setFacetsSelectedByPreselectedProp = (preselection: Record<string, string[]> | undefined) => {
    const validSelected: FacetSelected[] = [];

    //validate preselect values to see if they are included in the facets list
    if (preselection) {
        for (const key of Object.keys(preselection)) {
            const existingFacet = facets.value.find((x) => x.id === key);
            if (existingFacet) {
                validSelected.push({
                    id: existingFacet.id,
                    values: [...preselection[key]],
                    type: existingFacet.facetType,
                    referencedId: existingFacet.referencedId,
                });
            }
        }
    }

    if (isEqual(facetsSelected.value, validSelected)) return;

    facetsSelected.value = validSelected;
    emits("update:selected", facetsSelected.value);
};

//watcher handles preselect facets and add product facet in case of product context
watch(
    () => [facets.value, preselect?.value, product?.value],
    () => {
        let selection = preselect?.value ?? {};

        if (product?.value) {
            const productSelected = { [PRODUCT_REFERENCED_ID]: [product.value] };
            selection = { ...selection, ...productSelected };
        }

        setFacetsSelectedByPreselectedProp(selection);
    }
);

watch(
    facetsSelected,
    () => {
        const newSelected: Record<string, string[]> = {};
        facetsSelected.value.forEach((x) => {
            newSelected[x.referencedId] = x.values;
        });

        currentSelected.value = newSelected;
        setDisableDeleteButton();
    },
    { deep: true }
);

const setDisableDeleteButton = () => {
    //ignore product facet if we are in a product context
    const selected = facetsSelected.value.filter((x) => {
        if (!x.values.length) return false;

        if (product?.value && x.referencedId === PRODUCT_REFERENCED_ID) return false;

        return !!x.values.length;
    });

    disableDeleteButton.value = selected.length === 0;
};

const valueChangedHandler = (value: FacetSelected) => {
    const values = value.values;
    const found = facetsSelected.value.find((x) => x.referencedId === value.referencedId);

    if (found) {
        found.values = values;
    } else {
        facetsSelected.value.push(value);
    }
    emits("update:selected", facetsSelected.value);
};

const deleteFilterSelection = () => {
    facetsSelected.value = [];
    const productFacet = getProductFacet();
    if (productFacet) facetsSelected.value = [productFacet];

    emits("update:selected", facetsSelected.value);
};

const getProductFacet = () => {
    if (!product?.value) return undefined;

    //check if product facet includes in facets
    const existingFacet = facets.value.find((x) => x.referencedId === PRODUCT_REFERENCED_ID);
    if (!existingFacet) return undefined;

    return {
        id: existingFacet.id,
        values: [product.value],
        type: existingFacet.facetType,
        referencedId: existingFacet.referencedId,
    };
};

const disabledFacets = computed(() => {
    if (product?.value) return [PRODUCT_REFERENCED_ID];
    return [];
});

const getFacetDropdownProps = (facet: Facet): FacetDropdownProps => {
    const facetSelectorProps = {
        facet,
        facetCollectionId: props.facetCollectionId,
        fetchAggregation: props.fetchAggregation,
    };

    let selectedIds: string[] = [];
    const facetSelected = facetsSelected.value.find((x) => x.referencedId === facet.referencedId);
    if (facetSelected) {
        selectedIds = facet.displayOptions.isMultiSelect ? facetSelected.values : [facetSelected.values[0]];
    }

    if (disabledFacets.value.length > 0 && disabledFacets.value.includes(facet.referencedId)) {
        return { ...facetSelectorProps, selectedIds, disable: true };
    }

    return { ...facetSelectorProps, selectedIds };
};

const emitOnSortSelectionChanged = (sortEntry: SortEntry) => {
    emits("onSortSelectionChanged", sortEntry);
};
</script>
