import { createAbilityList } from "@/shared/environment/ability-list";
import { Ability } from "@/shared/environment/ability.types";

export const DEFAULT_LOCALES: string[] = ["de", "en", "fr", "es", "it", "ja", "zh"];

export function getMessages(locales: string[], abilities: Ability[]) {
    const messages: any = {};

    // @ts-ignore
    const modulesCore = import.meta.glob("@/shared/assets/locales/*.json", { eager: true, import: "default" });
    locales.forEach((locale: string) => {
        messages[locale] = {};
        messages[locale].core = modulesCore[`/src/shared/assets/locales/${locale}.json`];

        // @ts-ignore
        const modulesAbilities = import.meta.glob("@/abilities/*/assets/locales/*.json", {
            eager: true,
            import: "default",
        });
        abilities.forEach((ability: Ability) => {
            try {
                messages[locale][ability.alias] =
                    modulesAbilities[`/src/abilities/${ability.alias}/assets/locales/${locale}.json`];
            } catch (error) {
                if ((error as any).code !== "MODULE_NOT_FOUND") {
                    if (error instanceof Error) {
                        console.log(error.message);
                    } else {
                        console.log("Error: ", error);
                    }
                }
            }
        });
    });

    return messages;
}

let messages: any | null = null;

export function getAllMessages() {
    if (messages === null) {
        //TODO init i18n message with abilities from ability store
        messages = getMessages(DEFAULT_LOCALES, createAbilityList());
    }
    return messages;
}
