import { gql } from "graphql-tag";

gql`
    mutation completeActivity($spec: UpdateActivityProcessInput!) {
        updateActivityProcess(spec: $spec)
    }
`;

gql`
    query activity($activityId: String!) {
        activity(id: $activityId) {
            id
            localizations {
                title
            }
            introduction
            procedure
        }
    }
`;

export function createActivityQuery(
    articleDatafieldsFragment: Record<string, string[]>,
    articleTeaserDatafieldsFragment: Record<string, string[]>,
    activityDataFieldsFragment: Record<string, string[]>,
    activityTeaserDatafieldsFragment: Record<string, string[]>
) {
    const allArticleDataFieldFragments = Object.values(articleDatafieldsFragment).join(" ");
    const allArticleLocalizedDataFieldFragments = Object.values(articleTeaserDatafieldsFragment).join(" ");

    const allActivityDataFieldFragments = Object.values(activityDataFieldsFragment).join(" ");
    const allActivityLocalizedDataFieldFragments = Object.values(activityTeaserDatafieldsFragment).join(" ");

    const teasers =
        allActivityLocalizedDataFieldFragments.length > 0
            ? `    teasers {  ${allActivityLocalizedDataFieldFragments}
      }`
            : "";

    const result = `
    query activity($activityId: String!,
    $acceptedLanguages: [LanguageWithWildcard!]) {
        activity(id: $activityId) {
            id
            language
            creationDate
            uploadDate
            modificationDate
            localizations {
                    title
            }                  
            ${teasers}  
            ${allActivityDataFieldFragments}
            relations {
                localizations {
                    title
                }
                references {
                    ...on Article{
                      id
                      articleNumber
                      referenceId
                      teasers {        
                          title(acceptedLanguages: $acceptedLanguages)
                          ${allArticleLocalizedDataFieldFragments}              
                      }
                      ${allArticleDataFieldFragments}
                      previewImage {
                          url
                      }
                   }
                 ...on UnresolvedArticleActivityReference {
                    id
                    teasers {        
                        title(acceptedLanguages: $acceptedLanguages)               
                    }
                }
              }
            }
          }
        }`;

    return gql(result);
}

export const getActivitiesQuery = (
    dataFieldsFragment: Record<string, string[]>,
    localizationDatafields: Record<string, string[]>
) => {
    const allDataFieldFragments = Object.values(dataFieldsFragment).join(" ");
    const allLocalizedDataFieldFragments = Object.values(localizationDatafields).join(" ");

    const teasers = allLocalizedDataFieldFragments.length > 0 ? ` teasers { ${allLocalizedDataFieldFragments} }` : "";

    return gql`query activities($filter: ActivityFilter,${
        teasers ? "$acceptedLanguages: [LanguageWithWildcard!]," : ""
    } $first: NonNegativeInt, $after: String, $sort: [ActivitySort]) {
             activities(filter: $filter) {
                 total {
                     count
                     countRelation
                 }
                 activities(first:$first, after: $after, sort: $sort) {
                     cursor
                     node { 
                        id
                        language
                        creationDate
                        uploadDate
                        modificationDate
                        localizations {
                            title
                        }
                        ${teasers}                                                   
                        ${allDataFieldFragments}
                     }
                     highlights {
                        title
                        text
                    }
                 }
             }
         }
     `;
};

export type RelatedActivitiesContext = "article" | "product" | "asset";

export const generateRelatedActivitiesQuery = (
    relatedActivitiesContext: RelatedActivitiesContext,
    dataFieldsFragment: Record<string, string[]>,
    localizationDatafields: Record<string, string[]>
) => {
    const allDataFieldFragments = Object.values(dataFieldsFragment).join(" ");
    const allLocalizedDataFieldFragments = Object.values(localizationDatafields).join(" ");

    const teasers = allLocalizedDataFieldFragments.length > 0 ? ` teasers { ${allLocalizedDataFieldFragments} }` : "";

    const query = `query relatedActivities($id: String!, $first: NonNegativeInt, $filter: ActivityFilter, $after: String) {
                        ${relatedActivitiesContext} (id: $id) {
                            id
                            relatedActivities(filter: $filter) {
                                total {
                                    count
                                }
                                activities(first: $first, after: $after) {
                                cursor
                                node {
                                    id
                                    language
                                    creationDate
                                    uploadDate
                                    modificationDate
                                    localizations {
                                        title
                                    }
                                    ${teasers}
                                    ${allDataFieldFragments}
                                }
                                 highlights {
                                    title
                                    text
                                 }
                            }    
                        }
                }
            }`;
    return gql(query);
};
