<template>
    <AssetsOverview
        ref="assetsOverviewRef"
        :full-text-search-value="fullTextSearchValue"
        :product-id="search.product"
        :is-search="true"
    />
</template>

<script setup lang="ts">
/* Komponente, die bei der Suche nach Assets angezeigt wird. Es ist eine einfacher Wrapper über AssetSelection, um den
Suchbegriff an die Komponente zu übergeben und bei einem nicht vorhandenen Suchbegriff eine Meldung anzuzeigen */
import AssetsOverview from "@/abilities/product-and-asset/pages/assets-overview.vue";
import { ref, computed } from "vue";

const props = defineProps<{
    search: Record<string, string>;
}>();

const assetsOverviewRef = ref<typeof AssetsOverview | null>(null);

const fullTextSearchValue = computed(() => {
    return props.search.text;
});

function resizeAssetsOverview(width: number) {
    assetsOverviewRef.value?.onResize({ width: width });
}
defineExpose({ resizeAssetsOverview });
</script>
