import {
    Ability,
    DataProcessType,
    QualificationLevelSelectList,
    Route,
    RouteElementWithParam,
    SearchTab,
    Widget,
} from "@/shared/environment/ability.types";
import { createRouteFromRouteElements } from "@/shared/environment/ability-route-helper";
import { CommonRouteAliases, CommonSearchTabAliases } from "@/shared/environment/common-route-aliases";
import { ProcessType } from "@/shared/services/graphql/generated/consumer-graph-types";
import { AccessFeature } from "@/shared/access-control/access-control";
import { useMoreMenuMechanicProductItems } from "../actions/mechanic-product-actions";
import { createBreadCrumbsForMechanic } from "../helpers/mechanic-breadcrumbs";
import ArticleSearch from "../pages/article-search/article-search.vue";
import MechanicArticleTitleSpan from "../pages/components/breadcrumb/mechanic-article-title-span.vue";
import ArticleImportWizard from "../pages/components/data-process/article/article-import-wizard.vue";
import MechanicImportWizard from "../pages/components/data-process/mechanic/mechanic-import-wizard.vue";
import MechanicSelectionForQualification from "../pages/mechanic-location-for-qualification.vue";
import IdentifyPartsWidget from "../pages/widgets/identify-part.widget.vue";
import InstallationLocationWidget from "../pages/widgets/installation-location.widget.vue";
import LinkedArticlesWidget from "../pages/widgets/linked-articles-in-documentation.widget.vue";
import { keyText } from "@/shared/i18n/translation-types";

export const MECHANIC_ALIAS = "mechanic";

export class MechanicAbility implements Ability {
    alias = MECHANIC_ALIAS;
    access = {
        accessFeature: AccessFeature.spare_parts,
    };
    getWidgets = (): Array<Widget> => [
        {
            widgetContextAlias: ["product", "asset"],
            widgetComponent: IdentifyPartsWidget,
        },
        {
            widgetContextAlias: ["mechanicArticle"],
            widgetComponent: InstallationLocationWidget,
        },
        {
            widgetContextAlias: ["documentDetail"],
            widgetComponent: LinkedArticlesWidget,
        },
    ];

    getMenuEntries = useMoreMenuMechanicProductItems;

    getRoutes = (): Array<Route> => [
        {
            alias: CommonRouteAliases.mechanic,
            params: [CommonRouteAliases.mechanic],
            getComponent: () => import("@/abilities/mechanic/pages/mechanic-main.vue"),
            parentAliases: [CommonRouteAliases.asset, CommonRouteAliases.product],
            getBreadCrumbs: (parentElements: Array<RouteElementWithParam>, currentElement: RouteElementWithParam) => {
                return createBreadCrumbsForMechanic(parentElements, currentElement);
            },
        },
        {
            alias: CommonRouteAliases.mechanicArticle,
            params: [CommonRouteAliases.mechanicArticle],
            getComponent: () => import("@/abilities/mechanic/pages/mechanic-article-detail-page.vue"),
            parentAliases: [CommonRouteAliases.mechanic],
            getBreadCrumbs: (parentElements: Array<RouteElementWithParam>, currentElement: RouteElementWithParam) => {
                return [
                    {
                        content: {
                            componentDefinition: {
                                getComponent: () => MechanicArticleTitleSpan,
                                props: {
                                    mechanicArticleId: currentElement.namedParams[currentElement.alias],
                                },
                            },
                        },
                        getRouterTo: () => createRouteFromRouteElements(parentElements, currentElement),
                    },
                ];
            },
        },
        {
            alias: CommonRouteAliases.commonArticle,
            params: [CommonRouteAliases.commonArticle],
            getComponent: () => import("@/abilities/mechanic/pages/common-article-detail-page.vue"),
            isRoot: true,
            getSearchParams: (currentElement: RouteElementWithParam) => {
                const result: Record<string, string> = {};
                const paramValue = currentElement.namedParams[currentElement.alias];
                if (paramValue) result[CommonRouteAliases.commonArticle] = paramValue;

                return {
                    routeParams: result,
                };
            },
        },
        //Temporäre Zwischenseite um den Einbauort eines in der Suche gefundenen Artikels zu ermitteln
        {
            alias: CommonRouteAliases.searchToMechanicArticle,
            params: [CommonRouteAliases.searchToMechanicArticle],
            parentAliases: [CommonRouteAliases.product, CommonRouteAliases.asset],
            getComponent: () =>
                import("@/abilities/mechanic/pages/article-search/search-to-mechanic-article-redirect.vue"),
            isRoot: true,
        },
    ];

    getDataProcessTypes = (): Array<DataProcessType> => [
        {
            type: ProcessType.mechanic,
            tileSettings: {
                title: "mechanic.Mechanics",
                text: "mechanic.Configure an import for mechanical parts lists for your products",
                icon: "fa-solid fa-gears mechanic-color",
                cssClass: "mechanic-process-tile",
            },
            dialogFormComponent: {
                getComponent: () => MechanicImportWizard,
                props: {
                    title: "mechanic.Create New Process (Mechanic)",
                },
            },
        },
        {
            type: ProcessType.article,
            tileSettings: {
                title: "core.Article master",
                text: "data-process.Configure an import for general and product-specific articles",
                icon: "fa-solid fa-gear master-data-color",
                cssClass: "master-data-process-tile",
            },
            dialogFormComponent: {
                getComponent: () => ArticleImportWizard,
                props: {
                    title: "mechanic.Create New Process (Master Data)",
                },
            },
        },
    ];

    getSearchTabs: () => Array<SearchTab> = () => [
        {
            alias: CommonSearchTabAliases.commonArticles,
            title: keyText("mechanic.Articles"),
            searchComponent: {
                getComponent: () => ArticleSearch,
            },
        },
    ];

    getQualificationSelectList: () => Array<QualificationLevelSelectList> = () => [
        {
            alias: CommonRouteAliases.mechanic,
            title: keyText("mechanic.Article"),
            component: {
                getComponent: () => MechanicSelectionForQualification,
            },
        },
    ];
}
