const defaultLocale = "en";
const defaultAvailableLocales = ["de", "en", "es", "fr", "ja"];

export enum LocalStorageLanguageSettings {
    qit_language_settings = "qit-languages",
}

export interface LanguageSettings {
    uiLocale: string;
    databaseLocale: string;
    fallbackLocales: string[];
    availableLocales: string[];
}

export interface LanguageState {
    languageSettings: LanguageSettings;
}

const defaultLanguageSettings = {
    uiLocale: defaultLocale,
    databaseLocale: defaultLocale,
    fallbackLocales: [defaultLocale],
    availableLocales: defaultAvailableLocales, //5 definierte Oberflächensprachen
};

const state = () => ({
    languageSettings: defaultLanguageSettings,
});

const mutations = {
    setLanguages(state: LanguageState, settings: LanguageSettings) {
        state.languageSettings = settings;
        setLanguageToLocalStorage(settings);
    },
};

const getters = {
    languageSettings: (state: LanguageState) => {
        return state.languageSettings;
    },
    languageFromLocalStorage(): LanguageSettings | null {
        const settingsJson = localStorage.getItem(LocalStorageLanguageSettings.qit_language_settings);
        return settingsJson ? JSON.parse(settingsJson) : null;
    },
};

function setLanguageToLocalStorage(settings: LanguageSettings) {
    localStorage.setItem(LocalStorageLanguageSettings.qit_language_settings, JSON.stringify(settings));
}

export default {
    namespaced: true,
    mutations,
    getters,
    state,
};
