import { CommonRouteAliases, CommonSearchTabAliases } from "@/shared/environment/common-route-aliases";
import { LocationQueryRaw } from "vue-router";

export const CURRENT_TAB_PARAM_NAME = "tab";

export const TAB_PRODUCTS_ALIAS = "products";
export const TAB_ASSETS_ALIAS = "assets";

export function getQueryParamsForPathToProduct(): LocationQueryRaw {
    return {
        [CURRENT_TAB_PARAM_NAME]: TAB_PRODUCTS_ALIAS,
    };
}

export function getQueryParamsForPathToAsset(filter: string | undefined): LocationQueryRaw {
    const result: LocationQueryRaw = {
        [CURRENT_TAB_PARAM_NAME]: TAB_ASSETS_ALIAS,
        filter: filter,
    };
    return result;
}

export function getPathToProductSelection(tab: CommonSearchTabAliases) {
    return `${CommonRouteAliases.productSelection}?tab=${tab}`;
}
