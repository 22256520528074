const state = () => ({
    mechanicDeleted: 0,
});

export interface NotificationState {
    mechanicDeleted: number;
    partsListAvailable: boolean;
}

const mutations = {
    notifyMechanicDeleted(state: NotificationState) {
        state.mechanicDeleted++;
    },
};

const getters = {
    mechanicDeleted: (state: NotificationState) => {
        return state.mechanicDeleted;
    },
};

export default {
    namespaced: true,
    mutations,
    getters,
    state,
};
