import { computed, ComputedRef, ref, Ref, watchEffect } from "vue";
import { SearchContext } from "@/shared/search/search.types";
import { Facet, FacetSelected } from "@/shared/facets/facets.model";
import { SortEntry } from "@/shared/components/sort-dropdown/sort-dropdown.model";
import { ArticleEdge, ArticleFilter } from "@/shared/services/graphql/generated/consumer-graph-types";
import { getDataQueryLanguages } from "@/shared/services/providers/language-provider";
import { generateOrGroupsByFacetsSelected } from "@/shared/facets/graphql/content-filter-generator";
import { getSortEntries } from "@/shared/environment/filter/filter-types";
import { generateDynamicSearchArticlesOnlyAggregationsQuery } from "@/abilities/mechanic/helpers/article-search-helper";
import { useDataDisplayConfig } from "@/shared/data-display-config/composable/data-display-config";
import { DataDisplayConfigId } from "@/shared/data-display-config/composable/data-display.model";
import { createArticlesQuery } from "@/abilities/mechanic/graphql/articles.query";
import { useLazyQuery } from "@vue/apollo-composable";
import { loadMore } from "@/shared/components/scroll/lazy-loading-fetch";
import { getDataDisplayValue } from "@/shared/data-display-config/composable/data-display.helper";
import {
    QDataSource,
    QIT_PREVIEW_IMAGE_COLUMN,
    QIT_PREVIEW_IMAGE_ROW_FIELD,
} from "@/shared/components/table/q-data-source";
import { QTableProps } from "quasar";
import { consumerClient } from "@/shared/services/apollo-clients/consumer-client";
import { convertAggregationsResult } from "@/shared/aggregations/aggregations-resolver";
import { useHasAccess } from "@/shared/access-control/composables/use-has-access.ts";
import { AccessFeature } from "@/shared/access-control/access-control.ts";

export const ADD_TO_COLLECTION_COL_NAME = "addToCollection";

export const useArticlesDataSource = (
    searchContext: Ref<SearchContext>,
    selected: Ref<FacetSelected[]>,
    sort: Ref<SortEntry | undefined>,
    facet: Ref<Facet | undefined>
) => {
    const { hasAccess } = useHasAccess({ featureID: AccessFeature.spare_parts });
    const generateArticleFilterVariables = (
        searchContext: SearchContext,
        selected: FacetSelected[],
        sort: SortEntry | undefined
    ) => {
        let filter: ArticleFilter = {};

        //The wildcard search with * behaves like empty-string search, otherwise the regex will not fit and no results will be returned (see QIT-2531).
        if (searchContext.text && searchContext.text !== "*") {
            const titleLocale = "title".concat("_").concat(getDataQueryLanguages()[0]);
            const titleFallbackLocale = "title".concat("_").concat(getDataQueryLanguages()[1]);

            filter = {
                orGroup: [
                    {
                        regex: {
                            [titleLocale]: `/.*${searchContext.text}.*/i`,
                        },
                        boost: 10,
                    },
                    {
                        regex: {
                            [titleFallbackLocale]: `/.*${searchContext.text}.*/i`,
                        },
                        boost: 10,
                    },
                    {
                        regex: {
                            articleNumber: `/${searchContext.text}.*/i`,
                        },
                        boost: 10,
                    },
                    {
                        fullText: {
                            query: searchContext.text,
                            fuzzy: true,
                            fields: {
                                [titleLocale]: 1,
                                [titleFallbackLocale]: 1,
                            },
                        },
                    },
                ],
            };
        }

        const copySelected = [...selected];
        const orGroups = generateOrGroupsByFacetsSelected(copySelected);

        if (orGroups.length) filter.andGroup = orGroups;

        const sortEntries = getSortEntries(sort, getDataQueryLanguages());

        return {
            filter,
            first: FIRST_LOAD,
            acceptedLanguages: getDataQueryLanguages(),
            sort: sortEntries,
        };
    };

    const filter = computed(() => {
        return generateArticleFilterVariables(searchContext.value, selected.value, sort.value);
    });

    const FIRST_LOAD = 100;
    const FETCH_MORE_LOAD = 50;

    const {
        result: dataDisplayResult,
        loading: dataConfigLoading,
        error: dataConfigError,
        priorityDataDisplayFields,
    } = useDataDisplayConfig(DataDisplayConfigId.articleSelection);

    const stop = watchEffect(() => {
        if (!dataDisplayResult.value || !sort.value) return;

        stop();
        load(
            createArticlesQuery({
                datafieldsFragment: dataDisplayResult.value.datafieldsFragment.datafields,
                teaserDatafieldsFragment: dataDisplayResult.value.datafieldsFragment.localizationDatafields,
                withPreviewImages: true,
            })
        );
    });

    const {
        loading: articlesLoading,
        error: articlesError,
        fetchMore,
        load,
        result: articlesResult,
    } = useLazyQuery(
        createArticlesQuery({}),
        computed(() => {
            return filter.value;
        })
    );

    const hasMoreResults = ref(true);

    function loadMoreArticles() {
        if (!articlesResult.value) {
            return;
        }
        const lastArticle = articlesResult.value.articles.articles[articlesResult.value.articles.articles.length - 1];
        if (!lastArticle || !lastArticle.cursor) {
            return;
        }
        loadMore(fetchMore, lastArticle.cursor, FETCH_MORE_LOAD).then((data) => {
            hasMoreResults.value = data?.data.articles.articles.length === FETCH_MORE_LOAD;
        });
    }

    const articles = computed(() => {
        return articlesResult.value?.articles?.articles ?? [];
    });

    const total = computed(() => {
        return articlesResult.value?.articles?.total;
    });

    const loading = computed(() => {
        return articlesLoading.value || dataConfigLoading.value;
    });

    const error = computed(() => {
        return dataConfigError.value || articlesError.value;
    });

    const columns = computed(() => {
        if (!dataDisplayResult.value || dataDisplayResult.value.dataDisplayFields.length === 0) {
            //if no datadisplayfields configured, we need an empty column so that the rows are shown
            return [{ name: "", label: "", field: "", align: "left" }];
        }

        const result = dataDisplayResult.value.dataDisplayFields.map((field) => {
            return {
                name: field.key,
                label: field.title,
                field: field.key,
                align: "left",
            };
        });

        return [
            {
                name: QIT_PREVIEW_IMAGE_COLUMN,
                field: QIT_PREVIEW_IMAGE_ROW_FIELD,
                label: "",
            },
            ...result,
            // TODO - check, if (hasAccess.value) is needed, because the column is always added
            { name: ADD_TO_COLLECTION_COL_NAME, label: "", field: "", align: "left" },
        ];
    });

    const mobileColumns = computed(() => {
        let columns = priorityDataDisplayFields.value.map((displayField) => {
            return {
                name: displayField.key,
                field: displayField.key,
                label: displayField.title,
                align: "left",
            };
        });

        if (hasAccess.value)
            columns = [...columns, { name: ADD_TO_COLLECTION_COL_NAME, label: "", field: "", align: "left" }];

        return columns;
    });

    const dataRows = computed(() => {
        return articles.value?.map((edge: ArticleEdge) => {
            const node = edge?.node;
            if (!node) {
                return {};
            }
            const result: any = {};
            dataDisplayResult.value?.dataDisplayFields.forEach((dataDisplayField) => {
                const dataValue = getDataDisplayValue(dataDisplayField, node);
                result[dataDisplayField.key] = dataValue.formattedValue;
            });
            const dataDisplayFieldId = dataDisplayResult.value?.dataDisplayFields.find((field) => field.key === "id");
            if (!dataDisplayFieldId) {
                result["id"] = node.id;
            }
            result[QIT_PREVIEW_IMAGE_ROW_FIELD] = node.previewImage?.url;
            return result;
        });
    });

    const qDataSource: ComputedRef<QDataSource> = computed(() => {
        return {
            columns: columns.value as QTableProps["columns"],
            mobileColumns: mobileColumns.value as QTableProps["columns"],
            rows: dataRows.value,
        };
    });

    const resultListEmpty = computed(() => {
        return dataRows.value.length === 0;
    });

    const fetchAggregation = async (searchPhrase: string) => {
        if (!facet.value) return {};

        //remove own facet from selected
        const selectedCopy = [...selected.value.filter((x) => x.referencedId !== facet.value?.referencedId)];
        const filter = generateArticleFilterVariables(searchContext.value, selectedCopy, sort.value);

        const aggregationResult = await consumerClient.query({
            query: generateDynamicSearchArticlesOnlyAggregationsQuery(facet.value, searchPhrase),
            variables: filter,
        });

        const convertedAggregations = convertAggregationsResult(
            facet.value,
            aggregationResult.data["articles"].aggregations
        );

        return convertedAggregations[facet.value.id];
    };

    return {
        loading,
        error,
        qDataSource,
        resultListEmpty,
        hasMoreResults,
        loadMoreArticles,
        FIRST_LOAD,
        total,
        fetchAggregation,
        articlesResult,
    };
};
