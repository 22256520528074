import { consumerClient } from "@/shared/services/apollo-clients/consumer-client";
import { ProductDocument, ProductQuery } from "@/shared/services/graphql/generated/consumer-graph-types";
import { getDataQueryLanguages } from "@/shared/services/providers/language-provider";
import { CommonTabAliases } from "@/shared/environment/common-route-aliases";
import { LocationQueryRaw } from "vue-router";
import { SearchContextForDropDown, SearchItem } from "@/shell/pages/header/search/header-search.types";
import { stringify } from "qs";
import { facetValuesToUrlParam, urlParamToFacetValues } from "@/shared/facets/facet-utils";

export const createSearchContextForDropDown = async (product?: string, asset?: string) => {
    const searchContext: SearchContextForDropDown = {};

    if (product) {
        const result = await consumerClient.query<ProductQuery>({
            query: ProductDocument,
            variables: {
                id: product,
                acceptedLanguages: getDataQueryLanguages(),
            },
            fetchPolicy: "cache-first",
        });

        if (result.data.product) {
            searchContext["product"] = {
                id: product,
                type: "product",
                title: `in ${result.data.product?.localizations?.title ?? result.data.product?.id}`,
                icon: "fa-regular fa-box default-grey-icon-color",
            };
        }
    }

    if (asset) {
        searchContext["asset"] = {
            id: asset,
            type: "asset",
            title: `in ${asset}`,
            icon: "fa-regular fa-tag default-grey-icon-color margin-top-4",
        };
    }

    return searchContext;
};

const getNamedParamsForSearch = (
    searchValue: string,
    searchContextType: "all" | "product" | "asset",
    availableSearchContexts: SearchContextForDropDown
) => {
    const searchParam: Record<string, string> = {};
    if (searchContextType !== "all") {
        const productContext = availableSearchContexts["product"];
        const assetContext = availableSearchContexts["asset"];
        if (searchContextType === "asset") {
            if (productContext) {
                searchParam["product"] = productContext.id;
            }
            if (assetContext) {
                searchParam["asset"] = assetContext.id;
            }
        }
        if (searchContextType === "product") {
            if (productContext) {
                searchParam["product"] = productContext.id;
            }
        }
    }
    searchParam.text = searchValue;
    return searchParam;
};

export const getPathToSearch = (
    searchItem: SearchItem,
    searchTab: string | undefined,
    query: LocationQueryRaw,
    selectedSearchContextType: "all" | "product" | "asset",
    currentContext: SearchContextForDropDown,
    isSearchRoute: boolean
) => {
    let queryParams: LocationQueryRaw | undefined;
    //selected by context buttons
    if (searchItem.tab) {
        queryParams = { tab: searchItem.tab };
    } else if (isSearchRoute) {
        queryParams = query;
    } else {
        if (searchTab) {
            const searchTabParam: Record<string, string> = {};
            searchTabParam[CommonTabAliases.search] = searchTab;
            queryParams = searchTabParam;
        }
    }

    const searchParam = getNamedParamsForSearch(searchItem.searchValue, selectedSearchContextType, currentContext);
    const searchParamString = stringify(searchParam, { encode: false });

    return {
        searchParamString,
        queryParams,
    };
};

export const getPathToSearchWithFacet = (
    searchItem: SearchItem,
    facetReferencedId: string,
    facetValue: string,
    selectedSearchContextType: "all" | "product" | "asset",
    currentContext: SearchContextForDropDown
) => {
    const urlParams = new URLSearchParams(window.location.search);
    const isSearchTabChanging = urlParams.get(CommonTabAliases.search) !== searchItem.tab;
    let queryParams: LocationQueryRaw | undefined = {};

    if (isSearchTabChanging) urlParams.set(CommonTabAliases.search, searchItem.tab);

    const currentFacetValues = urlParamToFacetValues(urlParams.get(facetReferencedId));
    const newFacetValues =
        currentFacetValues && currentFacetValues.length > 0 && !currentFacetValues.includes(facetValue)
            ? [...currentFacetValues, facetValue]
            : [facetValue];
    urlParams.set(facetReferencedId, facetValuesToUrlParam(newFacetValues));
    queryParams = Object.fromEntries(urlParams);

    const searchParam = getNamedParamsForSearch(searchItem.searchValue, selectedSearchContextType, currentContext);
    const searchParamString = stringify(searchParam, { encode: false });

    return {
        searchParamString,
        queryParams,
    };
};
