import { computed, ComputedRef } from "vue";
import { useActivitiesSearch } from "@/abilities/activities/composeables/activities-search";
import { ActivityEdge } from "@/shared/services/graphql/generated/admin-graph-types";
import { ActivityResult } from "../models/activity.model";
import { SearchFilteredResultsParams } from "@/shared/search/search.types";
import { useActivitiesAggregations } from "@/abilities/activities/composeables/activities-aggregations";

/**
 * A composable function to handle activity search and converts the search result to activity results.
 *
 * @param selected - A reference to the selected facets for filtering.
 * @param facet - A reference to the current opened facet.
 * @param searchContext - A reference to the search context.
 * @param searchPhrase - A reference to the search phrase.
 * @param sort - A reference to the sort entry for sorting the search results.
 * @returns An object containing the loading state, error state, total result, content results,
 *          function to fetch more activity results and a function to fetch aggregations
 */
export const useFilteredActivityResults = ({
    selected,
    facet,
    searchContext,
    searchPhrase,
    sort,
}: SearchFilteredResultsParams) => {
    const { result, searchFields, loading, fetchMore, getDataDisplayPropertiesByConfig } = useActivitiesSearch({
        selected,
        searchContext,
        searchPhrase,
        sort,
    });

    const { fetchAggregation } = useActivitiesAggregations({
        selectedFacets: selected,
        facet,
        searchFields,
        searchContext,
        searchPhrase,
    });

    const activities: ComputedRef<ActivityResult[] | null> = computed(() => {
        if (!result.value) return null;

        return result.value?.activities.map((x: ActivityEdge) => {
            const propertyItems = getDataDisplayPropertiesByConfig(x.node);
            const highlightText = (x.highlights?.text ?? x.highlights?.title ?? undefined) as string[] | undefined;
            const highlightTitle = x.highlights?.title?.[0] ?? undefined;
            const value: ActivityResult = {
                id: x.node.id,
                title: x.node.localizations?.title ?? "",
                language: x.node.language,
                cursor: x.cursor,
                highlights: { text: highlightText, title: highlightTitle },
                propertyItems,
            };

            return value;
        });
    });

    const total = computed(() => {
        if (!result.value) return null;

        return result.value.total;
    });

    const loadMore = async (after: string, first: number) => {
        const fetchResult = await fetchMore({
            variables: {
                after: after,
                first: first,
            },
        });

        if (!fetchResult?.data?.activities?.activities || fetchResult.data.activities.activities.length === 0)
            return undefined;

        return fetchResult.data.activities.activities[fetchResult.data.activities.activities.length - 1].cursor;
    };

    return {
        loading,
        activities,
        total,
        fetchAggregation,
        loadMore,
    };
};
