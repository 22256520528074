<template>
    <div class="something-went-wrong content" data-qs="something-went-wrong" style="height: auto">
        <i v-if="icon" :class="iconClass"></i>
        <img v-else :src="errorImage ?? DefaultErrorImage" :width="300" />
        <div class="container">
            <h3 class="message">{{ errorMessage }}</h3>
            <div class="detail-div" v-for="(value, key) in detailMessages" :key="key">
                <div class="qit-property-key">{{ key }}:</div>
                <div class="qit-property-values">{{ value }}</div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import DefaultErrorImage from "./assets/images/lost.svg?url";

const props = defineProps<{
    errorMessage: string;
    detailMessages?: { [key: string]: string };
    icon?: string;
    errorImage?: string;
}>();

const iconClass = computed(() => {
    return props.icon;
});
</script>

<style lang="scss" scoped>
.something-went-wrong {
    padding: $spacing-m;
    margin-top: $spacing-xxxl;
    text-align: center;

    & i {
        width: 50px;
        height: 50px;
    }

    .container {
        justify-content: center;
        height: inherit;

        .message {
            font-weight: $semi-bold;
        }

        .detail-div {
            margin-bottom: 16px;
        }
    }
}
</style>
