<template>
    <q-layout>
        <q-header class="bg-primary text-white" style="z-index: 1501">
            <HeaderToolbar @toggle-nav="openLeftDrawer" />
            <q-resize-observer :debounce="0" @resize="setHeaderHeight" />
        </q-header>
        <q-drawer
            v-model="leftDrawerOpen"
            :mini="miniState"
            :behavior="isXSmall ? 'mobile' : 'desktop'"
            :width="300"
            :mini-width="68"
        >
            <template #mini>
                <q-scroll-area class="mini-slot fit">
                    <div>
                        <div class="column items-start">
                            <SideNavigation
                                :minified="miniState"
                                :footer-icons="false"
                                style="position: absolute"
                                @on-minify="onMinify"
                                @on-mobile-close="closeLeftDrawer"
                            />
                        </div>
                    </div>
                </q-scroll-area>
            </template>

            <q-scroll-area class="fit">
                <SideNavigation
                    :minified="miniState"
                    :footer-icons="false"
                    style="position: absolute"
                    @on-minify="onMinify"
                    @on-mobile-close="closeLeftDrawer"
                />
            </q-scroll-area>

            <div class="q-mini-drawer-hide absolute">
                <q-icon
                    flat
                    round
                    class="cursor-pointer"
                    size="xs"
                    name="fa-regular fa-chevrons-left"
                    @click="onMinify(true)"
                />
            </div>
        </q-drawer>

        <!-- TODO: left and right drawer goes here -->

        <q-page-container class="fit">
            <q-page class="default-page fit" data-qs="q-page-default-layout">
                <slot />
            </q-page>
        </q-page-container>
        <q-resize-observer :debounce="0" @resize="onResize" />
    </q-layout>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";
import { useRoute } from "vue-router";
import SideNavigation from "@/shell/pages/side-navigation/side-navigation.vue";
import HeaderToolbar from "@/shell/pages/header/header-toolbar.vue";
import { DASHBOARD_PATH } from "@/abilities/dashboard/config/dashboard.ability";
import { useScreenSize } from "@/shared/screen/composables/screen-size";

const route = useRoute();
const { isXSmall } = useScreenSize();

const miniState = ref(isMiniState());
const leftDrawerOpen = ref(!isXSmall.value);
const headerHeight = ref(0);

function setHeaderHeight(size: { height: number }) {
    headerHeight.value = size.height;
}

watch(
    () => isXSmall.value,
    () => {
        leftDrawerOpen.value = !isXSmall.value;
        miniState.value = isMiniState();
    }
);

watch(
    () => route,
    () => {
        if (route) {
            miniState.value = isMiniState();
            leftDrawerOpen.value = !isXSmall.value;
        }
    },
    {
        immediate: true,
        deep: true,
    }
);

function openLeftDrawer() {
    leftDrawerOpen.value = true;
}

function closeLeftDrawer() {
    leftDrawerOpen.value = false;
}

function onMinify(minify: boolean) {
    miniState.value = minify;
}

/**
 * Minify on Resize when not mobile and not homepage
 */
function onResize() {
    if (!miniState.value && isMiniState()) {
        miniState.value = true;
    }
}

function isMiniState() {
    return !isXSmall.value && !route.path.includes(DASHBOARD_PATH);
}
</script>
