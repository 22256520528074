import { computed, Ref } from "vue";
import { useEditableCollections } from "@/abilities/collections/composables/editable-collections";
import { useContentItemsForCollection } from "@/abilities/collections/composables/content-items-for-collection.ts";

export const useCollectionsOverlay = (contentMapId: Ref<string>, topicId: Ref<string | undefined>) => {
    const { loading: loadingCollections, collections, refetch } = useEditableCollections();
    const { loading: collectionOverlayLoading, items } = useContentItemsForCollection(contentMapId, topicId);

    const loading = computed(() => {
        return loadingCollections.value || collectionOverlayLoading.value;
    });

    const result = computed(() => {
        if (loading.value) return null;

        return {
            collections: collections.value,
            items: items.value,
        };
    });

    return {
        loading,
        result,
        refetch,
    };
};
