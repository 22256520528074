import { computed, ref, Ref, watch } from "vue";
import { HtmlDocument } from "@/abilities/documentation/model/document-data";
import { useTocPathAndTitleQuery } from "@/shared/services/graphql/generated/consumer-graph-types";

export function useDocumentationQuery(contentMapId: Ref<string>, topicId: Ref<string>) {
    const loadingDocument = ref<boolean>(true);
    const title = ref<string>();
    const path = ref<string>();
    const childPath = ref<string>();
    const hasChildren = ref<boolean>();
    const document = ref<HtmlDocument>();

    const { result, error, loading } = useTocPathAndTitleQuery(
        computed(() => ({ contentId: topicId.value, rootContentId: contentMapId.value })),
        computed(() => ({ enabled: topicId.value !== "-", fetchPolicy: "no-cache" }))
    );

    const pathNotFound = computed(() => {
        return !loading.value && topicId.value !== "-" && result.value?.contentInContext?.length !== 1;
    });

    watch(result, () => {
        if (
            result.value?.contentInContext?.length == 1 &&
            result.value.contentInContext[0].node?.__typename === "Topic"
        ) {
            title.value = result.value.contentInContext[0].node.teasers?.title!;
            path.value = result.value.contentInContext[0].path;
            childPath.value = result.value.contentInContext[0].childPath;
            hasChildren.value = !!result.value.contentInContext[0].hasChildren;
            const { url, mimeType } = result.value.contentInContext[0].node;
            document.value = { mimeType: mimeType!, url };
            loadingDocument.value = false;
        }
    });

    return { loadingDocument, title, path, document, pathNotFound, error, childPath, hasChildren };
}
