<template>
    <!-- Display a loading spinner for mobile view when data is loading -->
    <div v-if="isXSmall && !desktopOnly && allLoading" class="mobile-loading">
        <q-spinner-ios size="2em" />
    </div>
    <!-- Scrollable area for mobile view -->

    <!-- Scroll component for mobile view with List Cars -->
    <q-scroll-area v-if="isXSmall && !desktopOnly" class="full-height mobile-scroll-area">
        <q-infinite-scroll @load="onMobileScroll">
            <div
                v-for="(row, index) in rows"
                :key="index"
                class="q-gutter-md q-my-xs col-12"
                id="MobileCard"
                :class="{ 'mobile-card-selected': row[rowKey] === selectedRowKey && !noSelect }"
                @click="onRowClick(row)"
            >
                <ListCard
                    image-separator
                    :is-selected-card="row[rowKey] === selectedRowKey && !noSelect"
                    :image-src="row[QIT_PREVIEW_IMAGE_ROW_FIELD]"
                    fallback-icon="fa-gears"
                >
                    <!-- Content section for mobile card -->
                    <template #content>
                        <div class="row">
                            <template
                                v-for="col in (dynamicColumns ?? []).map((col) => ({ ...col, value: row[col.name] }))
                                            .filter((col: any) => col.value)
                                            .slice(0, maxPropertyMobileDisplay)"
                                :key="col.name"
                            >
                                <div class="q-pa-md" :class="{ 'col-6': !isXXSmall, 'col-12': isXXSmall }">
                                    <div class="qit-property-key qit-text-ellipsis">
                                        {{ col.label }}
                                    </div>
                                    <div class="qit-property-values qit-text-ellipsis">
                                        {{ col.value }}
                                    </div>
                                </div>
                            </template>
                        </div>
                    </template>
                    <!-- Buttons section for mobile card -->
                    <template #buttons v-if="mobileActionColumnName && cardActionVisibleCallback(row)">
                        <q-card-actions>
                            <slot name="mobile-action" :row="row" />
                        </q-card-actions>
                    </template>
                </ListCard>
            </div>
            <!-- Resize observer for mobile view -->
            <q-resize-observer :debounce="0" @resize="onResize" />
        </q-infinite-scroll>
    </q-scroll-area>

    <!-- Table component for desktop view -->
    <div v-else class="fit">
        <q-table
            :data-qs="dataQs"
            class="dynamic-sticky-table full-width"
            ref="qTableRef"
            :class="{ 'sticky-last-column': stickyLastColumn, 'table-shadow': hasShadow && !isXSmall }"
            :selected="[selectedRowKey]"
            :style="isXSmall ? 'height: auto' : 'height: 100%'"
            :title="title"
            :rows="rows"
            :visible-columns="visibleColumns"
            :columns="dynamicColumns"
            :loading="allLoading"
            :grid="false"
            :virtual-scroll-item-size="virtualScrollItemSize"
            :virtual-scroll-sticky-size-start="virtualScrollItemSize"
            :pagination="pagination"
            :rows-per-page-options="[0]"
            :hide-bottom="rows.length > 0 || allLoading"
            :row-key="rowKey"
            virtual-scroll
            flat
            :bordered="bordered"
            @virtual-scroll="onScroll"
            color="primary"
        >
            <!-- Template for each row in desktop view -->
            <template #body="desktopProps">
                <q-tr
                    :data-qs="`row-${desktopProps.cols.find((col:any) => col.name === rowKey)?.value}`"
                    :props="desktopProps"
                    :class="{ selected: desktopProps.row[rowKey] === selectedRowKey && !noSelect, hover: !noHover }"
                    :no-hover="noHover"
                    @mouseenter="onCustomStickyRightRowMouseEnter(desktopProps)"
                    @mouseleave="isCustomStickyRightRowHovered = false"
                    @click="onRowClick(desktopProps.row)"
                >
                    <!-- Image cell for desktop view -->
                    <template v-if="customCells.includes(QIT_PREVIEW_IMAGE_COLUMN)">
                        <q-td style="height: 64px; max-height: 64px; padding: 0" :auto-width="true">
                            <InlineTablePreviewImage
                                :image-src="desktopProps.row[QIT_PREVIEW_IMAGE_ROW_FIELD]"
                                fallback-icon="fa-gears"
                                :image-size="64"
                            />
                        </q-td>
                    </template>
                    <!-- Custom cell slot -->
                    <template v-if="slots.custom">
                        <slot name="custom" :data="desktopProps" />
                    </template>
                    <!-- Default cell content -->
                    <template v-else>
                        <template
                            v-for="cell in desktopProps.cols.filter((cell:any) => !customCells.includes(cell.name))"
                            :key="cell.key"
                        >
                            <!-- Custom sticky right cell -->
                            <q-td
                                v-if="cell.name === customStickyRightCellColumnName"
                                :class="{ 'bg-white': cell.name === customStickyRightCellColumnName }"
                            >
                                <div class="q-px-xs q-py-sm" style="float: right">
                                    <slot name="custom-cell-column-name" :row="desktopProps.row" />
                                    <div
                                        class="overlay"
                                        :class="{
                                            'custom-cell-selected':
                                                cell.name === customStickyRightCellColumnName &&
                                                desktopProps.row[rowKey] === selectedRowKey &&
                                                !noSelect,
                                            'custom-cell-hover':
                                                cell.name === customStickyRightCellColumnName &&
                                                !noHover &&
                                                isCustomStickyRightRowHovered &&
                                                desktopProps.rowIndex === hoveredRowIndex,
                                        }"
                                    />
                                </div>
                            </q-td>
                            <!-- Default cell -->
                            <q-td v-else>
                                <div class="q-px-xs q-py-sm">
                                    <template v-if="slots['cell-content']">
                                        <slot name="cell-content" :row="desktopProps.row" :cell="cell.value" />
                                    </template>
                                    <q-item-label v-else>{{ cell.value }}</q-item-label>
                                </div>
                            </q-td>
                        </template>
                        <!-- Custom cell slot -->
                        <template v-if="slots.customCell">
                            <slot name="customCell" :row="desktopProps.row" />
                        </template>
                    </template>
                </q-tr>
            </template>
            <!-- Template for no data available -->
            <template #no-data>
                <div class="full-width row flex-center text-accent q-gutter-sm">
                    <q-icon name="fa-regular fa-triangle-exclamation q-pr-xs" size="20px" />
                    <span>
                        {{ noDataText }}
                    </span>
                </div>
            </template>
        </q-table>
        <!-- Resize observer for desktop view -->
        <q-resize-observer :debounce="0" @resize="onResize" />
    </div>
</template>

<script setup lang="ts">
import { computed, onMounted, reactive, ref, useSlots, watch } from "vue";
import { QTable, QScrollArea, QInfiniteScroll } from "quasar";
import ListCard from "@/shared/components/card/list-card.vue";
import InlineTablePreviewImage from "@/shared/components/table/inline-table-preview-image.vue";
import { QIT_PREVIEW_IMAGE_COLUMN, QIT_PREVIEW_IMAGE_ROW_FIELD, QTableRowType } from "./q-data-source";
import { TableProps } from "./table.model";

// Define component props with default values
const props = withDefaults(defineProps<TableProps>(), {
    selected: "",
    virtualScrollItemSize: 48,
    dataLoading: false,
    noDataText: "No data",
    maxPropertyMobileDisplay: 4,
    noSelect: false,
    noHover: false,
    customCells: () => [],
    bordered: true,
    hasMoreResults: false,
    stickyLastColumn: false,
    rowKey: "id",
    desktopOnly: false,
    hasShadow: true,
    cardActionVisibleCallback: () => true,
    dataQs: "table",
});

// Define component events
const emit = defineEmits<{
    (e: "rowClick", row: QTableRowType): void;
    (e: "onLoadMore"): void;
}>();

// Use slots
const slots = useSlots();

const pagination = reactive({ rowsPerPage: 0 });

// Refs for responsive design
const isXXSmall = ref(false);
const isXSmall = ref(false);

// Function to handle resize event
function onResize(size: { width: number }) {
    isXXSmall.value = size.width < 450;
    isXSmall.value = size.width < 600;
}

// Computed property to determine if grid view should be used
const gridView = computed(() => {
    return isXSmall.value && !props.desktopOnly;
});

// Computed property for rows
const rows = computed(() => {
    return props.qDataSource?.rows ?? [];
});

const visibleColumns = computed(() => {
    return props.qDataSource?.visibleColumns;
});

// Computed property for dynamic columns based on view
const dynamicColumns = computed(() => {
    if (gridView.value && props.qDataSource?.mobileColumns) {
        return props.qDataSource?.mobileColumns;
    }
    return props.qDataSource?.columns;
});

// Refs for lazy loading state
const lazyLoading = ref(false);

// Function to handle scroll event
function onScroll({ index }: { index: number }) {
    const lastIndex = rows.value.length - 1;
    if (!allLoading.value && props.hasMoreResults && index === lastIndex) {
        emit("onLoadMore");
        lazyLoading.value = true;
    }
}

// Function to handle scroll event
function onMobileScroll(_index: number, done: (stop: boolean) => void) {
    if (props.hasMoreResults && _index < rows.value.length - 1) {
        emit("onLoadMore");
        lazyLoading.value = true;
        done(false);
    } else {
        done(true);
    }
}

// Ref for QTable component
const qTableRef = ref<QTable>();

// Refs for custom sticky right row hover state
const isCustomStickyRightRowHovered = ref(false);
const hoveredRowIndex = ref("");

// Function to handle mouse enter event on custom sticky right row
function onCustomStickyRightRowMouseEnter(props: any) {
    hoveredRowIndex.value = props.rowIndex;
    isCustomStickyRightRowHovered.value = true;
}

// Lifecycle hook to handle component mount
onMounted(() => {
    const index = rows.value.findIndex((row) => row[props.rowKey] === props.selected);
    qTableRef.value?.scrollTo(index, "start");
});

// Watcher for rows to reset lazy loading state
watch(
    () => rows.value,
    () => {
        lazyLoading.value = false;
    }
);

// Watcher for hasMoreResults prop to reset lazy loading state
watch(
    () => props.hasMoreResults,
    () => {
        if (!props.hasMoreResults) {
            lazyLoading.value = false;
        }
    }
);

// Computed property to determine if all loading states are active
const allLoading = computed(() => {
    return lazyLoading.value || props.dataLoading;
});

// Ref for selected row key
const selectedRowKey = ref("");

// Function to handle row click event
function onRowClick(row: QTableRowType) {
    if (typeof row[props.rowKey] === "undefined") {
        return;
    }
    selectedRowKey.value = row[props.rowKey];
    emit("rowClick", row);
}

// Watcher for selected prop to update selected row key
watch(
    () => props.selected,
    () => {
        selectedRowKey.value = String(props.selected);
    },
    { immediate: true }
);
</script>

<style lang="scss">
.table-shadow {
    background-color: white;
    box-shadow: $container-shadow;
    border-radius: $default-border-radius;
}

.dynamic-sticky-table {
    .q-table__top,
    .q-table__bottom,
    thead tr:first-child th {
        /* bg color is important for th; just specify one */
        background-color: white;
    }

    th {
        z-index: 99 !important;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }

    thead tr th {
        position: sticky;
        z-index: 1;
        color: #7a7a7a;
        font-weight: 600;
        height: 36px !important;
    }

    tr {
        height: 57px;
    }

    /* this will be the loading indicator */
    thead tr:last-child th {
        /* height of all previous header rows */
        top: 48px;
    }

    thead tr:first-child th {
        top: 0;
    }

    /* prevent scrolling behind sticky top row on focus */
    tbody {
        /* height of all previous header rows */
        scroll-margin-top: 48px;
    }

    .q-table tbody td::after {
        background: transparent;
    }

    .q-table tbody td::before {
        background: transparent;
    }

    .q-table td {
        background-color: transparent;
    }

    .selected {
        background-color: var(--q-highlight) !important;
    }

    .hover:hover:not(.selected) {
        background-color: var(--q-highlight-hover) !important;
        cursor: pointer;
    }

    .overlay {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;

        &.custom-cell-selected {
            background: var(--q-highlight) !important;
        }

        &.custom-cell-hover:not(.custom-cell-selected) {
            background-color: var(--q-highlight-hover) !important;
        }
    }

    .mobile-card-selected {
        box-shadow: var(--q-highlight) 0 0 0 4px !important;
    }

    &.sticky-last-column {
        th:last-child,
        td:last-child {
            position: sticky;
            right: 0;
            z-index: 1;
        }
    }
}

.mobile-loading {
    z-index: 9;
    position: absolute;
    left: 50%;
    bottom: 10%;
    transform: translate(-50%, -50%);
}
.mobile-scroll-area {
    .q-scrollarea__content {
        width: 100%;
    }
}
</style>
