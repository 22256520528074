import { onError } from "@apollo/client/link/error";
import { ServerError } from "@apollo/client/core";
import { ref } from "vue";
import { apmBase } from "@elastic/apm-rum";

export const isMaintenance = ref(false);

export const errorLink = onError(({ networkError, graphQLErrors }) => {
    if (networkError) {
        const result = (networkError as ServerError).result;
        const isMaintenanceMode =
            (networkError?.name === "ServerError" && (networkError as ServerError).statusCode) === 503 &&
            typeof result !== "string" &&
            result.message?.includes("maintenance mode");

        if (isMaintenanceMode) {
            isMaintenance.value = true;
            return;
        }

        apmBase.captureError(networkError);
    }

    if (graphQLErrors) {
        for (const graphQLError of graphQLErrors) {
            apmBase.captureError(JSON.stringify(graphQLError));
        }
    }
});
