const state = () => ({
    displayName: "",
    errorMessage: "",
});

export interface TenantInfoState {
    displayName: string;
    errorMessage: string;
}

const mutations = {
    setDisplayName(state: TenantInfoState, displayName: string) {
        state.displayName = displayName;
    },
    setErrorMessage(state: TenantInfoState, errorMessage: string) {
        state.errorMessage = errorMessage;
    },
};

const getters = {
    tenantName: (state: TenantInfoState) => {
        return state.displayName;
    },

    errorMessage: (state: TenantInfoState) => {
        return state.errorMessage;
    },
};

export default {
    namespaced: true,
    mutations,
    getters,
    state,
};
