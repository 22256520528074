<template>
    <div class="flex column fit">
        <div
            v-if="slots.header"
            class="col-auto q-pl-none"
            :class="{
                'q-pl-lg': isLargerThanMedium,
                'q-pl-md': !isLargerThanMedium && !isXSmall,
                'border-bottom': headerSlotBorderBottom,
            }"
        >
            <slot name="header" />
        </div>
        <div class="flex column col fit">
            <!-- every content has to be in charge itself of scrolling behaviour -->
            <slot />
        </div>
    </div>
</template>

<script setup lang="ts">
import { useSlots } from "vue";
import { useScreenSize } from "@/shared/screen/composables/screen-size";

defineProps<{
    headerSlotBorderBottom?: boolean;
}>();

const slots = useSlots();
const { isXSmall, isLargerThanMedium } = useScreenSize();
</script>

<style lang="scss" scoped>
.border-bottom {
    border-bottom: 1px solid $light-border-color;
}
</style>
