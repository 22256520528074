import { Ability, Route } from "@/shared/environment/ability.types";
import { CommonRouteAliases } from "@/shared/environment/common-route-aliases";

export const GO_ALIAS = "go";
export const GO_PATH = GO_ALIAS;

export class GoAbility implements Ability {
    alias = GO_ALIAS;

    getRoutes = (): Array<Route> => [
        {
            parentAliases: [CommonRouteAliases.asset, CommonRouteAliases.product],
            alias: CommonRouteAliases.go,
            params: [],
            getComponent: () => import("@/abilities/go/pages/go-main.vue"),
            isRoot: true,
        },
    ];
}
