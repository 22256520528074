<template>
    <AdvancedRouterLink :to="linkTo" :click-handler="clickHandler" @click="linkClicked">
        <template #content>
            <div class="selection-links border-top">
                <div class="selection-element" v-for="(title, index) in titles" :key="index">
                    {{ title }}<i class="fa-regular fa-chevron-right default-text-color" />
                </div>
            </div>
        </template>
    </AdvancedRouterLink>
</template>

<script setup lang="ts">
import { MechanicPath } from "@/shared/services/graphql/generated/consumer-graph-types";
import { computed } from "vue";
import AdvancedRouterLink from "@/shared/components/router-link/advanced-router-link.vue";
import { useMechanicRoute } from "@/abilities/mechanic/composable/mechanic-route";

const props = defineProps<{
    product: string;
    asset?: string;
    mechanicPath?: MechanicPath;
    emitSelection?: boolean;
}>();

const emit = defineEmits<{
    (e: "linkClicked"): void;
}>();

const { getAbsolutePathToMechanicLocation } = useMechanicRoute();

const linkTo = computed(() => {
    if (props.mechanicPath) {
        if (!props.emitSelection) {
            return getAbsolutePathToMechanicLocation(props.product, props.asset, props.mechanicPath);
        }
    }
    return undefined;
});

const clickHandler = computed(() => {
    if (props.emitSelection) {
        return () => {
            if (props.mechanicPath) {
                throw "Selection of mechanic path is not implemented";
            }
        };
    }
    return undefined;
});

const titles = computed(() => {
    const result = props.mechanicPath?.path?.map(
        (item) => item?.mechanic?.localizations?.title ?? item?.mechanic?.teasers?.title
    );
    if (result && result.length > 0) {
        // Letzter Eintrag ist der Stücklisteneintrag selbst und gehört nicht zur Liste
        result.pop();
    }
    return result;
});

function linkClicked() {
    if (!props.emitSelection) {
        emit("linkClicked");
    }
}
</script>

<style lang="scss" scoped>
.selection-links {
    display: flex;
    cursor: pointer;
    flex-wrap: wrap;
    flex-direction: row;
    padding-bottom: $spacing-l;
    padding-top: $spacing-l;
    i {
        display: flex;
        align-items: center;
        margin-left: $spacing-m;
        margin-right: $spacing-m;
        width: $chevron-height;
    }
    &:hover {
        background-color: var(--q-highlight-hover);
    }
}

.selection-element {
    display: flex;
}
</style>
