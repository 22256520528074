<template>
    <Card
        :title="$t('product-and-asset.My last {productsName}', { productsName: productName })"
        title-icon="fa-regular fa-box light-border-color"
        :show-footer="false"
    >
        <template v-if="loading">
            <LastProductSkeletonTile />
        </template>
        <template v-else-if="isEmpty(products)">
            <NoLastElement :is-product="true" />
        </template>
        <template v-else>
            <template v-for="(product, index) in productsByIds" :key="product.id">
                <router-link :to="getPathToProduct(product.id)">
                    <LastProductAssetListCard
                        :advanced-product-or-asset="productWithId(product.id)"
                        :test-id="`last-products-${index}`"
                        fallback-icon="fa-box"
                    />
                </router-link>
            </template>
        </template>
        <template #close>
            <router-link :to="getPathToProductSelection(CommonSearchTabAliases.products)" class="router-link">{{
                $t("product-and-asset.Show all {productsName}", { productsName: productName })
            }}</router-link>
        </template>
    </Card>
</template>

<script lang="ts" setup>
import { computed, ComputedRef } from "vue";
import Card from "@/shared/components/card/card.vue";
import { CommonRouteAliases, CommonSearchTabAliases } from "@/shared/environment/common-route-aliases";
import { WidgetContext } from "@/shared/environment/widgets/widget-context";
import { getPathToProductSelection } from "../../helpers/product-selection-routing";
import LastProductAssetListCard from "./last-product-asset-list-card.vue";
import LastProductSkeletonTile from "./last-product-asset-skeleton-tile.vue";
import { isEmpty } from "lodash";
import { useProductsResultWithDisplayConfig } from "./last-products.widget.model";
import { getDataQueryLanguages } from "@/shared/services/providers/language-provider";
import { RouterLink } from "vue-router";
import { useProductsAssetsHistory } from "@/abilities/product-and-asset/store/products-assets-history";
import { useAbilityRoute } from "@/shared/environment/ability-route";
import { useProductNames } from "@/abilities/product-and-asset/composables/product-names";
import NoLastElement from "./no-last-element.vue";

defineProps<{
    widgetContext: WidgetContext;
}>();

const { getPathToChildrenWithoutParents } = useAbilityRoute();

const { lastProductIDs, loading: loadingLastProductIds } = useProductsAssetsHistory();
const productName = useProductNames("pluralLower");

function getPathToProduct(productId: string) {
    return getPathToChildrenWithoutParents([
        {
            alias: CommonRouteAliases.product,
            namedParams: { [CommonRouteAliases.product]: productId },
        },
    ]);
}

const productIDs: ComputedRef<string[]> = computed(() => {
    return lastProductIDs.value;
});

const { loading: loadingProductsWithDisplayConfig, products } = useProductsResultWithDisplayConfig(
    computed(() => {
        return {
            productIds: productIDs.value,
            acceptedLanguages: getDataQueryLanguages(),
        };
    })
);

const loading = computed(() => {
    return loadingProductsWithDisplayConfig.value || loadingLastProductIds.value;
});

// Graphql does not return the products in the order that was requested,
// therefore we sort the graphql products result by the productIds-Array
const productsByIds = computed(() => {
    if (products.value) {
        const productsByIdsSort = products.value;
        productsByIdsSort.sort((product1, product2) => {
            return productIDs.value.indexOf(product1.id) - productIDs.value.indexOf(product2.id);
        });
        return productsByIdsSort;
    }
    return [];
});

function productWithId(id: string) {
    return products.value.find((product) => product.id === id);
}
</script>

<style lang="scss" scoped>
.router-link {
    font-weight: $semi-bold;
    color: var(--q-link);
}
</style>
