import {
    useFieldDefinitionsTitleLazyQuery,
    FieldDefinitionsTitleQueryVariables,
} from "@/shared/services/graphql/generated/consumer-graph-types";
import { ComputedRef } from "vue";

/**
 * Composable to fetch datafield definitions based on provided IDs.
 */
export function useDatafieldDefinition(datafieldDefinitionFilter: ComputedRef<FieldDefinitionsTitleQueryVariables>) {
    // Destructure the result, loading state, load function, and error state from the lazy query
    const {
        result: datafieldDefintionsTitles,
        loading,
        load: loadDatafieldDefinitionsTitles,
        error,
    } = useFieldDefinitionsTitleLazyQuery(datafieldDefinitionFilter);

    // Return the destructured values for use in components
    return {
        datafieldDefintionsTitles,
        loading,
        loadDatafieldDefinitionsTitles,
        error,
    };
}
