<template>
    <div class="form-row">
        <div class="row q-mb-xs">
            <div class="col semi-bold">
                {{ $t("core.Template file") }}
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-sm-6">
                <div class="row">
                    <div class="col">
                        <div class="custom-dropzone-wrapper">
                            <div
                                v-if="showSpinner && !formModel?.headers.valid"
                                class="transform-middle default-padding-top"
                            >
                                <q-spinner-ios size="2em" />
                            </div>
                            <div v-else>
                                <FileBadge
                                    v-if="formModel?.file?.name && formModel.file.size"
                                    :file-name="formModel.file.name"
                                    :bytes="formModel.file.size"
                                    v-model:delimiter="formModel.delimiter.value"
                                    v-model:escape="formModel.escape.value"
                                    v-model:encoding="formModel.encoding.value"
                                    @input-changed="reprocessFile"
                                />
                            </div>
                            <FileUploader :show-error="false" @on-validated="getHeaders" background="white" />
                        </div>
                        <div class="message-wrapper" v-if="formModel?.headers.messageKey">
                            <span class="message" :class="{ 'error-message': !formModel.headers.valid }">{{
                                $t(formModel.headers.messageKey)
                            }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-6">
                <div class="description-field" :class="{ 'description-field-mobile': isXSmall }">
                    {{ $t("core.Select a template file for the import configuration") }}
                    <div class="paragraph-top-margin">
                        {{
                            $t(
                                "core.You can assign the columns of the template file to the corresponding system fields in the next step"
                            )
                        }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { ref, watch } from "vue";
import FileUploader from "@/shared/components/file-uploader/file-uploader.vue";
import FileBadge from "@/shared/components/file-uploader/file-badge.vue";
import { CommonStepModel } from "../form-types";
import { DEFAULT_DELIMITER, DEFAULT_ENCODING, DEFAULT_ESCAPE, STANDARD_DELIMITERS } from "../form-constants";
import { ProcessEncodingType } from "@/shared/services/graphql/generated/consumer-graph-types";
import { detectBom, getEncodingOptionsByType, getTypeByEncodingOptions } from "./file-upload-row-helper";
import { useScreenSize } from "@/shared/screen/composables/screen-size";
import { getHeaderFromStringOrBuffer } from "@/shared/utils/text-parser";

import { Buffer } from "buffer";

const props = defineProps<{
    modelValue: CommonStepModel | undefined;
}>();
const emit = defineEmits(["update:modelValue"]);
const showSpinner = ref(false);
const formModel = ref(props.modelValue);
const { isXSmall } = useScreenSize();

watch(
    formModel,
    () => {
        emit("update:modelValue", formModel.value);
    },
    {
        deep: true,
    }
);

/**
 * Reads header from example file and validates it.
 * @param e The Array of File Objects from FileUploader Component.
 * @param options.activateSpinner Initially show spinner, but optionally, don't show it
 * @param options.knownDelimiter If delimiter is set manually, specify the delimiter or leave this option
 * to default and let the function find out the best delimiter, from a standard list of delimiters `STANDARD_DELIMITERS`
 */
async function getHeaders(
    files: File[],
    options?: { activateSpinner: boolean; manualDelimiter: string; manualEncoding: ProcessEncodingType }
) {
    const errorMessage = ref("");
    const fileBlob: File | null = files.length > 0 ? files[0] : null;
    if (formModel.value && formModel.value.delimiter.value && formModel.value.file) {
        if (fileBlob) {
            if (options?.activateSpinner) {
                showSpinner.value = true;
            }
            setFormModelHeaders(false, "");
            const escapeSign = formModel.value?.escape.value ?? DEFAULT_ESCAPE;
            //detect and set encoding if no manual encoding set
            const encodingOptions = options?.manualEncoding
                ? getEncodingOptionsByType(formModel.value?.encoding.value)
                : await detectBom(fileBlob);
            let headerColumns: string[] = [];
            // get file as Buffer
            const fileArrayBuffer = await fileBlob.arrayBuffer();
            const fileBuffer: Buffer = Buffer.alloc(fileArrayBuffer.byteLength, encodingOptions.encoding);
            const view = new Uint8Array(fileArrayBuffer);
            for (let i = 0; i < fileBuffer.length; ++i) {
                fileBuffer[i] = view[i];
            }
            if (options?.manualDelimiter) {
                try {
                    headerColumns = await getHeaderFromStringOrBuffer(fileBuffer, {
                        delimiter: options.manualDelimiter,
                        escape: escapeSign,
                        encoding: encodingOptions.encoding,
                        bom: encodingOptions.bom,
                    });
                } catch (e: any) {
                    console.debug(e);
                    errorMessage.value = (e as Error).message;
                }
            } else {
                // Get the header with the most occurrences of certain delimiters, when manualDelimiter is not set
                type Header = { delimiter: string; columns: string[] };
                const headers: Header[] = await Promise.all(
                    STANDARD_DELIMITERS.map(async (delimiter: string): Promise<Header> => {
                        let headersFromString;
                        try {
                            headersFromString = await getHeaderFromStringOrBuffer(fileBuffer, {
                                delimiter: delimiter,
                                escape: escapeSign,
                                encoding: encodingOptions.encoding,
                                bom: encodingOptions.bom,
                            });
                        } catch (e: any) {
                            console.debug(e);
                            errorMessage.value = (e as Error).message;
                        }
                        return {
                            delimiter: delimiter,
                            columns: headersFromString ?? [],
                        };
                    })
                );
                const headerLengths: number[] = headers.map((header) => header.columns.length);
                const maxHeaders = Math.max(...headerLengths);
                const headerIndexWithMostColumns = headerLengths.indexOf(maxHeaders);
                formModel.value.delimiter.value = headers[headerIndexWithMostColumns].delimiter;
                headerColumns = headers[headerIndexWithMostColumns].columns;
            }
            if (headerColumns && headerColumns.length > 0) {
                formModel.value.encoding.value = getTypeByEncodingOptions(encodingOptions);
                formModel.value.headers.value = headerColumns;
                formModel.value.file.name = fileBlob.name;
                formModel.value.file.value = files;
                formModel.value.file.size = fileBlob.size;
                formModel.value.file.valid = true;
                setFormModelHeaders(true, "");
            } else {
                if (errorMessage.value !== "") {
                    let messageKey = errorMessage.value;

                    if (errorMessage.value.includes("Invalid Opening Quote")) {
                        messageKey =
                            "core.An error occurred The file contains a quotation mark which cant be processed because the schema doesnt match For more information go to the help";
                    } else if (errorMessage.value.includes("out of memory")) {
                        messageKey =
                            "core.An error occurred The file is too large and cant be processed Use a smaller file for the process configuration";
                    } else {
                        messageKey = errorMessage.value;
                    }

                    setFormModelHeaders(false, messageKey);
                } else {
                    setFormModelHeaders(false, "core.The headers cant be retrieved from the file");
                }

                formModel.value.file.name = "";
                formModel.value.file.value = [];
                formModel.value.file.size = undefined;
                formModel.value.file.valid = false;
                formModel.value.headers.value = [];
            }
        } else {
            setFormModelHeaders(false, "core.Mime type isnt supported");
        }
    }
    showSpinner.value = false;
}
function setFormModelHeaders(valid: boolean, messageLanguageKey: string) {
    if (formModel.value) {
        formModel.value.headers.valid = valid;
        formModel.value.headers.messageKey = messageLanguageKey;
    }
}
async function reprocessFile(data: { delimiter: string; escape: string; encoding: ProcessEncodingType }) {
    if (formModel.value) {
        formModel.value.delimiter.value = data.delimiter;
        formModel.value.escape.value = data.escape;
        formModel.value.encoding.value = data.encoding;
        if (formModel.value?.file?.value) {
            await getHeaders(formModel.value?.file?.value, {
                activateSpinner: false,
                manualDelimiter: formModel.value?.delimiter.value ?? DEFAULT_DELIMITER,
                manualEncoding: formModel.value?.encoding.value ?? DEFAULT_ENCODING,
            });
        }
    }
}
</script>

<style lang="scss" scoped>
.custom-dropzone-wrapper {
    border: 1px dashed $grey-55;
    border-radius: $default-border-radius;
}
</style>
