import {
    ActivitySortFields,
    ArticleSortFields,
    AssetSortFields,
    ContentSortFields,
    LanguageWithWildcard,
    MechanicSortFields,
    ProductSortFields,
} from "@/shared/services/graphql/generated/consumer-graph-types";
import { SortEntry } from "@/shared/components/sort-dropdown/sort-dropdown.model";

export type SortFields =
    | ContentSortFields
    | MechanicSortFields
    | ProductSortFields
    | AssetSortFields
    | ArticleSortFields
    | ActivitySortFields;

export function getSortEntries(sort: SortEntry | undefined, acceptedLanguages: LanguageWithWildcard[]): SortEntry[] {
    if (!sort) return [];
    if (sort.field === "title") {
        //no fallback languages until further discussion
        return [
            {
                field: sort.field.concat("_").concat(acceptedLanguages[0]) as SortFields,
                order: sort.order,
            },
        ];
    }
    return [sort];
}
