/* istanbul ignore file */
/* eslint-disable */

// @generated by Peggy 4.0.3.
//
// https://peggyjs.org/

class peg$SyntaxError extends Error {
    expected: any;
    found: any;
    location: any;

    constructor(message: string, expected: any, found: any, location: any) {
        super(message);
        // istanbul ignore next Check is a necessary evil to support older environments
        if (Object.setPrototypeOf) {
            Object.setPrototypeOf(this, peg$SyntaxError.prototype);
        }
        this.expected = expected;
        this.found = found;
        this.location = location;
        this.name = "SyntaxError";
    }

    format(sources: any[]): string {
        let str = "Error: " + this.message;
        if (this.location) {
            let src: string[] | null = null;
            let k;
            for (k = 0; k < sources.length; k++) {
                if (sources[k].source === this.location.source) {
                    src = sources[k].text.split(/\r\n|\n|\r/g);
                    break;
                }
            }
            const s = this.location.start;
            const offset_s =
                this.location.source && typeof this.location.source.offset === "function"
                    ? this.location.source.offset(s)
                    : s;
            const loc = `${this.location.source}:${offset_s.line}:${offset_s.column}`;
            if (src) {
                const e = this.location.end;
                const filler = peg$padEnd("", offset_s.line.toString().length, " ");
                const line = src[s.line - 1];
                const last = s.line === e.line ? e.column : line.length + 1;
                const hatLen = last - s.column || 1;
                str +=
                    `\n --> ${loc}\n` +
                    `${filler} |\n` +
                    `${offset_s.line} | ${line}\n` +
                    `${filler} | ${peg$padEnd("", s.column - 1, " ")}` +
                    `${peg$padEnd("", hatLen, "^")}`;
            } else {
                str += `\n at ${loc}`;
            }
        }
        return str;
    }

    static buildMessage(expected: any, found: any): string {
        const DESCRIBE_EXPECTATION_FNS: { [key: string]: (expectation: any) => string } = {
            literal: (expectation) => `"${literalEscape(expectation.text)}"`,

            class: (expectation) => {
                const escapedParts = expectation.parts.map((part: any) => {
                    return Array.isArray(part) ? classEscape(part[0]) + "-" + classEscape(part[1]) : classEscape(part);
                });

                return "[" + (expectation.inverted ? "^" : "") + escapedParts.join("") + "]";
            },

            any: () => "any character",

            end: () => "end of input",

            other: (expectation) => expectation.description,
        };

        function hex(ch: string): string {
            return ch.charCodeAt(0).toString(16).toUpperCase();
        }

        function literalEscape(s: string): string {
            return s
                .replace(/\\/g, "\\\\")
                .replace(/"/g, '\\"')
                .replace(/\0/g, "\\0")
                .replace(/\t/g, "\\t")
                .replace(/\n/g, "\\n")
                .replace(/\r/g, "\\r")
                .replace(/[\x00-\x0F]/g, (ch) => "\\x0" + hex(ch))
                .replace(/[\x10-\x1F\x7F-\x9F]/g, (ch) => "\\x" + hex(ch));
        }

        function classEscape(s: string): string {
            return s
                .replace(/\\/g, "\\\\")
                .replace(/\]/g, "\\]")
                .replace(/\^/g, "\\^")
                .replace(/-/g, "\\-")
                .replace(/\0/g, "\\0")
                .replace(/\t/g, "\\t")
                .replace(/\n/g, "\\n")
                .replace(/\r/g, "\\r")
                .replace(/[\x00-\x0F]/g, (ch) => "\\x0" + hex(ch))
                .replace(/[\x10-\x1F\x7F-\x9F]/g, (ch) => "\\x" + hex(ch));
        }

        function describeExpectation(expectation: any): string {
            return DESCRIBE_EXPECTATION_FNS[expectation.type](expectation);
        }

        function describeExpected(expected: any[]): string {
            const descriptions = expected.map(describeExpectation);
            let i, j;

            descriptions.sort();

            if (descriptions.length > 0) {
                for (i = 1, j = 1; i < descriptions.length; i++) {
                    if (descriptions[i - 1] !== descriptions[i]) {
                        descriptions[j] = descriptions[i];
                        j++;
                    }
                }
                descriptions.length = j;
            }

            switch (descriptions.length) {
                case 1:
                    return descriptions[0];

                case 2:
                    return descriptions[0] + " or " + descriptions[1];

                default:
                    return descriptions.slice(0, -1).join(", ") + ", or " + descriptions[descriptions.length - 1];
            }
        }

        function describeFound(found: any): string {
            return found ? `"${literalEscape(found)}"` : "end of input";
        }

        return `Expected ${describeExpected(expected)} but ${describeFound(found)} found.`;
    }
}

function peg$padEnd(str: string, targetLength: number, padString?: string): string {
    padString = padString || " ";
    if (str.length > targetLength) {
        return str;
    }
    targetLength -= str.length;
    padString += padString.repeat(targetLength);
    return str + padString.slice(0, targetLength);
}

function peg$parse(input: string, options?: any): any {
    options = options !== undefined ? options : {};

    const peg$FAILED = {};
    const peg$source = options.grammarSource;

    const peg$startRuleFunctions: { [key: string]: Function } = { start: peg$parsestart };
    let peg$startRuleFunction: Function = peg$parsestart;

    const peg$c0 = "(";
    const peg$c1 = ")";
    const peg$c2 = "/";
    const peg$c3 = "\\";
    const peg$c4 = "u";
    const peg$c5 = "OR NOT";
    const peg$c6 = "AND NOT";
    const peg$c7 = "OR";
    const peg$c8 = "AND";
    const peg$c9 = "NOT";
    const peg$c10 = "||";
    const peg$c11 = "&&";

    const peg$r0 = /^[ \t\r\n\f{}()\/[\]:]/;
    const peg$r1 = /^[\\"bfnrt]/;
    const peg$r2 = /^[0-9a-fA-F]/;
    const peg$r3 = /^[ \t\r\n\f]/;

    const peg$e0 = peg$literalExpectation("(", false);
    const peg$e1 = peg$literalExpectation(")", false);
    const peg$e2 = peg$literalExpectation("/", false);
    const peg$e3 = peg$classExpectation(
        [" ", "\t", "\r", "\n", "\f", "{", "}", "(", ")", "/", "[", "]", ":"],
        false,
        false
    );
    const peg$e4 = peg$anyExpectation();
    const peg$e5 = peg$literalExpectation("\\", false);
    const peg$e6 = peg$classExpectation(["\\", '"', "b", "f", "n", "r", "t"], false, false);
    const peg$e7 = peg$literalExpectation("u", false);
    const peg$e8 = peg$classExpectation(
        [
            ["0", "9"],
            ["a", "f"],
            ["A", "F"],
        ],
        false,
        false
    );
    const peg$e9 = peg$literalExpectation("OR NOT", false);
    const peg$e10 = peg$literalExpectation("AND NOT", false);
    const peg$e11 = peg$literalExpectation("OR", false);
    const peg$e12 = peg$literalExpectation("AND", false);
    const peg$e13 = peg$literalExpectation("NOT", false);
    const peg$e14 = peg$literalExpectation("||", false);
    const peg$e15 = peg$literalExpectation("&&", false);
    const peg$e16 = peg$otherExpectation("whitespace");
    const peg$e17 = peg$classExpectation([" ", "\t", "\r", "\n", "\f"], false, false);

    const peg$f0 = (node: any) => {
        return node[0];
    };
    const peg$f1 = () => {
        return {};
    };
    const peg$f2 = (operator: any) => {
        return {
            operator: operator,
        };
    };
    const peg$f3 = (start: any, left: any, operator: any, right: any) => {
        const node: any = {
            start: start,
            left: left,
        };

        right = right.length == 0 ? null : right[0]["right"] == null ? right[0]["left"] : right[0];

        if (right != null) {
            node["operator"] = operator == "" ? "<implicit>" : operator[0];
            node["right"] = right;
        }

        return node;
    };
    const peg$f4 = (_: any, right: any) => {
        return right;
    };
    const peg$f5 = (left: any, operator: any, right: any) => {
        const node: any = {
            left: left,
        };

        right = right.length == 0 ? null : right[0]["right"] == null ? right[0]["left"] : right[0];

        if (right != null) {
            node["operator"] = operator == "" ? "<implicit>" : operator[0];
            node["right"] = right;
        }

        return node;
    };
    const peg$f6 = (field_exp: any) => {
        return field_exp;
    };
    const peg$f7 = (node: any) => {
        node[0]["parenthesized"] = true;
        return node[0];
    };
    const peg$f8 = (term: any) => {
        return {
            term: term.term,
        };
    };
    const peg$f9 = (term: any) => {
        return {
            term: term.label,
        };
    };
    const peg$f10 = (term: any) => {
        return {
            label: term.join(""),
            location: location(),
        };
    };
    const peg$f11 = (char: any) => {
        return char;
    };
    const peg$f12 = (char: any) => {
        return "\\" + char;
    };
    const peg$f13 = () => {
        4;
    };
    const peg$f14 = (unicodeEscapeSequence: any) => {
        return "\\u" + unicodeEscapeSequence.join("");
    };
    const peg$f15 = (operator: any) => {
        return operator;
    };
    const peg$f16 = (operator: any) => {
        return operator;
    };
    let peg$currPos = options.peg$currPos | 0;
    let peg$savedPos = peg$currPos;
    let peg$posDetailsCache = [{ line: 1, column: 1 }];
    let peg$maxFailPos = peg$currPos;
    let peg$maxFailExpected = options.peg$maxFailExpected || [];
    let peg$silentFails = options.peg$silentFails | 0;

    let peg$result;

    if (options.startRule) {
        if (!(options.startRule in peg$startRuleFunctions)) {
            throw new Error(`Can't start parsing from rule "${options.startRule}".`);
        }

        peg$startRuleFunction = peg$startRuleFunctions[options.startRule];
    }

    function location(): any {
        return peg$computeLocation(peg$savedPos, peg$currPos);
    }

    function peg$literalExpectation(text: string, ignoreCase: boolean): any {
        return { type: "literal", text: text, ignoreCase: ignoreCase };
    }

    function peg$classExpectation(parts: any[], inverted: boolean, ignoreCase: boolean): any {
        return { type: "class", parts: parts, inverted: inverted, ignoreCase: ignoreCase };
    }

    function peg$anyExpectation(): any {
        return { type: "any" };
    }

    function peg$endExpectation(): any {
        return { type: "end" };
    }

    function peg$otherExpectation(description: string): any {
        return { type: "other", description: description };
    }

    function peg$computePosDetails(pos: number): any {
        let details = peg$posDetailsCache[pos];
        let p;

        if (details) {
            return details;
        } else {
            if (pos >= peg$posDetailsCache.length) {
                p = peg$posDetailsCache.length - 1;
            } else {
                p = pos;
                while (!peg$posDetailsCache[--p]) {}
            }

            details = peg$posDetailsCache[p];
            details = {
                line: details.line,
                column: details.column,
            };

            while (p < pos) {
                if (input.charCodeAt(p) === 10) {
                    details.line++;
                    details.column = 1;
                } else {
                    details.column++;
                }

                p++;
            }

            peg$posDetailsCache[pos] = details;

            return details;
        }
    }

    function peg$computeLocation(startPos: number, endPos: number, offset?: boolean): any {
        const startPosDetails = peg$computePosDetails(startPos);
        const endPosDetails = peg$computePosDetails(endPos);

        let res = {
            source: peg$source,
            start: {
                offset: startPos,
                line: startPosDetails.line,
                column: startPosDetails.column,
            },
            end: {
                offset: endPos,
                line: endPosDetails.line,
                column: endPosDetails.column,
            },
        };
        if (offset && peg$source && typeof peg$source.offset === "function") {
            res.start = peg$source.offset(res.start);
            res.end = peg$source.offset(res.end);
        }
        return res;
    }

    function peg$fail(expected: any): void {
        if (peg$currPos < peg$maxFailPos) {
            return;
        }

        if (peg$currPos > peg$maxFailPos) {
            peg$maxFailPos = peg$currPos;
            peg$maxFailExpected = [];
        }

        peg$maxFailExpected.push(expected);
    }
    function peg$buildStructuredError(expected: any[], found: any, location: any): peg$SyntaxError {
        return new peg$SyntaxError(peg$SyntaxError.buildMessage(expected, found), expected, found, location);
    }

    function peg$parsestart(): any {
        let s0, s1, s2, s3;

        s0 = peg$currPos;
        s1 = [];
        s2 = peg$parse_();
        while (s2 !== peg$FAILED) {
            s1.push(s2);
            s2 = peg$parse_();
        }
        s2 = [];
        s3 = peg$parsenode();
        if (s3 !== peg$FAILED) {
            while (s3 !== peg$FAILED) {
                s2.push(s3);
                s3 = peg$parsenode();
            }
        } else {
            s2 = peg$FAILED;
        }
        if (s2 !== peg$FAILED) {
            peg$savedPos = s0;
            s0 = peg$f0(s2);
        } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
        }
        if (s0 === peg$FAILED) {
            s0 = peg$currPos;
            s1 = [];
            s2 = peg$parse_();
            while (s2 !== peg$FAILED) {
                s1.push(s2);
                s2 = peg$parse_();
            }
            peg$savedPos = s0;
            s1 = peg$f1();
            s0 = s1;
        }

        return s0;
    }

    function peg$parsenode(): any {
        let s0, s1, s2, s3, s4, s5;

        s0 = peg$currPos;
        s1 = peg$parseoperator_exp();
        if (s1 !== peg$FAILED) {
            s2 = peg$parseEOF();
            if (s2 !== peg$FAILED) {
                peg$savedPos = s0;
                s0 = peg$f2(s1);
            } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
            }
        } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
        }
        if (s0 === peg$FAILED) {
            s0 = peg$currPos;
            s1 = peg$parseoperator_exp();
            if (s1 !== peg$FAILED) {
                s2 = peg$parsegroup_exp();
                if (s2 !== peg$FAILED) {
                    s3 = [];
                    s4 = peg$parseoperator_exp();
                    while (s4 !== peg$FAILED) {
                        s3.push(s4);
                        s4 = peg$parseoperator_exp();
                    }
                    s4 = [];
                    s5 = peg$parsenode();
                    while (s5 !== peg$FAILED) {
                        s4.push(s5);
                        s5 = peg$parsenode();
                    }
                    peg$savedPos = s0;
                    s0 = peg$f3(s1, s2, s3, s4);
                } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                }
            } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
            }
            if (s0 === peg$FAILED) {
                s0 = peg$currPos;
                s1 = peg$parseoperator_exp();
                if (s1 !== peg$FAILED) {
                    s2 = peg$parsenode();
                    if (s2 !== peg$FAILED) {
                        peg$savedPos = s0;
                        s0 = peg$f4(s1, s2);
                    } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                    }
                } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                }
                if (s0 === peg$FAILED) {
                    s0 = peg$currPos;
                    s1 = peg$parsegroup_exp();
                    if (s1 !== peg$FAILED) {
                        s2 = [];
                        s3 = peg$parseoperator_exp();
                        while (s3 !== peg$FAILED) {
                            s2.push(s3);
                            s3 = peg$parseoperator_exp();
                        }
                        s3 = [];
                        s4 = peg$parsenode();
                        while (s4 !== peg$FAILED) {
                            s3.push(s4);
                            s4 = peg$parsenode();
                        }
                        peg$savedPos = s0;
                        s0 = peg$f5(s1, s2, s3);
                    } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                    }
                }
            }
        }

        return s0;
    }

    function peg$parsegroup_exp(): any {
        let s0, s1, s2, s3;

        s0 = peg$currPos;
        s1 = peg$parsefield_exp();
        if (s1 !== peg$FAILED) {
            s2 = [];
            s3 = peg$parse_();
            while (s3 !== peg$FAILED) {
                s2.push(s3);
                s3 = peg$parse_();
            }
            peg$savedPos = s0;
            s0 = peg$f6(s1);
        } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
        }
        if (s0 === peg$FAILED) {
            s0 = peg$parseparen_exp();
        }

        return s0;
    }

    function peg$parseparen_exp(): any {
        let s0, s1, s2, s3, s4, s5, s6;

        s0 = peg$currPos;
        if (input.charCodeAt(peg$currPos) === 40) {
            s1 = peg$c0;
            peg$currPos++;
        } else {
            s1 = peg$FAILED;
            if (peg$silentFails === 0) {
                peg$fail(peg$e0);
            }
        }
        if (s1 !== peg$FAILED) {
            s2 = [];
            s3 = peg$parse_();
            while (s3 !== peg$FAILED) {
                s2.push(s3);
                s3 = peg$parse_();
            }
            s3 = [];
            s4 = peg$parsenode();
            if (s4 !== peg$FAILED) {
                while (s4 !== peg$FAILED) {
                    s3.push(s4);
                    s4 = peg$parsenode();
                }
            } else {
                s3 = peg$FAILED;
            }
            if (s3 !== peg$FAILED) {
                if (input.charCodeAt(peg$currPos) === 41) {
                    s4 = peg$c1;
                    peg$currPos++;
                } else {
                    s4 = peg$FAILED;
                    if (peg$silentFails === 0) {
                        peg$fail(peg$e1);
                    }
                }
                if (s4 !== peg$FAILED) {
                    s5 = [];
                    s6 = peg$parse_();
                    while (s6 !== peg$FAILED) {
                        s5.push(s6);
                        s6 = peg$parse_();
                    }
                    peg$savedPos = s0;
                    s0 = peg$f7(s3);
                } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                }
            } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
            }
        } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
        }

        return s0;
    }

    function peg$parsefield_exp(): any {
        let s0, s1;

        s0 = peg$currPos;
        s1 = peg$parseterm();
        if (s1 !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$f8(s1);
        }
        s0 = s1;

        return s0;
    }

    function peg$parseterm(): any {
        let s0, s1, s2, s3;

        s0 = peg$currPos;
        s1 = peg$parseunquoted_term();
        if (s1 !== peg$FAILED) {
            s2 = [];
            s3 = peg$parse_();
            while (s3 !== peg$FAILED) {
                s2.push(s3);
                s3 = peg$parse_();
            }
            peg$savedPos = s0;
            s0 = peg$f9(s1);
        } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
        }

        return s0;
    }

    function peg$parseunquoted_term(): any {
        let s0, s1, s2;

        s0 = peg$currPos;
        s1 = [];
        s2 = peg$parseterm_char();
        if (s2 === peg$FAILED) {
            if (input.charCodeAt(peg$currPos) === 47) {
                s2 = peg$c2;
                peg$currPos++;
            } else {
                s2 = peg$FAILED;
                if (peg$silentFails === 0) {
                    peg$fail(peg$e2);
                }
            }
        }
        if (s2 !== peg$FAILED) {
            while (s2 !== peg$FAILED) {
                s1.push(s2);
                s2 = peg$parseterm_char();
                if (s2 === peg$FAILED) {
                    if (input.charCodeAt(peg$currPos) === 47) {
                        s2 = peg$c2;
                        peg$currPos++;
                    } else {
                        s2 = peg$FAILED;
                        if (peg$silentFails === 0) {
                            peg$fail(peg$e2);
                        }
                    }
                }
            }
        } else {
            s1 = peg$FAILED;
        }
        if (s1 !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$f10(s1);
        }
        s0 = s1;

        return s0;
    }

    function peg$parseterm_char(): any {
        let s0, s1, s2, s3, s4;

        s0 = peg$currPos;
        s1 = peg$currPos;
        peg$silentFails++;
        s2 = input.charAt(peg$currPos);
        if (peg$r0.test(s2)) {
            peg$currPos++;
        } else {
            s2 = peg$FAILED;
            if (peg$silentFails === 0) {
                peg$fail(peg$e3);
            }
        }
        peg$silentFails--;
        if (s2 === peg$FAILED) {
            s1 = undefined;
        } else {
            peg$currPos = s1;
            s1 = peg$FAILED;
        }
        if (s1 !== peg$FAILED) {
            if (input.length > peg$currPos) {
                s2 = input.charAt(peg$currPos);
                peg$currPos++;
            } else {
                s2 = peg$FAILED;
                if (peg$silentFails === 0) {
                    peg$fail(peg$e4);
                }
            }
            if (s2 !== peg$FAILED) {
                peg$savedPos = s0;
                s0 = peg$f11(s2);
            } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
            }
        } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
        }
        if (s0 === peg$FAILED) {
            s0 = peg$currPos;
            if (input.charCodeAt(peg$currPos) === 92) {
                s1 = peg$c3;

                peg$currPos++;
            } else {
                s1 = peg$FAILED;
                if (peg$silentFails === 0) {
                    peg$fail(peg$e5);
                }
            }
            if (s1 !== peg$FAILED) {
                s2 = input.charAt(peg$currPos);
                if (peg$r1.test(s2)) {
                    peg$currPos++;
                } else {
                    s2 = peg$FAILED;
                    if (peg$silentFails === 0) {
                        peg$fail(peg$e6);
                    }
                }
                if (s2 !== peg$FAILED) {
                    peg$savedPos = s0;
                    s0 = peg$f12(s2);
                } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                }
            } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
            }
            if (s0 === peg$FAILED) {
                s0 = peg$currPos;
                if (input.charCodeAt(peg$currPos) === 92) {
                    s1 = peg$c3;
                    peg$currPos++;
                } else {
                    s1 = peg$FAILED;
                    if (peg$silentFails === 0) {
                        peg$fail(peg$e5);
                    }
                }
                if (s1 !== peg$FAILED) {
                    s2 = peg$currPos;
                    if (input.charCodeAt(peg$currPos) === 117) {
                        s3 = peg$c4;
                        peg$currPos++;
                    } else {
                        s3 = peg$FAILED;
                        if (peg$silentFails === 0) {
                            peg$fail(peg$e7);
                        }
                    }
                    if (s3 !== peg$FAILED) {
                        s4 = input.charAt(peg$currPos);
                        if (peg$r2.test(s4)) {
                            peg$currPos++;
                        } else {
                            s4 = peg$FAILED;
                            if (peg$silentFails === 0) {
                                peg$fail(peg$e8);
                            }
                        }
                        if (s4 !== peg$FAILED) {
                            peg$savedPos = s2;
                            s2 = peg$f13();
                        } else {
                            peg$currPos = s2;
                            s2 = peg$FAILED;
                        }
                    } else {
                        peg$currPos = s2;
                        s2 = peg$FAILED;
                    }
                    if (s2 !== peg$FAILED) {
                        peg$savedPos = s0;
                        s0 = peg$f14(s2);
                    } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                    }
                } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                }
            }
        }

        return s0;
    }

    function peg$parseoperator_exp(): any {
        let s0, s1, s2, s3, s4;

        s0 = peg$currPos;
        s1 = [];
        s2 = peg$parse_();
        while (s2 !== peg$FAILED) {
            s1.push(s2);
            s2 = peg$parse_();
        }
        s2 = peg$parseoperator();
        if (s2 !== peg$FAILED) {
            s3 = [];
            s4 = peg$parse_();
            if (s4 !== peg$FAILED) {
                while (s4 !== peg$FAILED) {
                    s3.push(s4);
                    s4 = peg$parse_();
                }
            } else {
                s3 = peg$FAILED;
            }
            if (s3 !== peg$FAILED) {
                peg$savedPos = s0;
                s0 = peg$f15(s2);
            } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
            }
        } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
        }
        if (s0 === peg$FAILED) {
            s0 = peg$currPos;
            s1 = [];
            s2 = peg$parse_();
            while (s2 !== peg$FAILED) {
                s1.push(s2);
                s2 = peg$parse_();
            }
            s2 = peg$parseoperator();
            if (s2 !== peg$FAILED) {
                s3 = peg$parseEOF();
                if (s3 !== peg$FAILED) {
                    peg$savedPos = s0;
                    s0 = peg$f16(s2);
                } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                }
            } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
            }
        }

        return s0;
    }

    function peg$parseoperator(): any {
        let s0;

        if (input.substr(peg$currPos, 6) === peg$c5) {
            s0 = peg$c5;
            peg$currPos += 6;
        } else {
            s0 = peg$FAILED;
            if (peg$silentFails === 0) {
                peg$fail(peg$e9);
            }
        }
        if (s0 === peg$FAILED) {
            if (input.substr(peg$currPos, 7) === peg$c6) {
                s0 = peg$c6;
                peg$currPos += 7;
            } else {
                s0 = peg$FAILED;
                if (peg$silentFails === 0) {
                    peg$fail(peg$e10);
                }
            }
            if (s0 === peg$FAILED) {
                if (input.substr(peg$currPos, 2) === peg$c7) {
                    s0 = peg$c7;
                    peg$currPos += 2;
                } else {
                    s0 = peg$FAILED;
                    if (peg$silentFails === 0) {
                        peg$fail(peg$e11);
                    }
                }
                if (s0 === peg$FAILED) {
                    if (input.substr(peg$currPos, 3) === peg$c8) {
                        s0 = peg$c8;
                        peg$currPos += 3;
                    } else {
                        s0 = peg$FAILED;
                        if (peg$silentFails === 0) {
                            peg$fail(peg$e12);
                        }
                    }
                    if (s0 === peg$FAILED) {
                        if (input.substr(peg$currPos, 3) === peg$c9) {
                            s0 = peg$c9;
                            peg$currPos += 3;
                        } else {
                            s0 = peg$FAILED;
                            if (peg$silentFails === 0) {
                                peg$fail(peg$e13);
                            }
                        }
                        if (s0 === peg$FAILED) {
                            if (input.substr(peg$currPos, 2) === peg$c10) {
                                s0 = peg$c10;
                                peg$currPos += 2;
                            } else {
                                s0 = peg$FAILED;
                                if (peg$silentFails === 0) {
                                    peg$fail(peg$e14);
                                }
                            }
                            if (s0 === peg$FAILED) {
                                if (input.substr(peg$currPos, 2) === peg$c11) {
                                    s0 = peg$c11;
                                    peg$currPos += 2;
                                } else {
                                    s0 = peg$FAILED;
                                    if (peg$silentFails === 0) {
                                        peg$fail(peg$e15);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        return s0;
    }

    function peg$parse_(): any {
        let s0, s1;

        peg$silentFails++;
        s0 = [];
        s1 = input.charAt(peg$currPos);
        if (peg$r3.test(s1)) {
            peg$currPos++;
        } else {
            s1 = peg$FAILED;
            if (peg$silentFails === 0) {
                peg$fail(peg$e17);
            }
        }
        if (s1 !== peg$FAILED) {
            while (s1 !== peg$FAILED) {
                s0.push(s1);
                s1 = input.charAt(peg$currPos);
                if (peg$r3.test(s1)) {
                    peg$currPos++;
                } else {
                    s1 = peg$FAILED;
                    if (peg$silentFails === 0) {
                        peg$fail(peg$e17);
                    }
                }
            }
        } else {
            s0 = peg$FAILED;
        }
        peg$silentFails--;
        if (s0 === peg$FAILED) {
            s1 = peg$FAILED;
            if (peg$silentFails === 0) {
                peg$fail(peg$e16);
            }
        }

        return s0;
    }

    function peg$parseEOF(): any {
        let s0, s1;

        s0 = peg$currPos;
        peg$silentFails++;
        if (input.length > peg$currPos) {
            s1 = input.charAt(peg$currPos);
            peg$currPos++;
        } else {
            s1 = peg$FAILED;
            if (peg$silentFails === 0) {
                peg$fail(peg$e4);
            }
        }
        peg$silentFails--;
        if (s1 === peg$FAILED) {
            s0 = undefined;
        } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
        }

        return s0;
    }

    peg$result = peg$startRuleFunction();

    if (options.peg$library) {
        return /** @type {any} */ {
            peg$result,
            peg$currPos,
            peg$FAILED,
            peg$maxFailExpected,
            peg$maxFailPos,
        };
    }
    if (peg$result !== peg$FAILED && peg$currPos === input.length) {
        return peg$result;
    } else {
        if (peg$result !== peg$FAILED && peg$currPos < input.length) {
            peg$fail(peg$endExpectation());
        }

        throw peg$buildStructuredError(
            peg$maxFailExpected,
            peg$maxFailPos < input.length ? input.charAt(peg$maxFailPos) : null,
            peg$maxFailPos < input.length
                ? peg$computeLocation(peg$maxFailPos, peg$maxFailPos + 1)
                : peg$computeLocation(peg$maxFailPos, peg$maxFailPos)
        );
    }
}

export { peg$SyntaxError as SyntaxError, peg$parse as parse };
