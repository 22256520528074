<!--  
    Wrapper für die eigentliche Komponente BreadcrumbInnerElement.
    Das ist nötig, weil getBreadCrumbsFromRoute im setup-Context aufgerufen werden muss, weil Apollo Queries in den Implementierungen verwendet werden
    getBreadCrumbsFromRoute muss aber erneut aufgerufen werden, wenn sich dir Route ändert.

    Deshalb hier der Wrapper um die eigentliche Componente, der einen Reload der inneren Componente triggert, wenn sich die Route oder die Params geändert haben
-->

<template>
    <BreadcrumbInnerElement :key="reloadKey" :abilities="abilities" />
</template>

<script setup lang="ts">
import { ref, watch } from "vue";
import { useRoute } from "vue-router";
import BreadcrumbInnerElement from "./breadcrumbs-from-route-inner-element.vue";
import { useAbilityStore } from "@/shared/store/ability.store";

const route = useRoute();
const { abilities } = useAbilityStore();
const reloadKey = ref(1);

watch(
    () => route.params,
    (value, oldValue) => {
        // Nur wenn die Parameter wirklich von den Werten unterschiedlich sind
        if (JSON.stringify(value) !== JSON.stringify(oldValue)) {
            reloadKey.value++;
        }
    }
);

watch(
    () => route.name,
    (value, oldValue) => {
        // Nur wenn die Parameter wirklich von den Werten unterschiedlich sind
        if (JSON.stringify(value) !== JSON.stringify(oldValue)) {
            reloadKey.value++;
        }
    }
);
</script>
