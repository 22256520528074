import { ContentEdge } from "@/shared/services/graphql/generated/consumer-graph-types.ts";
import { Documentation } from "@/abilities/documentation/model/documentation.model.ts";

export const mapContentEdgesToDocuments = (result: ContentEdge[]): Documentation[] => {
    return result
        .map((x) => {
            if (!x.node) return undefined;

            const type = (x.node as any).__typename ?? "";
            const splitID = x.node.id.split("@");
            let contentID = x.node.contentId!;
            let topicID;
            if (type === "Topic") {
                topicID = splitID![0];
                contentID = splitID![1];
            }

            const result: Documentation = {
                type,
                topicId: topicID,
                contentId: contentID,
                language: x.node.language ?? "",
                title: x.node.teasers?.title ?? "",
                cursor: x.cursor ?? "",
            };

            return result;
        })
        .filter((x) => x !== undefined) as Documentation[];
};
