import { useQuasar } from "quasar";
import { computed } from "vue";

export const useScreenSize = () => {
    const { screen } = useQuasar();

    const isXSmall = computed(() => {
        return screen.name === "xs";
    });

    const isSmall = computed(() => {
        return screen.name === "sm";
    });

    const isLessThanMedium = computed(() => {
        return screen.lt.md;
    });

    const isLargerThanMedium = computed(() => {
        return screen.gt.md;
    });

    const isMedium = computed(() => {
        return screen.name === "md";
    });

    const isLarge = computed(() => {
        return screen.name === "lg";
    });

    return {
        isXSmall,
        isSmall,
        isLessThanMedium,
        isLargerThanMedium,
        isMedium,
        isLarge,
    };
};
