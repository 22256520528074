<template>
    <ListFrame
        :no-results="!resultExists"
        :loading="initialDocsLoading"
        :error="error"
        :no-results-error-message="noResultsErrorMessage"
    >
        <template #filter="{ isMobile }">
            <ListFilterUrlSync
                v-model:selected="selected"
                :facet-collection-id="FacetCollection.documentSelection"
                :is-mobile-view="isMobile"
                :fetch-aggregation="fetchAggregation"
                :product="productID"
                :more-menu-entries="configMenuEntries"
                :sort-order-entries="sortOrderItems"
                @on-sort-selection-changed="onChangeSortOrder"
                @on-open-filter="onOpenFilterHandler"
                sort-save-key="documentation_sort"
            />
        </template>
        <template #count>
            {{ searchHitsText }}
        </template>
        <template #content>
            <InfinityScroll @load-more="onLoadMore">
                <div class="column gap">
                    <DocumentListCard
                        v-for="(item, index) in contents"
                        :key="item.id"
                        :document="item"
                        :context="searchContext"
                        :index="index"
                        @toggle-collection-overlay="onOpenOverlay"
                    />
                </div>
            </InfinityScroll>
        </template>
    </ListFrame>
    <CollectionContentOverlay
        v-if="contentMapIdForOverlay"
        :title="$t('documentation.Add to Collection')"
        :content-title="titleForOverlay"
        :content-map-id="contentMapIdForOverlay"
        :topic-id="topicIdForOverlay"
        :product-id="product"
        :asset-id="asset"
        @on-close="() => closeOverlay()"
        @on-save-succeeded="() => closeOverlay()"
    />
</template>

<script setup lang="ts">
import { computed, ComputedRef, ref, toRefs, watch } from "vue";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { MenuTextEntry } from "@/shared/components/menu/menu.model";
import CollectionContentOverlay from "@/abilities/collections/components/overlay/collection-content-overlay.vue"; //TODO Remove dependency to collection module
import ListFilterUrlSync from "@/shared/list-filter/list-filter-renderer-url-sync.vue";
import { Facet, FacetCollection, FacetSelected } from "@/shared/facets/facets.model";
import ListFrame from "@/shared/list-frame/list-frame.vue";
import { useSelectedFacets } from "@/shared/facets/composables/selected-facets";
import { AccessAction, AccessResource } from "@/shared/access-control/access-control";
import { useHasAccess } from "@/shared/access-control/composables/use-has-access";
import { useFilteredDocumentationResults } from "./filtered-documentation-results.ts";
import { useSortOrderItems } from "./documentation-sort-order-items";
import { generateSearchHitsText } from "@/shared/utils/total-formatter";
import DocumentListCard from "@/shared/documentation/document-list-card.vue";
import InfinityScroll from "@/shared/components/scroll/infinity-scroll.vue";

const LAZY_LOAD_AMOUNT = 10;

const props = defineProps<{
    text?: string;
    product?: string;
    asset?: string;
}>();

const { product, asset, text } = toRefs(props);
const contentMapIdForOverlay = ref<string | undefined>();
const topicIdForOverlay = ref<string>("");
const titleForOverlay = ref<string>("");
const selected = ref<FacetSelected[]>([]);
const facet = ref<Facet>();
const resultExists = ref(false);
const noResultButFiltersAreSet = ref(false);

const router = useRouter();
const { t } = useI18n();
const { sortOrderItems } = useSortOrderItems();
const { sorting, onChangeSortOrder } = useSelectedFacets();

const productID = computed(() => {
    if (!asset?.value && product?.value) return product?.value;

    return undefined;
});

const onOpenOverlay = (topicId: string, contentMapId: string, title: string) => {
    contentMapIdForOverlay.value = contentMapId;
    topicIdForOverlay.value = topicId;
    titleForOverlay.value = title;
};

const closeOverlay = () => {
    contentMapIdForOverlay.value = undefined;
};

const searchContext = computed(() => {
    return {
        product: product.value,
        asset: asset.value,
    };
});

const searchPhrase = computed(() => {
    return text?.value ?? "";
});

const { contents, total, error, loadMore, fetchAggregation } = useFilteredDocumentationResults({
    selected,
    facet,
    searchContext,
    searchPhrase,
    sort: sorting,
});

watch(contents, () => {
    resultExists.value = total.value?.count ? total.value?.count > 0 : false;
    noResultButFiltersAreSet.value = !resultExists.value && selected.value.length > 0;
});

const initialDocsLoading = computed(() => {
    return !contents.value;
});

const { hasAccess: hasConfigAccess } = useHasAccess({
    resource: AccessResource.data_display_configs,
    action: AccessAction.write,
});

const { hasAccess: hasFilterConfigAccess } = useHasAccess({
    resource: AccessResource.facet_collections,
    action: AccessAction.write,
});

const configMenuEntries: ComputedRef<MenuTextEntry[]> = computed(() => {
    return [
        {
            text: t("core.Configure view"),
            icon: "fa-regular fa-sliders-simple icon-color",
            click: () => {
                const route = router.resolve({ path: "/admin/datadisplay/document-selection" });
                window.open(route.href, "_blank");
            },
            visible: hasConfigAccess.value,
            elementAttrs: { "data-qs": "more-menu-configure-document-selection-view" },
        },
        {
            text: t("core.Configure filter"),
            icon: "fa-regular fa-sliders-simple icon-color",
            click: () => {
                const route = router.resolve({ path: "/admin/filter/document-selection" });
                window.open(route.href, "_blank");
            },
            visible: hasFilterConfigAccess.value,
            elementAttrs: { "data-qs": "more-menu-configure-document-filter" },
        },
    ];
});

const onLoadMore = async (done: (stop: boolean) => void) => {
    if (!contents.value || contents.value.length < 1 || contents.value.length === total.value.count) {
        done(true);
        return;
    }

    const lastCursor = contents.value[contents.value.length - 1].cursor ?? "";

    const loadMoreLastCursor = await loadMore(lastCursor, LAZY_LOAD_AMOUNT);

    done(loadMoreLastCursor ? lastCursor === loadMoreLastCursor : true);
};

const searchHitsText = computed(() => {
    return generateSearchHitsText(total.value);
});

const noResultsErrorMessage = computed(() => {
    if (noResultButFiltersAreSet.value) {
        return t(
            "documentation.No documentation found with the set filters Adjust the filter criteria to get more results"
        );
    }
    if (props.text) {
        return t("core.No search results found");
    } else {
        return t("documentation.No documentation available");
    }
});

const onOpenFilterHandler = (facetProp: Facet) => {
    facet.value = facetProp;
};
</script>
