<template>
    <div>
        <QitButton
            flat
            class="q-pa-none"
            :label="label"
            :icon-right="collapsed ? 'fa-regular fa-chevron-down' : 'fa-regular fa-chevron-up'"
            @click="emits('onClick')"
            :data-qs="collapsed ? `${name}-show-more-button` : `${name}-show-less-button`"
        >
            <q-badge
                v-if="collapsed && count && count > 0"
                color="negative"
                :label="count"
                class="q-ml-sm q-mr-xs badge"
                rounded
            />
        </QitButton>
    </div>
</template>

<script setup lang="ts">
import QitButton from "@/shared/components/buttons/qit-button.vue";
import { computed, toRefs } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const props = defineProps<{
    collapsed: boolean;
    name: string;
    count?: number;
    collapsedText?: string;
    expandedText?: string;
}>();
const emits = defineEmits<{ onClick: [] }>();

const { collapsed } = toRefs(props);

const label = computed(() => {
    if (collapsed.value) return props.collapsedText ? props.collapsedText : t("core.Show more");

    return props.expandedText ? props.expandedText : t("core.Show less");
});
</script>

<style lang="scss" scoped>
.q-btn {
    :deep(.q-icon) {
        font-size: 12px;
        margin-left: 4px;
    }
}
.badge {
    font-size: 10px;
}
</style>
