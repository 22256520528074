import { SearchContext } from "@/shared/search/search.types";
import { RouteElementWithParam } from "@/shared/environment/ability.types";
import { CommonRouteAliases } from "@/shared/environment/common-route-aliases";
import { useAbilityRoute } from "@/shared/environment/ability-route";

export const useActivityRoute = () => {
    const { getPathToChildrenWithoutParents } = useAbilityRoute();

    const calculatePathForActivities = (searchContext?: SearchContext) => {
        const routeElements = getRouteElementsForContext(searchContext);

        routeElements.push({
            alias: CommonRouteAliases.activities,
            namedParams: {},
        });

        return getPathToChildrenWithoutParents(routeElements);
    };

    const calculatePathForActivitiesSubalias = (
        routeAlias: CommonRouteAliases.activityOverview | CommonRouteAliases.activityDetail,
        activityId: string,
        searchContext?: SearchContext
    ) => {
        const routeElements = getRouteElementsForContext(searchContext);

        routeElements.push({
            alias: CommonRouteAliases.activities,
            namedParams: {},
        });

        if (activityId) {
            routeElements.push({
                alias: routeAlias,
                namedParams: { activityId },
            });
        }

        return getPathToChildrenWithoutParents(routeElements);
    };

    const getRouteElementsForContext = (searchContext?: SearchContext) => {
        const routeElements: RouteElementWithParam[] = [];

        if (searchContext?.product) {
            routeElements.push({
                alias: CommonRouteAliases.product,
                namedParams: { [CommonRouteAliases.product]: searchContext.product },
            });
        }

        if (searchContext?.asset) {
            routeElements.push({
                alias: CommonRouteAliases.asset,
                namedParams: { [CommonRouteAliases.asset]: searchContext.asset },
            });
        }

        if (searchContext?.article) {
            routeElements.push({
                alias: CommonRouteAliases.commonArticle,
                namedParams: { [CommonRouteAliases.commonArticle]: searchContext.article },
            });
        }

        return routeElements;
    };

    return {
        calculatePathForActivities,
        calculatePathForActivitiesSubalias,
    };
};
