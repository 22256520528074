<template>
    <ListFrame
        class="assets-overview"
        :loading="initialAssetsLoading"
        :no-results="!resultExists"
        :error="error"
        :no-results-error-message="noResultsErrorMessage"
    >
        <template #filter="{ isMobile }">
            <!-- @vue-ignore -->
            <component
                v-model:selected="selected"
                @update:selected="updateSelected"
                @on-sort-selection-changed="onChangeSortOrder"
                @on-open-filter="onOpenFilterHandler"
                v-bind="listFilterComponentProps"
                :is="listFilterComponent"
                :is-mobile-view="isMobile"
                :sort-save-key="isSearch ? 'asset_search_sort' : 'asset_sort'"
                :initial-selected-sort-entry="
                    isSearch
                        ? {
                              field: AssetSortFields.score,
                              order: SortOrder.desc,
                          }
                        : {
                              field: AssetSortFields.assetId,
                              order: SortOrder.asc,
                          }
                "
            />
        </template>
        <template #count>
            {{ searchHitsText }}
        </template>
        <template #content>
            <LazyLoadScrollView
                :reach-bottom-text="t('product-and-asset.Loading {assetsName}', { assetsName: assetName })"
                @load-more="onLoadMore"
                class="col"
            >
                <TilesGrid
                    :tiles="listCards"
                    :always-single-col="true"
                    @select="emitSelect"
                    class="assets-overview-tiles-grid"
                    :class="{ dense: dense }"
                />
            </LazyLoadScrollView>
        </template>
    </ListFrame>
</template>

<script setup lang="ts">
import { computed, onMounted, Ref, ref, toRefs, watch } from "vue";
import { AssetQueryResult } from "@/shared/services/graphql/generated/admin-graph-types";
import { useAssetsOverviewConfig, useAssetsOverviewSort } from "./assets-overview.model";
import { loadMore } from "@/shared/components/scroll/lazy-loading-fetch";
import { useI18n } from "vue-i18n";
import { Facet, FacetCollection, FacetSelected } from "@/shared/facets/facets.model";
import ListFrame from "@/shared/list-frame/list-frame.vue";
import ListFilterRenderer from "@/shared/list-filter/list-filter-renderer.vue";
import ListFilterUrlSync from "@/shared/list-filter/list-filter-renderer-url-sync.vue";
import TilesGrid from "@/shared/components/tiles/tiles-grid.vue";
import LazyLoadScrollView from "@/shared/components/scroll/lazy-loading-scroll-view.vue";
import { QualificationLevelSelectionResult } from "@/shared/environment/ability.types";
import { useRoute } from "vue-router";
import { PRODUCT_REFERENCED_ID } from "@/shared/facets/facet-utils";
import { AssetSortFields, SortOrder } from "@/shared/services/graphql/generated/consumer-graph-types";
import { generateSearchHitsText } from "@/shared/utils/total-formatter";
import { useAssetDataSource } from "@/abilities/product-and-asset/pages/asset-data-source";
import { SortEntry } from "@/shared/components/sort-dropdown/sort-dropdown.model";
import { useAssetNames } from "@/abilities/product-and-asset/composables/asset-names";

const props = withDefaults(
    defineProps<{
        fullTextSearchValue?: string;
        productId?: string; //TODO validate if productId is necessary or global product param is enough
        showJumpToProduct?: boolean;
        emitSelectionInTiles?: boolean;
        multiLevel?: boolean;
        hasLevelTitle?: boolean;
        selectionData?: QualificationLevelSelectionResult;
        dense?: boolean;
        isSearch?: boolean;
    }>(),
    {
        fullTextSearchValue: "",
        productId: "",
        showJumpToProduct: true,
        multiLevel: false,
        hasLevelTitle: false,
        dense: false,
        isSearch: false,
    }
);

const emit = defineEmits(["select"]);

const route = useRoute();
const isProductDetailPage = ref(false);
const facet = ref<Facet>();
const { selectionData } = toRefs(props);
const sorting: Ref<SortEntry | undefined> = ref();
const selected = ref<FacetSelected[]>([]);

onMounted(() => {
    isProductDetailPage.value = "product" in route.params;
});

const assetName = useAssetNames("pluralLower");

const selectedProducts = computed<string[] | undefined>(() => {
    return selectionData?.value?.product ? [selectionData.value.product] : undefined;
});

const preselected = computed(() => {
    return selectedProducts.value ? { custom_products: selectedProducts.value } : undefined;
});

const listFilterComponent = computed(() => {
    return selectionData?.value ? ListFilterRenderer : ListFilterUrlSync;
});

const listFilterComponentProps = computed(() => {
    let listFilterProps = {
        fetchAggregation: fetchAggregation,
        "facet-collection-id": FacetCollection.assetSelection,
        "more-menu-entries": selectionData?.value ? [] : configMenuEntries.value,
        "sort-order-entries": sortOrderItems.value,
        "initial-selected-sort-entry": sorting.value,
        product: props.productId,
    };

    if (selectionData?.value)
        listFilterProps = {
            ...listFilterProps,
            //@ts-ignore
            preselect: preselected.value,
            "disabled-facets": isProductDetailPage.value ? [PRODUCT_REFERENCED_ID] : undefined,
        };
    else listFilterProps = { ...listFilterProps, product: props.productId };

    return listFilterProps;
});

const emitSelect = (...params: any) => {
    emit("select", ...params);
};

const fullTextSearchValue = computed(() => {
    return props.fullTextSearchValue;
});

const resultExists = ref(false);
const noResultButFiltersAreSet = ref(false);

const { sortOrderItems } = useAssetsOverviewSort(props.isSearch);

const { configMenuEntries } = useAssetsOverviewConfig();

const { refetch, error, fetchMore, assets, listCards, fetchAggregation } = useAssetDataSource(
    computed(() => {
        return { text: fullTextSearchValue.value, product: props.productId };
    }),
    props.showJumpToProduct,
    props.emitSelectionInTiles ?? false,
    selected,
    sorting,
    facet
);

const initialAssetsLoading = computed(() => {
    return !assets.value;
});

watch(assets, () => {
    if (!assets.value?.assets) {
        return;
    }
    resultExists.value = assets.value?.assets.length > 0;
    noResultButFiltersAreSet.value = !resultExists.value && selected.value.length > 0;
});

const lazyLoadAmount = 30;

const onLoadMore = async (callBackAfterLoaded: (hasMoreElements: boolean) => void) => {
    if (!assets.value) {
        // Noch nicht initialisiert. Also wahrscheinlich gibt es schon was zum Nachladen
        callBackAfterLoaded(true);
        return;
    }

    const assetsValue = assets.value as AssetQueryResult;
    const lastAsset = assetsValue.assets[assetsValue.assets.length - 1];

    if (!lastAsset || !lastAsset.cursor) {
        callBackAfterLoaded(false);
        return;
    }

    const result = await loadMore(fetchMore, lastAsset.cursor, lazyLoadAmount);
    const isAtEnd = result?.data.assets.assets.length == 0;
    callBackAfterLoaded(!isAtEnd);

    return;
};

const { t } = useI18n();
const assetsAvailable = useAssetNames("noAssetsAvailable");
const assetsAvailableWithFilter = useAssetNames("noAssetsWithSetFilterAvailable");

const noResultsErrorMessage = computed(() => {
    if (noResultButFiltersAreSet.value) {
        return assetsAvailableWithFilter.value;
    }

    if (props.fullTextSearchValue) {
        return t("core.No search results found");
    } else {
        return assetsAvailable.value;
    }
});

const searchHitsText = computed(() => {
    return generateSearchHitsText(assets.value?.total);
});

const onOpenFilterHandler = (facetProp: Facet) => {
    facet.value = facetProp;
};

const updateSelected = () => {
    if (sorting.value) refetch();
};

const onChangeSortOrder = (selectedItem: SortEntry) => {
    sorting.value = { field: selectedItem.field, order: selectedItem.order };
    if (selected.value) refetch();
};
</script>

<style lang="scss" scoped>
.assets-overview-tiles-grid:not(.dense) {
    padding: 0;
}
</style>
