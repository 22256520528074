import { ApolloClient, createHttpLink } from "@apollo/client/core";
import { clientCache } from "./client-cache";
import { authLink, loginLink } from "@/shared/authentication/authentication";
import { errorLink } from "@/shared/services/apollo-clients/links/error-link";

const httpLink = createHttpLink({
    uri: "/graphql",
});

export const consumerClient = new ApolloClient({
    link: loginLink.concat(errorLink).concat(authLink).concat(httpLink),
    cache: clientCache,
    //Keep for MVP
    connectToDevTools: true,
    defaultOptions: {
        watchQuery: {
            fetchPolicy: "network-only",
        },
        query: {
            fetchPolicy: "network-only",
        },
    },
});
