import { RouteElementPathWithParams, RouteElementWithParam } from "@/shared/environment/ability.types";
import { LocationQueryRaw, RouteLocationRaw } from "vue-router";
import qs from "qs";

export const EMPTY_PARAM = "-";

/**
 * generiert aus den ParentElementen und den Parametern eine Route, die in den router gepusht werden kann
 */
export const createRouteFromRouteElements = (
    parentElements: RouteElementPathWithParams,
    currentElement: RouteElementWithParam | undefined,
    queryParams?: LocationQueryRaw
): RouteLocationRaw => {
    const allElementsWithParam = Array.from(parentElements);
    if (currentElement) {
        allElementsWithParam.push(currentElement);
    }
    const name =
        "/" +
        allElementsWithParam
            .map((element) => element.alias)
            .reduce((accumulator, currentValue) => accumulator + "/" + currentValue);

    let params: any = {};
    allElementsWithParam.forEach((element) => {
        params = Object.assign({}, params, element.namedParams);
    });

    return {
        name: name,
        params: params,
        ...(queryParams && { query: queryParams }),
    };
};

export const decodeParamArrayFromString = (param: string): string[] => {
    const parsed = qs.parse(param.substring(1));
    const elements = Object.values(parsed);
    return Array.isArray(elements) ? (elements as string[]) : [];
};

export const encodeParamArrayToString = (values: string[]) => {
    return "-" + qs.stringify(values);
};

/**
 * Legt die Parameter der Route als Props an. Das Ergebnis kann mit v-bind der Componente übergeben werden.
 * z.b.:
 * {
 *  product: "123456",
 *  asset: "1000"
 * }
 */
export const getRouteElementParamsAsComponentProps = (
    parentElements: RouteElementWithParam[]
): Record<string, string> => {
    let result: Record<string, string> = {};
    parentElements.forEach((item) => {
        result = { ...result, ...item.namedParams };
    });
    return result;
};
