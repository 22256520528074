import { useSearchConfigQuery } from "@/shared/services/graphql/generated/consumer-graph-types";
import { useDataDisplayConfig } from "@/shared/data-display-config/composable/data-display-config";
import { DataDisplayConfigId } from "@/shared/data-display-config/composable/data-display.model";
import { SearchField } from "@/shared/search/search.types";
import { computed } from "vue";

export const useSearchConfig = (id: DataDisplayConfigId) => {
    const {
        result: dataDisplayResult,
        entries,
        loading: loadingDocumentationDataDisplayConfig,
        getDataDisplayPropertiesByConfig,
    } = useDataDisplayConfig(id);

    const {
        result: searchConfigResult,
        loading: searchConfigLoading,
        error: searchConfigError,
    } = useSearchConfigQuery({
        searchConfigId: id,
    });

    const loading = computed(() => {
        return searchConfigLoading.value || loadingDocumentationDataDisplayConfig.value;
    });

    const result = computed(() => {
        if (!dataDisplayResult.value || !searchConfigResult.value) return undefined;

        const searchEntries = searchConfigResult.value.searchConfig?.entries?.map((x) => {
            const val: SearchField = { ...x };
            return val;
        });

        return {
            ...dataDisplayResult.value,
            searchEntries,
            dataDisplayEntries: entries,
        };
    });

    const error = computed(() => {
        return searchConfigError;
    });

    return {
        result,
        loading,
        error,
        getDataDisplayPropertiesByConfig,
    };
};
