const state = () => ({
    authenticated: false,
    errorMessage: "",
});

export interface AuthState {
    authenticated: boolean;
    errorMessage: string;
}

const mutations = {
    setLogIn(state: AuthState, gotToken: boolean) {
        state.authenticated = gotToken;
    },
    setErrorMessage(state: AuthState, errorMessage: string) {
        state.errorMessage = errorMessage;
    },
};

const getters = {
    authenticated: (state: AuthState) => {
        return state.authenticated;
    },

    errorMessage: (state: AuthState) => {
        return state.errorMessage;
    },
};

export default {
    namespaced: true,
    mutations,
    getters,
    state,
};
