import { Ability, Route } from "@/shared/environment/ability.types";
import { CommonRouteAliases } from "@/shared/environment/common-route-aliases";
export const STATIC_PAGES_ROUTE_ALIAS = CommonRouteAliases.staticPages;
export const STATIC_PAGE_ALIAS = "static-pages";
export class StaticPagesAbility implements Ability {
    alias = STATIC_PAGE_ALIAS;
    getRoutes = (): Array<Route> => [
        {
            alias: STATIC_PAGES_ROUTE_ALIAS,
            params: [STATIC_PAGES_ROUTE_ALIAS],
            isRoot: true,
            getComponent: () => import("@/abilities/static-pages/pages/static-pages-main.vue"),
        },
    ];
}
