<template>
    <div class="card-container fit small-content-container">
        <div
            class="column card-container-inner-wrapper"
            :class="{ 'bg-white border-radius': whiteBackground, 'container-shadow': container }"
        >
            <div
                class="col-auto card-header flex q-px-md"
                :class="{ 'border-bottom': whiteBackground, '': !whiteBackground }"
                style="align-items: center; justify-content: space-between; min-height: 50px"
            >
                <div class="col">
                    <div class="row items-center">
                        <div class="col-auto">
                            <QitBtn
                                v-if="titleIcon"
                                data-qs="card-top-left-button-left-to-title-button"
                                class="title-icon icon-color bg-transparent"
                                :icon="`${titleIcon}`"
                                @click="$emit('onTitleLeftIconClicked')"
                            />
                        </div>
                        <div class="col card-header-title">
                            {{ title }}
                        </div>
                    </div>
                </div>
                <div class="col-auto" v-if="!slots.close">
                    <QitBtn
                        class="bg-transparent icon-color"
                        data-qs="card-close-button"
                        @click="$emit('onCloseButtonClicked')"
                        icon="fa-regular fa-xmark"
                    />
                </div>
                <div v-else class="col-auto text-right q-mr-sm">
                    <!-- @slot Slot where you can overwrite the default close icon and place something else in it. -->
                    <slot name="close" />
                </div>
            </div>
            <div class="col content-column">
                <div class="column fit">
                    <div
                        class="col content-slot q-pb-lg"
                        ref="contentRef"
                        style="overflow: auto"
                        :class="{ 'q-pa-md': !whiteBackground }"
                    >
                        <!-- @slot Slot for displaying the detail-card content. -->
                        <slot :content-height="contentHeight" />
                    </div>
                    <div v-if="showFooter" class="col-auto border-top q-pa-sm">
                        <!-- @slot Slot for displaying footer, f.e. for footer buttons. -->
                        <slot name="footer" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
/**
 * Component that can display a title, footer slot and is closable.
 * @displayName Card
 * @example `
 *  Can be used to display data-process configuration forms or something else.
 *   <Card :title="Create a new Mechanic Process" @onCloseButtonClicked="handleOnCloseClickedFunction">
 *       [... dynamic form content]
 *       <template #footer>
 *           [... dynamic footer buttons <back> <continue>]
 *       </template>
 *   </Card>
 * `
 */
import { computed, ref, useSlots } from "vue";
import QitBtn from "@/shared/components/buttons/qit-button.vue";

const props = withDefaults(
    defineProps<{
        /**
         * Title in Header.
         */
        title: string;
        /**
         * Optional title icon left to the title string.
         * @example 'qss-icon-world'
         */
        titleIcon?: string;
        /**
         * Makes title icon clickable
         */
        titleIconClickable?: boolean;
        /**
         * Displays the footer.
         */
        showFooter?: boolean;
        /**
         * In white-background mode, the card has a background with border-radius
         * and a border bottom on the header.
         * e.g. used in process configuration, otherwise like used in dashboard f.e.
         */
        whiteBackground?: boolean;
        /**
         * Use car as container
         */
        container?: boolean;
    }>(),
    {
        titleIconClickable: false,
        showFooter: true,
    }
);

const slots = useSlots();

/**
 * @onCloseButtonClicked Emits when the close buttin is clicked.
 * @onTitleLeftIconClicked Emits when the icon next to the title is clicked.
 */
defineEmits(["onCloseButtonClicked", "onTitleLeftIconClicked"]);

const titleIconMouseState = computed(() => {
    return props.titleIconClickable ? "pointer" : "auto";
});

const contentRef = ref<HTMLElement>();

const contentHeight = computed(() => {
    if (contentRef.value) {
        return contentRef.value.clientHeight;
    }
    return 0;
});
</script>

<style lang="scss" scoped>
.card-container {
    .card-container-inner-wrapper {
        overflow: overlay;
        height: 100%;
        display: flex;
        flex-direction: column;

        .screen--sm &,
        .screen--xs & {
            flex-basis: 100%;
            margin-right: 0;
            margin-bottom: $spacing-l;
        }

        & .card-header {
            & h3 {
                margin-left: $spacing-m;
            }

            .card-header-title {
                font-size: 16px;
                font-weight: $semi-bold;
            }
        }

        .card-body-wrapper {
            .card-body {
                height: 100%;
            }
        }
    }
}

.title-icon {
    cursor: v-bind(titleIconMouseState);
    padding-left: 0;
}

.content-column {
    &:deep(.q-scrollarea__content.absolute) {
        overflow: auto !important;
    }

    .content-slot {
        display: flex;
        flex-direction: column;
        gap: $spacing-m;
    }
}
</style>

<style lang="scss">
.card-container {
    .q-scrollarea__container > div {
        height: 100% !important;
    }
}
</style>
