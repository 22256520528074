<template>
    <SortDropdown
        v-if="selectedSortItemId"
        :sort-order-items="sortOrderEntries"
        :selected-id="selectedSortItemId"
        @on-selection-changed="onSelectionChangedHandler"
    />
</template>

<script setup lang="ts">
import SortDropdown from "@/shared/components/sort-dropdown/sort-dropdown.vue";
import { SortEntry, SortOrderEntry } from "@/shared/components/sort-dropdown/sort-dropdown.model";
import { SortSaveKey, useSortSettings } from "@/shared/components/sort-dropdown/composables/sort-settings";
import { ref } from "vue";
import { watchImmediate } from "@vueuse/core";
const isE2E = location.href.includes("playwright");

const selectedSortItemId = ref<string | undefined>();
const selectedSortEntry = defineModel<SortEntry | undefined>("selectedSortEntry", { required: true });
const props = defineProps<{
    sortOrderEntries: SortOrderEntry[];
    initialSelectedSortEntry?: SortEntry;
    sortSaveKey: SortSaveKey;
}>();

const { update, sortOrderEntry } = useSortSettings(
    props.sortSaveKey,
    props.sortOrderEntries,
    props.initialSelectedSortEntry
);

const onSelectionChangedHandler = async (newSelected: SortOrderEntry, initialCall?: boolean) => {
    selectedSortItemId.value = newSelected.id;
    const selectedItem: SortEntry = { field: newSelected.value.field, order: newSelected.value.order };
    selectedSortEntry.value = selectedItem;

    //Don't save for e2e
    if (isE2E || initialCall) {
        //Todo create new tenant for e2e tests
        return;
    }

    await update(selectedItem);
};

watchImmediate(sortOrderEntry, () => {
    if (!sortOrderEntry.value) return;
    onSelectionChangedHandler(sortOrderEntry.value, true);
});
</script>
