import { CommonRouteAliases } from "@/shared/environment/common-route-aliases.ts";
import { useAbilityRoute } from "@/shared/environment/ability-route.ts";
import { RouteElementWithParam } from "@/shared/environment/ability.types.ts";

export const useMechanicArticleRoute = () => {
    const { getPathToChildrenWithoutParents } = useAbilityRoute();

    const getPathToMechanicArticle = (productId: string, assetId: string | undefined | null, mechanicId: string) => {
        const routeElements: RouteElementWithParam[] = [
            {
                alias: CommonRouteAliases.product,
                namedParams: { [CommonRouteAliases.product]: productId },
            },
        ];

        if (assetId)
            routeElements.push({
                alias: CommonRouteAliases.asset,
                namedParams: { [CommonRouteAliases.asset]: assetId as string },
            });

        routeElements.push({
            alias: CommonRouteAliases.searchToMechanicArticle,
            namedParams: {
                [CommonRouteAliases.searchToMechanicArticle]: mechanicId,
            },
        });

        return getPathToChildrenWithoutParents(routeElements);
    };

    return {
        getPathToMechanicArticle,
    };
};
