<template>
    <div class="search-in-btns-container">
        <QitButton
            color="secondary"
            v-for="i in items"
            :key="i.alias"
            :label="trans(i.title)"
            :icon="`fa-regular ${getIcon(i.alias)}`"
            @click="onClick(i.alias)"
            :data-qs="`search-in-${i.alias}-context-btn`"
        />
    </div>
</template>

<script setup lang="ts">
import { useTextObjectTranslation } from "@/shared/i18n/translation-types";
import { SearchTab } from "@/shared/environment/ability.types";
import { CommonSearchTabAliases } from "@/shared/environment/common-route-aliases";
import QitButton from "@/shared/components/buttons/qit-button.vue";

const { trans } = useTextObjectTranslation();

defineProps<{ items: SearchTab[] }>();

const emit = defineEmits<{
    (e: "onContextSelection", searchInCategory: string): void;
}>();

const onClick = (alias: CommonSearchTabAliases) => {
    emit("onContextSelection", alias);
};

const getIcon = (alias: CommonSearchTabAliases) => {
    switch (alias) {
        case CommonSearchTabAliases.activities:
            return "fa-wrench ";
        case CommonSearchTabAliases.products:
            return "fa-box";
        case CommonSearchTabAliases.documentations:
            return "fa-file-lines";
        case CommonSearchTabAliases.assets:
            return "fa-tag";
        case CommonSearchTabAliases.commonArticles:
            return "fa-gear";
    }
};
</script>

<style lang="scss" scoped>
.search-in-btns-container {
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    gap: #{$spacing-m + $spacing-s};
    margin-top: $spacing-l;
    :deep(i) {
        font-size: 16px;
    }
}
</style>
