<template>
    <component
        :is="levelComponent.componentDefinition.getComponent()"
        v-bind="levelComponent.componentDefinition.props"
        :is-last-level="isLastLevel"
        :selection-data="selectionData"
        :emit-last-level-selection="emitLastLevelSelection"
        @select="newSelection"
        @back="goBack"
        @link-clicked="linkClicked"
    />
</template>

<script setup lang="ts">
import { QualificationLevelSelectionResult } from "@/shared/environment/ability.types";
import { LevelComponent } from "./need-qualification-wizard.model";

defineProps<{
    levelComponent: LevelComponent;
    selectionData: QualificationLevelSelectionResult;
    isLastLevel: boolean;
    emitLastLevelSelection?: boolean;
}>();

const emit = defineEmits<{
    (e: "select", selectionResult: QualificationLevelSelectionResult): void;
    (e: "back"): void;
    (e: "linkClicked"): void;
}>();

const newSelection = (newSelection: QualificationLevelSelectionResult) => {
    emit("select", newSelection);
};

const goBack = () => {
    emit("back");
};

const linkClicked = () => {
    emit("linkClicked");
};
</script>
