<template>
    <div class="item-availability-indicator">
        <span
            class="item-availability-indicator-dot"
            :class="{
                available: stockStatus === 0,
                'low-stock': stockStatus === 1,
                'out-of-stock': stockStatus !== -1 && stockStatus !== 0 && stockStatus !== 1,
                unavailable: !isShopOnline || stockStatus === -1,
            }"
        ></span>
        <div v-if="isShopOnline">
            {{
                $t("fdi-shop.{stockDisplayText} in stock", {
                    stockDisplayText: amountAvailable.toLocaleString(getUiLanguage(), {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                    }),
                })
            }}
        </div>
        <div v-else>
            {{ $t("fdi-shop.No status information") }}
        </div>
    </div>
</template>
<script setup lang="ts">
import { getUiLanguage } from "@/shared/services/providers/language-provider";
import { useShopStatusStore } from "../../stores/shop-status";

defineProps<{
    amountAvailable: number;
    stockStatus: number;
}>();

const { isShopOnline } = useShopStatusStore();
</script>
<style lang="scss">
.item-availability-indicator {
    display: flex;
    align-items: baseline;

    .item-availability-indicator-dot {
        height: 10px;
        width: 10px;
        border-radius: 50%;
        display: block;
        margin-right: $spacing-m;
    }

    $dot-size: 10px;
    $dot-colors: (
        available: var(--q-positive),
        low-stock: var(--q-warning),
        out-of-stock: var(--q-negative),
        unavailable: var(--q-negative),
    );

    @each $status, $color in $dot-colors {
        .item-availability-indicator-dot.#{$status} {
            background-color: $color;
        }
    }
}
</style>
