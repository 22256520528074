import {
    AccessControlSettingsQuery,
    AvailableFeaturesQuery,
    useAccessComposableQuery,
    UserQuery,
} from "@/shared/services/graphql/generated/consumer-graph-types";
import { computed, Ref, unref } from "vue";

import AccessControl from "role-acl";

export interface useHasAccessParams {
    resource?: string;
    action?: string;
    featureID?: string;
    externalContext?: Ref<Record<string, any>> | Record<string, any>;
    ignoreConditions?: boolean;
}

export function useHasAccess({ resource, action, featureID, externalContext, ignoreConditions }: useHasAccessParams) {
    if ((action && !resource) || (resource && !action)) {
        throw new Error("Action and resource have to be both defined.");
    }

    if (!action && !resource && !featureID) {
        throw new Error("If no action and resource are defined, then at least featureID must be defined.");
    }

    const { result, loading, error } = useAccessComposableQuery({ fetchPolicy: "cache-first" });

    const hasAccess = computed(() => {
        if (error.value || loading.value) return false;

        if (featureID) {
            const hasFeature = hasFeatureAccess(featureID, result.value);
            if (!hasFeature) return false;

            if (!action && !resource) return hasFeature;
        }

        return hasActionAndResourceAccess(
            result.value,
            result.value,
            action,
            resource,
            externalContext,
            ignoreConditions
        );
    });

    return { hasAccess, error, loading };
}

function getRelevantRoles(rolesConfig: any, userRoles: string[] | undefined) {
    if (!userRoles || !rolesConfig) {
        return [];
    }
    const configRoles = Object.keys(rolesConfig);
    return configRoles.filter((role) => userRoles.includes(role));
}

export function hasFeatureAccess(featureID: string, availableFeaturesQueryResult?: AvailableFeaturesQuery) {
    const features =
        availableFeaturesQueryResult?.availableFeatures?.map((x) => {
            return x?.id;
        }) || [];

    //Features starting with minus, will remove features
    const negativeFeatures = features
        .filter((x) => {
            return x?.startsWith("-");
        })
        .map((x) => x?.slice(1));

    const filteredFeatures = features.filter((x) => {
        return !negativeFeatures.includes(x);
    });

    return filteredFeatures.includes(featureID);
}

export function hasActionAndResourceAccess(
    userData?: UserQuery,
    accessControlSettingsQueryResult?: AccessControlSettingsQuery,
    action?: string,
    resource?: string,
    externalContext?: Ref<Record<string, any>> | Record<string, any>,
    ignoreConditions?: boolean | undefined
): boolean {
    if (!accessControlSettingsQueryResult?.accessControlSettings?.rolesConfig || !userData?.me) {
        return false;
    }

    const effectiveRoles = userData.me.effectiveRoles?.length ? userData.me.effectiveRoles : [];
    const relevantRoles = getRelevantRoles(
        accessControlSettingsQueryResult.accessControlSettings?.rolesConfig,
        effectiveRoles as string[]
    );

    // @ts-ignore
    const control = new AccessControl(accessControlSettingsQueryResult.accessControlSettings?.rolesConfig);

    const externalContextValue = unref(externalContext);
    if (externalContextValue) {
        const enrichedContext = {
            user: userData.me,
            context: { ...externalContextValue, userId: externalContextValue.user?.id },
        };

        const permission = control.can(relevantRoles).context(enrichedContext).execute(action).sync().on(resource);
        return permission.granted;
    } else {
        const permission = control.can(relevantRoles).execute(action).sync().on(resource, !!ignoreConditions);
        return permission.granted;
    }
}
