<template>
    <router-link v-if="isRealRoute && to" :to="to" class="router-link">
        <slot name="content"></slot>
    </router-link>
    <div v-else @click="clickHandler" class="router-link">
        <slot name="content"></slot>
    </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { RouteLocationRaw } from "vue-router";

type Callback = () => void;

const props = withDefaults(
    defineProps<{
        disabled?: boolean;
        to?: RouteLocationRaw;
        clickHandler?: Callback;
    }>(),
    {
        disabled: false,
    }
);

const isRealRoute = computed(() => {
    return !props.disabled && props.to;
});

const clickHandler = computed(() => {
    if (!props.disabled && props.clickHandler) {
        return props.clickHandler;
    } else {
        return undefined;
    }
});
</script>
