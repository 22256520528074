<template>
    <AdvancedRouterLink :to="linkTo" :click-handler="clickHandler">
        <template #content>
            <ListCard
                class="asset-list-card"
                :test-id="`asset-list-card-${asset.assetId}`"
                :title="asset.assetId ?? ''"
                :image-separator="!!asset.getPreviewImage()"
                :image-src="asset.getPreviewImage()"
                fallback-icon="fa-tag"
            >
                <template #content>
                    <div class="row properties">
                        <PropertyView :items="propertyItems" :multiline="true" />
                    </div>
                </template>
            </ListCard>
        </template>
    </AdvancedRouterLink>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import PropertyView from "@/shared/components/views/property-view.vue";
import { AdvAsset } from "../../../data-objects/advanced-asset";
import { CommonRouteAliases } from "@/shared/environment/common-route-aliases";
import { useDataDisplayConfig } from "@/shared/data-display-config/composable/data-display-config";
import AdvancedRouterLink from "@/shared/components/router-link/advanced-router-link.vue";
import { DataDisplayConfigId } from "@/shared/data-display-config/composable/data-display.model";
import { computedText, useTextObjectTranslation } from "@/shared/i18n/translation-types";
import { useAbilityRoute } from "@/shared/environment/ability-route";
import { useProductNames } from "@/abilities/product-and-asset/composables/product-names";
import ListCard from "@/shared/components/card/list-card.vue";
import { PropertyItem } from "@/shared/components/views/property-item.model";

const props = withDefaults(
    defineProps<{
        asset: AdvAsset;
        showJumpToProduct?: boolean;
        emitSelection?: boolean;
    }>(),
    {
        showJumpToProduct: true,
    }
);

const emit = defineEmits<{
    (e: "select", productId: string, assetId: string): void;
}>();

const { getPathToChildrenWithoutParents, getPathToChildWithoutParents } = useAbilityRoute();

const linkTo = computed(() => {
    if (props.asset.productId && props.asset.assetId) {
        if (!props.emitSelection) {
            return getPathToAsset(props.asset.productId, props.asset.assetId);
        }
    }
    return undefined;
});

const clickHandler = computed(() => {
    if (props.emitSelection) {
        return () => {
            if (props.asset.productId && props.asset.assetId) {
                emit("select", props.asset.productId, props.asset.assetId);
            }
        };
    }
    return undefined;
});

/**
 * Asset Pfad erzeugen
 * @param productId Product ID
 * @param assetId Asset ID
 */
function getPathToAsset(productId: string, assetId: string) {
    return getPathToChildrenWithoutParents([
        {
            alias: CommonRouteAliases.product,
            namedParams: { [CommonRouteAliases.product]: productId },
        },
        {
            alias: CommonRouteAliases.asset,
            namedParams: { [CommonRouteAliases.asset]: assetId },
        },
    ]);
}

const { getDataDisplayPropertiesByConfig } = useDataDisplayConfig(DataDisplayConfigId.assetSelection);

const productName = useProductNames("singular");
const { trans } = useTextObjectTranslation();

const productId = ref(props.asset.productId ?? "");
const productText = props.asset.localizations?.title ?? props.asset.teasers?.title ?? "";

const productPropertyItem = computed<PropertyItem>(() => {
    return {
        title: trans(computedText(productName)),
        key: "productid",
        value: productText ? productText : "",
        ...(props.showJumpToProduct && {
            getRoute: productId.value
                ? () => {
                      return getPathToChildWithoutParents(CommonRouteAliases.product, {
                          [CommonRouteAliases.product]: productId.value,
                      });
                  }
                : undefined,
        }),
    };
});

const propertyItems = computed(() => {
    return [productPropertyItem.value].concat(getDataDisplayPropertiesByConfig(props.asset));
});
</script>

<style lang="scss" scoped>
.asset-list-card {
    cursor: pointer;
    .default-preview-image-container {
        height: 107px;
    }
    .properties {
        column-gap: $spacing-xl;
    }
}
</style>
