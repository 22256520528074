export enum CommonRouteAliases {
    dashboard = "dashboard",
    asset = "asset",
    collections = "collections",
    activities = "activities",
    activityOverview = "activity-overview",
    activityDetail = "activity",
    product = "product",
    mechanic = "mechanic",
    mechanicArticle = "mechanicArticle",
    commonArticle = "article",
    documentations = "docs",
    documentationDetail = "doc",
    search = "search",
    staticPages = "staticPages",
    productSelection = "product-selection",
    searchToMechanicArticle = "searchToMechanicArticle",
    go = "go",
    aiAssistant = "ai-assistant",
}
export enum CommonTabAliases {
    search = "tab",
}

export enum CommonSearchTabAliases {
    assets = "assets",
    commonArticles = "articles",
    documentations = "documentations",
    products = "products",
    activities = "activities",
}
