<template>
    <Fragment v-if="hasAccess">
        <slot />
    </Fragment>
</template>
<script setup lang="ts">
import { toRefs } from "vue";
import { useHasAccess } from "../composables/use-has-access";
import Fragment from "@/shared/components/fragment/fragment.vue";

const props = defineProps({
    resource: String,
    action: String,
    featureID: String,
    externalContext: Object,
    ignoreConditions: Boolean,
});

const { externalContext } = toRefs(props);
const { hasAccess } = useHasAccess({ ...props, externalContext });
</script>
