<template>
    <div class="file-identification-wrapper">
        <div class="file-identification">
            <i class="fa-regular fa-file icon-light-color" />
            <span>
                <div class="file-identification-name">{{ fileName }}</div>
                <div class="file-identification-size">{{ fileSize }}</div>
            </span>
        </div>
        <div class="delimiter-adjustment-button" @click="toggleDelimiterInput" v-if="!delimiterInputShow">
            {{ $t("core.Customize delimiter") }} <i class="fa-regular fa-chevron-down" />
        </div>
        <div class="form" v-if="delimiterInputShow">
            <div class="form-label row">
                {{ $t("core.Delimiter") }}
            </div>
            <div class="form-input row">
                <q-input
                    outlined
                    dense
                    v-model="delimiterModel"
                    @update:model-value="emitInputChanged"
                    maxlength="2"
                    bg-color="white"
                    class="flex-grow"
                />
            </div>
            <div class="form-label row">
                {{ $t("core.Escape character") }}
            </div>
            <div class="form-input row flex-grow">
                <q-input
                    outlined
                    dense
                    v-model="escapeModel"
                    @update:model-value="emitInputChanged"
                    maxlength="2"
                    bg-color="white"
                    class="flex-grow"
                />
            </div>
            <div class="form-label row">
                {{ $t("core.Encoding") }}
            </div>
            <div class="form-input row">
                <q-select
                    outlined
                    dense
                    :options="encodings"
                    v-model="encodingModel"
                    @update:model-value="emitInputChanged"
                    bg-color="white"
                    class="flex-grow"
                />
            </div>
        </div>
        <div class="delimiter-adjustment-button" @click="toggleDelimiterInput" v-if="delimiterInputShow">
            {{ $t("core.Hide options") }} <i class="fa-regular fa-chevron-up" />
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed, ref, watch, watchEffect } from "vue";
import { bytesToSize } from "./file-uploader.helper";
import { useProcessEncodingTypesQuery } from "@/shared/services/graphql/generated/admin-graph-types";
import { ProcessEncodingType } from "@/shared/services/graphql/generated/consumer-graph-types";

const props = defineProps<{
    fileName: string;
    bytes: number;
    delimiter: string;
    escape: string;
    encoding: ProcessEncodingType;
}>();

const emit = defineEmits<{
    (e: "inputChanged", data: { delimiter: string; escape: string; encoding: ProcessEncodingType }): void;
}>();

const delimiterModel = ref(props.delimiter);
const escapeModel = ref(props.escape);
const encodingModel = ref();

const { result } = useProcessEncodingTypesQuery({
    clientId: "admin",
});

const encodings = computed(() => {
    return (
        result.value?.processEncodingTypes?.map((resultVal) => {
            return resultVal as string;
        }) ?? []
    );
});

watchEffect(() => {
    if (!encodingModel.value && encodings.value.length > 0) encodingModel.value = encodings.value[0];
});

transformEmptyStringTabToBackSlashTab();

const fileSize = computed(() => {
    return bytesToSize(props.bytes);
});

const delimiterInputShow = ref(false);

function toggleDelimiterInput() {
    delimiterInputShow.value = !delimiterInputShow.value;
}

function transformEmptyStringTabToBackSlashTab(): void {
    if (delimiterModel.value === "	") {
        delimiterModel.value = "\\t";
    }
    if (escapeModel.value === "	") {
        escapeModel.value = "\\t";
    }
}

function emitInputChanged(): void {
    emit("inputChanged", {
        delimiter: transformBackSlashTabToEmptyStringTab(delimiterModel.value),
        escape: transformBackSlashTabToEmptyStringTab(escapeModel.value),
        encoding: encodingModel.value,
    });
}

function transformBackSlashTabToEmptyStringTab(value: string): string {
    if (value === "\\t") {
        return "	";
    } else {
        return value;
    }
}

watch(
    () => props.delimiter,
    () => {
        delimiterModel.value = props.delimiter;
    }
);

watch(
    () => props.escape,
    () => {
        escapeModel.value = props.escape;
    }
);

watch(
    () => props.encoding,
    () => {
        encodingModel.value = props.encoding;
    },
    { immediate: true }
);

watch([delimiterModel, escapeModel], () => {
    transformEmptyStringTabToBackSlashTab();
});
</script>

<style lang="scss" scoped>
.file-identification-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    background-color: var(--q-highlight-hover);
    border: 1px solid $default-border-color;
    margin: $spacing-s;
    border-radius: $default-border-radius;

    & .file-identification {
        margin: auto;
        padding: #{$spacing-m + $spacing-s};
        display: flex;
        min-width: 0;

        & i {
            flex-shrink: 0;
        }

        & span {
            margin: auto;
            margin-left: #{$spacing-m + $spacing-s};
            overflow: hidden;

            & .file-identification-name {
                font-weight: $semi-bold;
                text-overflow: ellipsis;
                overflow: hidden;
                /** add word break - so long filenames would be broken into parts, to fit element width
                    use vendor specific definitions - see description here: https://css-tricks.com/almanac/properties/w/word-break/
                */
                -ms-word-break: break-all;
                word-break: break-all;

                /* Non standard for WebKit */
                word-break: break-word;
                /** also add automatically hyphens to splitted words, where possible */
                -webkit-hyphens: auto;
                -moz-hyphens: auto;
                hyphens: auto;
            }

            & .file-identification-size {
                font-size: $smaller-font-size;
                color: $light-text-color;
            }
        }
    }

    .delimiter-adjustment-button {
        margin-bottom: $spacing-m;
        margin-left: $spacing-m;
        margin-right: $spacing-m;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: var(--q-primary);
        font-weight: $semi-bold;

        &:hover {
            cursor: pointer;
        }
    }

    .form {
        margin: $spacing-m;
        padding-left: $spacing-m;
        padding-right: $spacing-m;
        .form-label {
            font-weight: $semi-bold;
        }
        .form-input {
            margin-bottom: $spacing-m;
        }
    }
}
</style>
