import { computed, ComputedRef, onMounted, Ref, watch } from "vue";
import { useLazyQuery } from "@vue/apollo-composable";
import { useDataDisplayConfig } from "@/shared/data-display-config/composable/data-display-config";
import {
    InputMaybe,
    LanguageWithWildcard,
    ProductFilter,
} from "@/shared/services/graphql/generated/consumer-graph-types";
import { createProductsQuery } from "../../graphql/products.query";
import { AdvProduct, advProduct } from "../../data-objects/advanced-product";
import { DataDisplayConfigId } from "@/shared/data-display-config/composable/data-display.model";

interface ProductsQueryParams {
    productIds: string[];
    acceptedLanguages: LanguageWithWildcard[];
}

export function useProductsResultWithDisplayConfig(params: Ref<ProductsQueryParams>) {
    const filter = computed<{ filter: InputMaybe<ProductFilter> } | undefined>(() => {
        return {
            filter: {
                ids: params.value.productIds,
            },
        };
    });

    const {
        result: dataDisplayResult,
        loading: dataConfigLoading,
        error: dataError,
    } = useDataDisplayConfig(DataDisplayConfigId.product);

    const loadProducts = () => {
        if (!dataDisplayResult.value) return;
        load(
            createProductsQuery(
                dataDisplayResult.value.datafieldsFragment.datafields,
                dataDisplayResult.value.datafieldsFragment.localizationDatafields
            )
        );
    };

    onMounted(() => {
        loadProducts();
    });

    watch(dataDisplayResult, () => {
        loadProducts();
    });

    const {
        error: productError,
        result,
        loading: productLoading,
        load,
    } = useLazyQuery(
        createProductsQuery({}, {}),
        computed(() => {
            return {
                ...filter.value,
                acceptedLanguages: params.value.acceptedLanguages,
            };
        })
    );

    const loading = computed(() => {
        return productLoading.value || dataConfigLoading.value;
    });

    const error = computed(() => {
        return dataError.value || productError.value;
    });

    const products: ComputedRef<AdvProduct[]> = computed(() => {
        if (result.value?.products?.products) {
            return result.value.products.products.map((product: any) => {
                return advProduct(product.node as AdvProduct);
            });
        }
        return [];
    });

    return {
        error,
        loading,
        products,
    };
}
