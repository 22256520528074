import { gql } from "graphql-tag";

export const LOGO_QUERY = gql`
    query logo {
        theme {
            logo {
                url
            }
        }
    }
`;
