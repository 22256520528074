/*
    Custom-Directive welches eine Callback-Funktion entgegennimmt.
    Das Directive fügt dem Window einen Eventlistener hinzu, sobald die Komponente gemountet wird. 
    Im beforeUnmount wird der Eventlistener wieder entfernt.
    Die übergebene Callback-Funktion wird aufgerufen, sobald ein window-Resize-Event getriggert wird.

    Kann im Template als folgendermaßen eingesetzt werden:
    <div v-resize:"callBackFunction">Text...</div>

    Mit Modifiern und Argumenten könnte man z.B. auch einen timeout einbauen: v-resize:timeout.3000
*/

export const windowResizeDirective = {
    created() {},
    beforeMount() {},
    mounted(_el: any, binding: any) {
        const onResizeCallback = binding.value;
        window.addEventListener("resize", () => onResizeCallback());
    },
    beforeUpdate() {},
    updated() {},
    beforeUnmount(_el: any, binding: any) {
        const onResizeCallback = binding.value;
        window.removeEventListener("resize", () => onResizeCallback());
    },
    unmounted() {},
};
