import { ContentQueryResult } from "@/shared/services/graphql/generated/consumer-graph-types";
import { ContentMapResult, TopicResult } from "@/shared/documentation/document-list-card.types.ts";
import { PropertyItem } from "@/shared/components/views/property-item.model.ts";

export const mapContentQueryResultToContentResult = (
    content: ContentQueryResult,
    getDataDisplayPropertiesByConfig: (graphqlResultObject: any) => PropertyItem[]
) => {
    const contents = content ? content.contents : [];
    return contents
        .map((content) => {
            if (!content || !content.node) return;
            const typename = (content.node as any).__typename;
            let topicId, contentMapId, rootContentMapTitle;

            if (typename === "Topic") {
                const topicParts = content.node.id.split("@");
                topicId = topicParts[0];
                contentMapId = topicParts[1];
                rootContentMapTitle = content.node.useContext?.rootContentMap?.teasers?.title ?? "";
            } else {
                contentMapId = content.node.contentId;
            }

            return {
                id: content.node.id,
                topicId,
                contentMapId,
                rootContentMapTitle,
                title: content.node.teasers?.title ?? "",
                language: content.node.locale?.substring(0, 2) ?? "",
                propertyItems: getDataDisplayPropertiesByConfig(content.node),
                mimeType: (content.node as any).mimeType,
                cursor: content.cursor,
                highlightTitle: content.highlights?.title,
                highlightText: content.highlights?.text,
                __typename: typename,
            };
        })
        .filter((x) => x !== undefined) as (TopicResult | ContentMapResult)[];
};
