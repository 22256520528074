import { BreadcrumbItem } from "@/shared/components/breadcrumbs/breadcrumbs.model";
import { RouteElementWithParam } from "@/shared/environment/ability.types";
import {
    createRouteFromRouteElements,
    decodeParamArrayFromString,
    encodeParamArrayToString,
} from "@/shared/environment/ability-route-helper";

export function createArrayBreadCrumbs(
    parentElements: RouteElementWithParam[],
    currentElement: RouteElementWithParam
): Array<BreadcrumbItem> {
    const result: Array<BreadcrumbItem> = [];

    const paramValue = currentElement.namedParams[currentElement.alias];
    if (paramValue) {
        const params = decodeParamArrayFromString(paramValue);
        const localParams: Array<string> = [];
        params.forEach((param) => {
            localParams.push(param);
            const newRouteElement: RouteElementWithParam = {
                alias: currentElement.alias,
                namedParams: { [currentElement.alias]: encodeParamArrayToString(localParams) },
            };

            result.push({
                content: {
                    simpleText: param ?? "-",
                },
                getRouterTo: () => createRouteFromRouteElements(parentElements, newRouteElement),
            });
        });
    }
    return result;
}
