<template>
    <template v-if="breadcrumbItems">
        <Breadcrumbs :items="breadcrumbItems" :loading="loading" />
    </template>
</template>

<script setup lang="ts">
import Breadcrumbs from "@/shared/components/breadcrumbs/breadcrumbs.vue";
import { isArray } from "lodash";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { BreadcrumbItem } from "./breadcrumbs.model";
import { Ability } from "@/shared/environment/ability.types";
import { useAbilityRoute } from "@/shared/environment/ability-route";

const props = defineProps<{
    abilities: Array<Ability>;
}>();

const route = useRoute();
const { t } = useI18n({ useScope: "global" });
const { getBreadCrumbsFromRoute } = useAbilityRoute();

const breadCrumbsFromRoute = getBreadCrumbsFromRoute(route, props.abilities, t);

function loadBreadCrumbs() {
    const result: BreadcrumbItem[] = [];

    breadCrumbsFromRoute?.forEach((item) => {
        if (isArray(item)) {
            item.forEach((element) => {
                result.push(element);
            });
        } else {
            item.items.value.forEach((element) => {
                result.push(element);
            });
        }
    });
    // Auf dem letzten sind wir, das brauchen wir nicht
    result.pop();
    return result;
}

const breadcrumbItems = computed<BreadcrumbItem[]>(() => {
    return loadBreadCrumbs();
});

const loading = computed<boolean>(() => {
    if (breadCrumbsFromRoute) {
        let result = false;
        breadCrumbsFromRoute.forEach((element) => {
            if (!isArray(element)) {
                if (element.loading.value) {
                    result = true;
                }
            }
        });
        return result;
    } else {
        return true;
    }
});
</script>
