<template>
    <ExpansionPanel :label="$t('mechanic.Identify Installation Location')" icon="fa-regular fa-gear icon-light-color">
        <q-card class="container-shadow q-pa-md">
            <div class="fit row q-col-gutter-lg">
                <template v-for="item in locationItems" :key="item.title">
                    <InstallationLocationBoxItem
                        class="installation-location-item col-md-6 col-xs-12"
                        :location-item="item"
                    />
                </template>
            </div>
        </q-card>
        <q-resize-observer @resize="onResize"></q-resize-observer>
    </ExpansionPanel>
    <Dialog
        v-model="overlayVisible"
        position="left"
        :title="$t('mechanic.Select installation location')"
        icon="fa-regular fa-code-branch warning-color"
        :width="500"
    >
        <NeedQualificationWizard
            :level-definition="qualificationLevels"
            :selection-data="qualificationSearchData"
            @hide="hideOverlay"
            class="installation-location-selection"
        />
    </Dialog>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import ExpansionPanel from "@/shared/components/expansion/expansion-panel.vue";
import { MechanicArticleContext } from "@/shared/environment/widgets/widget-context";
import { CURRENT_LOCATION, FURTHER_LOCATIONS, getLocationItems } from "./installation-location.model";
import InstallationLocationBoxItem from "@/abilities/mechanic/pages/components/installation-location-item.vue";
import { RouteElementPathWithParams, RouteElementWithParam } from "@/shared/environment/ability.types";
import { CommonRouteAliases } from "@/shared/environment/common-route-aliases";
import NeedQualificationWizard from "@/shared/need-qualification/components/need-qualification-wizard.vue";
import { SelectionLevelDefinition } from "@/shared/need-qualification/components/need-qualification-wizard.model";
import { useI18n } from "vue-i18n";
import { useMechanicLocation } from "../../helpers/mechanic-location-helper";
import { getDataQueryLanguages } from "@/shared/services/providers/language-provider";
import Dialog from "@/shared/components/dialog/title-content-footer-dialog.vue";
import { useAbilityRoute } from "@/shared/environment/ability-route";

const props = defineProps<{
    widgetContext: MechanicArticleContext;
}>();

const { createRouteFromRouteElements } = useAbilityRoute();

const overlayVisible = ref(false);

const hideOverlay = () => {
    overlayVisible.value = false;
};

const showOverlay = () => {
    overlayVisible.value = true;
};

function currentLocationButtonAction() {
    const parentRoute: RouteElementPathWithParams = [];
    // Aus dem Kontext Product und Asset in die Route nehmen

    parentRoute.push({
        alias: CommonRouteAliases.product,
        namedParams: {
            product: props.widgetContext.product,
        },
    });

    if (props.widgetContext.asset) {
        parentRoute.push({
            alias: CommonRouteAliases.asset,
            namedParams: {
                asset: props.widgetContext.asset,
            },
        });
    }

    // Jetzt noch den eigentlichen Pfad auf den Einbauort der Mechanic
    let currentRouteElement: RouteElementWithParam | undefined;
    currentRouteElement = {
        alias: CommonRouteAliases.mechanic,
        namedParams: {
            mechanic: props.widgetContext.mechanic,
        },
    };

    return createRouteFromRouteElements(parentRoute, currentRouteElement, {
        selectedMechanicID: props.widgetContext.mechanicArticle,
    });
}

function furtherLocationClickEvent() {
    showOverlay();
}

const { paths } = useMechanicLocation(
    computed(() => {
        return {
            first: 10,
            acceptedLanguages: getDataQueryLanguages(),
            articleId: props.widgetContext.mechanicArticleId,
            product: props.widgetContext.product,
            asset: props.widgetContext.asset,
        };
    })
);

const numberOfLocations = computed(() => {
    return paths.value.length;
});

const locationItems = computed(() => {
    return getLocationItems().map((item) => {
        item.productId = props.widgetContext.product;
        item.assetId = props.widgetContext.asset;

        if (item.locationKey === CURRENT_LOCATION) {
            item.getButtonRoute = currentLocationButtonAction;
        } else if (item.locationKey === FURTHER_LOCATIONS) {
            item.buttonClickEvent = furtherLocationClickEvent;
            item.isActive = numberOfLocations.value > 1;
        }
        return { ...item };
    });
});

const isLarge = ref(true);
const onResize = (value: { width: number; height: number }) => {
    isLarge.value = value.width > 550;
};

const { t } = useI18n({ useScope: "global" });

const qualificationLevels = computed((): Array<SelectionLevelDefinition> => {
    return [
        {
            aliases: [CommonRouteAliases.mechanic],
            levelText: computed(() =>
                t("mechanic.Select installation location for {articleTitle}", {
                    articleTitle: props.widgetContext.mechanicArticleTitle,
                })
            ),
        },
    ];
});

const qualificationSearchData = computed<Record<string, string>>(() => {
    const result: Record<string, string> = {};
    result[CommonRouteAliases.product] = props.widgetContext.product;
    if (props.widgetContext.asset) {
        result[CommonRouteAliases.asset] = props.widgetContext.asset;
    }
    result[CommonRouteAliases.commonArticle] = props.widgetContext.mechanicArticleId ?? "";
    return result;
});
</script>
