<!-- 
    Einfacher span, der den Text einer Assembly enthält.
    Wird für das Breadcrumb benötigt und zeigt ein Skeleton an, wenn es noch nicht geladen wurde.
    Als Parameter muss das Produkt, optional das Asset und der Mechanikpfad codiert mit 
    encodeParamArrayToString vorhanden sein.
-->
<template>
    <div v-if="isRootAssembly" class="breadcrumb-mechanic-main underlined-on-hover">
        <i class="fa-regular fa-gear" />
        <span>
            {{ $t("mechanic.Mechanics") }}
        </span>
    </div>
    <div v-else class="underlined-on-hover">
        {{ title }}
    </div>
</template>

<script setup lang="ts">
import { computed, nextTick, onMounted, watch } from "vue";
import { useAssemblyTitleResult } from "../../../helpers/assembly-title-result";
import { getDataQueryLanguages } from "@/shared/services/providers/language-provider";
import { decodeParamArrayFromString } from "@/shared/environment/ability-route-helper";

const props = defineProps<{
    product: string;
    asset?: string;
    mechanic: string;
}>();

const emit = defineEmits<{
    (e: "breadcrumbLoaded"): void;
}>();

const currentAssemblyId = computed(() => {
    const assetIds = decodeParamArrayFromString(props.mechanic);
    if (assetIds.length > 0) {
        return assetIds[assetIds.length - 1];
    } else {
        return "";
    }
});

const { loading, title } = useAssemblyTitleResult(
    currentAssemblyId,
    computed(() => props.product),
    computed(() => props.asset),
    computed(() => getDataQueryLanguages())
);

const isRootAssembly = computed<boolean>(() => {
    return !!((currentAssemblyId.value ?? "") === "");
});

async function emitBreadcrumbLoaded() {
    if (!loading.value) {
        await nextTick();
        emit("breadcrumbLoaded");
    }
}

watch(loading, async () => {
    emitBreadcrumbLoaded();
});

onMounted(async () => {
    // Im Falle, dass das Result schon gecached ist
    emitBreadcrumbLoaded();
});
</script>

<style lang="scss" scoped>
.breadcrumb-mechanic-main {
    font-weight: $semi-bold;
    color: var(--q-link);

    i {
        font-size: 1rem;
        margin-right: $spacing-s;
        margin-bottom: $spacing-s;
        margin-top: $spacing-s;
    }
}

.underlined-on-hover {
    &:hover {
        text-decoration: underline;
        cursor: pointer;
    }
}
</style>
