import { Ability, Route } from "@/shared/environment/ability.types";
import { CommonRouteAliases } from "@/shared/environment/common-route-aliases";
export const SEARCH_ALIAS = CommonRouteAliases.search;

export class SearchAbility implements Ability {
    alias = SEARCH_ALIAS;
    getRoutes = (): Array<Route> => [
        {
            alias: SEARCH_ALIAS,
            params: [SEARCH_ALIAS],
            isRoot: true,
            getComponent: () => import("@/abilities/search/pages/search-main.vue"),
        },
    ];
}
