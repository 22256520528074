import {
    DataDisplayConfigQuery,
    DatafieldType,
    FieldDefinitionTypes,
} from "@/shared/services/graphql/generated/consumer-graph-types";
import { getUiLanguage } from "@/shared/services/providers/language-provider";
import { PropertyItem } from "@/shared/components/views/property-item.model";
import { getDateString } from "@/shared/time/convert";
import { computed } from "vue";
import { DataDisplayField, DatafieldFragments } from "./data-display.model";

export const getDatafieldsFragmentsFromConfig = (config: DataDisplayConfigQuery): DatafieldFragments => {
    const datafields: Record<string, string[]> = {};
    const localizationDatafields: Record<string, string[]> = {};

    const addFragmentToDatafields = (
        datafieldType: Record<string, string[]>,
        objectTypes: string[] | null,
        fragment: string
    ) => {
        if (objectTypes === null) {
            if (datafieldType["all"]) {
                datafieldType["all"].push(fragment);
            } else {
                datafieldType["all"] = [fragment];
            }
        } else {
            for (const objectType of objectTypes) {
                if (datafieldType[objectType]) {
                    datafieldType[objectType].push(fragment);
                } else {
                    datafieldType[objectType] = [fragment];
                }
            }
        }
    };

    config.dataDisplayConfig?.entries?.forEach((entry) => {
        if (entry.type === DatafieldType.metadata) {
            const objectTypes =
                entry.__typename === "DataDisplayConfigDatafieldEntry"
                    ? (entry?.datafieldDefinition?.objectTypes as string[]) || null
                    : null;

            const fragment =
                entry.fieldType === FieldDefinitionTypes.text
                    ? `${entry.referencedId}(acceptedLanguages: $acceptedLanguages)`
                    : entry.referencedId;

            if (entry.fieldType === FieldDefinitionTypes.text) {
                addFragmentToDatafields(localizationDatafields, objectTypes, fragment);
            } else {
                addFragmentToDatafields(datafields, objectTypes, fragment);
            }
        }
    });

    return {
        datafields,
        localizationDatafields,
    };
};

export const getDataDisplayFields = (config: DataDisplayConfigQuery | undefined): Array<DataDisplayField> => {
    if (!config?.dataDisplayConfig?.entries?.length) return [];

    return config.dataDisplayConfig.entries.map((entry) => {
        const displayValues: Record<string, string> = {};

        if (
            entry.datafieldDefinition?.__typename === "TaxonomyDatafieldDefinition" ||
            entry.datafieldDefinition?.__typename === "EnumDatafieldDefinition"
        ) {
            if (entry.datafieldDefinition.values && entry.datafieldDefinition.values.length > 0) {
                for (const x of entry.datafieldDefinition.values) {
                    const referencedId = x.key;
                    displayValues[referencedId] = x.teasers?.title ?? referencedId;
                }
            }
        }

        const isPriority = entry.displayOptions?.showMobile === true;
        const property: DataDisplayField = {
            key: entry.referencedId,
            title: entry.teasers?.title ?? entry.referencedId,
            fieldTypeInfo: {
                type: entry.type,
                fieldType: entry.fieldType,
            },
            displayValues,
            isPriority,
        };

        return property;
    });
};

export const getPriorityDataDisplayFields = (config: DataDisplayConfigQuery | undefined) => {
    return getDataDisplayFields(config).sort((x, y) => {
        return x.isPriority === y.isPriority ? 0 : x.isPriority ? -1 : 1;
    });
};

export const getNodeDataFragments = (config: DataDisplayConfigQuery) => {
    //fields, which are only valid for topics
    //fields, which are only valid for contentMaps
    //workaround for field title
    const results: Record<string, string> = {};
    let topicFields = "";
    let contentMapFields = "";
    const entries = config.dataDisplayConfig?.entries;

    const dataDisplayConfigFields =
        entries
            ?.filter((val) => val.type === DatafieldType.field)
            ?.filter((x) => {
                return x.referencedId !== "title";
            }) || [];

    const dataDisplayConfigTopicFields = dataDisplayConfigFields?.filter((x) => {
        if (x.__typename === "DataDisplayConfigFieldEntry") return x?.nodeTypes?.includes("topic");
    });

    const dataDisplayConfigContentMapFields = dataDisplayConfigFields?.filter((x) => {
        if (x.__typename === "DataDisplayConfigFieldEntry") return x.nodeTypes?.includes("contentMap");
    });

    if (dataDisplayConfigFields?.length) {
        for (const field of dataDisplayConfigTopicFields) {
            topicFields += `${field.referencedId}\n`;
        }

        for (const field of dataDisplayConfigContentMapFields) {
            contentMapFields += `${field.referencedId}\n`;
        }
    }

    results.topic = topicFields;
    results.contentMap = contentMapFields;

    return results;
};

export const getDataDisplayValue = (
    field: DataDisplayField,
    graphqlResultObject: any
): { formattedValue: string; exists: boolean } => {
    const locale = computed(() => {
        return getUiLanguage();
    });

    if (graphqlResultObject) {
        let displayValues = undefined;

        //Taxonomy or Enum fields
        if (
            Object.keys(field.displayValues).length > 0 &&
            (field.fieldTypeInfo.fieldType === FieldDefinitionTypes.taxonomy ||
                field.fieldTypeInfo.fieldType === FieldDefinitionTypes.enum)
        ) {
            let values = (graphqlResultObject as any)[field.key];
            if (values) {
                //system fields are string not string[]
                if (!Array.isArray(values)) {
                    values = [values];
                }
                displayValues = [];
                for (const value of values) {
                    const displayValue = field.displayValues[value];
                    displayValues.push(displayValue || value);
                }
            }
        }
        //Multilingualfield teaser
        else if (field.fieldTypeInfo.fieldType === FieldDefinitionTypes.text && graphqlResultObject.teasers) {
            displayValues = (graphqlResultObject.teasers as any)[field.key];
        }
        //Multilingualfield localization
        else if (field.fieldTypeInfo.fieldType === FieldDefinitionTypes.text && graphqlResultObject.localizations) {
            displayValues = (graphqlResultObject.localizations as any)[field.key];
        }
        // Datetimefield
        else if (
            field.fieldTypeInfo.fieldType === FieldDefinitionTypes.dateTime &&
            (graphqlResultObject as any)[field.key]
        ) {
            if (Array.isArray((graphqlResultObject as any)[field.key])) {
                displayValues = (graphqlResultObject as any)[field.key].map((val: string) => {
                    return getDateString(val, locale.value);
                });
            } else {
                displayValues = getDateString((graphqlResultObject as any)[field.key], locale.value);
            }
        }
        //other fields (strings or numbers)
        else {
            displayValues = (graphqlResultObject as any)[field.key];
        }

        let exists = false;
        let value = "";
        if (displayValues) {
            if (Array.isArray(displayValues)) {
                exists = displayValues.length > 0;
                value = displayValues.join(", ");
            } else {
                exists = true;
                value = displayValues.toString();
            }
        }
        //TODO: Bis in QIT-1139 geklärt ist, ob '' ein zulässiger Wert ist und ob im Import leer Strings als undefined abgespeichert werden hier alles leere als nicht existierend setzen
        if (value == "") {
            exists = false;
        }

        return {
            exists: exists,
            formattedValue: value,
        };
    } else {
        return {
            exists: false,
            formattedValue: "",
        };
    }
};

export const getDataDisplayPropertyItems = (fields: Array<DataDisplayField>, graphqlResultObject: any) => {
    const result: Array<PropertyItem> = [];
    if (graphqlResultObject) {
        fields.forEach((field) => {
            const { exists, formattedValue } = getDataDisplayValue(field, graphqlResultObject);

            if (exists) {
                const property: PropertyItem = {
                    key: field.key,
                    title: field.title,
                    value: formattedValue,
                    fieldType: (field.fieldTypeInfo?.fieldType as string) ?? undefined,
                    isPriority: field.isPriority,
                };

                result.push(property);
            }
        });
    }

    return result;
};
