<template>
    <QitButton
        @click.stop.prevent
        v-if="hasVisibleElements"
        icon="fa-solid fa-ellipsis-vertical default-grey-icon-color"
        size="13px"
        padding="sm"
        color="none"
        class="more-menu-button"
        data-qs="more-menu-button"
    >
        <q-menu>
            <q-list>
                <q-item
                    clickable
                    v-close-popup
                    v-for="(entry, index) in entries"
                    :key="index"
                    class="items-center q-px-md q-py-sm item"
                    @click="entry.click()"
                    v-bind="entry.elementAttrs"
                    :class="{ 'item-invisible': !(entry.visible === undefined || entry.visible) }"
                >
                    <template v-if="entry.visible === undefined || entry.visible">
                        <template v-if="'text' in entry">
                            <div class="q-mr-xs" v-if="entry.icon">
                                <q-icon color="primary" :name="entry.icon" size="xs" />
                            </div>
                            <div>{{ entry.text }}</div>
                        </template>
                        <template v-else>
                            <component :is="entry.component.getComponent()" />
                        </template>
                    </template>
                </q-item>
            </q-list>
        </q-menu>
    </QitButton>
</template>

<script setup lang="ts">
import { computed } from "vue";
import QitButton from "@/shared/components/buttons/qit-button.vue";

const props = withDefaults(
    defineProps<{
        entries: any; //TODO: (MenuTextEntry | MenuComponentEntry)[];
        open?: boolean;
    }>(),
    {
        open: false,
    }
);

const hasVisibleElements = computed(() => {
    return (
        props.entries.length > 0 &&
        props.entries.some((entry: any) => {
            return entry.visible ?? true;
        })
    );
});
</script>

<style lang="scss" scoped>
.item {
    min-height: unset;
    max-width: 350px;
}

.more-menu-button {
    background: transparent;
    margin: 0 0 auto 0;

    &:hover {
        background: rgba(0, 0, 0, 0.04);
    }
}

.item-invisible {
    display: none;
}
</style>
