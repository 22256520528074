<template>
    <div class="need-qualification-wizard column fit">
        <div class="col-auto" v-if="levelTitle">
            <p class="wizard-title">
                {{ levelTitle }}
            </p>
        </div>
        <div v-if="currentLevel" class="col fit wizard-content" :key="currentLevelIndex">
            <LevelSingleComponent
                v-if="currentLevel.components.length === 1"
                :level-component="currentLevel.components[0]"
                :selection-data="currentSelectionData[currentLevelIndex]"
                :is-last-level="currentLevelIsLast"
                :emit-last-level-selection="props.emitLastLevelSelection"
                @select="newSelection"
                @back="goBack"
                @link-clicked="linkClicked"
            />
            <LevelMultiComponent
                v-if="currentLevel.components.length > 1"
                :level-components="currentLevel.components"
                :selection-data="currentSelectionData[currentLevelIndex]"
                :is-last-level="currentLevelIsLast"
                :has-level-title="levelTitle !== ''"
                :emit-last-level-selection="props.emitLastLevelSelection"
                @select="newSelection"
                @back="goBack"
                @link-clicked="linkClicked"
            />
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref, computed, Ref, unref } from "vue";
import { LevelComponent, SelectionLevelDefinition } from "./need-qualification-wizard.model";
import LevelMultiComponent from "./need-qualification-wizard-tabbed-level.vue";
import LevelSingleComponent from "./need-qualification-wizard-single-level.vue";
import { useQualificationLevelSelectListsFromAbilities } from "@/shared/environment/ability-qualification-select-lists";
import { QualificationLevelSelectionResult } from "@/shared/environment/ability.types";
import { useTextObjectTranslation } from "@/shared/i18n/translation-types";

interface Level {
    components: Array<LevelComponent>;
    levelText: Ref<string> | string;
}

const props = defineProps<{
    levelDefinition: Array<SelectionLevelDefinition>;
    selectionData: QualificationLevelSelectionResult;
    emitLastLevelSelection?: boolean;
}>();

const emit = defineEmits<{
    (e: "hide"): void;
    (e: "select", selectionResult: QualificationLevelSelectionResult): void;
}>();

const linkClicked = () => {
    emit("hide");
};

const { trans } = useTextObjectTranslation();

const currentSelectionData = ref<Array<QualificationLevelSelectionResult>>([props.selectionData]);

const currentLevelIndex = ref(0);

const { qualificationLevels } = useQualificationLevelSelectListsFromAbilities();

const levels = computed(() => {
    const result: Level[] = [];

    props.levelDefinition.forEach((element) => {
        const level: Level = { components: [], levelText: element.levelText };
        element.aliases.forEach((alias) => {
            const selectListFromAbility = qualificationLevels.value?.find((item) => item.alias === alias);
            if (selectListFromAbility) {
                level.components.push({
                    componentDefinition: selectListFromAbility.component,
                    alias: selectListFromAbility.alias,
                    title: trans(selectListFromAbility.title),
                });
            }
        });
        if (level.components.length > 0) {
            result.push(level);
        }
    });

    return result;
});

const currentLevel = computed(() => {
    return levels.value[currentLevelIndex.value];
});

const currentLevelIsLast = computed(() => {
    return currentLevelIndex.value >= levels.value.length - 1;
});

const newSelection = (newSelection: QualificationLevelSelectionResult) => {
    if (currentLevelIndex.value < levels.value.length - 1) {
        currentSelectionData.value.push(newSelection);
        currentLevelIndex.value++;
    }
    if (currentLevelIsLast.value && props.emitLastLevelSelection) {
        emit("select", newSelection);
    }
};

const goBack = () => {
    if (currentLevelIndex.value > 0) {
        currentSelectionData.value.pop();
        currentLevelIndex.value--;
    }
};

const levelTitle = computed(() => unref(currentLevel.value?.levelText));
</script>

<style lang="scss" scoped>
.need-qualification-wizard {
    display: flex;
    flex-direction: column;

    &:deep(.loading),
    &:deep(.inner-content) {
        background: $light-background-color;
    }

    .wizard-title {
        padding: $spacing-l;
        margin: 0;
        width: 100%;
        font-size: 1rem !important;
        background-color: $white;
        border-bottom: 1px solid $default-border-color;

        .screen--xs & {
            padding: $spacing-l;
        }
    }

    .wizard-content {
        background-color: $light-background-color;
    }
}
</style>
