<template>
    <div class="column fit">
        <div v-if="slots.banner" class="col-auto q-mx-sm q-mt-sm">
            <slot name="banner" />
        </div>
        <div v-if="slots.count" class="col q-pa-sm slot-count">
            <slot name="count" />
        </div>
        <div class="col fit q-pa-sm">
            <slot name="content" />
        </div>
        <div class="col-auto">
            <div v-show="!noChildren" class="row" style="min-height: 60px">
                <div class="col">
                    <MutationObserverDiv @on-change="onMutation" class="bg-white" style="height: 60px; bottom: 0">
                        <slot name="panel" />
                    </MutationObserverDiv>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref, useSlots } from "vue";
import MutationObserverDiv from "@/shared/components/mutation-observer/mutation-observer-div.vue";

const noChildren = ref(true);

const slots = useSlots();

function onMutation(children: HTMLCollection) {
    noChildren.value = children.length === 0;
}
</script>
