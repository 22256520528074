<template>
    <Card
        :title="$t(title)"
        @on-close-button-clicked="$emit('onCloseButtonClicked')"
        :key="locale"
        data-qs="activity-import-wizard"
        class="small-content-container"
        white-background
        container
    >
        <ConfigurationFormWrapper>
            <ActivityImportForm />
        </ConfigurationFormWrapper>
        <template #footer>
            <ConfigurationWizardFooter
                process-type="activity"
                continue-text="documentation.Close"
                :continue-disabled="!formValid"
                @go-to-next-step="closeForm"
            />
        </template>
    </Card>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import Card from "@/shared/components/card/card.vue";
import ConfigurationFormWrapper from "@/shared/components/data-process/configuration-form-wrapper.vue";
import ConfigurationWizardFooter from "@/shared/components/data-process/configuration-wizard-footer.vue";
import ActivityImportForm from "@/abilities/activities/components/data-process/activity-import-form.vue";
import { getUiLanguage } from "@/shared/services/providers/language-provider";

defineProps<{
    title: string;
}>();

const emit = defineEmits(["onCreateProcess", "onCloseButtonClicked"]);

const locale = computed(() => {
    return getUiLanguage();
});

const formValid = ref(true);

function closeForm() {
    emit("onCloseButtonClicked");
}
</script>
