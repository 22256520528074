import { computed } from "vue";

import { useUserSettings } from "@/shared/settings/user-settings";

export enum ViewHistorySettingsKey {
    products_assets_history = "products-assets-history",
}

export interface ProductsAssetsHistoryState {
    lastProductIDs: string[];
    lastAssetIDs: string[];
}

const MAX_LAST_PRODUCTS = 3;
const MAX_LAST_ASSETS = 3;

export const useProductsAssetsHistory = () => {
    const {
        data: settings,
        update,
        loading,
    } = useUserSettings<ProductsAssetsHistoryState | undefined>(ViewHistorySettingsKey.products_assets_history);

    const getValue = () => {
        return {
            lastProductIDs: settings.value?.lastProductIDs ?? [],
            lastAssetIDs: settings.value?.lastAssetIDs ?? [],
        };
    };
    const addProductIdToViewHistory = async (newProductId: string) => {
        const _settings: ProductsAssetsHistoryState = getValue();

        if (_settings.lastProductIDs.includes(newProductId)) {
            const fromIndex = _settings.lastProductIDs.indexOf(newProductId);
            _settings.lastProductIDs.splice(fromIndex, 1);
        }
        if (_settings.lastProductIDs.length === MAX_LAST_PRODUCTS) {
            _settings.lastProductIDs.pop();
            _settings.lastProductIDs.unshift(newProductId);
        } else {
            _settings.lastProductIDs.unshift(newProductId);
        }

        await updateUserSettings(_settings);
    };

    const deleteProductIdFromHistory = async (toDeleteProductId: string) => {
        const _settings: ProductsAssetsHistoryState = getValue();
        _settings.lastProductIDs = _settings.lastProductIDs.filter((id: string) => id !== toDeleteProductId);
        //delete also assets of product (we assume assetIds are of schema {productId}_{assetId})
        _settings.lastAssetIDs = _settings.lastAssetIDs.filter((id: string) => id.split("_")[0] !== toDeleteProductId);
        await updateUserSettings(_settings);
    };

    const addAssetIdToViewHistory = async (newAssetId: string) => {
        const _settings: ProductsAssetsHistoryState = getValue();

        if (_settings.lastAssetIDs.includes(newAssetId)) {
            const fromIndex = _settings.lastAssetIDs.indexOf(newAssetId);
            _settings.lastAssetIDs.splice(fromIndex, 1);
        }
        if (_settings.lastAssetIDs.length === MAX_LAST_ASSETS) {
            _settings.lastAssetIDs.pop();
            _settings.lastAssetIDs.unshift(newAssetId);
        } else {
            _settings.lastAssetIDs.unshift(newAssetId);
        }
        await updateUserSettings(_settings);
    };

    const deleteAssetIdFromHistory = async (toDeleteAssetId: string) => {
        const _settings: ProductsAssetsHistoryState = getValue();
        _settings.lastAssetIDs = _settings.lastAssetIDs.filter((id: string) => id !== toDeleteAssetId);

        await updateUserSettings(_settings);
    };

    const deleteAllProductAndAssetIdsFromHistory = async () => {
        const _settings: ProductsAssetsHistoryState = getValue();
        _settings.lastAssetIDs = [];
        _settings.lastProductIDs = [];

        await updateUserSettings(_settings);
    };

    const updateUserSettings = async (state: ProductsAssetsHistoryState) => {
        await update(state);
    };

    const lastProductIDs = computed(() => {
        return settings.value?.lastProductIDs ?? [];
    });

    const lastAssetIDs = computed(() => {
        return settings.value?.lastAssetIDs ?? [];
    });

    return {
        addProductIdToViewHistory,
        addAssetIdToViewHistory,
        deleteAllProductAndAssetIdsFromHistory,
        deleteAssetIdFromHistory,
        deleteProductIdFromHistory,
        lastProductIDs,
        lastAssetIDs,
        loading,
    };
};
