import { ModelElement } from "@/shared/components/data-process/form-types";
import {
    ImportProcessesQuery,
    ProcessMappingType,
    useImportProcessesQuery,
} from "@/shared/services/graphql/generated/consumer-graph-types";
import { ApolloQueryResult } from "@apollo/client/core";
import { Ref, ref } from "vue";
import { useI18n } from "vue-i18n";

/**
 * File import form model with form view model elements
 */
export interface FileImportFormModel {
    name: ModelElement<string>;
    fileNameScheme: ModelElement<string>;
    mappingOptions: ModelElement<MappingOption[]>;
    upToChar: ModelElement<string>;
}

/**
 * Mapping option with ProcessMappingtype, upToChar, title and value
 */
export interface MappingOption {
    type: ProcessMappingType;
    upToChar: string;
    title: string;
    value: boolean;
}

export function useFileImportFormValidation(formFields: Ref<FileImportFormModel>, entryId: string) {
    /**
     * Helper form field to set all mapping options true
     */
    const mappingViaAll = ref(true);

    const { result: processes, onResult, loading: processesLoading } = useImportProcessesQuery({});
    const editName = ref("");

    /**
     * If processes loaded, set editName if entryId available
     * and check directly if name valid.
     */
    onResult((data: ApolloQueryResult<ImportProcessesQuery>) => {
        editName.value = data.data?.processes?.find((process) => process?.id === entryId)?.name ?? "";
        validateProcessName();
    });

    /**
     * Validate Process name
     * Process name cannot be empty or duplicate
     */
    function validateProcessName() {
        if (!formFields.value.name.value) {
            _setFormFieldValidation(formFields.value.name, false, "data-process.Field must not be empty");
        } else if (formFields.value.name.value === editName.value) {
            _setFormFieldValidation(formFields.value.name, true);
        } else if (processes?.value?.processes) {
            if (processes.value.processes.find((process) => process?.name === formFields.value.name.value)) {
                _setFormFieldValidation(formFields.value.name, false, "data-process.The process name already exists");
            } else {
                _setFormFieldValidation(formFields.value.name, true);
            }
        } else if (processesLoading) {
            _setFormFieldValidation(formFields.value.name, true, "data-process.Checking process names");
        }
    }

    /**
     * Validate file name scheme, cannot be empty
     */
    function validateFileNameScheme() {
        if (formFields.value.fileNameScheme.value) {
            _setFormFieldValidation(formFields.value.fileNameScheme, true);
        } else {
            _setFormFieldValidation(formFields.value.fileNameScheme, false, "data-process.Field must not be empty");
        }
    }

    /**
     * Validate mapping options, at least one option must be selected
     */
    function validateMappingVia() {
        if (formFields.value.mappingOptions.value.some((option) => option.value)) {
            _setFormFieldValidation(formFields.value.mappingOptions, true);
        } else {
            _setFormFieldValidation(
                formFields.value.mappingOptions,
                false,
                "data-process.Select at least one assignment"
            );
        }
    }

    /**
     * Validate fileNameUpToChar mapping option, cannot be empty if selected
     */
    function validateUpToChar() {
        const upToCharMappingOption = formFields.value.mappingOptions.value.find(
            (option) => option.type === ProcessMappingType.filenameUpToChar
        );
        if (upToCharMappingOption?.value && upToCharMappingOption?.upToChar.length === 0) {
            _setFormFieldValidation(formFields.value.upToChar, false, "data-process.Field must not be empty");
        } else {
            _setFormFieldValidation(formFields.value.upToChar, true);
        }
    }

    function _setFormFieldValidation(field: ModelElement<any>, valid: boolean, messageLanguageKey: string = "") {
        field.valid = valid;
        field.messageKey = messageLanguageKey;
    }

    return {
        formFields,
        mappingViaAll,
        validateProcessName,
        validateFileNameScheme,
        validateMappingVia,
        validateUpToChar,
    };
}

/**
 * Form fields with initial values for the file import process form.
 */
export function getFileImportModel(): Ref<FileImportFormModel> {
    const { t } = useI18n();

    const formFields = ref<FileImportFormModel>({
        name: {
            value: t("data-process.Files"),
            valid: true,
            messageKey: "",
        },
        fileNameScheme: {
            value: "CAD.zip",
            valid: true,
            messageKey: "",
        },
        mappingOptions: {
            value: [
                {
                    type: ProcessMappingType.folderName,
                    upToChar: "",
                    title: "data-process.Folder name",
                    value: true,
                },
                {
                    type: ProcessMappingType.filenameUpToChar,
                    upToChar: "-",
                    title: "data-process.File name until character",
                    value: true,
                },
                {
                    type: ProcessMappingType.filenameComplete,
                    upToChar: "",
                    title: "data-process.Complete file name",
                    value: true,
                },
            ],
            valid: true,
            messageKey: "",
        },
        upToChar: {
            value: "-",
            valid: true,
            messageKey: "",
        },
    });

    return formFields;
}
