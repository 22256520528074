<template>
    <DropdownSearchField v-if="searchEnabled" @on-value-changed="filterItems" />
    <q-list v-if="items.length > 0">
        <q-item
            v-for="item in filteredItems"
            :key="item.id"
            clickable
            v-close-popup
            @click="onClick(item)"
            class="items-center"
            :data-qs="`dropdown-item-${item.id}`"
        >
            {{ item.text }}
            {{ aggregations && aggregations[item.id] > 0 ? `(${aggregations[item.id] ?? "0"})` : "(0)" }}
        </q-item>
    </q-list>
</template>

<script setup lang="ts">
import { FacetValue } from "../../facets.model";
import { ref, watch } from "vue";
import DropdownSearchField from "@/shared/facets/dropdown/content/dropdown-search-field.vue";
import { MIN_SEARCH_CHARS_FILTER } from "@/shared/facets/dropdown/facet-dropdown-lazy.ts";

const props = defineProps<{
    facetType: string;
    items: FacetValue[];
    aggregations: Record<string, number>;
    searchEnabled?: boolean;
}>();

const emits = defineEmits<{
    onSelect: [selectedId: string];
    onFilterChanged: [filterPhrase: string];
}>();

const filteredItems = ref<FacetValue[]>(props.items);

watch(
    () => props.items,
    () => {
        filteredItems.value = props.items;
    }
);

const filterItems = (filterPhrase: string) => {
    if (props.facetType === "keyword") {
        emits("onFilterChanged", filterPhrase);
        return;
    }

    filteredItems.value =
        filterPhrase.length >= MIN_SEARCH_CHARS_FILTER
            ? props.items.filter((x) => x.text.toLowerCase().includes(filterPhrase.toLowerCase()))
            : props.items;
};

const onClick = (val: FacetValue) => {
    emits("onSelect", val.id);
};
</script>

<style lang="scss" scoped>
.items-center {
    padding: 0 16px;
}
</style>
