import { Ability, Route, RouteElementWithParam, Widget } from "@/shared/environment/ability.types";
import { AccessAction, AccessFeature, AccessResource } from "@/shared/access-control/access-control";
import ShopWidget from "../pages/components/widgets/shop.widget.vue";
import { useToolBarMenuFDIShopItems } from "../actions/fdi-shop-actions";
import { createRouteFromRouteElements } from "@/shared/environment/ability-route-helper";
import { getShopClient } from "../graphql/fdi-shop-client";
import { useShopStatusStore } from "../stores/shop-status";

export const FDI_SHOP_ALIAS = "fdi-shop";

/**
 * Ability for FDI Shop
 *
 * Access to shop-widget and shop-menu with role basic-shop-user (resource: shop, action: query) and feature fdi_shop_connector
 *
 * Access to shop-config with role shop-admin (resource: config_shop, action: write) and feature fdi_shop_connector
 */
export class FdiShopAbility implements Ability {
    alias = FDI_SHOP_ALIAS;
    init = async () => {
        const { checkConnection } = useShopStatusStore();
        const isShopOnline = await checkConnection(); //mockStoreOffline();
        if (isShopOnline) {
            const shopClient = await getShopClient();
            return shopClient !== undefined;
        } else {
            // TODO: Check, if this is needed or if we have to return true,
            // but instead handle undefined shopClient in components
            return true;
        }
    };
    access = {
        accessFeature: AccessFeature.fdi_shop_connector,
        accessResource: AccessResource.shop,
        accessAction: AccessAction.query,
    };

    getWidgets = (): Array<Widget> => [
        {
            widgetContextAlias: ["shopContext"],
            widgetComponent: ShopWidget,
        },
    ];

    getMenuEntries = useToolBarMenuFDIShopItems;

    getRoutes = (): Array<Route> => [
        {
            alias: "fdi-shop-login-landing",
            params: [],
            getComponent: () => import("../pages/shop-login-landing-page.vue"),
            isRoot: true,
            getBreadCrumbs: (parentElements: Array<RouteElementWithParam>, currentElement: RouteElementWithParam) => {
                return [
                    {
                        content: {
                            simpleText: "Landing",
                        },
                        getRouterTo: () => createRouteFromRouteElements(parentElements, currentElement),
                    },
                ];
            },
        },
    ];
}
