import { computed, ref, Ref, watch } from "vue";
import { HtmlDocument } from "@/abilities/documentation/model/document-data";
import { consumerClient } from "@/shared/services/apollo-clients/consumer-client";
import { TocDocument, TocQuery, TocQueryVariables } from "@/shared/services/graphql/generated/consumer-graph-types";
import { isEqual } from "lodash";
import { useDocumentationQuery } from "./documentation-query";
import { useQuery } from "@vue/apollo-composable";
import { getQueryTopicIsAllowedInContext } from "@/abilities/documentation/graphql/toc.query.ts";
export interface TocNode {
    id: string;
    label: string;
    content?: HtmlDocument;
    children?: Array<TocNode>;
    lazy: boolean;
    childPath: string;
    path: string;
}

const tocCache: Record<string, TocQuery> = {};

export const useDocumentStructure = (
    contentMapId: Ref<string>,
    topicId: Ref<string>,
    productId: Ref<string | undefined>,
    assetId: Ref<string | undefined>
) => {
    const toc = ref<TocNode[]>([]);
    const loading = ref(true);
    const breadCrumbsLoading = ref(true);
    const tocLoadingError = ref<Error>();
    const breadCrumbs = ref<{ id: string; label: string }[]>();
    let preloadToc: TocNode[] = [];
    const {
        title,
        document,
        pathNotFound,
        childPath,
        hasChildren,
        path,
        error: documentError,
    } = useDocumentationQuery(
        computed(() => contentMapId.value),
        computed(() => topicId.value)
    );

    const allowedInContextQuery = computed(() => getQueryTopicIsAllowedInContext(productId.value, assetId.value));

    const { loading: loadingAllowedInContext, result: resultAllowedInContext } = useQuery(
        allowedInContextQuery,
        {
            filter: { contentIds: [contentMapId.value] },
            productId: productId.value,
            assetId: `${productId.value}_${assetId.value}`,
        },
        { fetchPolicy: "cache-first", enabled: !!allowedInContextQuery.value }
    );

    const topicNotFound = computed(() => {
        const notAllowedInContext =
            allowedInContextQuery.value &&
            !loadingAllowedInContext.value &&
            !(
                resultAllowedInContext.value?.product?.relatedContents?.contents?.[0]?.node?.id ||
                resultAllowedInContext.value?.asset?.relatedContents?.contents?.[0]?.node?.id
            );

        return pathNotFound.value || notAllowedInContext;
    });

    const loadAllLazy: any = async (childPath: string, after: string, lastEntries?: TocNode[]) => {
        const variables: TocQueryVariables = {
            filter: {
                contentIds: [contentMapId.value],
            },
            first: 100,
            after,
            paths: childPath,
        };
        const cacheKey = JSON.stringify(variables);
        let tocResultFromCache = tocCache[cacheKey];
        if (!tocResultFromCache) {
            const tocResult = await consumerClient.query<TocQuery>({
                query: TocDocument,
                variables,
                fetchPolicy: "no-cache",
            });

            tocResultFromCache = tocCache[cacheKey] = tocResult.data;
        }
        if (
            !(
                tocResultFromCache?.contentMaps?.contentMaps?.length === 1 &&
                tocResultFromCache.contentMaps.contentMaps[0]?.node?.toc?.tocEntries?.length
            )
        ) {
            throw new Error("Error corrupted contentMap");
        }
        const _entries = tocResultFromCache.contentMaps.contentMaps[0]?.node?.toc?.tocEntries.map((tocEntry) => {
            const label = tocEntry?.node?.teasers?.title!;

            const { path, hasChildren, childPath, id } = tocEntry;
            const tocNode: TocNode = { label, id, lazy: !!hasChildren, childPath, path };
            if (tocEntry?.node?.__typename === "Topic") {
                const { url, mimeType } = tocEntry.node;
                tocNode["content"] = { url, mimeType: mimeType! };
            }
            return tocNode;
        });
        const entries = [...(lastEntries ?? []), ..._entries];

        if (entries.length < tocResultFromCache.contentMaps.contentMaps[0]?.node.toc?.total.count) {
            const lastCursor =
                tocResultFromCache.contentMaps.contentMaps[0]?.node.toc?.tocEntries?.at(-1)?.cursor ?? "";
            return await loadAllLazy(childPath, lastCursor, entries);
        } else {
            return entries;
        }
    };

    const loadAndTransformToc = async (
        childPath: string,
        treeCallback: ((tocNode: TocNode[]) => void) | undefined,
        last: boolean,
        flatTocCache: Record<string, TocNode>
    ) => {
        const entries = await loadAllLazy(childPath, "");

        if (childPath === "~") {
            const rootEntry = entries[0];
            flatTocCache[rootEntry.childPath] = rootEntry;
            preloadToc = entries;
        } else {
            //lazy loading
            if (treeCallback) {
                treeCallback(entries);
                return;
            }

            const tocNode = flatTocCache[childPath];
            tocNode.children = entries;
            //if children are preloaded lazy has to be set to false, or else they will be loaded again
            tocNode.lazy = false;
            for (const entry of entries) {
                flatTocCache[entry.childPath] = entry;
            }
        }
        if (last) {
            if (isEqual(toc.value, preloadToc)) {
                return;
            }
            toc.value = preloadToc;
        }
    };

    const calculateBreadCrumb = (flatTocCache: Record<string, TocNode>) => {
        const parts = childPath?.value?.split("/");
        breadCrumbsLoading.value = true;
        if (!parts || loading.value) return undefined;
        let absolutePath = "";
        const _breadCrumbs = [];
        for (const part of parts) {
            absolutePath += (absolutePath ? "/" : "") + part;
            if (part === "~") continue;
            const label = flatTocCache[absolutePath]?.label;
            const id = flatTocCache[absolutePath]?.id;
            _breadCrumbs.push({ id, label });
        }
        breadCrumbsLoading.value = false;
        return _breadCrumbs;
    };

    const load = async (loadUntil: string) => {
        const parts = loadUntil.split("/");
        let absolutePath = "";
        const flatTocCache: Record<string, TocNode> = {};

        try {
            for (const part of parts) {
                absolutePath += (absolutePath ? "/" : "") + part;
                await loadAndTransformToc(absolutePath, undefined, absolutePath === loadUntil, flatTocCache);
            }
            loading.value = false;
            breadCrumbs.value = calculateBreadCrumb(flatTocCache);
        } catch (_: any) {
            tocLoadingError.value = _;
            loading.value = false;
        }
    };

    const loadTocSubNode = async (childPath: string, done: (tocNode: TocNode[]) => void) => {
        await loadAndTransformToc(childPath, done, false, {});
    };

    watch(childPath, async () => {
        if (!(childPath.value && path.value)) {
            return;
        }
        //first child node should be loaded
        //unfortunately childPath is returned even when there is no child node
        if (hasChildren.value) {
            await load(childPath.value);
        } else {
            await load(path.value);
        }
    });

    const error = computed(() => {
        const anyError = tocLoadingError.value || documentError.value;
        if (anyError) {
            loading.value = false;
        }
        return anyError;
    });

    watch(topicNotFound, () => {
        if (topicNotFound.value) {
            loading.value = false;
        }
    });

    return {
        toc,
        path,
        title,
        error,
        loading,
        document,
        childPath,
        breadCrumbs,
        loadTocSubNode,
        breadCrumbsLoading,
        notFound: topicNotFound,
    };
};
