import "@fortawesome/fontawesome-pro/css/fontawesome.css";
import "@fortawesome/fontawesome-pro/css/regular.css";
import "@fortawesome/fontawesome-pro/css/solid.css";
import "@fortawesome/fontawesome-pro/css/light.css";
import "@fortawesome/fontawesome-pro/css/thin.css";
import "quasar/dist/quasar.css";
import "@/shared/assets/fontawesome/css/custom-icons.css";

import { ApmVuePlugin } from "@elastic/apm-rum-vue";
import { Dialog, Notify, Quasar } from "quasar";
import { createApp, h, provide } from "vue";
import { ApolloClients } from "@vue/apollo-composable";
import { getStore, key } from "@/shared/store/store";
import App from "./App.vue";
import appInfo from "./app-info";
import { getApmOptions } from "./apm-client";
import { consumerClient } from "@/shared/services/apollo-clients/consumer-client";
import { adminClient } from "@/shared/services/apollo-clients/admin-client";
import { windowResizeDirective } from "@/shared/directives/window-resize-directive";
import { getI18n } from "@/shared/i18n/i18n";
import iconSet from "quasar/icon-set/fontawesome-v6-pro";
import { createPinia } from "pinia";
import { publicClient } from "@/shared/services/apollo-clients/public-client";
import { createRouter, createWebHistory } from "vue-router";

const router = createRouter({
    routes: [],
    history: createWebHistory(),
});

const store = getStore();
const i18n = getI18n();
const pinia = createPinia();

const app = createApp({
    setup() {
        provide(ApolloClients, {
            default: consumerClient,
            admin: adminClient,
            public: publicClient,
        });
    },
    render: () => h(App),
});

// make sure, pinia stores are available as soon as possible
app.use(pinia);
app.use(Quasar, {
    plugins: { Dialog, Notify },
    config: {
        brand: {
            /**
             * As in static-colors.scss, used for button colors etc. (e.g. type="accent")
             * Rest of the colors are set dynamically in shell.vue:#DB2828
             *   ('primary' -> colors.primary, 'secondary' -> colors.highlight, 'dark' -> colors.textOnPrimary)
             */
            accent: "#337ab7",
            positive: "#60a966",
            negative: "#DB2828",
            info: "#2185d0",
            warning: "#fbbd08",
        },
        notify: {
            classes: "notification",
            iconSize: "26px",
            timeout: 3000,
            type: "info",
        },
        screen: {
            bodyClasses: true,
        },
    },
    iconSet: iconSet,
});
app.use(router);
app.use(store, key);
app.use(i18n);

if (location.hostname !== "localhost") {
    app.use(ApmVuePlugin, getApmOptions(router));
}
app.config.globalProperties.$appInfo = appInfo;
app.directive("resize", windowResizeDirective);
app.mount("#app");
