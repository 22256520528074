<template>
    <div v-if="loading" class="loading row justify-center">
        <q-spinner-ios size="2em" />
    </div>
    <template v-else-if="appKey">
        <div class="semi-bold row no-wrap">
            <div>1.&nbsp;</div>
            <div>
                {{
                    $t(
                        "core.First open the Companion app on your mobile your mobile device (Multiple devices possible)"
                    )
                }}
            </div>
        </div>
        <div class="semi-bold row no-wrap q-mt-md">
            <div>2.&nbsp;</div>
            <div>{{ $t("core.Then scan the generated QR code or enter the key") }}</div>
        </div>
        <div class="column justify-center items-center">
            <div class="q-pt-md">
                <vue-qrcode :value="appKey.apiPass" :options="{ width: 250 }" />
            </div>
            <div class="light-text-color">
                {{
                    `${$t("core.QR-Code valid until")} ${new Intl.DateTimeFormat(locale).format(
                        new Date(appKey.valid)
                    )}`
                }}
            </div>
            <qit-button
                data-qs="copy-key-btn"
                class="full-width q-mt-md"
                color="secondary"
                :label="$t('core.Copy key')"
                icon="fa-regular fa-copy icon-color"
                @click="copyKey"
            />
            <qit-button
                data-qs="reset-link-btn"
                class="full-width q-mt-md border"
                color="white"
                :label="$t('core.Reset link')"
                @click="openResetDialogue"
            />
        </div>
        <ResetDeviceLinkDialog
            :visible="isResetLinkDialogVisible"
            @on-close="closeResetDialogue"
            @on-reset-link="resetLink"
        />
    </template>
</template>

<script setup lang="ts">
import VueQrcode from "@chenfengyuan/vue-qrcode";
import { computed, onMounted, ref } from "vue";
import { useQuasar } from "quasar";
import { useI18n } from "vue-i18n";
import { useCreateAppKeyMutation } from "@/shared/services/graphql/generated/consumer-graph-types.ts";
import { useClipboard } from "@vueuse/core";
import ResetDeviceLinkDialog from "@/shared/components/connect-device/reset-device-link-dialog.vue";
import { getUiLanguage } from "@/shared/services/providers/language-provider.ts";
import QitButton from "@/shared/components/buttons/qit-button.vue";

const loading = ref<boolean>(true);
const isResetLinkDialogVisible = ref<boolean>(false);
const appKey = ref<undefined | { apiPass: string; valid: string }>();

const { notify } = useQuasar();
const { t } = useI18n();
const { mutate } = useCreateAppKeyMutation();
const { copy } = useClipboard();

onMounted(async () => {
    await createCode();
});

const locale = computed(() => {
    return getUiLanguage();
});

const createCode = async () => {
    loading.value = true;

    try {
        const result: any = await mutate();
        if (result?.data?.createAppKey)
            appKey.value = {
                apiPass: result.data.createAppKey.apiPass,
                valid: result.data.createAppKey.key.validUntil,
            };
    } catch (e: any) {
        console.error(e);
        notify({ message: typeof e === "string" ? e : e.message, type: "negative" });
    } finally {
        loading.value = false;
    }
};

const copyKey = () => {
    if (!appKey.value) return;

    copy(appKey.value.apiPass);
    notify({ message: t("core.Key was successfully copied to the clipboard"), type: "positive" });
};

const openResetDialogue = () => {
    isResetLinkDialogVisible.value = true;
};

const closeResetDialogue = () => {
    isResetLinkDialogVisible.value = false;
};

const resetLink = async () => {
    closeResetDialogue();
    await createCode();
};
</script>

<style lang="scss" scoped>
.loading {
    height: 50px;
}
</style>
