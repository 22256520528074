<template>
    <q-drawer
        :side="side"
        :model-value="visible"
        overlay
        persistent
        :width="$q.screen.gt.xs ? 400 : $q.screen.width"
        class="overlay overflow-hidden column col-grow no-wrap shadow-1"
        :no-swipe-close="true"
    >
        <q-toolbar>
            <div v-if="icon" data-qs="left-overlay-next-to-title-toolbar-item-icon-button">
                <i :class="[icon]" style="font-size: 22px" />
            </div>
            <q-toolbar-title class="drawer-title"> {{ title }} </q-toolbar-title>
            <i
                class="fa-regular fa-xmark icon-color cursor-pointer q-mr-sm"
                @click="emit('onClose')"
                style="font-size: 22px"
            />
        </q-toolbar>
        <q-separator />
        <slot />
    </q-drawer>
</template>

<script setup lang="ts">
import { QDrawerProps } from "quasar";

withDefaults(defineProps<{ visible: boolean; title: string; icon?: string; side?: QDrawerProps["side"] }>(), {
    side: "right",
});
const emit = defineEmits<{ (e: "onClose"): void }>();
</script>

<style lang="scss" scoped>
.drawer-title {
    color: $default-text-color;
    font-weight: 700;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: $bigger-font-size;
}
</style>
