import { AsyncLoadedBreadCrumbs, RouteElementWithParam } from "@/shared/environment/ability.types";
import { computed } from "vue";
import { useDocumentStructure } from "@/abilities/documentation/composables/document-structure";
import { useDocumentRoute } from "@/shared/router/document-route";

export function useDocumentationBreadCrumbs(currentElement: RouteElementWithParam): AsyncLoadedBreadCrumbs {
    const contentMapId = currentElement.namedParams.contentMapId;
    const topicId = currentElement.namedParams.topicId;

    const { getPathToDocumentationDetailPage } = useDocumentRoute();
    const { breadCrumbs, breadCrumbsLoading } = useDocumentStructure(
        computed(() => contentMapId),
        computed(() => topicId),
        computed(() => undefined),
        computed(() => undefined)
    );

    const items = computed(() => {
        if (breadCrumbs.value?.length) {
            return breadCrumbs.value.map(({ label, id }) => {
                return {
                    content: { simpleText: label },
                    getRouterTo: () => getPathToDocumentationDetailPage(contentMapId, id),
                };
            });
        } else {
            return [];
        }
    });

    return {
        items,
        loading: breadCrumbsLoading,
    };
}
