<template>
    <span :class="classNames">{{ text }}</span>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";

const props = defineProps<{
    type: string;
    classNames?: string;
}>();

const { t } = useI18n();

const text = computed(() => {
    if (props.type === "Topic") return t("documentation.Topic");
    if (props.type === "ContentMap") return t("documentation.Document");

    return "";
});
</script>
