import { BreadcrumbItem } from "@/shared/components/breadcrumbs/breadcrumbs.model";
import { RouteElementWithParam } from "@/shared/environment/ability.types";
import {
    createRouteFromRouteElements,
    decodeParamArrayFromString,
    encodeParamArrayToString,
    getRouteElementParamsAsComponentProps,
} from "@/shared/environment/ability-route-helper";
import { CommonRouteAliases } from "@/shared/environment/common-route-aliases";
import AssemblyTitleSpan from "../pages/components/breadcrumb/assembly-title-span.vue";

export function createBreadCrumbsForMechanic(
    parentElements: RouteElementWithParam[],
    currentElement: RouteElementWithParam
): Array<BreadcrumbItem> {
    const result: Array<BreadcrumbItem> = [];

    const paramValue = currentElement.namedParams[CommonRouteAliases.mechanic];
    if (paramValue) {
        const parentParamsAsProps = getRouteElementParamsAsComponentProps(parentElements);

        const assemblyIds = decodeParamArrayFromString(paramValue);
        const localParams: Array<string> = [];

        assemblyIds.forEach((assemblyId) => {
            localParams.push(assemblyId);

            const mechanicParam = encodeParamArrayToString(localParams);
            // Route zu diesem Teilpfad für das Breadcrumb
            const newRouteElement: RouteElementWithParam = {
                alias: currentElement.alias,
                namedParams: { [CommonRouteAliases.mechanic]: mechanicParam },
            };

            // Eigentliches Breadcrumb-Element mit der Anzeigekomponente erzeugen
            result.push({
                content: {
                    componentDefinition: {
                        getComponent: () => AssemblyTitleSpan,
                        props: {
                            ...parentParamsAsProps,
                            [CommonRouteAliases.mechanic]: mechanicParam,
                        },
                    },
                },
                getRouterTo: () => createRouteFromRouteElements(parentElements, newRouteElement),
            });
        });
    }
    return result;
}
