<template>
    <div class="notifications-card" id="notificationsCard">
        <div class="notifications-card-inner">
            <div class="notifications-card-info">
                <div class="notifications-card-header">
                    <div class="notifications-title">{{ title }}</div>
                    <div class="lighter-text-color">{{ date }}</div>
                </div>
                <div>{{ notification.text }}</div>
            </div>
            <div class="notifications-card-action">
                <i v-if="action" @click="onAction" :class="iconClass"></i>
                <i v-else @click="onMarkAsRead" :class="iconClass"></i>
            </div>
        </div>
        <q-resize-observer :debounce="0" @resize="onResize" />
    </div>
</template>

<script setup lang="ts">
import { getDateString } from "@/shared/time/convert";
import { computed } from "vue";
import { Notifications } from "../models/notifications.model";
import { getUiLanguage } from "@/shared/services/providers/language-provider";
import { useI18n } from "vue-i18n";
const { t } = useI18n();

const props = defineProps<{
    notification: Notifications;
    action: boolean;
}>();

const emit = defineEmits<{
    (e: "onAction", notification: Notifications): void;
    (e: "onMarkAsRead", notification: Notifications): void;
    (e: "notificationsCardHeight", height: number): void;
    (e: "setNotificationsCardWidth", width: number): void;
}>();

const notificationsCardMargin = computed(() => {
    const notificationsCard = document.getElementById("notificationsCard");
    if (!notificationsCard) {
        return undefined;
    }
    const notificationsCardStyles = window.getComputedStyle(notificationsCard);
    return {
        marginLeft: parseInt(notificationsCardStyles.getPropertyValue("margin-left")),
        marginRight: parseInt(notificationsCardStyles.getPropertyValue("margin-right")),
        marginTop: parseInt(notificationsCardStyles.getPropertyValue("margin-top")),
        marginBottom: parseInt(notificationsCardStyles.getPropertyValue("margin-bottom")),
    };
});

const onAction = () => {
    emit("onAction", props.notification);
};

const onMarkAsRead = () => {
    emit("onMarkAsRead", props.notification);
};

function onResize(size: { width: number; height: number }) {
    if (!notificationsCardMargin.value) {
        return;
    }
    emit(
        "setNotificationsCardWidth",
        size.width + notificationsCardMargin.value.marginRight + notificationsCardMargin.value.marginLeft
    );
    emit(
        "notificationsCardHeight",
        size.height + notificationsCardMargin.value.marginTop + notificationsCardMargin.value.marginBottom
    );
}

const date = computed(() => {
    return getDateString(props.notification.creationDate, getUiLanguage());
});

const titleForType: Record<string, string> = {
    newPublicNote: t("core.New note"),
    newComment: t("core.New comment"),
    noteHasBeenApproved: t("core.Note approved"),
    newPendingNote: t("core.Pending approval"),
};

const iconForType: Record<string, string> = {
    newPublicNote: "fa-regular fa-circle-arrow-up info-color",
};

const title = computed(() => {
    return titleForType[props.notification.notificationType];
});

const iconClass = computed(() => {
    return `action-icon  ${iconForType[props.notification.notificationType] ?? "fa-regular fa-check info-color"}`;
});
</script>

<style lang="scss" scoped>
.notifications-card-header {
    display: flex;
}
.notifications-card {
    margin: $spacing-l;
    background-color: $light-background-color;
    border-radius: $default-border-radius;
}
.notifications-title {
    padding-right: $spacing-l;
    padding-bottom: $spacing-m;
    font-weight: $bold;
}
.notifications-card-inner {
    padding: #{$spacing-m + $spacing-s};
    display: flex;
}
.notifications-card-info {
    width: 250px;
}
.notifications-card-action {
    padding-left: $spacing-xxl;
}

.action-icon {
    cursor: pointer;
}
</style>
