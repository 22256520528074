import { useFacetsQuery } from "@/shared/services/graphql/generated/consumer-graph-types";
import { computed } from "vue";
import { getDataQueryLanguages } from "@/shared/services/providers/language-provider";
import { DisplayType, Facet } from "@/shared/facets/facets.model";

export const useFacets = (facetCollectionId: string) => {
    const { result, loading } = useFacetsQuery(
        computed(() => {
            return {
                id: facetCollectionId,
                acceptedLanguages: getDataQueryLanguages(),
            };
        }),
        // TODO: mit Cache kommen teilweise Daten von anderen ids, siehe QIT-1685
        { fetchPolicy: "no-cache" }
    );

    const facets = computed(() => {
        const facetsResult: Facet[] =
            result.value?.facetsCollection?.facets?.map((facet) => {
                return {
                    id: facet?.referencedId!,
                    title: facet?.teasers?.title!,
                    type: facet?.type!,
                    displayOptions: {
                        isMultiSelect: facet?.display.type === DisplayType.multiSelect,
                        isModal: !!facet?.display.options?.modal,
                        isTree: facet?.display.options?.visualization === DisplayType.tree,
                        alwaysVisible: !!facet?.display.options?.alwaysVisible,
                    },
                    facetType: facet?.definition?.fieldType!,
                    referencedId: facet?.referencedId!,
                };
            }) ?? [];

        return facetsResult;
    });

    return { facets, loading };
};
