<template>
    <InfinityScroll @load-more="onLoadMore">
        <div class="column gap">
            <router-link :to="getPath(item.id)" v-for="(item, index) in activities" :key="item.id">
                <ActivityListCard
                    :activity="item"
                    :index="index"
                    @toggle-collection-overlay="toggleCollectionOverlay"
                />
            </router-link>
        </div>
    </InfinityScroll>
</template>

<script setup lang="ts">
import ActivityListCard from "@/abilities/activities/components/activity-list-card.vue";
import { CommonRouteAliases } from "@/shared/environment/common-route-aliases";
import { ActivityResult } from "@/abilities/activities/models/activity.model";
import { SearchContext } from "@/shared/search/search.types";
import { useActivityRoute } from "@/shared/router/activity-route";
import InfinityScroll from "@/shared/components/scroll/infinity-scroll.vue";

export interface ActivitiesListProps {
    activities: ActivityResult[];
    searchContext?: SearchContext;
}

const props = defineProps<ActivitiesListProps>();

const emit = defineEmits<{
    toggleCollectionOverlay: [activity: ActivityResult];
    loadMore: [done: (stop: boolean) => void];
}>();

const { calculatePathForActivitiesSubalias } = useActivityRoute();

const getPath = (activityId: string) => {
    return calculatePathForActivitiesSubalias(CommonRouteAliases.activityOverview, activityId, props.searchContext);
};

const toggleCollectionOverlay = (activity: ActivityResult) => {
    emit("toggleCollectionOverlay", activity);
};

const onLoadMore = (done: (stop: boolean) => void) => {
    emit("loadMore", done);
};
</script>
