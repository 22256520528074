import {
    ContentEdge,
    useRelatedContentsForArticleQuery,
} from "@/shared/services/graphql/generated/consumer-graph-types";
import { computed } from "vue";
import { mapContentEdgesToDocuments } from "../../mapper/content-edges.mapper.ts";
import { RelatedDocumentsQuery } from "./related-documents-query-factory";

export function useRelatedDocumentsForArticle(articleId: string, count: number): RelatedDocumentsQuery {
    const {
        loading,
        error,
        result,
        fetchMore: fetchMoreContent,
    } = useRelatedContentsForArticleQuery({ articleId, first: count });

    const docs = computed(() => {
        if (!result?.value?.article?.relatedContents.contents) return undefined;

        return mapContentEdgesToDocuments(result.value.article.relatedContents.contents as ContentEdge[]);
    });

    const total = computed(() => {
        if (!result?.value?.article?.relatedContents) return undefined;

        return result.value.article.relatedContents.total?.count;
    });

    const fetchMore = (cursor: string, moreCount: number) => {
        return fetchMoreContent({ variables: { articleId, first: moreCount, after: cursor } });
    };

    return {
        loading,
        error,
        docs,
        total,
        fetchMore,
    };
}
