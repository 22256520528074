import {
    ArticleCollectionEntryNode,
    CollectionEntryEdge,
} from "@/shared/services/graphql/generated/consumer-graph-types.ts";
import { CommonRouteAliases } from "@/shared/environment/common-route-aliases.ts";
import { ArticleCollectionEntry } from "@/abilities/collections/components/collection-entry.types.ts";
import { PropertyItem } from "@/shared/components/views/property-item.model.ts";
import { useAbilityRoute } from "@/shared/environment/ability-route.ts";

export const mapToArticleCollectionEntry = (
    entry: CollectionEntryEdge,
    getDataDisplayPropertiesByConfig: (graphqlResultObject: any) => PropertyItem[]
): ArticleCollectionEntry => {
    const { getPathToChildWithoutParents } = useAbilityRoute();

    const article = (entry.node as ArticleCollectionEntryNode).article;

    const returnArticle: ArticleCollectionEntry = {
        articleId: article.id,
        title: article.localizations?.title || article.id,
        cursor: entry.cursor,
        routeLocation: "",
        properties: getDataDisplayPropertiesByConfig(article),
        previewImageUrl: article.previewImage?.url ? (article.previewImage?.url as string) : undefined,
        __typename: "Article",
    };

    if (entry.node.navContext?.asset?.assetId || entry.node.navContext?.product?.id) {
        returnArticle.useContext = {
            assetId: entry.node.navContext?.asset?.assetId || undefined,
            productId: entry.node.navContext?.product?.id || undefined,
        };
    }

    //Common article
    return {
        ...returnArticle,
        routeLocation: getPathToChildWithoutParents(CommonRouteAliases.commonArticle, {
            [CommonRouteAliases.commonArticle]: article.id,
        }),
    };
};
