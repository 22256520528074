import { parse } from "./lucene-query-parser";
import { ParseNode } from "./lucene-query-parser-types";

export const parseQuery = (input: string, translation?: (x: string) => string): ParseNode => {
    try {
        return parse(input);
    } catch (e: any) {
        if (!translation) throw e.message;
        const expected = translation("core.Expected");
        const endOfInput = translation("core.end of input");
        const found = translation("core.found");
        const whitespace = translation("core.whitespace");
        const or = translation("core.or");
        const but = translation("core.but");
        const anyCharacter = translation("core.any character");

        throw `${e.message
            ?.replace("Expected", expected)
            ?.replace("end of input", endOfInput)
            ?.replace("found", found)
            ?.replace("whitespace", whitespace)
            ?.replace("or", or)
            ?.replace("but", but)
            ?.replace("any character", anyCharacter)}`;
    }
};

export const queryIsValid = (input: string) => {
    try {
        parseQuery(input);
        return true;
    } catch (e: any) {
        return false;
    }
};
