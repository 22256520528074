<script>
import { QBtn } from "quasar";

export default {
    name: "QitButton",
    extends: QBtn,
    props: {
        ripple: {
            type: Boolean,
            default: false,
        },
        noCaps: {
            type: Boolean,
            default: true,
        },
        noWrap: {
            type: Boolean,
            default: true,
        },
        unElevated: {
            type: Boolean,
            default: true,
        },
        color: {
            type: String,
            default: "primary",
        },
    },
    setup: QBtn.setup,
};
</script>
