import { provideApolloClient } from "@vue/apollo-composable";
import { consumerClient } from "@/shared/services/apollo-clients/consumer-client.ts";
import { GetConfigProductAndAssetDocument } from "@/shared/services/graphql/generated/consumer-graph-types.ts";

export interface ProductAndAssetSettings {
    assetsActivated: boolean;
}

/**
 * Async function evaluating if a assets are activated or not
 *
 * @returns a Promise with a boolean result if assets are activated or not
 */
export async function useProductAndAssetSettingsAsync(): Promise<ProductAndAssetSettings> {
    provideApolloClient(consumerClient);
    const result = await consumerClient.query({
        query: GetConfigProductAndAssetDocument,
        fetchPolicy: "cache-first",
    });

    return {
        assetsActivated: !result.data.config?.configProductAndAsset?.deactivateAssets,
    };
}
