import { ref, watchEffect } from "vue";
import { useUserSettings } from "@/shared/settings/user-settings";
import { SortEntry, SortOrderEntry } from "@/shared/components/sort-dropdown/sort-dropdown.model";

export type SortSaveKey =
    | "article_search_sort"
    | "article_sort"
    | "product_sort"
    | "product_search_sort"
    | "documentation_sort"
    | "asset_sort"
    | "asset_search_sort"
    | "activities_sort"
    | "mechanic_sort";

export const useSortSettings = (
    sortSaveKey: SortSaveKey,
    sortOrderEntries: SortOrderEntry[],
    initialSelectedSortEntry?: SortEntry
) => {
    const { data: savedSortEntry, update, loading } = useUserSettings<SortEntry>(sortSaveKey);
    //watch stop doesn't work in test
    const initialized = ref(false);
    const sortOrderEntry = ref<SortOrderEntry>();
    const isE2E = location.href.includes("playwright");

    watchEffect(() => {
        if (loading.value || initialized.value) return;
        try {
            if (savedSortEntry.value && !isE2E) {
                const sortEntry = sortOrderEntries.find(
                    (entry) =>
                        entry.value.field === savedSortEntry.value?.field &&
                        entry.value.order === savedSortEntry.value?.order
                );
                if (sortEntry) {
                    sortOrderEntry.value = sortEntry;
                    return;
                }
            }

            if (initialSelectedSortEntry) {
                const sortEntry = sortOrderEntries.find(
                    (entry) =>
                        entry.value.field === initialSelectedSortEntry?.field &&
                        entry.value.order === initialSelectedSortEntry?.order
                );
                if (sortEntry) {
                    sortOrderEntry.value = sortEntry;
                    return;
                }
            }

            const sortEntry = sortOrderEntries[0];
            if (sortEntry) {
                sortOrderEntry.value = sortEntry;
            }
        } finally {
            initialized.value = true;
        }
    });

    return { update, sortOrderEntry };
};
