<template>
    <div class="column first-side-navigation fit">
        <!-- mobile Header-->
        <div v-if="isXSmall && (!minified || isXSmall)" class="col-auto first-side-navigation-header bg-white">
            <div class="row full-width">
                <div class="col q-pa-sm">
                    <router-link :to="pathToRoot()" class="router-link">
                        <img @mousedown="logoClickedEasterEgg" class="logo" :src="themeLogoURL ?? QUANOS_LOGO" />
                    </router-link>
                    <div class="title text-weight-bold q-my-xs" v-if="themeDefinitions?.showTitle">
                        {{ themeDefinitions?.title }}
                    </div>
                </div>
                <div class="row q-pa-sm">
                    <q-icon
                        flat
                        round
                        class="cursor-pointer"
                        size="sm"
                        name="fa-regular fa-xmark icon-color"
                        data-qs="left-overlay-toggle-navigation-button"
                        @click="$emit('onMobileClose')"
                    />
                </div>
            </div>
        </div>
        <q-scroll-area
            class="col-grow first-side-navigation-container text-weight-bold"
            :horizontal-thumb-style="{ height: '0px' }"
            :horizontal-bar-style="{ height: '0px' }"
        >
            <q-list>
                <q-item
                    class="q-pa-lg"
                    v-show="item.visible"
                    clickable
                    v-for="item in navElements"
                    :key="item.index"
                    :href="item.externalLink"
                    :to="item.externalLink ?? item.path"
                    @click="handleMinifyEventNavigationClick(item)"
                    :target="item.target ?? '_self'"
                    :active="item.selected"
                    active-class="selected-menu-item"
                >
                    <q-item-section avatar>
                        <q-icon flat :name="item.icon" size="20px" />
                    </q-item-section>
                    <q-item-section v-if="item.title">
                        <span>{{ trans(item.title) }}</span>
                    </q-item-section>
                    <q-item-section v-else>
                        <span class="title-skeleton skeleton-square"></span>
                    </q-item-section>
                </q-item>
            </q-list>
        </q-scroll-area>
        <div class="col-auto">
            <div class="flex no-wrap" :class="{ 'justify-between': !minified && !isXSmall, column: isXSmall }">
                <div v-if="!minified" class="footer-text q-pa-sm">
                    <q-btn-dropdown
                        class="side-navigation-btn-dropdown bg-transparent"
                        data-qs="privacy-policy-dropdown-sidenavigation"
                        dense
                        no-caps
                        dropdown-icon="fa-solid fa-caret-down"
                        :ripple="false"
                        :label="$t('core.Privacy Policy')"
                    >
                        <q-list dense>
                            <q-item clickable v-close-popup>
                                <q-item-section>
                                    <a :href="privacyPolicyLink" target="_blank" class="no-underline">
                                        {{ $t("core.Privacy Policy Quanos") }}
                                    </a>
                                </q-item-section>
                            </q-item>
                            <q-item v-for="page in privacyStatements" :key="page.to as string" clickable v-close-popup>
                                <q-item-section>
                                    <router-link :to="page.to" class="no-underline">
                                        {{ page.title }}
                                    </router-link>
                                </q-item-section>
                            </q-item>
                        </q-list>
                    </q-btn-dropdown>
                </div>
                <q-icon
                    v-if="minified && !isXSmall"
                    flat
                    round
                    class="cursor-pointer q-mx-auto q-py-sm"
                    size="xs"
                    name="fa-regular fa-chevrons-right text-on-navbar-color"
                    @click="changeMinifyModeTo(false)"
                    data-qs="side-navigation-expand-btn"
                />
                <q-icon
                    v-if="!minified && !isXSmall"
                    flat
                    round
                    class="cursor-pointer q-pa-sm"
                    size="xs"
                    name="fa-regular fa-chevrons-left text-on-navbar-color"
                    @click="changeMinifyModeTo(true)"
                    data-qs="side-navigation-collapse-btn"
                />
                <q-btn-group
                    v-if="isXSmall"
                    class="footer-group no-border-radius"
                    color="transparent"
                    unelevated
                    spread
                >
                    <template v-for="(item, i) in userMenuItems">
                        <q-btn
                            :ripple="false"
                            v-if="(item.visible ?? true) && !('text' in item)"
                            color="transparent"
                            class="grey-hover q-px-xs"
                            style="min-width: fit-content"
                            :key="i"
                        >
                            <component :is="item.component.getComponent()" v-bind="item.component.props" />
                        </q-btn>
                    </template>
                </q-btn-group>
            </div>
        </div>
        <component v-if="dialogComponent" :is="dialogComponent.getComponent()" v-bind="dialogComponent.props" />
    </div>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { playgroundEasterEggClicked } from "@/shared/development/play-ground-easter-egg";
import { useTheme } from "@/shell/helpers/theme-definition";
import { DASHBOARD_ALIAS } from "@/abilities/dashboard/config/dashboard.ability";
import { useTextObjectTranslation } from "@/shared/i18n/translation-types";
import { useMenuItems } from "@/shell/composables/menu-items";
import { MenuEntry } from "@/shared/components/menu/menu.model";
import { ComponentDefinition } from "@/shared/components/component-definition.model";
import { useI18n } from "vue-i18n";
import { useStaticPages } from "@/shared/composables/static-pages";
import { useScreenSize } from "@/shared/screen/composables/screen-size";
import QUANOS_LOGO from "@/shared/assets/images/quanos.svg?url";
import { useAbilityRoute } from "@/shared/environment/ability-route";
import { useNavItems } from "@/shell/pages/side-navigation/composables/nav-items";
import { useMenuEntriesFromAbilities } from "@/shared/environment/menu/menu-entries-from-abilities";

defineProps<{
    minified?: boolean;
}>();

const emit = defineEmits<{
    (e: "onMinify", minify: boolean): void;
    (e: "onMobileClose"): void;
}>();

const { locale } = useI18n();
const { privacyStatements } = useStaticPages();
const menuItems = useMenuItems();

const PRIVACY_LINK_DE = "https://quanos.com/datenschutz/#c16108";
const PRIVACY_LINK_EN = "https://quanos.com/en/privacy-policy/#c16111";

const privacyPolicyLink = computed(() => {
    if (locale.value === "de") {
        return PRIVACY_LINK_DE;
    } else {
        return PRIVACY_LINK_EN;
    }
});

const { trans } = useTextObjectTranslation();
const { isXSmall } = useScreenSize();
const { themeDefinitions } = useTheme();
const { getPathToRoot } = useAbilityRoute();

const themeLogoURL = computed(() => {
    const themeLogoUrl = themeDefinitions.value?.logoURL;
    if (themeLogoUrl === "" || themeLogoUrl === undefined) {
        return undefined;
    } else {
        return themeLogoUrl;
    }
});
const { navElements } = useNavItems();

const dialogComponent = ref<ComponentDefinition | undefined>(undefined);

const { menuEntries: toolbarEntriesFromAbility } = useMenuEntriesFromAbilities(
    computed(() => {
        return {
            __type: "toolbarMenuDetail",
            callerContext: {
                notifyDone: () => {
                    dialogComponent.value = undefined;
                },
                showDialogComponent: (component: ComponentDefinition) => {
                    dialogComponent.value = component;
                },
            },
        };
    })
);

const userMenuItems = computed<MenuEntry[]>(() => {
    if (!isXSmall.value) {
        return [];
    }

    return [...menuItems.value, ...toolbarEntriesFromAbility.value].reverse();
});

function pathToRoot() {
    return getPathToRoot({});
}

function logoClickedEasterEgg() {
    playgroundEasterEggClicked();
}

function handleMinifyEventNavigationClick(item: any) {
    if (item?.alias) {
        if (item.alias === DASHBOARD_ALIAS) {
            changeMinifyModeTo(false);
        } else {
            changeMinifyModeTo(true);
        }
    }
}

function changeMinifyModeTo(isMiniMode: boolean) {
    emit("onMinify", isMiniMode);
}
</script>

<style scoped lang="scss">
.first-side-navigation {
    display: flex;
    background-color: var(--q-nav-bar-color);
    flex-direction: column;

    .screen--xs & {
        border: none;
    }

    &:deep(.q-list) {
        .q-item {
            transition: none;
            &:hover {
                &:not(.q-item--active) {
                    background-color: var(--q-nav-bar-color-dark) !important;
                }
                .q-focus-helper {
                    background: inherit;
                    color: inherit;
                }
            }
            .selected-menu-item:hover {
                background-color: $white !important;
            }
        }
    }
}

.first-side-navigation-header {
    border-bottom: 1px solid $default-border-color;

    .screen--xs & {
        display: flex !important;
    }
}

.first-side-navigation-container {
    color: var(--q-text-on-navbar-color-light);

    &:deep(a) {
        color: var(--q-text-on-navbar-color-light);
    }
    &:deep(.selected-menu-item) {
        background-color: var(--q-text-on-navbar-color);
        color: var(--q-nav-bar-color);
    }
}

.logo {
    max-height: 42px;
    min-height: 23px;
    width: 165px;
}
.title {
    color: $default-text-color;
    font-size: 1rem;
}
.title-skeleton {
    height: 15px;
}

.footer-text {
    color: var(--q-text-on-navbar-color-light);
    font-size: $smaller-font-size;
    cursor: pointer;
}

.footer-text:hover {
    text-decoration: underline;
}

.footer-group {
    background-color: rgba(0, 0, 0, 0.1);
}

.footer-toolbar-button:hover {
    div:hover {
        span {
            text-decoration: none !important;
        }
    }
}

.side-navigation-btn-dropdown {
    font-size: $smaller-font-size;
    &:deep(.q-btn__content) {
        color: var(--q-text-on-navbar-color-light) !important;
    }
}
</style>
