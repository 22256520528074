<template>
    <div class="form-container">
        <SanitizeHtml
            :content="
                $t(
                    'documentation.You dont need a process for importing documentation You can import documentation directly via Import - Overview_html'
                )
            "
            tag="div"
        />
        <div class="form-label">
            {{ $t("documentation.Configure data fields") }}
        </div>
        <SanitizeHtml
            :content="
                $t(
                    'documentation.If the import fails, you will probably need to modify data fields You can see, create and adjust data fields in the Administration area under Information Model'
                )
            "
            tag="div"
        />
        <router-link :to="getAdminMetadataPath()" target="_blank" class="router-link">
            <QitButton type="default" :label="$t('documentation.Configure data fields')" class="btn" />
        </router-link>
    </div>
</template>

<script setup lang="ts">
import SanitizeHtml from "@/shared/components/sanitize/sanitize-html.vue";
import QitButton from "@/shared/components/buttons/qit-button.vue";

function getAdminMetadataPath() {
    return "/admin/metadata";
}
</script>

<style lang="scss" scoped>
.form-container {
    padding: $spacing-xl;
    display: flex;
    flex-direction: column;
    gap: $spacing-xxl;
}

.form-label {
    margin-bottom: $spacing-s;
    font-weight: $semi-bold;
}
.btn {
    width: 250px;
}
</style>
