import { useLazyQuery } from "@vue/apollo-composable";
import {
    generateRelatedActivitiesQuery,
    getActivitiesQuery,
    RelatedActivitiesContext,
} from "@/abilities/activities/graphql/activity.query";
import { computed, ref, watch, watchEffect } from "vue";
import { generateQueryFilterForSearch } from "@/shared/facets/graphql/content-filter-generator";
import { SearchParams } from "@/shared/search/search.types";
import { getDataQueryLanguages } from "@/shared/services/providers/language-provider";
import { useSearchConfig } from "@/shared/search/search-config";
import { DataDisplayConfigId } from "@/shared/data-display-config/composable/data-display.model";
import { useHasAccess } from "@/shared/access-control/composables/use-has-access.ts";
import { AccessFeature } from "@/shared/access-control/access-control.ts";

export const useActivitiesSearch = ({ selected, searchPhrase, sort, searchContext }: SearchParams) => {
    const COUNT = 20;

    const context = ref<RelatedActivitiesContext>();
    const { hasAccess: fuzzy } = useHasAccess({ featureID: AccessFeature.fuzzy_search });

    watch(
        () => searchContext?.value,
        () => {
            context.value = undefined;
            if (searchContext?.value?.asset) context.value = "asset";
            else if (searchContext?.value?.article) context.value = "article";
            else if (searchContext?.value?.product) context.value = "product";
        },
        { immediate: true }
    );

    //first fetch search configuration for activities selection
    const {
        result: activitiesSearchConfigResult,
        loading: activitiesSearchConfigLoading,
        getDataDisplayPropertiesByConfig,
    } = useSearchConfig(DataDisplayConfigId.activitiesSelection);

    const variables = computed(() => {
        const { contentFilter } = generateQueryFilterForSearch(
            selected.value,
            fuzzy.value,
            searchContext?.value,
            activitiesSearchConfigResult.value?.searchEntries,
            searchPhrase?.value
        );

        const variables = {
            filter: contentFilter,
            acceptedLanguages: getDataQueryLanguages(),
            first: COUNT,
            after: "",
            sort: sort?.value,
        };

        if (!searchContext?.value) return variables;

        let id;

        if (searchContext?.value?.asset) id = `${searchContext?.value?.product}_${searchContext?.value.asset}`;
        else if (searchContext?.value.article) id = searchContext?.value.article;
        else if (searchContext?.value.product) id = searchContext?.value.product;

        return { id, ...variables };
    });

    const query = computed(() => {
        if (!context.value)
            return getActivitiesQuery(
                activitiesSearchConfigResult.value?.datafieldsFragment.datafields ?? {},
                activitiesSearchConfigResult.value?.datafieldsFragment.localizationDatafields ?? {}
            );

        return generateRelatedActivitiesQuery(
            context.value,
            activitiesSearchConfigResult.value?.datafieldsFragment.datafields ?? {},
            activitiesSearchConfigResult.value?.datafieldsFragment.localizationDatafields ?? {}
        );
    });

    const {
        loading: searchLoading,
        error: searchError,
        result: lazyQueryResult,
        fetchMore,
        load,
    } = useLazyQuery(query, variables);

    const stop = watchEffect(() => {
        if (!activitiesSearchConfigResult.value || !sort?.value) return;

        stop();
        load();
    });

    const loading = computed(() => !sort?.value || searchLoading.value || activitiesSearchConfigLoading.value);

    const error = computed(() => {
        return searchError.value;
    });

    const result = computed(() => {
        if (!lazyQueryResult.value) return null;

        let activities = [];
        let total;

        if (context.value && lazyQueryResult.value[context.value]) {
            activities = lazyQueryResult.value[context.value].relatedActivities.activities;
            total = lazyQueryResult.value[context.value].relatedActivities.total;
        } else if (lazyQueryResult.value.activities) {
            activities = lazyQueryResult.value.activities.activities;
            total = lazyQueryResult.value.activities.total;
        }

        return {
            activities,
            total,
        };
    });

    const searchFields = computed(() => {
        return activitiesSearchConfigResult.value?.searchEntries ?? [];
    });

    return {
        result,
        searchFields,
        loading,
        error,
        fetchMore,
        getDataDisplayPropertiesByConfig,
    };
};
