import { SortEntry } from "@/shared/components/sort-dropdown/sort-dropdown.model";
import { FacetSelected } from "@/shared/facets/facets.model";
import { Ref, ref } from "vue";

export function useSelectedFacets() {
    const selected = ref<FacetSelected[]>([]);
    const sorting: Ref<SortEntry | undefined> = ref();

    const selectionChanged = (selectedFacets: FacetSelected[]) => {
        selected.value = selectedFacets;
    };

    const onChangeSortOrder = (selectedItem: SortEntry) => {
        sorting.value = { field: selectedItem.field, order: selectedItem.order };
    };

    return {
        selected,
        sorting,
        selectionChanged,
        onChangeSortOrder,
    };
}
