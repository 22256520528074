import { CommonSearchTabAliases } from "@/shared/environment/common-route-aliases";
import { ComputedRef } from "vue";
import gql from "graphql-tag";

/**
 * Generates a query block based on the provided name, filter, and condition.
 * @param name - The name of the query block.
 * @param filter - The filter variable name.
 * @param condition - The condition to determine if the query block should be included.
 * @returns The generated query block.
 */
const generateQueryBlock = (name: string, filter: string | null, condition: boolean) =>
    condition
        ? `
    ${name}(query: $query${filter ? `, filter: $${filter}` : ""}) {
        query, hits { suggest }
    }`
        : "";

/**
 * Generates the GraphQL query for search suggestions based on the provided search tab aliases.
 * @param searchTabsAliases - ComputedRef containing the search tab aliases.
 * @returns The constructed GraphQL query.
 */
export const generateSearchSuggestionQuery = (searchTabsAliases: ComputedRef<CommonSearchTabAliases[]>) => {
    // Determine if the search tab aliases are empty
    const searchTabsEmpty = searchTabsAliases.value.length === 0;

    // Determine which search tab aliases are present
    // The search tab aliases are used to determine which search suggestions to fetch
    const hasProducts = searchTabsAliases.value.includes(CommonSearchTabAliases.products) || searchTabsEmpty;
    const hasAssets = searchTabsAliases.value.includes(CommonSearchTabAliases.assets);
    const hasCommonArticles = searchTabsAliases.value.includes(CommonSearchTabAliases.commonArticles) && hasProducts;
    const hasMechanicArticles = searchTabsAliases.value.includes(CommonSearchTabAliases.commonArticles) && !hasProducts;
    const hasDocumentations = searchTabsAliases.value.includes(CommonSearchTabAliases.documentations);
    const hasActivities = searchTabsAliases.value.includes(CommonSearchTabAliases.activities);

    // Construct the GraphQL query using gql template literal
    // The query includes the autocomplete query for each search suggestion type
    // The order of the query blocks is based on the search tab aliases
    const query = gql`
query autocomplete(
    $query: String
    ${hasAssets ? "$assetFilter: AssetFilter" : ""}
    ${hasMechanicArticles ? "$mechanicFilter: MechanicFilter" : ""}
) {
    ${generateQueryBlock("productAutocomplete", null, hasProducts)}
    ${generateQueryBlock("assetAutocomplete", "assetFilter", hasAssets)}
    ${generateQueryBlock("articleAutocomplete", null, hasCommonArticles)}
    ${generateQueryBlock("mechanicAutocomplete", "mechanicFilter", hasMechanicArticles)}
    ${generateQueryBlock("contentAutocomplete", null, hasDocumentations)}
    ${generateQueryBlock("activityAutocomplete", null, hasActivities)}
}`;

    // Return the constructed query
    return query;
};
