<template>
    <div v-if="isWidgetVisible">
        <h4>{{ $t("mechanic.Connected content") }}</h4>
        <div class="content-wrapper">
            <div class="linked-articles-nav-header" v-if="secondLayer">
                <div
                    data-qs="linked-articles-go-back-to-linked-content"
                    class="icon-button tabs-header-back-button"
                    @click="secondLayer = false"
                >
                    <i class="fa-regular fa-arrow-left" />
                </div>
                <div class="header-layer-name">
                    <strong>{{ $t("mechanic.Back to linked content") }}</strong>
                </div>
            </div>
            <div class="container">
                <div v-if="secondLayer" class="item">
                    <MechanicInstallationLocationTile
                        v-for="(path, index) in mechanicPaths"
                        :key="index"
                        :product="widgetContext?.product ?? ''"
                        :asset="widgetContext?.asset"
                        :mechanic-path="path"
                    />
                </div>
                <div v-else-if="articles.length > 0" class="item">
                    <div v-for="article in articles" :key="article?.node.id" class="article-items">
                        <LinkedArticlesBoxItem
                            :product="widgetContext?.product"
                            :asset="widgetContext?.asset"
                            :article="article"
                            @second-level-click="onMechanicPathShow"
                        />
                    </div>
                </div>
                <div v-else-if="mechanicArticles.length > 0" class="item">
                    <div v-for="article in mechanicArticles" :key="article?.node.id" class="article-items">
                        <LinkedArticlesBoxItem
                            :product="widgetContext?.product"
                            :asset="widgetContext?.asset"
                            :article="article"
                            @second-level-click="onMechanicPathShow"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { onMounted, computed, ref, watch } from "vue";
import LinkedArticlesBoxItem from "./linked-articles-box-item.vue";
import { DocumentDetailContext } from "@/shared/environment/widgets/widget-context";
import { getDataQueryLanguages } from "@/shared/services/providers/language-provider";
import {
    ArticleFilter,
    MechanicEdge,
    MechanicFilter,
    MechanicPath,
} from "@/shared/services/graphql/generated/consumer-graph-types";
import { ArticleEdge } from "@/shared/services/graphql/generated/consumer-graph-types";
import { useLazyQuery } from "@vue/apollo-composable";
import { createArticlesQuery } from "../../graphql/articles.query";
import MechanicInstallationLocationTile from "../components/mechanic-installation-location-tile.vue";
import { createMechanicPartsListQuery } from "../../graphql/mechanics-partslist.query";
import { loadMore } from "@/shared/components/scroll/lazy-loading-fetch";

const props = defineProps<{
    widgetContext: DocumentDetailContext;
}>();

watch(
    () => props.widgetContext.loadMoreTrigger,
    () => {
        if (props.widgetContext.loadMoreTrigger) {
            onLoadMore(props.widgetContext.loadMoreTrigger);
        }
    },
    { deep: true }
);

const secondLayer = ref(false);

const linkedArticleNumbers = computed<Array<string>>(() => {
    return props.widgetContext.linkedArticles;
});

const articleFilter = computed(() => {
    if (!linkedArticleNumbers.value) return {};
    const equalArray: ArticleFilter[] = [];
    linkedArticleNumbers.value.forEach((articleNumber) => {
        equalArray.push({
            equals: {
                articleNumber: articleNumber,
            },
        });
    });
    return equalArray.length > 0 ? { orGroup: equalArray } : {};
});

const mechanicArticleFilter = computed(() => {
    if (!linkedArticleNumbers.value) return {};
    const equalArray: MechanicFilter[] = [];
    linkedArticleNumbers.value.forEach((articleNumber) => {
        equalArray.push({
            equals: {
                articleNumber: articleNumber,
            },
        });
    });
    return equalArray.length > 0 ? { orGroup: equalArray } : {};
});

const {
    result: articlesResult,
    load: loadArticles,
    fetchMore: fetchMoreArticles,
} = useLazyQuery(
    createArticlesQuery({ withPreviewImages: true }),
    computed(() => {
        return {
            filter: articleFilter.value,
            acceptedLanguages: getDataQueryLanguages(),
        };
    })
);

const {
    result: mechanicArticlesResult,
    load: loadMechanicArticles,
    fetchMore: fetchMoreMechanicArticles,
} = useLazyQuery(
    createMechanicPartsListQuery({ withPreviewImages: true }),
    computed(() => {
        return {
            filter: mechanicArticleFilter.value,
            productId: props.widgetContext.product,
            assetId: props.widgetContext.asset,
            acceptedLanguages: getDataQueryLanguages(),
        };
    })
);

const articles = computed(() => {
    if (!articlesResult.value) {
        return [];
    }
    return articlesResult.value.articles.articles as ArticleEdge[];
});

const mechanicArticles = computed(() => {
    if (!mechanicArticlesResult.value) {
        return [];
    }
    const result: MechanicEdge[] = [];
    const articleIds: string[] = [];
    mechanicArticlesResult.value.mechanics.mechanics.forEach((edge: MechanicEdge) => {
        const articleId = edge.node.articleId ?? "";
        if (articleId && !articleIds.includes(articleId)) {
            result.push(edge);
        }
        articleIds.push(articleId);
    });
    return result;
});

const isWidgetVisible = computed(() => {
    return articles.value.length > 0 || mechanicArticles.value.length > 0;
});

const mechanicPaths = ref<MechanicPath[]>([]);

function onMechanicPathShow(paths: MechanicPath[]) {
    mechanicPaths.value = paths;
    secondLayer.value = true;
}

const loadMoreCount = 20;

const onLoadMore = async (callBackAfterLoaded: (hasMoreElements: boolean) => void) => {
    if (props.widgetContext.product) {
        if (!mechanicArticles.value) {
            callBackAfterLoaded(true);
            return;
        }

        const mechanicEdge = mechanicArticlesResult.value.mechanics.mechanics as MechanicEdge[];
        const lastContent = mechanicEdge[mechanicEdge.length - 1];

        if (!lastContent || !lastContent.cursor) {
            callBackAfterLoaded(false);
            return;
        }

        const data = await loadMore(fetchMoreMechanicArticles, lastContent.cursor, loadMoreCount);

        const isAtEnd = data?.data?.mechanics.mechanics.length === 0;
        callBackAfterLoaded(!isAtEnd);
    } else {
        if (!articles.value) {
            callBackAfterLoaded(true);
            return;
        }

        const articlesEdge = articlesResult.value.articles.articles as ArticleEdge[];
        const lastContent = articlesEdge[articlesEdge.length - 1];

        if (!lastContent || !lastContent.cursor) {
            callBackAfterLoaded(false);
            return;
        }

        const data = await loadMore(fetchMoreArticles, lastContent.cursor, loadMoreCount);

        const isAtEnd = data?.data?.articles.articles.length === 0;
        callBackAfterLoaded(!isAtEnd);
    }
};

onMounted(() => {
    if ((props.widgetContext.linkedArticles?.length || 0) === 0) return;

    if (props.widgetContext.product) {
        loadMechanicArticles();
    } else {
        loadArticles();
    }
});
</script>

<style lang="scss" scoped>
.content-wrapper {
    .linked-articles-nav-header {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-bottom: $spacing-m;
        padding-right: $spacing-l;

        .tabs-header-back-button {
            margin-right: $spacing-m;
            padding: $spacing-m;
            background: $light-background-color;
            &:hover {
                background: $grey-80;
            }
            &:active {
                background: $grey-60;
            }
        }
    }

    .header-layer-name {
        font-size: 1.2em;
        flex-grow: 2;
    }

    .container {
        display: flex;
        flex-direction: row;
        align-items: stretch;
        height: 100%;
    }

    .item {
        flex: auto;
        position: relative;
    }

    .article-items:not(:last-child) {
        margin-bottom: $spacing-m;
    }
}
</style>
