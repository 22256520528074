<template>
    <Dialog :visible="visible" :title="$t('core.Reset current link')" @on-close="emits('onClose')">
        <template #content>
            <div class="q-mb-md">
                {{
                    $t(
                        "core.Resetting invalidates the current link and thus disconnects the previously connected devices"
                    )
                }}
                <br />
                {{ $t("core.A new link must be created so that you can reconnect your devices") }}
            </div>
            <q-separator class="q-mb-md" />
            <div class="row flex-end">
                <qit-button color="secondary" class="q-mr-sm" :label="$t('core.Cancel')" @click="emits('onClose')" />
                <qit-button color="destructive" :label="$t('core.Reset link')" @click="emits('onResetLink')" />
            </div>
        </template>
    </Dialog>
</template>

<script setup lang="ts">
import Dialog from "@/shared/components/dialog/dialog.vue";
import QitButton from "@/shared/components/buttons/qit-button.vue";

defineProps<{ visible: boolean }>();

const emits = defineEmits<{ onClose: []; onResetLink: [] }>();
</script>
