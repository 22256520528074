<template>
    <div v-if="advancedProduct" class="product-link-container qit-property-values">
        {{ advancedProduct.getTitle() }}
    </div>
</template>

<script setup lang="ts">
import { computed, nextTick, onMounted, watch } from "vue";
import { getDataQueryLanguages } from "@/shared/services/providers/language-provider";
import { useProductQuery } from "@/shared/services/graphql/generated/consumer-graph-types";
import { advProduct, AdvProduct } from "../../../data-objects/advanced-product";

const props = defineProps<{
    product: string;
}>();

const emit = defineEmits<{
    (e: "breadcrumbLoaded"): void;
}>();

const { result } = useProductQuery(
    {
        id: props.product,
        acceptedLanguages: getDataQueryLanguages(),
    },
    {
        fetchPolicy: "cache-first",
    }
);

const advancedProduct = computed(() => {
    return result.value?.product ? advProduct(result.value?.product as AdvProduct) : null;
});

async function emitBreadcrumbLoaded() {
    if (advancedProduct.value) {
        await nextTick();
        emit("breadcrumbLoaded");
    }
}

watch(advancedProduct, async () => {
    emitBreadcrumbLoaded();
});

onMounted(async () => {
    // Im Falle, dass das Result schon gecached ist
    emitBreadcrumbLoaded();
});
</script>
