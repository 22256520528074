import { RouteElementWithParam } from "@/shared/environment/ability.types";
import { CommonRouteAliases } from "@/shared/environment/common-route-aliases";
import { useActivityRoute } from "@/shared/router/activity-route";
import { getRouteElementParamsAsComponentProps } from "@/shared/environment/ability-route-helper";

export function useActivityBreadcrumb(
    parentElements: Array<RouteElementWithParam>,
    currentElement: RouteElementWithParam,
    translateMethod: (text: string) => string
) {
    const { calculatePathForActivitiesSubalias } = useActivityRoute();

    const items = [
        {
            content: {},
            getRouterTo: () =>
                calculatePathForActivitiesSubalias(
                    CommonRouteAliases.activityOverview,
                    currentElement.namedParams.activityId,
                    {}
                ),
        },
    ];

    if (currentElement.alias === CommonRouteAliases.activityDetail)
        items.unshift({
            content: { simpleText: translateMethod("activities.Overview") },
            getRouterTo: () => {
                const context = getRouteElementParamsAsComponentProps(parentElements);
                return calculatePathForActivitiesSubalias(
                    CommonRouteAliases.activityOverview,
                    currentElement.namedParams.activityId,
                    context
                );
            },
        });
    return items;
}
