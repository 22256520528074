import { RouteLocationRaw } from "vue-router";

export const CURRENT_LOCATION = "currentLocation";
export const FURTHER_LOCATIONS = "furtherLocations";

export interface InstallationLocationItem {
    locationKey: string;
    titleKey: string;
    icon: string;
    textKey: string;
    buttonTextKey: string;
    productId: string;
    assetId: string | undefined;
    getButtonRoute?: () => RouteLocationRaw | undefined;
    buttonClickEvent?: () => void | undefined;
    isActive: boolean;
}

function createItems(): Array<InstallationLocationItem> {
    return [
        {
            locationKey: CURRENT_LOCATION,
            titleKey: "mechanic.Show installation location",
            icon: "fa-regular fa-code-branch icon-light-color fa-rotate-90 fa-2xl",
            textKey: "mechanic.Shows information about the assembly in which the article is located",
            buttonTextKey: "mechanic.Open installation location",
            productId: "",
            assetId: undefined,
            isActive: true,
        },
        {
            locationKey: FURTHER_LOCATIONS,
            titleKey: "mechanic.Where Else Is the Article Located?",
            icon: "fa-regular fa-code-branch icon-light-color fa-rotate-90 fa-2xl",
            textKey: "mechanic.Shows all other installation locations of the article",
            buttonTextKey: "mechanic.Show all locations",
            productId: "",
            assetId: undefined,
            isActive: true,
        },
        // { Bilderkennung temporär deaktivieren
        //     identifyPartsItemKey: IMAGE_RECOGNITION,
        //     titleKey: "mechanic.Image recognition",
        //     icon: "fa-regular fa-camera icon-light-color",
        //     textKey: "mechanic.Identify the required component by photographing the nameplate or QR code with a mobile device",
        //     buttonTextKey: "mechanic.Photograph component",
        //     isActive: false,
        // },
    ];
}

let items: Array<InstallationLocationItem> | null = null;

export function getLocationItems() {
    if (items === null) {
        items = createItems();
    }
    return items;
}
