<template>
    <div class="dialog-content-wrapper">
        <div class="dialog-icon-content">
            <i :class="[icon, 'fa-2xl']" />
        </div>
        <div class="dialog-content-slim full-width q-pa-sm" :class="[isXSmall ? 'column items-start' : 'row']">
            <div class="col q-pr-md full-width" :style="{ 'min-width': '150px' }">
                {{ text }}
            </div>
            <div class="q-py-xs q-pr-xs col-auto" :class="[{ 'full-width': isXSmall }]">
                <q-btn
                    class="col"
                    data-qs="select-installation-location-button"
                    color="primary"
                    :label="buttonText"
                    @click="dialogButtonClick"
                    :no-caps="true"
                    :no-wrap="false"
                />
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { useScreenSize } from "@/shared/screen/composables/screen-size";

const emit = defineEmits<{
    (e: "onButtonClick"): void;
}>();

defineProps<{
    icon: string;
    text: string;
    buttonText: string;
}>();

const { isXSmall } = useScreenSize();

/**
 * Emits Button click.
 */
function dialogButtonClick() {
    emit("onButtonClick");
}
</script>

<style lang="scss" scoped>
.dialog-content-wrapper {
    display: flex;
    flex-direction: row;
    border-radius: $default-border-radius;
    overflow: hidden;
    background-color: $light-warning-color;

    .dialog-content-slim {
        > div {
            align-self: center;
        }
    }

    .dialog-icon-content {
        max-width: 200px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        border-top-left-radius: $default-border-radius;
        border-bottom-left-radius: $default-border-radius;

        & i {
            margin: $spacing-xl;
        }
    }
}
</style>
