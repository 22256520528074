<template>
    <div class="form-wrapper">
        <div v-if="processDatafieldMappingsLoading" class="fixed-center loading">
            <q-spinner-ios size="2em" />
        </div>
        <div class="col form-content" v-else>
            <div v-if="assignErrorMandatorySystemFieldsWarning" class="q-mx-lg q-my-sm text-weight-bold">
                <i class="fa-regular fa-light fa-brake-warning error-color fa-lg" />
                {{
                    t(
                        "core.The system fields level or assemblyId with parentAssemblyId map hierarchies in the mechanics Therefore you cannot use these hierarchies in parallel Either remove the mapping for the level system field or for the assemblyId and parentAssemblyId system fields"
                    )
                }}
            </div>
            <div v-if="duplicateFieldAssignmentWarning" class="q-mx-lg q-my-sm text-weight-bold">
                <i class="fa-regular fa-light fa-brake-warning error-color fa-lg" />
                {{
                    t(
                        "core.One or more data fields are assigned multiple times Please assign a unique mapping to the data fields via the administration"
                    )
                }}
            </div>
            <div v-if="assignErrorMandatorySystemFieldsInfo" class="q-mx-lg q-my-sm text-weight-bold">
                <i class="fa-regular fa-regular fa-circle-exclamation info-color fa-lg" />
                {{
                    t(
                        "core.The system fields level or assemblyId with parentAssemblyId map hierarchies in the mechanics Please assign either the system field level or the system fields assemblyId and parentAssemblyId to the corresponding columns of your mechanics"
                    )
                }}
            </div>
            <div v-if="systemFieldRows.length > 0" class="form-row">
                <div class="form-label">
                    {{ $t("core.System Fields") }}
                </div>
                <div class="form-label">
                    <div class="data-source-container">
                        <Table
                            data-qs="assign-step-table-systemfields"
                            class="assign-step-table"
                            :q-data-source="qDataSourceSystem"
                            :custom-cells="['columnId', 'assign-icon', 'data-field', 'specification']"
                            :bordered="false"
                            row-key="columnId"
                            no-select
                            no-hover
                            :desktop-only="true"
                            :has-shadow="false"
                        >
                            <template #customCell="{ row }">
                                <q-td key="columnId" :row="row">
                                    <div class="q-px-xs q-py-sm">
                                        <span v-if="row.columnId" class="simple-text">
                                            {{ row.columnId }}
                                        </span>
                                        <WarningBox v-else :height="MIN_ROW_HEIGHT - 20 - 1" />
                                    </div>
                                </q-td>
                                <q-td key="assign-icon" :row="row">
                                    <div class="q-px-xs q-py-sm">
                                        <RightArrowGreen v-if="row.columnId && row.infoTwinTitle" />
                                        <RightArrowGrey v-else-if="row.columnId" />
                                        <RightArrowYellow v-else />
                                    </div>
                                </q-td>
                                <q-td key="data-field" :row="row">
                                    <div class="q-px-xs q-py-sm">
                                        <span class="simple-text">{{ row.infoTwinTitle }}</span>
                                    </div>
                                </q-td>
                                <q-td class="text-center" key="specification" :row="row">
                                    <span
                                        v-if="['numberRange', 'dateTimeRange'].includes(row.fieldType ?? '')"
                                        class="simple-text"
                                    >
                                        {{ showFromTo(row.operator ?? "") }}
                                    </span>
                                    <span v-else class="simple-text">
                                        {{ row.language }}
                                    </span>
                                </q-td>
                            </template>
                        </Table>
                    </div>
                </div>
            </div>
            <div v-if="otherFieldsRows.length > 0" class="form-row">
                <div class="form-label">
                    {{ $t("core.Other Data Fields") }}
                </div>
                <div class="form-label">
                    <div class="data-source-container">
                        <Table
                            data-qs="assign-step-table-otherfields"
                            class="assign-step-table"
                            :q-data-source="qDataSourceCustom"
                            :custom-cells="['columnId', 'assign-icon', 'data-field', 'specification']"
                            :bordered="false"
                            row-key="columnId"
                            no-select
                            no-hover
                            :desktop-only="true"
                            :has-shadow="false"
                        >
                            <template #customCell="{ row }">
                                <q-td key="columnId" :row="row">
                                    <div class="q-px-xs q-py-sm">
                                        <span v-if="row.columnId" class="simple-text">
                                            {{ row.columnId }}
                                        </span>
                                        <WarningBox v-else :height="MIN_ROW_HEIGHT - 20 - 1" />
                                    </div>
                                </q-td>
                                <q-td key="assign-icon" :row="row">
                                    <div class="q-px-xs q-py-sm">
                                        <RightArrowGreen v-if="row.columnId && row.infoTwinTitle" />
                                        <RightArrowGrey v-else-if="row.columnId" />
                                        <RightArrowYellow v-else />
                                    </div>
                                </q-td>
                                <q-td key="data-field" :row="row">
                                    <div class="q-px-xs q-py-sm">
                                        <span class="simple-text">{{ row.infoTwinTitle }}</span>
                                    </div>
                                </q-td>
                                <q-td class="text-center" key="specification" :row="row">
                                    <span
                                        v-if="['numberRange', 'dateTimeRange'].includes(row.fieldType ?? '')"
                                        class="simple-text"
                                    >
                                        {{ showFromTo(row.operator ?? "") }}
                                    </span>
                                    <span v-else class="simple-text">
                                        {{ row.language }}
                                    </span>
                                </q-td>
                            </template>
                        </Table>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-auto form-row-manage-fields q-pa-md">
            <div class="row form-label" :class="{ column: isXSmall }">
                <div class="col">
                    <router-link to="/admin/metadata" target="_blank">
                        <QitBtn
                            :label="$t('product-and-asset.Manage data fields')"
                            icon="fa-regular fa-pen text-on-primary-color"
                        />
                    </router-link>
                </div>
                <div class="col flex items-center" :class="{ 'q-mt-xs': isXSmall }">
                    <div
                        class="primary-color bold no-user-select"
                        data-qs="assign-step-refresh-datafields-button"
                        style="cursor: pointer"
                        @click="refreshDatafields"
                    >
                        <i class="fa-solid fa-arrows-rotate q-mr-xs" />
                        <span>{{ $t("product-and-asset.Refresh view") }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
/**
 * The 2nd step for the product-import-wizard, that assigns the mapping of example file headers to the desired
 * headers for the process configuration.
 * @displayName AssignStep
 * @example `
 *      Used in product-import-wizard.model.
 *      const formModels: FormModel<CommonStepModel & AssignStepModel>[] = [
 *          {
 *              ...
 *              formComponentDefinition: {
 *                  getComponent: () => CommonStep,
 *              },
 *              ...
 *          }, { ... }]
 * `
 */
import { computed, ComputedRef, PropType, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import QitBtn from "@/shared/components/buttons/qit-button.vue";
import RightArrowGreen from "@/shared/components/data-process/arrow-components/right-arrow-green.vue";
import RightArrowYellow from "@/shared/components/data-process/arrow-components/right-arrow-yellow.vue";
import RightArrowGrey from "@/shared/components/data-process/arrow-components/right-arrow-grey.vue";
import WarningBox from "@/shared/components/data-process/row-field-components/warning-box.vue";
import { WizardStep } from "@/shared/components/data-process/form-types";
import { DatafieldDefinition, ProcessType } from "@/shared/services/graphql/generated/consumer-graph-types";
import { getDataQueryLanguages } from "@/shared/services/providers/language-provider";
import Table from "@/shared/components/table/table.vue";
import { QDataSource } from "@/shared/components/table/q-data-source";
import { QTableProps } from "quasar";
import { useScreenSize } from "@/shared/screen/composables/screen-size";
import {
    useAssignStepModel,
    useProcessDatafieldMappings,
    useProcessDefinitionsAndMetadataDefinitions,
} from "./assign-step.model";

interface RowDefinition {
    columnId: string;
    infoTwinTitle: string;
    referencedId?: string;
    fieldType?: string;
    operator?: string;
    language?: string;
}

const props = defineProps({
    /**
     * Form Model as two way binded prop.
     */
    modelValue: {
        type: Array as PropType<WizardStep<any>[]>,
        required: true,
    },
    /**
     * Defines the processType for the metadata query.
     * @example ProcessType.product
     */
    processType: {
        type: String as PropType<ProcessType>,
        required: true,
    },
    /**
     * If true, shows only systemFields.
     */
    showOnlySystemFields: {
        type: Boolean,
        default: false,
    },
    entryId: {
        type: String,
        default: "",
    },
});

const emit = defineEmits(["update:modelValue", "onFormValidation"]);

emit("onFormValidation", true); // initially set validation to true

const MIN_ROW_HEIGHT = 57; // row Height for datagrid

const { isXSmall } = useScreenSize();
const { t } = useI18n();
const languages = computed(() => getDataQueryLanguages());

const { columns, showFromTo } = useAssignStepModel();
const { getProcessDefinitions, metaDataDefinitions, refetchMetadataDefinitions } =
    useProcessDefinitionsAndMetadataDefinitions(props.processType, languages);
const { processDatafieldMappingsResult, processDatafieldMappingsLoading, restartProcessDatafieldMappings } =
    useProcessDatafieldMappings(props.processType, languages, props.modelValue);

//TODO: Irgendwie in Abilities auslagern?
const processDefinitions = ref<(DatafieldDefinition | undefined)[]>();

const dataValues = ref();

const allMandatoryFieldsExist = ref(true);
const assignErrorMandatorySystemFieldsWarning = ref(false);
const assignErrorMandatorySystemFieldsInfo = ref(false);
const duplicateFieldAssignmentWarning = ref(false);
const waitForProcessDatafieldMappingsResult = ref(false);

const systemFieldRows = computed(() => dataValues.value?.systemFieldRows ?? []);
const otherFieldsRows = computed(() => dataValues.value?.otherFieldRows ?? []);

const qDataSourceSystem: ComputedRef<QDataSource> = computed(() => {
    return {
        columns: columns.value as QTableProps["columns"],
        rows: systemFieldRows.value,
    };
});

const qDataSourceCustom: ComputedRef<QDataSource> = computed(() => {
    return {
        columns: columns.value as QTableProps["columns"],
        rows: otherFieldsRows.value,
    };
});

function updateFieldRowsWithMappedData() {
    if (waitForProcessDatafieldMappingsResult.value) {
        return;
    }
    const systemFieldRows: RowDefinition[] = [];
    const otherFieldRows: RowDefinition[] = [];

    processDatafieldMappingsResult.value?.processDatafieldMappings?.forEach((mapping) => {
        const dataSourceRow = {
            referencedId: mapping?.field?.referencedId ?? "",
            columnId: mapping?.column ?? "",
            type: mapping?.field?.type ?? "",
            fieldType: mapping?.field?.fieldType ?? "",
            infoTwinTitle: mapping?.field?.localizations?.title ?? "",
            operator: (mapping as any).operator ?? "",
            language: (mapping as any).language?.toUpperCase() ?? "",
        };

        if (mapping?.field?.type === "field") {
            systemFieldRows.push(dataSourceRow);
        } else if (!props.showOnlySystemFields) {
            otherFieldRows.push(dataSourceRow);
        }
    });

    // 1st step is finding the `mappedNamesInProcessDefinitions`
    let mappedNamesInProcessDefinitions: string[] = [];
    processDefinitions.value?.forEach((metadataDefinition) => {
        if (!metadataDefinition) {
            return;
        }
        const mappedNameForMetaDefinition: string[] = [];
        metadataDefinition.mappedNames?.forEach((mappedName: string) => {
            if (systemFieldRows.find((row) => row.columnId === mappedName)) {
                mappedNameForMetaDefinition.push(mappedName);
            }
        });

        mappedNamesInProcessDefinitions = [...mappedNamesInProcessDefinitions, ...mappedNameForMetaDefinition];
    });

    if (props.processType === ProcessType.mechanic) {
        const systemFieldNamesFromMappedNames = mappedNamesInProcessDefinitions.map(
            (name) =>
                metaDataDefinitions.value?.fieldDefinitions.fieldDefinitions.find((definition) =>
                    definition?.node.mappedNames?.includes(name)
                )?.node.id
        );
        const hasSystemLevel =
            systemFieldNamesFromMappedNames.includes("system_level") ||
            systemFieldNamesFromMappedNames.includes("custom_level");
        const hasSystemAssemblyId = systemFieldNamesFromMappedNames.includes("system_assemblyId");
        const hasSystemParentAssemblyId = systemFieldNamesFromMappedNames.includes("system_parentAssemblyId");

        if (hasSystemLevel && (hasSystemParentAssemblyId || (hasSystemAssemblyId && !hasSystemParentAssemblyId))) {
            assignErrorMandatorySystemFieldsWarning.value = true;
        }

        if (!(hasSystemLevel || hasSystemAssemblyId || hasSystemParentAssemblyId)) {
            assignErrorMandatorySystemFieldsInfo.value = true;
        }

        if ((!hasSystemLevel && hasSystemParentAssemblyId) || (!hasSystemLevel && hasSystemAssemblyId)) {
            processDefinitions.value = processDefinitions.value?.filter(
                (definition) => definition?.id === "system_assemblyId" || definition?.id === "system_parentAssemblyId"
            );
        } else {
            if (hasSystemLevel) {
                processDefinitions.value = processDefinitions.value?.filter(
                    (definition) => definition?.id === "system_level"
                );
            }
        }
    }

    // 2nd step is to find mandatory fields that don't exist after modifying processDefinitions to match the needed ones
    mappedNamesInProcessDefinitions = [];
    processDefinitions.value?.forEach((metadataDefinition) => {
        if (!metadataDefinition) {
            return;
        }
        const mappedNameForMetaDefinition: string[] = [];
        metadataDefinition.mappedNames?.forEach((mappedName: string) => {
            if (systemFieldRows.find((row) => row.columnId === mappedName)) {
                mappedNameForMetaDefinition.push(mappedName);
            }
        });

        if (mappedNameForMetaDefinition.length == 0) {
            // create yellow row in system fields data-grid to show that mappedName is not found
            systemFieldRows.push({
                referencedId: mappedNameForMetaDefinition[0],
                columnId: "",
                infoTwinTitle: metadataDefinition.localizations?.title ?? "",
            });
            allMandatoryFieldsExist.value = false;
        }
        mappedNamesInProcessDefinitions = [...mappedNamesInProcessDefinitions, ...mappedNameForMetaDefinition];
    });

    allMandatoryFieldsExist.value =
        processDefinitions.value?.length === mappedNamesInProcessDefinitions.length &&
        !assignErrorMandatorySystemFieldsWarning.value &&
        !assignErrorMandatorySystemFieldsInfo.value;

    duplicateFieldAssignmentWarning.value =
        processDatafieldMappingsResult.value?.processDatafieldMappings?.some(
            (mapping) => mapping?.ambiguousColumns !== undefined && mapping.ambiguousColumns?.length
        ) ?? false;

    dataValues.value = {
        systemFieldRows,
        otherFieldRows,
    };
}

watch([processDatafieldMappingsResult, metaDataDefinitions], () => {
    if (processDefinitions.value?.every((def) => def === undefined)) {
        processDefinitions.value = getProcessDefinitions();
    }
    if (processDatafieldMappingsResult.value && metaDataDefinitions.value) {
        processDefinitions.value = getProcessDefinitions();
        updateFieldRowsWithMappedData();
    }
});

watch(processDatafieldMappingsResult, () => {
    if (waitForProcessDatafieldMappingsResult.value) {
        waitForProcessDatafieldMappingsResult.value = false;
        updateFieldRowsWithMappedData();
        emit("onFormValidation", allMandatoryFieldsExist.value && !duplicateFieldAssignmentWarning.value);
    }
});

watch([allMandatoryFieldsExist, duplicateFieldAssignmentWarning], () => {
    emit("onFormValidation", allMandatoryFieldsExist.value && !duplicateFieldAssignmentWarning.value);
});

function refreshDatafields() {
    setTimeout(() => {
        allMandatoryFieldsExist.value = false;
        assignErrorMandatorySystemFieldsWarning.value = false;
        assignErrorMandatorySystemFieldsInfo.value = false;
        waitForProcessDatafieldMappingsResult.value = true;
        refetchMetadataDefinitions();
        restartProcessDatafieldMappings();
    }, 500);
}
</script>

<style lang="scss" scoped>
.data-source-container:deep(.data-grid-cell) {
    height: $minimum-row-height;
}

.form-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;

    .loading {
        background: white;
        display: flex;
        width: 100%;
        height: 100%;
        z-index: 99;
        justify-content: center;
        align-items: center;
    }

    .form-row-manage-fields {
        padding: $spacing-m;
    }
}

.form-content {
    overflow-y: auto;
}

.simple-text {
    font-weight: $regular-font-weight;
}

.assign-step-table {
    &:deep(.dynamic-sticky-table) {
        tr,
        td {
            height: 45px;
            padding: 0;
        }
    }
}
</style>
