import { computed, ref, Ref, watchEffect } from "vue";
import {
    useCreateOrUpdateUserSettingsMutation,
    useUserSettingsByKeysQuery,
} from "@/shared/services/graphql/generated/consumer-graph-types";
import { useLocalStorage } from "@vueuse/core";
import { useHasAccess } from "@/shared/access-control/composables/use-has-access";
import { AccessAction, AccessResource } from "@/shared/access-control/access-control";

interface UserSettingsResult<T> {
    update: (value: T | undefined) => Promise<void>;
    loading: Ref<boolean>;
    data: Ref<T | undefined>;
}

export const useUserSettings = <T>(key: string): UserSettingsResult<T> => {
    const { hasAccess: canWriteServerSettingSettings, loading: loadingCanWriteUserSetting } = useHasAccess({
        resource: AccessResource.userSettings,
        action: AccessAction.write,
    });

    const loading = ref(true);
    const loadingUserSettings = ref(true);

    const localStorageSettings = useLocalStorage(key, "");

    const { result, refetch, onResult } = useUserSettingsByKeysQuery(
        { keys: [key] },
        computed(() => ({ enabled: canWriteServerSettingSettings.value }))
    );

    onResult(({ data }) => {
        if (!data) return;
        //loading does not work, because of enabled parameter
        loadingUserSettings.value = false;
    });

    const data = ref<T>();

    const { mutate } = useCreateOrUpdateUserSettingsMutation({});

    watchEffect(() => {
        if (loadingCanWriteUserSetting.value) return undefined;

        //localstorage fallback
        if (!canWriteServerSettingSettings.value) {
            if ((localStorageSettings as Ref).value) {
                try {
                    data.value = JSON.parse((localStorageSettings as Ref).value);
                    return;
                } catch (e) {
                    console.error(e);
                    data.value = undefined;
                    return;
                } finally {
                    loading.value = false;
                }
            }
            loading.value = false;
            data.value = undefined;
            return;
        }

        if (loadingUserSettings.value) {
            return;
        }

        const userServerSetting = result.value?.getUserSettings?.settings;

        if (!userServerSetting) {
            data.value = undefined;
            loading.value = false;
            return;
        }

        try {
            data.value = JSON.parse(userServerSetting[key]);
        } catch (e) {
            console.error(e);
            data.value = undefined;
            return;
        } finally {
            loading.value = false;
        }
    });

    const update = async (value: T | undefined) => {
        if (canWriteServerSettingSettings.value) {
            loading.value = true;
            if (value === undefined) {
                await mutate({ diff: { settings: { [key]: undefined } } });
            } else {
                await mutate({ diff: { settings: { [key]: JSON.stringify(value) } } });
            }
            await refetch();
        } else {
            loading.value = true;
            (localStorageSettings as Ref).value = JSON.stringify(value);
        }
    };

    return { data, update, loading };
};
