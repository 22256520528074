import {
    ContentEdge,
    useRelatedContentsForProductQuery,
} from "@/shared/services/graphql/generated/consumer-graph-types";
import { computed } from "vue";
import { RelatedDocumentsQuery } from "./related-documents-query-factory";
import { mapContentEdgesToDocuments } from "../../mapper/content-edges.mapper.ts";

export function useRelatedDocumentsForProduct(productId: string, count: number): RelatedDocumentsQuery {
    const {
        loading,
        error,
        result,
        fetchMore: fetchMoreContent,
    } = useRelatedContentsForProductQuery({ productId, first: count });

    const docs = computed(() => {
        if (!result?.value?.product?.relatedContents.contents) return undefined;

        return mapContentEdgesToDocuments(result.value.product.relatedContents.contents as ContentEdge[]);
    });

    const total = computed(() => {
        if (!result?.value?.product?.relatedContents) return undefined;

        return result.value.product.relatedContents.total?.count;
    });

    const fetchMore = (cursor: string, moreCount: number) => {
        return fetchMoreContent({ variables: { productId, first: moreCount, after: cursor } });
    };

    return {
        loading,
        error,
        docs,
        total,
        fetchMore,
    };
}
