<template>
    <div class="identify-part-item-container row">
        <div class="identify-part-item-content col q-mx-md">
            <p class="identify-part-item-icon">
                <i :class="identifyPartsItem.icon" />
            </p>
            <div>
                <h3 class="identify-part-item-header">{{ $t(identifyPartsItem.titleKey) }}</h3>
                <p>{{ $t(identifyPartsItem.textKey) }}</p>
            </div>
        </div>
        <router-link
            class="router-link identify-part-button-content col"
            v-if="identifyPartsItem.isActive && identifyPartsItem.getButtonRoute"
            :to="identifyPartsItem.getButtonRoute()"
        >
            <QitBtn
                color="secondary"
                data-qs="identify-part-item-button"
                :label="$t(identifyPartsItem.buttonTextKey)"
                class="identify-part-item-button"
                :disabled="!identifyPartsItem.isActive"
            />
        </router-link>
        <div
            v-else-if="identifyPartsItem.buttonClickEvent"
            class="identify-part-button-content"
            :class="{ 'col-auto': isSmall || isXSmall, col: !isSmall && !isXSmall }"
            style="width: 100%"
        >
            <QitBtn
                color="secondary"
                data-qs="identify-part-item-button"
                :label="$t(identifyPartsItem.buttonTextKey)"
                class="identify-part-item-button"
                :disabled="!identifyPartsItem.isActive"
                @click="identifyPartsItem.buttonClickEvent()"
            />
        </div>
    </div>
</template>

<script setup lang="ts">
import { IdentifyPartsItem } from "@/abilities/mechanic/pages/widgets/identify-part.model";
import QitBtn from "@/shared/components/buttons/qit-button.vue";
import { useScreenSize } from "@/shared/screen/composables/screen-size";

defineProps<{
    identifyPartsItem: IdentifyPartsItem;
}>();

const { isXSmall, isSmall } = useScreenSize();
</script>

<style lang="scss" scoped>
.identify-part-item-content {
    display: flex;
    flex: 1 auto;
    p {
        margin: 0;
        .screen--sm &,
        .screen--xs & {
            margin-bottom: $spacing-m;
        }
    }
}

.identify-part-item-header {
    font-size: 1.14em;
    margin-top: 0;
    margin-bottom: $spacing-m;
}

.identify-part-item-icon i {
    margin-right: $spacing-l;
    display: flex;
    align-items: center;
    width: $icon-xl;
    height: 100%;
}

.identify-part-button-content {
    padding: $spacing-s;
    .screen--md &,
    .screen--xl &,
    .screen--lg & {
        align-self: self-end;
    }
    .screen--xs & {
        button {
            width: 100%;
        }
    }
    .screen--sm & {
        button {
            width: 50%;
        }
    }
}
</style>
