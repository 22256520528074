/**
 * Klasse, die beim LazyLoading verwendet wird um aus einem Source-Array die Result (meist Componenten) zu erzeugen.
 * Die erzeugten Componenten werden zwischen gespeichert, so dass sie nicht neu erzeugt werden, wenn das Element schon im Source-Array drin war.
 */
export class LazyLoadingDataSource<TSourceType, TResultType> {
    private results: Array<TResultType> = [];
    private sourceValues: Array<TSourceType> = [];
    private cachedResultItems: Map<TSourceType, TResultType> = new Map();

    /**
     *
     * @param transformMethod callback, der aus dem Source den Result erzeugt
     */
    constructor(private transformMethod: (source: TSourceType) => TResultType | undefined) {}

    public getResults() {
        return this.results;
    }

    public setSourceValues(sourceValues: Array<TSourceType>) {
        this.sourceValues = sourceValues;
        this.updateResults();
    }

    public addSourceValues(newValues: Array<TSourceType>) {
        this.sourceValues = this.sourceValues.concat(newValues);
        this.updateResults();
    }

    public clear() {
        this.setSourceValues([]);
    }

    private updateResults() {
        this.results = this.createResultList();
    }

    private createResultList(): Array<TResultType> {
        const result: Array<TResultType> = [];
        const validSources: Set<TSourceType> = new Set();

        for (const sourceItem of this.sourceValues) {
            // aktuell gültige Source-Elemente merken und in dem Cache nachsehen, ob der Result schon vorhanden ist
            validSources.add(sourceItem);
            let resultItem = this.cachedResultItems.get(sourceItem);

            if (!resultItem) {
                // Neuen, fehlenden Result erzeugen
                resultItem = this.transformMethod(sourceItem);
                if (resultItem) {
                    this.cachedResultItems.set(sourceItem, resultItem);
                }
            }

            if (resultItem) {
                result.push(resultItem);
            }
        }

        // Aus den createdResultItems alle Elemente löschen, die aktuell nicht mehr benötigt werden
        const keys = this.cachedResultItems.keys();
        for (const value of keys) {
            if (!validSources.has(value)) {
                this.cachedResultItems.delete(value);
            }
        }
        return result;
    }
}
