<!-- 
    Wrapper über q-infinite-scroll für die Verwendung mit LazyLoading.
            
    Verwendung:
        <LazyLoadScrollView reach-bottom-text="Loading Assets" @loadMore="onLoadMore">
            <div>
                Content, der in onLoadMore erweitert wird
            </div>
        </LazyLoadScrollView>

        // Die Methode on LoadMore muss am Ende den übergebenen callBack callBackAfterLoaded aufrufen,
        // Damit die Scrollbox aktualisiert wird und ein weiteres loadMore getriggert werden kann 
        // Wenn noch mehr zu laden ist muss der callBack den Parameter true erhalten
        const onLoadMore = (callBackAfterLoaded: (hasMoreElements: boolean) => void) => {
            Prüfe z.B. ob man am Ende ist
            if (isEnde) {
                callBackAfterLoaded(false);
                return;
            }

            async ladeDasZeug().then(() => {
                // Nachdem Ende des asynchronen Ladeteils muss der callback aufgerufen werden
                callBackAfterLoaded(true);
            });

        };
 -->
<template>
    <div ref="scrollTargetRef" class="fit" style="overflow: auto">
        <q-infinite-scroll :scroll-target="scrollTargetRef" @load="fireLoadMore">
            <slot />
            <template #loading>
                <div class="row loading q-pa-md">
                    <q-spinner-ios size="2em" />
                    <div class="q-pl-sm">{{ reachBottomText }}</div>
                </div>
            </template>
        </q-infinite-scroll>
    </div>
</template>

<script setup lang="ts">
import { ref } from "vue";

withDefaults(
    defineProps<{
        reachBottomText?: string;
    }>(),
    {
        reachBottomText: "",
    }
);

const emit = defineEmits<{
    (e: "loadMore", moreElements: (hasMoreElements: boolean) => void): void;
}>();

const scrollTargetRef = ref<string | Element | undefined>(undefined);

function fireLoadMore(_index: number, done: (stop?: boolean | undefined) => void) {
    emit("loadMore", (hasMoreElements: boolean) => {
        done(!hasMoreElements);
    });
}
</script>

<style lang="scss" scoped>
.loading {
    justify-content: center;
    align-items: center;
}
</style>
