import { computed } from "vue";
import { Facet } from "@/shared/facets/facets.model";
import { ActivitySortFields } from "@/shared/services/graphql/generated/admin-graph-types";
import { generateQueryFilterForSearch } from "@/shared/facets/graphql/content-filter-generator";
import { generateAggregationsFragment } from "@/shared/aggregations/aggegregations-generator";
import { gql } from "graphql-tag";
import { consumerClient } from "@/shared/services/apollo-clients/consumer-client";
import { convertAggregationsResult } from "@/shared/aggregations/aggregations-resolver";
import { useHasAccess } from "@/shared/access-control/composables/use-has-access.ts";
import { AccessFeature } from "@/shared/access-control/access-control.ts";
import { AggregationParams } from "@/shared/aggregations/aggregations.types.ts";

export const useActivitiesAggregations = ({
    selectedFacets,
    facet,
    searchFields,
    searchContext,
    searchPhrase,
}: AggregationParams) => {
    const { hasAccess: fuzzy } = useHasAccess({ featureID: AccessFeature.fuzzy_search });

    const variables = computed(() => {
        //remove own facet from selected
        const selectedCopy = [...selectedFacets.value.filter((x) => x.referencedId !== facet.value?.referencedId)];
        const { contentFilter } = generateQueryFilterForSearch(
            selectedCopy,
            fuzzy.value,
            searchContext?.value,
            searchFields?.value,
            searchPhrase?.value
        );

        const sort = [
            {
                field: ActivitySortFields.creationDate,
                order: "desc",
            },
        ];

        if (!searchContext?.value) return { filter: contentFilter, sort };

        let id;

        if (searchContext?.value.asset) id = `${searchContext.value.product}_${searchContext.value.asset}`;
        else if (searchContext?.value.article) id = searchContext.value.article;
        else if (searchContext?.value.product) id = searchContext.value.product;

        return { id, filter: contentFilter, sort };
    });

    const getActivitiesAggregationsQuery = (facet: Facet | undefined, keywordSearchValue: string = "") => {
        const aggregationsFragment = facet ? generateAggregationsFragment(facet, true, keywordSearchValue) : "";

        return gql`query activitiesWithAggregation($filter: ActivityFilter) {
             activities(filter: $filter) {
                 activities {       
                    node { 
                        id
                    }                                              
                 }
                 ${aggregationsFragment}  
             }
         }
     `;
    };

    const fetchAggregation = async (searchPhrase: string): Promise<Record<string, number>> => {
        if (!facet.value) return {};

        const aggregationResult = await consumerClient.query({
            query: getActivitiesAggregationsQuery(facet.value, searchPhrase),
            variables: variables.value,
        });

        const convertedAggregations = convertAggregationsResult(
            facet.value,
            aggregationResult.data["activities"].aggregations
        );

        return convertedAggregations[facet.value.id];
    };

    return {
        fetchAggregation,
    };
};
