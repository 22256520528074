<!--

    Komponente für die Darstellung von Breadcrumbs.
    Man muss items als props übergeben. Diese kann man auch als ref definieren um sie dynamisch zu ändern in der
    Laufzeit der Komponente.

    props:
        - items: Array<BreadcrumbItem>
            Beispiel:
            [
                {
                    content: {
                        simpleText: "Product",
                    },
                    onRouterTo: () => { return { name: "/seite"} }
                    },
                },
                {
                    content: {
                        simpleText: "Asset",
                    },
                    onRouterTo: () => { return { name: "/seite"} }
                }
            ];

-->
<template>
    <div
        v-if="items.length > 0"
        class="breadcrumb-wrapper full-width"
        :class="{ 'breadcrumbs-skeleton': somethingIsLoading, 'mobile-background q-my-xs': isXSmall }"
    >
        <ol class="breadcrumb" ref="breadCrumbRef">
            <li v-for="item in bItems" :key="item.uniqueId" class="breadcrumb-item">
                <template v-if="item.items">
                    <q-btn-dropdown label="..." class="q-px-none" :ripple="false">
                        <router-link
                            v-for="(data, i) in item.items"
                            :key="i"
                            :to="data.getRouterTo()"
                            class="fold-item router-link"
                        >
                            <div v-if="data.content.componentDefinition">
                                <component
                                    :is="data.content.componentDefinition.getComponent()"
                                    v-bind="data.content.componentDefinition.props"
                                />
                            </div>
                            <div v-else-if="data.content.simpleText" class="simple-string-item">
                                {{ data.content.simpleText }}
                            </div>
                        </router-link>
                    </q-btn-dropdown>
                    <i class="fa-regular fa-chevron-right default-text-color" />
                </template>
                <template v-else>
                    <router-link :to="item.getRouterTo()" class="router-link">
                        <div v-if="item.content.componentDefinition">
                            <component
                                :is="item.content.componentDefinition.getComponent()"
                                v-bind="item.content.componentDefinition.props"
                                @breadcrumb-loaded="onBreadcrumbLoaded(item.uniqueId)"
                            />
                        </div>
                        <div v-else-if="item.content.simpleText" class="simple-string-item">
                            {{ item.content.simpleText }}
                        </div>
                    </router-link>
                    <i class="fa-regular fa-chevron-right default-text-color" />
                </template>
            </li>
        </ol>
        <q-resize-observer @resize="onResize"></q-resize-observer>
    </div>
</template>

<script setup lang="ts">
import { computed, nextTick, onMounted, ref, watch } from "vue";
import { BreadcrumbItem, InnerItem } from "./breadcrumbs.model";
import { nanoid } from "nanoid";
import { useScreenSize } from "@/shared/screen/composables/screen-size";

const props = defineProps<{
    items: BreadcrumbItem[];
    loading: boolean;
}>();

const breadcrumbItemsLoaded = ref<Record<string, boolean>>({});

// Die BreadcrumbItems müssen 'loaded' emitten. So erkennt die
// Breadcrumbcomponente, dass das BreadcrumbItems geladen ist und ihre Breite stimmt
function onBreadcrumbLoaded(uniqueId: string) {
    breadcrumbItemsLoaded.value[uniqueId] = true;
}

const bItems = ref<InnerItem[]>([]);

const allBreadcrumbItemsLoaded = computed(() => {
    let result = true;
    bItems.value.forEach((item) => {
        if (!item.items?.length) {
            if (item.content.componentDefinition) {
                if (!breadcrumbItemsLoaded.value[item.uniqueId]) {
                    // Das item ist eine Componente und im breadcrumbItemsLoaded-Record ist für die Id noch kein LadeEmit gekommen
                    result = false;
                }
            }
        }
    });
    return result;
});

const somethingIsLoading = computed(() => !allBreadcrumbItemsLoaded.value || props.loading);

watch(allBreadcrumbItemsLoaded, async () => {
    updateItems();
});

watch(
    () => props.loading,
    async () => {
        updateItems();
    }
);

const breadCrumbRef = ref<HTMLDivElement | null>(null);

function createBItems() {
    return [...props.items].map((element) => {
        return { ...element, uniqueId: nanoid() };
    });
}

const extraWidth = computed((): number => {
    // Die Extrabreite muss 2x links und rechts aufaddiert werden für den richtigen Umbruchpunkt
    if (breadCrumbRef.value) {
        let result = undefined;
        const breadcrumbEl: any = window.getComputedStyle(breadCrumbRef.value, null);
        const breadcrumbElPadding = breadcrumbEl.getPropertyValue("padding");
        const navigator: any = window.navigator;
        if (breadcrumbElPadding) {
            result = parseInt(breadcrumbElPadding) * 2;
        } else if (navigator.userAgent.indexOf("Firefox") > -1) {
            result = parseInt(breadcrumbEl["MozPaddingEnd"]) * 2;
        }
        if (result) {
            return result;
        } else {
            return 16;
        }
    } else {
        return 0;
    }
});

// Items die in einem aufklapparen Menü gespeichert werden
const menuItems: InnerItem[] = [];

const { isXSmall } = useScreenSize();
const currentWidth = ref(0);

async function onResize(value: { width: number }) {
    currentWidth.value = value.width;
    updateItems();
}

let recursionCounter = 0;

async function updateItems() {
    if (currentWidth.value === 0) {
        return;
    }
    if (props.loading || !allBreadcrumbItemsLoaded.value) {
        return;
    }
    if (recursionCounter > 0) {
        // Updateitems darf nicht rekursiv aufgerufen werden.
        // Das ist dann ein Programmierfehler weil z.B. mehrere Watches das updateItem triggern und sich dann die Methode im 'await nextTick()' selbst überholt
        // Vom Aufrufer muss das verhindert werden
        console.warn("Recursive call of updateItems in breadcrumbs.vue");
        // Falls in der produktiven Version doch so ein Fehler drin sein sollte, dann soll nicht gleich ein harter Fehler angezeigt werden,
        // sondern es wird nach 2 Sekunden einfach nochmal aufgerufen, so dass die Breadcrumbs richtig dargestellt werden.
        // Das ist aber nur ein Workaround, falls sich so ein Fehler doch einmal eingeschlichen hat.
        setTimeout(updateItems, 2000);
        return;
    }

    recursionCounter++;

    //Erstmal alle Items die eingeklappt sind aus dem eingeklappten Menü wieder in die normalen Breadcrumbs
    while (menuItems.length > 0) {
        // menuItems werden wieder in die Breadcrumb items geschoben
        if (bItems.value.length < 1) {
            bItems.value.push(menuItems[0]);
            menuItems.shift();
        } else {
            if (menuItems.length < 2) {
                bItems.value.splice(1, 1, menuItems[menuItems.length - 1]);
            } else {
                bItems.value.splice(2, 0, menuItems[menuItems.length - 1]);
            }
            menuItems.pop();
        }
    }
    await nextTick();
    let liWidthSum = getLiWidthSumValue();

    /**
     * Verkleinerungsschleife:
     *
     * solange die breadcrumb Breite größer als die Breite der Div Komponente
     */
    while (bItems.value.length > 2 && liWidthSum + extraWidth.value > currentWidth.value) {
        let itemIndex = 0;
        if (menuItems.length > 0) {
            itemIndex = 1;
        }

        /**
         * das herauszuziehende Item aus den Breadcrumbs als Object Klon zu den MenuItems
         */
        const pullOutItem: InnerItem = { ...bItems.value[1 + itemIndex] };
        menuItems.push(pullOutItem);

        /**
         * das `dotItem` ist ein Object Klon des 2. Breadcrumbs von links gesehen,
         * die zwischengespeicherten items `menuItems` werden zugewiesen und die onClick funktion wird für das
         * ... - Breadcrumb item entfernt
         */
        const dotItem: InnerItem = { ...bItems.value[1] };
        dotItem.items = menuItems;
        dotItem.getRouterTo = () => {
            return { name: "" };
        };

        // das veränderte geklonte breadcrumb item dotItem wird an 2. Stelle oder an die 3. Stelle
        bItems.value.splice(1, 1, dotItem);
        if (menuItems.length > 1) {
            bItems.value.splice(1 + itemIndex, 1);
        }
        await nextTick();
        liWidthSum = getLiWidthSumValue();
    }
    recursionCounter--;
}

function initializeItems() {
    menuItems.length = 0;
    bItems.value = createBItems();
}

function getLiWidthSumValue(): number {
    if (breadCrumbRef.value) {
        return [...breadCrumbRef.value.children].reduce((pv, cv) => {
            return pv + cv.clientWidth;
        }, 0);
    } else return 0;
}

watch(
    () => props.items,
    async () => {
        initializeItems();
        await nextTick();
        updateItems();
    }
);

onMounted(() => {
    initializeItems();
});
</script>

<style lang="scss" scoped>
@mixin skeleton-background {
    display: flex;
    content: "";
    height: $icon-m;
    pointer-events: none;
    border-radius: $skeleton-border-radius;
}

.breadcrumb-wrapper {
    &.breadcrumbs-skeleton {
        ol {
            @include skeleton-background;
            width: 70%;
            background: $grey-90;
            z-index: 9999;
        }

        ol li {
            display: none;
        }
    }
}

.mobile-background:before {
    background: $white !important;
}

.breadcrumb {
    display: flex;
    margin: 0;
    padding-left: 0;
    margin-bottom: 0;
    align-items: center;

    .screen--xs & {
        margin-bottom: 0;
    }

    .breadcrumb-item {
        display: flex;
        white-space: nowrap;

        &:deep(.q-btn-dropdown__arrow-container) {
            display: none;
        }
    }
}

.breadcrumb-item {
    padding-right: calc(#{$spacing-m} + 1px);

    .dot-item {
        padding-left: $spacing-s;
        padding-right: $spacing-s;

        &:hover {
            cursor: pointer;
        }
    }

    i {
        display: flex;
        align-items: center;
        margin-left: $spacing-m;
        width: $chevron-height;
        height: auto;
        background-position: center !important;
    }
}

.simple-string-item {
    &:hover {
        text-decoration: underline;
        cursor: pointer;
    }
}

.fold-item {
    padding: $spacing-m $spacing-l;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
</style>
