export const PRODUCT_REFERENCED_ID = "custom_products";
export const FACET_DELIMITER = "|";

export function facetValuesToUrlParam(facetValues: string[]): string {
    //if facet value contains facet-delimiter, escape facet-delimiter in value
    const values = facetValues.map((facetValue) => {
        return facetValue.includes(FACET_DELIMITER)
            ? facetValue.replaceAll(FACET_DELIMITER, FACET_DELIMITER + FACET_DELIMITER)
            : facetValue;
    });
    return values.join(FACET_DELIMITER);
}

export function urlParamToFacetValues(urlParam: string | null): string[] {
    if (urlParam == null) {
        return [];
    } else {
        // exchange escaped facet-delimiters, because no negative lookahead possible with regex-split
        const facetValues = urlParam
            .replaceAll(FACET_DELIMITER + FACET_DELIMITER, "###NONBREAKING###")
            .split(FACET_DELIMITER);
        return facetValues
            ? facetValues.map((facetValue) => facetValue.replaceAll("###NONBREAKING###", FACET_DELIMITER))
            : [];
    }
}
