/**
 *  1. If the array is empty, add the object as the first (and only) item in the array.
    2. If the position is "first" or less than or equal to 1, add the object as the first item in the array and shift all other items to the right.
    3. If the position is "last" or greater than the length of the array, add the object as the last item in the array.
    4. Otherwise, insert the object at the specified position in the array and shift all subsequent items to the right.
 */
export function insertObjectAtPosition<T>(array: T[], object: T, position: number | "first" | "last") {
    if (array.length === 0) {
        // If the array is empty, add the object as the first item
        array.push(object);
    } else if (position === "first" || (typeof position === "number" && position <= 1)) {
        // If the position is "first" or 1 or less, add the object as the first item and shift all other items to the right
        array.splice(0, 0, object);
    } else if (position === "last" || position > array.length) {
        // If the position is "last" or greater than the length of the array, add the object as the last item
        array.push(object);
    } else {
        // Otherwise, insert the object at the specified position and shift all subsequent items to the right
        array.splice(position - 1, 0, object);
    }
}
