<template>
    <div
        class="custom-uploader"
        @dragover.prevent="dropZoneHover = true"
        @dragleave.prevent="dropZoneHover = false"
        @drop.prevent="handleDrop"
        @click="handleFileInputClick"
        :class="{
            'background-secondary': background === 'secondary',
            'background-white': background === 'white',
            'dropzone-hover': dropZoneHover,
        }"
    >
        <div class="custom-dropzone dropzone-flex-box" :id="dropZoneId + '-drop-zone'">
            <i class="fa-light fa-cloud-arrow-up fa-3x default-grey-icon-color cloud-u pload-icon-placement" />
            <div class="dropzone-flex-box">
                <div>
                    <span class="dropzone-text">{{
                        multiUpload ? t("core.Drop files here") : t("core.Drop file here")
                    }}</span
                    ><br />
                    <span class="dropzone-text">{{ t("core.or") }}</span
                    ><br />
                    <span class="dropzone-text dropzone-text-link">{{
                        multiUpload ? t("core.Choose files") : t("core.Choose file")
                    }}</span
                    ><br />
                </div>
            </div>
        </div>
        <template v-if="failedUploads.length > 0 && showError">
            <div v-for="(file, index) in failedUploads" :key="index" class="ext-error">
                <i class="fa-regular fa-circle-exclamation warning-color" />
                <div class="error-text">
                    <div class="error-heading">{{ file?.name + ":" }}</div>
                    <div>{{ t("core.File type/mime type isnt supported") }}</div>
                </div>
            </div>
        </template>
        <template v-else-if="toBigUploads.length > 0 && showError">
            <div v-for="(file, index) in toBigUploads" :key="index" class="ext-error">
                <i class="fa-regular fa-circle-exclamation warning-color" />
                <div class="error-text">
                    <div class="error-heading">{{ file?.name + ":" }}</div>
                    <div>
                        {{
                            t("core.File exceeds maximum upload size of {fileSizeDisplayText}", {
                                fileSizeDisplayText: fileSizeDisplayText(maxFileSize ?? 0),
                            })
                        }}
                    </div>
                </div>
            </div>
        </template>
        <input type="file" ref="fileInput" @change="handleFileInput" :multiple="multiUpload" style="display: none" />
    </div>
</template>

<script setup lang="ts">
import { Ref, inject, onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { getAuthHeader } from "@/shared/authentication/authentication";

const props = withDefaults(
    defineProps<{
        allowedFileTypes?: string[];
        allowedMimeTypes?: string[];
        uploadHeaders?: Record<string, string>[];
        uploadUrl?: string;
        multiUpload?: boolean;
        dropZoneId?: string;
        checkFileTypeAndMimeType?: ({
            mimetype,
            allowedMimeTypes,
        }: {
            mimetype: string;
            allowedMimeTypes: string[];
        }) => boolean;
        maxFileSize?: number;
        showError?: boolean;
        background?: string;
    }>(),
    {
        allowedFileTypes: () => [".csv", ".txt"],
        allowedMimeTypes: () => ["text/plain", "text/csv", "application/zip", "application/x-zip-compressed"],
        uploadHeaders: () => [],
        uploadUrl: "",
        multiUpload: false,
        dropZoneId: "qss-custom-dropzone",
        showError: true,
        maxFileSize: 1e9, // 1 GB in Bytes
        background: "secondary",
    }
);

const emit = defineEmits<{
    (e: "onValidated", files: File[]): void;
    (e: "onUploaded", files: File[], responseData: any): void;
    (e: "onUploadError", files: File[], errorData: any): void;
}>();

const { t } = useI18n();
const failedUploads: Ref<Array<File | undefined>> = ref([]);

const toBigUploads: Ref<Array<File | undefined>> = ref([]);

const fileStreamStatus = inject("fileStreamStatus") as { filesToUpload: File[]; abortFileUpload: File[] };

const fileInput = ref<HTMLInputElement>();

const dropZoneHover = ref(false);

const abortControllerByFile = new Map<File, AbortController>();

function handleDrop(e: DragEvent) {
    dropZoneHover.value = false;

    const files = e.dataTransfer?.files;
    if (files) {
        handleFiles(Array.from(files));
    }
}

function handleFileInput() {
    const files = fileInput.value?.files;
    if (files) {
        handleFiles(Array.from(files));
    }
}

function handleFileInputClick() {
    fileInput.value?.click();
}

async function handleFiles(files: File[]) {
    if (files.length === 0) {
        return;
    }
    if (files && props.allowedFileTypes.length > 0) {
        if (validateFiles(files as File[])) {
            emit("onValidated", files as File[]);

            try {
                await upload(files);
            } catch (error) {
                emit("onUploadError", files, error);
            }
        } else {
            emit("onValidated", []);
        }
    }
}

async function upload(files: File[]) {
    if (!props.uploadUrl) {
        throw new Error("No upload URL provided");
    }

    await Promise.all(
        files.map(async (file) => {
            let abortController;
            if (abortControllerByFile.has(file)) {
                abortController = abortControllerByFile.get(file) ?? new AbortController();
            } else {
                abortController = new AbortController();
                abortControllerByFile.set(file, abortController);
            }

            const formData = new FormData();
            formData.append("files", file);

            const headers = new Headers({
                Authorization: getAuthHeader(),
            });

            const signal = abortController.signal;

            try {
                const response = await fetch(props.uploadUrl, {
                    method: "POST",
                    headers: headers,
                    body: formData,
                    signal: signal,
                });

                if (!response.ok) {
                    const error = new Error("Response not ok.");
                    abortControllerByFile.delete(file);
                    emit("onUploadError", [file], error);
                    throw error;
                }

                const data = await response.json();
                abortControllerByFile.delete(file);
                emit("onUploaded", [file], data);

                return {
                    url: props.uploadUrl,
                    headers: [{ name: "Authorization", value: getAuthHeader() }, ...props.uploadHeaders],
                    fieldName: "files",
                };
            } catch (error: any) {
                if (error.name !== "AbortError") {
                    throw error;
                }
                // Handle abort scenario
            } finally {
                if (signal) {
                    // Cleanup for externally controlled abort
                    signal.removeEventListener("abort", abortController.abort);
                } else {
                    // Cleanup for internally controlled abort
                    abortController.abort();
                }
                abortControllerByFile.delete(file);
            }
        })
    );
}

onMounted(() => {
    if (fileStreamStatus) {
        watch(
            () => fileStreamStatus.filesToUpload,
            () => {
                if (fileStreamStatus.filesToUpload) {
                    handleFiles(fileStreamStatus.filesToUpload);
                }
            }
        );

        watch(
            () => fileStreamStatus.abortFileUpload,
            () => {
                if (fileStreamStatus.abortFileUpload?.length) {
                    fileStreamStatus.abortFileUpload.forEach((file) => {
                        abortControllerByFile.get(file)?.abort();
                    });
                }
            }
        );
    }
});

/**
 * Returns true if FileType or Mimetype valid
 *
 * If checkFileTypeaAndMimeType is not overriden by prop,
 * returns true if only file extension is valid.
 */
function checkFileTypeAndMimeType(extension: string, mimetype: string) {
    if (props.checkFileTypeAndMimeType) {
        return props.checkFileTypeAndMimeType({ mimetype, allowedMimeTypes: props.allowedMimeTypes });
    }
    return props.allowedFileTypes.some((x) => x.toLowerCase() === `.${extension}`.toLowerCase());
}

/**
 * Returns true if maximum file size is not exceeded
 */
function checkFileSize(fileSize: number): boolean {
    if (props.maxFileSize) {
        return fileSize <= props.maxFileSize;
    }
    return true;
}

/**
 * invalide Dateien finden
 */
function validateFiles(files: File[]) {
    failedUploads.value = [];
    toBigUploads.value = [];
    if (files) {
        if (files.length > 0) {
            let allFilesValid = true;

            files.forEach((file: File) => {
                const fileNameParts = file.name.split(".");
                const ext = fileNameParts[fileNameParts.length - 1];
                // check if file extension and mimeType are correct
                if (!checkFileTypeAndMimeType(ext, file.type) && !failedUploads.value.includes(file)) {
                    failedUploads.value.push(file);
                    allFilesValid = false;
                } else if (!checkFileSize(file.size)) {
                    toBigUploads.value.push(file);
                    allFilesValid = false;
                }
            });
            return allFilesValid;
        } else {
            return false;
        }
    } else {
        return false;
    }
}

/**
 * Generates message depending on filesize
 *
 * @param fileSizeInBytes
 */
function fileSizeDisplayText(fileSizeInBytes: number) {
    const KB = 1024;
    const MB = KB * 1024;
    const GB = MB * 1024;

    if (fileSizeInBytes < KB) {
        return fileSizeInBytes + " bytes";
    } else if (fileSizeInBytes < MB) {
        return (fileSizeInBytes / KB).toFixed(2) + " KB";
    } else if (fileSizeInBytes < GB) {
        return (fileSizeInBytes / MB).toFixed(2) + " MB";
    } else {
        return (fileSizeInBytes / GB).toFixed(2) + " GB";
    }
}
</script>

<style lang="scss" scoped>
.custom-uploader {
    width: auto;

    &.dropzone-hover {
        background: $grey-90;
    }
}

.background-secondary {
    background: var(--q-secondary);
    &:hover {
        background: $grey-90;
    }
}

.background-white {
    background: $white;
}

.custom-dropzone {
    padding: calc(#{$spacing-xxxl} + #{$spacing-xxl}) $spacing-l;
    text-align: center;
    cursor: pointer;
    .cloud-upload-icon-placement {
        display: flex;
        align-items: center;
    }
}

.dropzone-flex-box {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-left: $spacing-m;
}

.dropzone-text {
    color: $medium-text-color;
}

.dropzone-text-link {
    color: var(--q-link);
    text-decoration: underline;
}

.ext-error {
    display: flex;
    padding: $spacing-m;
    gap: $spacing-m;
    margin: $spacing-m;
    background: $light-background-color;

    .error-text {
        .error-heading {
            font-weight: $semi-bold;
        }
    }

    i {
        width: $icon-m;
        height: $icon-m;
        display: block;
    }
}
</style>
