<template>
    <!-- eslint-disable vue/attribute-hyphenation -->
    <component :is="tag" :innerHTML="sanitizedHTMLContent" />
    <!-- eslint-enable -->
</template>

<script setup lang="ts">
import { computed } from "vue";
import sanitizeHtml from "sanitize-html";

const props = withDefaults(
    defineProps<{
        content: string;
        tag: "span" | "div" | "p";
    }>(),
    {
        tag: "span",
    }
);

const sanitizedHTMLContent = computed(() => {
    return sanitizeHtml(props.content, {
        allowedTags: ["b", "br", "em", "i"],
        allowedAttributes: false,
        disallowedTagsMode: "recursiveEscape",
    });
});
</script>
