import { InjectionKey } from "vue";
// @ts-ignore
import { createStore, Module, Store } from "vuex";
import authentication, { AuthState } from "@/shared/store/modules/authentication";
import languages, { LanguageState } from "@/shared/store/modules/languages";
import tenantinfo, { TenantInfoState } from "@/shared/store/modules/tenantinfo";
import notifications, { NotificationState } from "@/shared/store/modules/notifications";
import apmTrace, { ApmTraceState } from "@/shared/store/modules/apmTrace";

interface Modules {
    [key: string]: Module<any, any>;
}

export interface State {
    auth: AuthState;
    lang: LanguageState;
    displayName: TenantInfoState;
    apmTrace: ApmTraceState;
    notification: NotificationState;
}

export const key: InjectionKey<Store<State>> = Symbol();

function buildStore(): Store<State> {
    return createStore<State>({
        // plugins: [createLogger()], zum debuggen wieder einkommentieren und von vuex importieren
        state: {} as State,
        mutations: {},
        actions: {},
        getters: {},
        modules: registerModules(),
    });
}

function registerModules() {
    const modules: Modules = {
        auth: authentication,
        lang: languages,
        tenant: tenantinfo,
        notification: notifications,
        apmTrace: apmTrace,
    };

    return modules;
}

let store: Store<State> | null = null;

export function getStore(): Store<State> {
    if (store === null) {
        store = buildStore();
    }
    return store;
}
