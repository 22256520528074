const state = () => ({
    traceId: "",
});

export interface ApmTraceState {
    traceId: string;
}

const mutations = {
    setTraceId(state: ApmTraceState, newTraceId: string) {
        state.traceId = newTraceId;
    },
};

const getters = {
    traceId: (state: ApmTraceState) => {
        return state.traceId;
    },
};

export default {
    namespaced: true,
    mutations,
    getters,
    state,
};
