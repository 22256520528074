import { createI18n, I18n } from "vue-i18n";
import { getAllMessages } from "@/shared/i18n/locales";

// @ts-ignore
function setupI18n(messages: any): I18n<any, unknown, unknown, false> {
    return createI18n({
        fallbackLocale: ["en"],
        globalInjection: true, // wird für die Nutzung von $t im Template benötigt
        legacy: false, // wird für die Nutzung in der setup Funktion benötigt
        locale: "en",
        messages,
        warnHtmlMessage: false,
        missing(_locale: any, key: string) {
            const keys = key.split(".");
            keys.splice(0, 1);
            const missingKey = keys.join(".");
            return missingKey;
        },
    });
}

// @ts-ignore
let i18n: I18n<any, unknown, unknown, false> | null = null;

// @ts-ignore
export function getI18n(): I18n<any, unknown, unknown, false> {
    if (i18n === null) {
        i18n = setupI18n(getAllMessages());
    }
    return i18n;
}
