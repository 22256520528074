<template>
    <div class="form-row">
        <div class="row q-mb-xs">
            <div class="col semi-bold">
                {{ $t("core.Process name") }}
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-sm-6">
                <q-input
                    v-if="formFields"
                    outlined
                    v-model="formFields.name.value"
                    :dense="true"
                    :rules="[(_val:any) => !!formFields?.name.valid]"
                    @focus="validateProcessName"
                    @blur="validateProcessName"
                    data-qs="process-name-input"
                />
                <div class="message-wrapper">
                    <span v-if="processesLoading" class="message">{{ $t("core.Checking process names") }}</span>
                    <span
                        v-else-if="formFields?.name.messageKey"
                        class="message"
                        :class="{ 'error-message': !formFields.name.valid }"
                        >{{ $t(formFields.name.messageKey) }}</span
                    >
                </div>
            </div>
            <div class="col-12 col-sm-6">
                <div class="description-field" :class="{ 'description-field-mobile': isXSmall }">
                    {{ $t("core.Enter a unique name for the process") }}
                </div>
            </div>
        </div>
    </div>
    <FileUploadRow v-if="formFields" v-model="formFields" />
</template>

<script setup lang="ts">
/**
 * The 1st step for the product-import-wizard for input of the process name and upload of an example configuration file.
 * @displayName CommonStep
 * @example `
 *      Used in .ability.ts file.
 *      const formModels: FormModel<CommonStepModel & AssignStepModel>[] = [
 *          {
 *              ...
 *              formComponentDefinition: {
 *                  getComponent: () => AssignStep,
 *              },
 *              ...
 *          }, { ... }]
 * `
 */
import { computed, ref, watch } from "vue";
import FileUploadRow from "@/shared/components/data-process/form-row-components/file-upload-row.vue";
import { CommonStepModel, WizardStep } from "@/shared/components/data-process/form-types";
import { useProcessesLazyQuery, useProcessLazyQuery } from "@/shared/services/graphql/generated/consumer-graph-types";
import { DEFAULT_ESCAPE } from "@/shared/components/data-process/form-constants";
import { useScreenSize } from "@/shared/screen/composables/screen-size";

const props = withDefaults(
    defineProps<{
        /**
         * Form Model as two way binded prop.
         */
        modelValue: WizardStep<CommonStepModel>[];
        /**
         * When editing the process, an Id should be available from this prop, which prevents
         * invalidating the input name when the process already has a name and only makes a CRUD change.
         * @example '_LYfvjuMw85qP9'
         */
        entryId: string;
    }>(),
    {
        entryId: "",
    }
);

const emit = defineEmits(["onFormValidation", "update:modelValue"]);

const { load: getProcessNames, result: processes, loading: processesLoading } = useProcessesLazyQuery({});
const { load: loadProcess, onResult: onProcessResult } = useProcessLazyQuery({
    processId: props.entryId,
});

// on Mounted
if (props.entryId) {
    loadProcess();
}

const formModel = ref(props.modelValue);

const formFields = ref<CommonStepModel | undefined>(
    formModel.value.find((model) => model.id === "commonStep")?.model ?? undefined
);

const formValid = computed(() => {
    if (formFields.value) {
        return Object.values(formFields.value).every((field) => field.valid);
    } else return false;
});

const { isXSmall } = useScreenSize();
const validateProcessNameToggle = ref(false);

/**
 * Validates the process name, fires a toggle event that is watched when processes query result is already available.
 */
function validateProcessName() {
    if (processes.value) {
        validateProcessNameToggle.value = !validateProcessNameToggle.value;
    } else if (!props.entryId) {
        getProcessNames();
    }
}

onProcessResult((result) => {
    if (formFields.value && result.data) {
        formFields.value.escape.value = result.data.process?.escape ?? DEFAULT_ESCAPE;
    }
});

/**
 * When form validation triggers, validate the process name again and emit formValid value.
 */
watch(formValid, () => {
    validateProcessName();
    // set valid value of formModel
    formModel.value[formModel.value.findIndex((obj) => obj.id === "commonStep")].valid = formValid.value;
    emit("onFormValidation", formValid.value);
});

/**
 * Prozess Name Validation.
 */
watch(
    () => formFields.value?.name.value,
    () => {
        if (formFields.value) {
            const nameValid = formFields.value.name.value.length > 0;
            formFields.value.name.valid = nameValid;
            if (formFields.value.name.valid) {
                formFields.value.name.messageKey = "";
            } else {
                formFields.value.name.messageKey = "core.Please enter a name";
            }
        }
    },
    {
        deep: true,
    }
);

/**
 * Watches the processes result and the validateProcessNameToggle to set form name validation and invalidation message.
 */
watch([validateProcessNameToggle, processes], () => {
    const _processes = processes.value?.processes;
    if (_processes && formFields.value) {
        const arrayOfProcessNames = _processes.map((process) => process?.name ?? "") ?? [];
        const processNameIndex = arrayOfProcessNames.indexOf(formFields.value.name.value);

        if (processNameIndex > -1 && _processes[processNameIndex]?.id !== props.entryId) {
            formFields.value.name.valid = false;
            formFields.value.name.messageKey = "core.The process name already exists";
        }
    }
});
</script>
