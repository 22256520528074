import { ApolloQueryResult, FetchMoreOptions, FetchMoreQueryOptions } from "@apollo/client/core";

type FetchMoreFunctionType = <K extends keyof any>(
    options: FetchMoreQueryOptions<any, K> & FetchMoreOptions<any, any>
) => Promise<ApolloQueryResult<any>> | undefined;

export async function loadMore(fetchMore: FetchMoreFunctionType, after: any, first: number) {
    const response = await fetchMore({
        variables: {
            first: first,
            after: after,
        },
    });
    return response;
}
