import { Ref, ref } from "vue";
import { Documentation } from "../../model/documentation.model";
import { useRelatedDocumentsForArticle } from "./article-query";
import { useRelatedDocumentsForAsset } from "./asset-query";
import { useRelatedDocumentsForProduct } from "./product-query";

export enum RelatedDocumentContext {
    Article = "article",
    Product = "product",
    Asset = "asset",
}

export interface RelatedDocumentsQuery {
    loading: Ref<boolean>;
    docs: Ref<Documentation[] | undefined>;
    total: Ref<number | undefined>;
    error: Ref<any>;
    fetchMore: (cursor: string, moreCount: number) => Promise<unknown> | undefined;
}

export function getDocumentationQuery(
    count: number,
    documentContext: RelatedDocumentContext | undefined,
    productId: string,
    assetId: string,
    articleId: string
) {
    const undefinedContext: () => RelatedDocumentsQuery = () => {
        return {
            loading: ref(false),
            docs: undefined as unknown as Ref<Documentation[] | undefined>,
            total: ref(undefined),
            error: ref(undefined),
            fetchMore: async () => {},
        };
    };

    if (!documentContext) {
        return undefinedContext;
    }

    switch (documentContext) {
        case RelatedDocumentContext.Article:
            return (): RelatedDocumentsQuery => {
                return useRelatedDocumentsForArticle(articleId, count);
            };
        case RelatedDocumentContext.Product:
            return (): RelatedDocumentsQuery => {
                return useRelatedDocumentsForProduct(productId, count);
            };
        case RelatedDocumentContext.Asset:
            return (): RelatedDocumentsQuery => {
                return useRelatedDocumentsForAsset(assetId, productId, count);
            };
        default:
            console.error(
                `No matching related document query found for given RelatedDocumentContext ${documentContext}`
            );
            return undefinedContext;
    }
}
