import { CommonRouteAliases } from "@/shared/environment/common-route-aliases";
import { encodeParamArrayToString } from "@/shared/environment/ability-route-helper";
import { useAbilityRoute } from "@/shared/environment/ability-route";
import { MechanicPath } from "@/shared/services/graphql/generated/consumer-graph-types";

export const useMechanicRoute = () => {
    const { getPathToChildrenWithoutParents } = useAbilityRoute();

    const getAbsolutePathToMechanic = (
        product: string,
        asset: string | undefined,
        assemblyPath: Array<string>,
        mechanicArticle: string
    ) => {
        return getPathToChildrenWithoutParents([
            {
                alias: CommonRouteAliases.product,
                namedParams: { [CommonRouteAliases.product]: product },
            },
            ...(asset
                ? [
                      {
                          alias: CommonRouteAliases.asset,
                          namedParams: { [CommonRouteAliases.asset]: asset },
                      },
                  ]
                : []),
            {
                alias: CommonRouteAliases.mechanic,
                namedParams: { [CommonRouteAliases.mechanic]: encodeParamArrayToString(assemblyPath) },
            },
            {
                alias: CommonRouteAliases.mechanicArticle,
                namedParams: { [CommonRouteAliases.mechanicArticle]: mechanicArticle },
            },
        ]);
    };

    const getAbsolutePathToMechanicLocation = (
        product: string,
        asset: string | undefined,
        mechanicPath: MechanicPath
    ) => {
        function getAssemblyPath(): Array<string> {
            const result: Array<string> = [];
            mechanicPath.path?.forEach((item) => {
                if (item?.mechanic?.assemblyId) {
                    result.push(item?.mechanic?.assemblyId);
                }
            });
            // Letzter Eintrag ist der Stücklisteneintrag selbst und gehört nicht zur Liste
            if (result.length > 0) {
                result.pop();
            }
            //Add root if not already in path
            if (result[0] !== "") {
                result.unshift("");
            }
            return result;
        }

        function getMechanicArticleFromPath(): string {
            const len = mechanicPath.path?.length ?? 0;
            if (len > 0) {
                const lastElement = mechanicPath.path?.[len - 1];
                return lastElement?.mechanicId ?? "";
            } else {
                return "";
            }
        }

        return getAbsolutePathToMechanic(product, asset, getAssemblyPath(), getMechanicArticleFromPath());
    };

    return {
        getAbsolutePathToMechanic,
        getAbsolutePathToMechanicLocation,
    };
};
