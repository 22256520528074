<template>
    <q-dialog v-model="popupVisible" :position="position" square no-shake @hide="closeDialog" :maximized="isXSmall">
        <q-card class="column" :style="`width: ${width}px; height: 100%;`">
            <q-card-section class="col-auto q-pa-xs">
                <component v-if="titleComponent" :is="titleComponent?.getComponent()" />
                <q-toolbar v-else class="text-primary">
                    <div v-if="icon">
                        <i :class="[icon]" style="font-size: 22px" />
                    </div>
                    <q-toolbar-title class="dialog-title"> {{ title }} </q-toolbar-title>
                    <i
                        class="fa-regular fa-xmark icon-color cursor-pointer q-mr-sm"
                        style="font-size: 22px"
                        @click="closeDialog"
                        data-qs="close-dialog-button"
                    />
                </q-toolbar>
            </q-card-section>
            <q-separator />
            <div class="col fit">
                <slot />
            </div>
            <q-card-actions class="col-auto" v-if="showFooter" vertical align="center">
                <slot name="footer" />
            </q-card-actions>
        </q-card>
    </q-dialog>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";
import { ComponentDefinition } from "../component-definition.model";
import { QDialogProps } from "quasar";
import { useScreenSize } from "@/shared/screen/composables/screen-size";

const props = withDefaults(
    defineProps<{
        modelValue: boolean;
        position?: QDialogProps["position"];
        title?: string;
        width?: number;
        showFooter?: boolean;
        icon?: string;
        titleComponent?: ComponentDefinition;
    }>(),
    {
        position: "left",
        width: 400,
        showFooter: false,
    }
);

const emit = defineEmits<{
    (e: "update:modelValue", visible: boolean): void;
    (e: "onLoadMore", moreElements: (hasMoreElements: boolean) => void): void;
}>();

const popupVisible = ref(props.modelValue);

watch(
    () => props.modelValue,
    () => {
        popupVisible.value = props.modelValue;
    }
);
watch(popupVisible, () => {
    emit("update:modelValue", popupVisible.value);
});

const { isXSmall } = useScreenSize();

function closeDialog() {
    popupVisible.value = false;
}
</script>

<style lang="scss" scoped>
.dialog-title {
    color: $default-text-color;
    font-weight: 700;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: $bigger-font-size;
}
</style>
