<template>
    <ListFrame
        class="products-overview"
        :no-results="!resultExists"
        :loading="initialProductsLoading"
        :error="error"
        :no-results-error-message="noResultsErrorMessage"
        large-content-container
    >
        <template #filter="{ isMobile }">
            <component
                :is="getListFilterComponent()"
                v-model:selected="selected"
                :fetch-aggregation="fetchAggregation"
                :facet-collection-id="FacetCollection.productSelection"
                :is-mobile-view="isMobile"
                :more-menu-entries="configMenuEntries"
                :sort-order-entries="sortOrderItems"
                :initial-selected-sort-entry="
                    isSearch
                        ? { field: ProductSortFields.score, order: SortOrder.desc }
                        : { field: ProductSortFields.title, order: SortOrder.asc }
                "
                :preselected="selectedProducts ? { custom_products: selectedProducts } : undefined"
                @on-sort-selection-changed="onChangeSortOrder"
                @on-open-filter="onOpenFilterHandler"
                :sort-save-key="isSearch ? 'product_search_sort' : 'product_sort'"
            />
        </template>
        <template #count>
            {{ searchHitsText }}
        </template>
        <template #content="{ isMobile }">
            <LazyLoadScrollView
                :reach-bottom-text="t('product-and-asset.Loading {productsName}', { productsName: productName })"
                @load-more="onLoadMore"
                class="col"
            >
                <TilesGrid
                    :is-mobile="isMobile"
                    :tiles="tiles"
                    :always-single-col="isMobile"
                    @select="emitSelect"
                    class="products-overview-tiles-grid"
                    :class="{ dense: dense }"
                />
            </LazyLoadScrollView>
        </template>
    </ListFrame>
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue";
import { ProductQueryResult } from "@/shared/services/graphql/generated/admin-graph-types";
import { useProductsOverviewConfig, useProductsOverviewSort } from "./products-overview.model";
import { loadMore } from "@/shared/components/scroll/lazy-loading-fetch";
import { useI18n } from "vue-i18n";
import { Facet, FacetCollection } from "@/shared/facets/facets.model";
import ListFrame from "@/shared/list-frame/list-frame.vue";
import TilesGrid from "@/shared/components/tiles/tiles-grid.vue";
import LazyLoadScrollView from "@/shared/components/scroll/lazy-loading-scroll-view.vue";
import ListFilterRenderer from "@/shared/list-filter/list-filter-renderer.vue";
import ListFilterUrlSync from "@/shared/list-filter/list-filter-renderer-url-sync.vue";
import { QualificationLevelSelectionResult } from "@/shared/environment/ability.types";
import { useSelectedFacets } from "@/shared/facets/composables/selected-facets";
import { useProductDataSource } from "@/abilities/product-and-asset/pages/product-data-source";
import { ProductSortFields, SortOrder } from "@/shared/services/graphql/generated/consumer-graph-types";
import { generateSearchHitsText } from "@/shared/utils/total-formatter";
import { useProductNames } from "@/abilities/product-and-asset/composables/product-names";

const props = withDefaults(
    defineProps<{
        fullTextSearchValue?: string;
        emitSelectionInTiles?: boolean;
        showButtons?: boolean;
        multiLevel?: boolean;
        hasLevelTitle?: boolean;
        selectionData?: QualificationLevelSelectionResult;
        dense?: boolean;
        isSearch?: boolean;
    }>(),
    {
        fullTextSearchValue: "",
        showButtons: true,
        multiLevel: false,
        hasLevelTitle: false,
        dense: false,
        isSearch: false,
    }
);

const productName = useProductNames("pluralLower");

const emit = defineEmits<{
    (e: "select", ...params: any): void;
}>();

const emitSelect = (...params: any) => {
    emit("select", ...params);
};

const facet = ref<Facet>();

const getListFilterComponent = () => {
    return props.selectionData ? ListFilterRenderer : ListFilterUrlSync;
};

const selectedProducts = computed<string[] | undefined>(() => {
    if (props.selectionData?.product) {
        return [props.selectionData?.product];
    } else {
        return undefined;
    }
});

const lazyLoadAmount = 30;
const onLoadMore = async (callBackAfterLoaded: (hasMoreElements: boolean) => void) => {
    if (!products.value) {
        // Noch nicht initialisiert. Also wahrscheinlich gibt es schon was zum Nachladen
        callBackAfterLoaded(true);
        return;
    }
    const productsValue = products.value as ProductQueryResult;
    const lastProduct = productsValue.products[productsValue.products.length - 1];
    if (!lastProduct || !lastProduct.cursor) {
        callBackAfterLoaded(false);
        return;
    }
    const result = await loadMore(fetchMore, lastProduct.cursor, lazyLoadAmount);
    const isAtEnd = result?.data.products.products.length == 0;
    callBackAfterLoaded(!isAtEnd);

    return;
};

const { t } = useI18n();

const productNotAvailableText = useProductNames("noProductsAvailable");
const productsAvailableWithFilter = useProductNames("noProductsWithSetFilterAvailable");

const fullTextSearchValue = computed(() => {
    return props.fullTextSearchValue;
});

const resultExists = ref(true);
const noResultButFiltersAreSet = ref(false);

const { sorting: sortEntry, onChangeSortOrder, selected } = useSelectedFacets();

const { sortOrderItems } = useProductsOverviewSort(props.isSearch);

const { configMenuEntries } = useProductsOverviewConfig();

const { error, fetchMore, products, tiles, total, fetchAggregation } = useProductDataSource(
    computed(() => {
        return { text: fullTextSearchValue.value };
    }),
    props.emitSelectionInTiles ?? false,
    props.showButtons,
    selected,
    sortEntry,
    facet
);

const initialProductsLoading = computed(() => {
    return !products.value;
});

watch(products, () => {
    if (!products.value) {
        return;
    }
    resultExists.value = products.value?.products.length > 0;
    noResultButFiltersAreSet.value = !resultExists.value && selected.value.length > 0;
});

const noResultsErrorMessage = computed(() => {
    if (noResultButFiltersAreSet.value) {
        return productsAvailableWithFilter.value;
    }
    if (props.fullTextSearchValue) {
        return t("core.No search results found");
    } else {
        return productNotAvailableText.value;
    }
});

const searchHitsText = computed(() => {
    return generateSearchHitsText(total.value);
});

const onOpenFilterHandler = (facetProp: Facet) => {
    facet.value = facetProp;
};
</script>

<style lang="scss" scoped>
.products-overview-tiles-grid:not(.dense) {
    padding: 0;

    &:deep(.router-link) {
        cursor: pointer;
    }
}

.tile-grid {
    gap: 12px;
}
</style>
