<!-- 
    Einfacher span, der den Titel eines Stücklisteneintrages enthält.
    Wird für das Breadcrumb benötigt und zeigt ein Skeleton an, wenn es noch nicht geladen wurde.
    Als Parameter muss die Id des Mechanik-Eintrages vorhanden sein.
-->
<template>
    <span>
        {{ getTitle() }}
    </span>
</template>

<script lang="ts">
import { computed, defineComponent, nextTick, onMounted, watch } from "vue";
import { getDataQueryLanguages } from "@/shared/services/providers/language-provider";
import { useMechanicArticleQuery } from "@/shared/services/graphql/generated/consumer-graph-types";

export default defineComponent({
    props: {
        mechanicArticleId: {
            type: String,
            required: true,
        },
    },
    emits: {
        breadcrumbLoaded: null,
    },
    setup(props, { emit }) {
        const mechanicArticleFilterVariables = computed(() => {
            return {
                mechanicId: props.mechanicArticleId,
                acceptedLanguages: getDataQueryLanguages(),
            };
        });

        const { result: mechanicArticle, loading, error } = useMechanicArticleQuery(mechanicArticleFilterVariables);

        function getTitle(): string {
            return mechanicArticle.value?.mechanic?.localizations?.title ?? props.mechanicArticleId;
        }

        async function emitBreadcrumbLoaded() {
            if (!loading.value) {
                await nextTick();
                emit("breadcrumbLoaded");
            }
        }

        watch(loading, async () => {
            emitBreadcrumbLoaded();
        });

        onMounted(async () => {
            // Im Falle, dass das Result schon gecached ist
            emitBreadcrumbLoaded();
        });

        return {
            getTitle,
            loading,
            error,
        };
    },
});
</script>
