import { consumerClient } from "@/shared/services/apollo-clients/consumer-client";
import {
    GetConfigFdiShopDocument,
    GetConfigFdiShopQuery,
} from "@/shared/services/graphql/generated/consumer-graph-types";
import { ApolloCache, ApolloClient, createHttpLink, InMemoryCache, NormalizedCacheObject } from "@apollo/client/core";
import { setContext } from "@apollo/client/link/context";
import { computed, Ref, ref, watch } from "vue";
import { useShopStatusStore } from "../stores/shop-status";

let savedShopBaseUrl: string | undefined = undefined;

export const basketUpdateKey = ref(0);

export function updateBasket() {
    clientContainer?.cache?.reset();
    basketUpdateKey.value++;
}

async function queryConfigFdiShop() {
    return await consumerClient.query<GetConfigFdiShopQuery>({
        query: GetConfigFdiShopDocument,
        fetchPolicy: "cache-first",
    });
}

export async function getFdiShopBaseUrl(): Promise<string | undefined> {
    if (savedShopBaseUrl) {
        return savedShopBaseUrl;
    }

    const config = await queryConfigFdiShop();
    savedShopBaseUrl = config.data.config?.configFdiShop?.baseUrl ?? undefined;

    return savedShopBaseUrl;
}

export interface FDIShopToken {
    accessToken: string;
    basketId: string;
    lastAliveTime?: number;
}

const token = ref<FDIShopToken | undefined>(undefined);

interface ShopClientWithCache {
    client: ApolloClient<NormalizedCacheObject>;
    cache: ApolloCache<NormalizedCacheObject>;
}

async function createShopClient(healthCheckOnly = false): Promise<ShopClientWithCache | undefined> {
    const cache = new InMemoryCache();

    const shopBaseUrl = await getFdiShopBaseUrl();

    if (shopBaseUrl) {
        const authLink = setContext((_, { headers }) => {
            const accessToken = token.value?.accessToken;
            return {
                headers: {
                    ...headers,
                    authorization: healthCheckOnly ? undefined : accessToken ? `Bearer ${accessToken}` : "",
                },
            };
        });

        const httpLink = createHttpLink({
            uri: new URL("graphql", shopBaseUrl).href,
            fetch,
        });
        const client = new ApolloClient<NormalizedCacheObject>({
            link: healthCheckOnly ? httpLink : authLink.concat(httpLink),
            cache: cache,
        });

        return {
            client,
            cache,
        };
    }
    return undefined;
}

let clientContainer: ShopClientWithCache | undefined;

// add withOutTokenClient param to be able to get apollo client without cretentials
export async function getShopClient(healthCheckOnly = false) {
    if (!token.value && !healthCheckOnly) {
        await connectFdiShop();
    }
    if (!clientContainer && !healthCheckOnly) {
        clientContainer = await createShopClient();
    }
    if (healthCheckOnly) {
        const res = await createShopClient(healthCheckOnly);
        return res?.client;
    } else {
        return clientContainer?.client;
    }
}

export function useShopClient(enabled: Ref<boolean> = ref(true)) {
    const result = ref<ApolloClient<NormalizedCacheObject> | undefined>(undefined);
    watch(
        enabled,
        async () => {
            if (enabled.value && !result.value) {
                result.value = await getShopClient();
            }
        },
        {
            immediate: true,
        }
    );
    return result;
}

async function connectFdiShop() {
    const shopBaseUrl = await getFdiShopBaseUrl();
    const { isShopOnline } = useShopStatusStore();
    if (shopBaseUrl && isShopOnline) {
        if (
            !window.location.href.includes("fdi-shop-login-landing") &&
            !window.location.href.includes("fdi-shop-config")
        ) {
            const loginURL = new URL("oauth/login", shopBaseUrl);
            const loginLandingPage = new URL("/fdi-shop-login-landing", window.location.origin);
            const callbackCurrentRouteAsString = window.location.pathname + window.location.search;

            loginLandingPage.searchParams.append("callingPage", callbackCurrentRouteAsString);
            loginURL.searchParams.append("redirectto", loginLandingPage.href);

            window.location.href = loginURL.href;

            //this is a workaround since loaction.href is async and we don't know when the url changes are active.
            //the clean solution would be to call the login before starting vue
            for (let i = 0; i < 10; i++) {
                if (window.location.href !== loginURL.href) {
                    await new Promise((resolve) => {
                        setTimeout(resolve, 250);
                    });
                }
            }
        }
    }
}

export const basketIdForCurrentUser = computed(() => {
    if (token.value?.basketId) {
        return token.value.basketId;
    } else {
        return undefined;
    }
});

export function getBasketIdForCurrentUser() {
    if (token.value?.basketId) {
        return token.value.basketId;
    }
}

export async function getToken(code: string) {
    const shopBaseUrl = await getFdiShopBaseUrl();
    if (shopBaseUrl) {
        const getTokenUrl = new URL("oauth/token", shopBaseUrl);
        getTokenUrl.searchParams.append("code", code);

        const tokenResponse = await fetch(getTokenUrl.href);
        if (tokenResponse.status === 401) throw new Error("401");
        if (tokenResponse.status === 403) throw new Error("403");
        if (!tokenResponse.ok) throw new Error(tokenResponse.statusText);

        token.value = await tokenResponse.json();
    }
}
