import { TotalCount } from "@/shared/services/graphql/generated/consumer-graph-types";
import { getI18n } from "@/shared/i18n/i18n";

export function generateSearchHitsText(total: TotalCount | undefined) {
    const i18n = getI18n();

    if (!total) return;

    if (total.count === 0) return;

    let countString = total.count;

    switch (total.countRelation) {
        case "gte":
            countString = `>${total.count}`;
            break;
        case "lte":
            countString = `<${total.count}`;
            break;
    }

    //@ts-ignore
    return i18n.global.t("core.{count} Results", { count: countString });
}
