<template>
    <q-btn-dropdown
        unelevated
        auto-close
        spread
        :ripple="false"
        class="language-selector text-weight-bold grey-hover"
        :class="{ 'text-on-navbar-color': isXSmall, 'icon-color': !isXSmall }"
        color="transparent"
        icon="fa-regular fa-earth-americas"
        padding="sm"
        dropdown-icon="fa-solid fa-caret-down"
        data-qs="language-selector"
    >
        <template #label>
            {{ currentLanguage }}
            <q-tooltip>{{ $t("core.Interface language") }}</q-tooltip>
        </template>
        <q-list unelevated class="text-center">
            <q-item
                dense
                class="full-width"
                v-for="language in availableLanguages"
                :key="language"
                clickable
                v-close-popup
                @click="onItemClick(language)"
            >
                <q-item-section :data-qs="`language-item-${language}`">
                    <q-item-label>{{ language }}</q-item-label>
                </q-item-section>
            </q-item>
        </q-list>
    </q-btn-dropdown>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import { getAvailableLanguages, getUiLanguage, setUiLanguage } from "@/shared/services/providers/language-provider";
import { useScreenSize } from "@/shared/screen/composables/screen-size";

const { isXSmall } = useScreenSize();

const availableLanguages = computed(() => getAvailableLanguages().map((locale) => locale.toUpperCase()));
const currentLanguage = computed(() => getUiLanguage());

function onItemClick(language: string) {
    switchLanguage(language);
}

function switchLanguage(language: string) {
    const selectedLanguage = language.toLowerCase();
    if (selectedLanguage && selectedLanguage !== currentLanguage.value) {
        setUiLanguage(selectedLanguage);
    }
}

defineExpose({ switchLanguage });
</script>

<style lang="scss" scoped>
.language-selector {
    &:deep(.q-icon.fa-caret-down) {
        font-size: 1rem;
    }
    &:deep(.q-icon.fa-earth-americas) {
        font-size: 1.3rem;
        margin-right: 6px;
    }
}
</style>
