<template>
    <q-checkbox v-model="checked" :label="title" @update:model-value="update" />
</template>

<script setup lang="ts">
import { ref, toRefs, watchEffect } from "vue";

const props = defineProps<{
    id: string;
    title: string;
    isSelected: boolean | undefined;
}>();

const emit = defineEmits<{
    (e: "onCheck", checked: boolean, id: string): void;
}>();

const { isSelected } = toRefs(props);

const checked = ref<boolean>(props.isSelected);

watchEffect(() => {
    checked.value = isSelected.value ?? false;
});

const update = (value: boolean) => {
    emit("onCheck", value, props.id);
};
</script>
