import { Asset, File } from "@/shared/services/graphql/generated/admin-graph-types";
import { IMAGE_FILETYPE, PREVIEW_FILETYPE } from "@/shared/environment/consts";

export interface AdvAsset extends Asset {
    getPreviewImage(): string | undefined;
    getImages(): File[];
    getImageUrls(): string[];
    getTitle(): string;
    advancedType: "asset"; // Kann für Objekt Unterscheidungen zwischen AdvProduct und AdvAsset benutzt werden
}

export function advAsset(asset: Asset): AdvAsset {
    //Clone erzeugen, weil von GraphQL preventExtensions gesetzt wird
    const result: Partial<AdvAsset> = Object.assign({}, asset);

    const getImages = (): File[] => {
        if (result.files) {
            // Sicherstellen, dass das Preview Image immer als erstes hinzugefügt wird.
            const files: File[] = result.files.filter((file) => file.objectType === PREVIEW_FILETYPE);
            return files.concat(result.files.filter((file) => file.objectType == IMAGE_FILETYPE));
        } else {
            return [];
        }
    };

    const getImageUrls = function (): string[] {
        const urls: string[] = [];
        const files = getImages();

        const previewFiles = files.filter((file) => {
            return file.objectType === PREVIEW_FILETYPE;
        });
        const plainFiles = files.filter((file) => {
            return file.objectType === IMAGE_FILETYPE;
        });

        if (files.length) {
            //Sicherstellen, dass das Preview Image immer als erste hinzugefügt wird.
            for (const file of previewFiles) {
                if (file.file && !urls.includes(file.file.url)) {
                    urls.push(file.file.url);
                }
            }

            // Restiliche Bilder hinzufügen
            for (const file of plainFiles) {
                if (file.file && !urls.includes(file.file.url)) {
                    urls.push(file.file.url);
                }
            }
        }

        return urls;
    };

    const getPreviewImage = (): string | undefined => {
        if (result.previewImage && result.previewImage.url) {
            return result.previewImage.url;
        }
        const files = getImages();
        if (files.length) {
            for (const file of files) {
                if (file.file) {
                    return file.file.url;
                }
            }
        }
        return;
    };

    const getTitle = function (): string {
        // Der Title eines Produkts ist der Name des Produkts, also z.B. Ford Fiesta 2-Türer mit 3.0er Diesel
        // Dieser Wert ist im Backend im Attribute text
        // In Title ist im Backend die Typbezeichnung, also z.B. PKW gespeichert.
        // Deshalb hier das ziemlich missverständliche getTitle ()=> return getText
        // Das sollte aber im Backend geändert werden
        return result.assetId!;
    };

    //Methoden ans Object hängen, damit AdvAsset implementiert ist
    result.getImages = getImages;
    result.getTitle = getTitle;
    result.getPreviewImage = getPreviewImage;
    result.getImageUrls = getImageUrls;
    result.advancedType = "asset";

    return result as AdvAsset;
}
