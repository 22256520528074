import { RouteLocationRaw } from "vue-router";

export const IDENTIFY_PART = "identifyPart";

export interface IdentifyPartsItem {
    identifyPartsItemKey: string;
    titleKey: string;
    icon: string;
    textKey: string;
    buttonTextKey: string;
    productId: string;
    assetId?: string;
    rootAssemblyId?: string;
    getButtonRoute?: () => RouteLocationRaw;
    buttonClickEvent?: () => void | undefined;
    isActive: boolean;
}

function createItems(): Array<IdentifyPartsItem> {
    return [
        {
            identifyPartsItemKey: IDENTIFY_PART,
            titleKey: "mechanic.Installation location",
            icon: "fa-light fa-sitemap icon-light-color fa-2xl",
            textKey: "mechanic.Identify components based on their location",
            buttonTextKey: "mechanic.Select installation location",
            productId: "",
            assetId: undefined,
            isActive: true,
        },
    ];
}

let items: Array<IdentifyPartsItem> | null = null;

export function getIdentifyPartsItems() {
    if (items === null) {
        items = createItems();
    }
    return items;
}
