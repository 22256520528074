<!-- 
Grid für Tiles. Die Tiles sind eine Liste von TileDefinition. 

Props:
        tile: Liste von TileDefinition, das sind die eigentlich darzustellenden Tiles
        tileMinimumSize: In der Griddarstellung werden die Tiles nie kleiner 
        layoutBreakPointToSingleCol: ab dieser Größe wird es einspaltig angezeigt
        alwaysSingleCol: das Grid wird immer einspaltig angezeigt
-->
<template>
    <div class="tile-grid" :class="[singleColumn ? 'single-column' : 'grid']" :style="gridStyleCssValue">
        <div
            v-for="(tileDefinition, index) in tiles"
            :key="stringify(tileDefinition)"
            class="q-pa-xs"
            :data-qs="`tile-${index}`"
        >
            <component
                :is="tileDefinition.getComponent()"
                v-bind="{ ...tileDefinition.props, ...{ isMobile: isMobile } }"
                @select="emitSelect"
                @toggle-overlay-left="$emit('toggleOverlayLeft')"
                @edit-process="emitEditProcess"
                @delete-process="emitDeleteProcess"
                @toggle-overlay-right="toggleOverlayRight"
                @delete="onDelete"
                @link-clicked="linkClicked"
            />
        </div>
        <q-resize-observer @resize="onResize" />
    </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { ComponentDefinition } from "@/shared/components/component-definition.model";
import { stringify } from "qs";

const props = withDefaults(
    defineProps<{
        tiles: ComponentDefinition[];
        tileMinimumSize?: number;
        layoutBreakPointToSingleCol?: number;
        alwaysSingleCol?: boolean;
        isMobile?: boolean;
    }>(),
    {
        tileMinimumSize: 250,
        layoutBreakPointToSingleCol: 550,
        alwaysSingleCol: false,
        isMobile: false,
    }
);

const emit = defineEmits([
    "toggleOverlayLeft",
    "deleteProcess",
    "editProcess",
    "select",
    "toggleOverlayRight",
    "delete",
    "linkClicked",
]);

const emitSelect = (...params: any) => {
    emit("select", ...params);
};

const gridStyleCssValue = ref<string>("");

const singleColumn = ref(false);

const onResize = (size: { width: number }) => {
    singleColumn.value = size.width < props.layoutBreakPointToSingleCol || props.alwaysSingleCol;
    gridStyleCssValue.value = singleColumn.value
        ? ""
        : "grid-template-columns: repeat(auto-fill, minmax(" + props.tileMinimumSize + "px, 1fr))";
};

/**
 * Fuktionsaufruf zum Emitten des Emits da sonnst der Paramerter id verloren geht
 * @param id ID des zu löschenden Prozesses
 */
function emitDeleteProcess(id: string) {
    emit("deleteProcess", id);
}

/**
 * Fuktionsaufruf zum Emitten des Emits da sonnst der Paramerter id verloren geht
 * @param id ID des zu bearbeitenden Prozesses
 */
function emitEditProcess(id: string, processType: string) {
    emit("editProcess", id, processType);
}

const toggleOverlayRight = (e: any) => {
    emit("toggleOverlayRight", e);
};

const onDelete = (e: any) => {
    emit("delete", e);
};

const linkClicked = () => {
    emit("linkClicked");
};
</script>

<style lang="scss" scoped>
.single-column {
    display: flex;
    flex-direction: column;
}

.grid {
    display: grid;
}

.tile-grid {
    gap: $spacing-s;
}
</style>
