import { useDataDisplayConfigQuery } from "@/shared/services/graphql/generated/consumer-graph-types";
import { getDataQueryLanguages } from "@/shared/services/providers/language-provider";
import { PropertyItem } from "@/shared/components/views/property-item.model";
import { computed } from "vue";
import {
    getDataDisplayFields,
    getDataDisplayPropertyItems,
    getDatafieldsFragmentsFromConfig,
    getNodeDataFragments,
    getPriorityDataDisplayFields,
} from "./data-display.helper";
import { DataDisplayConfigId, DataDisplayEntry, DataDisplayField, DatafieldFragments } from "./data-display.model";

export interface DataDisplayResult {
    datafieldsFragment: DatafieldFragments;
    nodeFieldsDataFragment: Record<string, string>;
    dataDisplayFields: DataDisplayField[];
}

export function useDataDisplayConfig(id: DataDisplayConfigId) {
    const dataDisplayConfigVariables = computed(() => {
        return {
            dataDisplayConfigId: id,
            acceptedLanguages: getDataQueryLanguages(),
            length: 200,
        };
    });

    const { result, error, loading } = useDataDisplayConfigQuery(dataDisplayConfigVariables, {
        fetchPolicy: "cache-first",
    });

    const dataDisplayFields = computed(() => getDataDisplayFields(result.value));

    const returnResult = computed((): DataDisplayResult | undefined => {
        return result.value
            ? {
                  datafieldsFragment: getDatafieldsFragmentsFromConfig(result.value),
                  nodeFieldsDataFragment: getNodeDataFragments(result.value),
                  dataDisplayFields: dataDisplayFields.value,
              }
            : undefined;
    });

    const entries = computed(() => {
        return result.value?.dataDisplayConfig?.entries?.map((x) => {
            const value: DataDisplayEntry = {
                referencedId: x.referencedId,
                fieldType: x.fieldType,
            };

            return value;
        });
    });

    /**
     * Returns an Array of PropertyItem for a graphql-Result by the displayConfig
     */
    function getDataDisplayPropertiesByConfig(graphqlResultObject: any): PropertyItem[] {
        return getDataDisplayPropertyItems(dataDisplayFields.value, graphqlResultObject);
    }

    const priorityDataDisplayFields = computed(() => {
        return getPriorityDataDisplayFields(result.value);
    });

    return {
        entries,
        result: returnResult,
        error,
        loading,
        getDataDisplayPropertiesByConfig,
        priorityDataDisplayFields,
    };
}
