/**
 * Datum in lesbaren String umformatieren.
 * @param dateString Unformatiertes Datum.
 * @returns Formatiertes Datum.
 */
export function getDateString(dateString: string, locale: string): string {
    const date = new Date(dateString);
    switch (locale) {
        case "de":
            return Intl.DateTimeFormat("de-DE", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit",
            }).format(date);
        default:
            return Intl.DateTimeFormat("en-US", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit",
            }).format(date);
    }
}
