import {
    LocationQueryRaw,
    NavigationGuardNext,
    RouteLocationNormalizedGeneric,
    RouteLocationNormalizedLoaded,
    RouteLocationNormalizedLoadedGeneric,
    RouteLocationRaw,
    RouteRecordRaw,
    useRouter,
} from "vue-router";
import {
    Ability,
    AccessOptions,
    AsyncLoadedBreadCrumbs,
    Route,
    RouteElementPathWithParams,
    RouteElementWithParam,
} from "@/shared/environment/ability.types";
import { useAbilityStore } from "@/shared/store/ability.store";
import { BreadcrumbItem } from "@/shared/components/breadcrumbs/breadcrumbs.model";
import { CommonRouteAliases } from "@/shared/environment/common-route-aliases";
import qs from "qs";
import { createRouteFromRouteElements } from "@/shared/environment/ability-route-helper";
import { useHasAccessAsync } from "@/shared/access-control/composables/use-has-access-async.ts";

export const useAbilityRoute = () => {
    const router = useRouter();
    const abilityStore = useAbilityStore();

    const extractRouteElementsFromRoute = (route: RouteLocationNormalizedLoaded): RouteElementPathWithParams => {
        const result: RouteElementPathWithParams = [];
        const aliases = route.name?.toString().substring(1).split("/");

        aliases?.forEach((alias) => {
            const routeByAlias = findRouteForAlias(abilityStore.abilities, alias);
            if (routeByAlias) {
                const namedParams: Record<string, string> = {};
                for (const paramName of routeByAlias.params) {
                    namedParams[paramName] = route.params[paramName]?.toString();
                }

                result.push({
                    alias: alias,
                    namedParams: namedParams,
                });
            }
        });

        return result;
    };

    /**
     * Ersetzte das letzte Pfadelement durch den neuen childPath mit dem angegebenen Parameter
     * @param childPath
     * @param namedParams
     * @param queryParams
     */
    const getPathToChild = (
        childPath: string,
        namedParams: Record<string, string>,
        queryParams?: LocationQueryRaw
    ): RouteLocationRaw => {
        const currentRoute = router.currentRoute.value;
        const parentElements = extractRouteElementsFromRoute(currentRoute);
        if (parentElements.length > 0 && parentElements[parentElements.length - 1].alias === childPath) {
            // Das letzte Element entfernen, weil wir schon in dieser Route sind
            parentElements.pop();
        }

        // Neues Element dazu
        const newChildElement: RouteElementWithParam = {
            alias: childPath,
            namedParams: namedParams,
        };

        return createRouteFromRouteElements(parentElements, newChildElement, queryParams);
    };

    /**
     * Pfad zu diesem mehreren Childs ohne einen Parent
     * @param routeElements
     * @param queryParams
     */
    const getPathToChildrenWithoutParents = (
        routeElements: RouteElementPathWithParams,
        queryParams?: LocationQueryRaw
    ): RouteLocationRaw => {
        return createRouteFromRouteElements(routeElements, undefined, queryParams);
    };

    // Alle Aliase der Route in der hierarchischen Reihenfolge,
    type RouteElementPath = Array<{
        alias: string;
        params: string[];
    }>;

    // Alle Aliase der Route in der hierarchischen Reihenfolge mit Parametern und BeforeEnterGuard
    type RouteElementPathWithBeforeEnterGuard = Array<{
        alias: string;
        params: string[];
        beforeEnter?: (
            to: RouteLocationNormalizedGeneric,
            from: RouteLocationNormalizedLoadedGeneric,
            next: NavigationGuardNext
        ) => Promise<void>;
    }>;

    const createRoutesFromAbilities = async (abilities: Ability[]): Promise<RouteRecordRaw[]> => {
        const result: RouteRecordRaw[] = [];

        for (const ability of abilities) {
            if (!ability.getRoutes) continue;
            const routes: Route[] = ability.getRoutes();

            for (const item of routes) {
                const routeRecords = await getAccessibleRoutes(item, abilities, ability);
                result.push(...routeRecords);
            }
        }

        return result;
    };

    const getAccessibleRoutes = async (
        route: Route,
        abilities: Ability[],
        ability: Ability
    ): Promise<RouteRecordRaw[]> => {
        if (route.access) {
            const hasAccess = await useHasAccessAsync({
                resource: route.access.accessResource,
                featureID: route.access.accessFeature,
                action: route.access.accessAction,
                ignoreConditions: true,
            });

            if (!hasAccess) return [];
        }

        const parentPaths: RouteElementPath[] = getAllParentPathsWithGuardForRoute(route, abilities);

        return parentPaths.map((parentPath) => {
            const aliasPathWithGuard = addRouteToElementPath(parentPath, route);
            const { name, path } = getRouteNameAndPathFromAliasPath(
                aliasPathWithGuard.map((item) => ({ alias: item.alias, params: item.params }))
            );
            const accessInformation = getRouteAccessInformation(route);

            return {
                name: name,
                path: path,
                component: () => route.getComponent!(),
                meta: {
                    abilityAlias: ability.alias,
                    routeAlias: route.alias,
                    ...(accessInformation ? { access: accessInformation } : {}),
                },
                props: true,
                beforeEnter: async (to, from, next) => {
                    if (aliasPathWithGuard.find((item) => item.beforeEnter !== undefined)) {
                        for (const pathWithGuard of aliasPathWithGuard) {
                            if (pathWithGuard.beforeEnter) {
                                await pathWithGuard.beforeEnter(to, from, next);
                            }
                        }
                    } else {
                        next();
                    }
                },
            };
        });
    };

    const addRouteToElementPath = (
        parentRoute: RouteElementPathWithBeforeEnterGuard,
        route: Route
    ): RouteElementPathWithBeforeEnterGuard => {
        return parentRoute.concat({
            alias: route.alias,
            params: route.params,
            beforeEnter: route.beforeEnter,
        });
    };

    const getRouteAccessInformation = (route: Route): AccessOptions | undefined => {
        const access = {
            ...(route.access?.accessAction ? { accessAction: route.access.accessAction } : {}),
            ...(route.access?.accessFeature ? { accessFeature: route.access.accessFeature } : {}),
            ...(route.access?.accessResource ? { accessResource: route.access.accessResource } : {}),
        };
        return Object.keys(access).length !== 0 ? access : undefined;
    };

    const getRouteNameAndPathFromAliasPath = (routeAliasPath: RouteElementPath): { name: string; path: string } => {
        let name = "";
        let path = "";

        routeAliasPath.forEach((element) => {
            name += "/" + element.alias;
            if (element.params.length) {
                path += `/${element.alias}/:${element.params.join("/:")}`;
            } else {
                path += "/" + element.alias;
            }
        });

        return {
            name: name,
            path: path,
        };
    };

    /**
     * Sucht für die Route alle möglichen Parents, unter denen die Route sein kann. Dabei werden die Parents rekursive durchsucht
     * @param route
     * @param abilities
     * @returns
     */
    const getAllParentPathsWithGuardForRoute = (
        route: Route,
        abilities: Array<Ability>
    ): RouteElementPathWithBeforeEnterGuard[] => {
        const result: RouteElementPathWithBeforeEnterGuard[] = [];
        if (route.isRoot) {
            result.push([]);
        }

        if (route.parentAliases) {
            // Durchsucht alle Abilities die in den parentAliases der Route angegeben sind
            abilities.forEach((element) => {
                element
                    .getRoutes?.()
                    .filter((value) => route.parentAliases?.find((parentAlias) => parentAlias === value.alias))
                    ?.forEach((parentRoute: Route) => {
                        const parentPaths: RouteElementPathWithBeforeEnterGuard[] = getAllParentPathsWithGuardForRoute(
                            parentRoute,
                            abilities
                        );
                        parentPaths.forEach((parentPath) => {
                            result.push(addRouteToElementPath(parentPath, parentRoute));
                        });
                    });
            });
        }

        return result;
    };

    const getBreadCrumbsFromRoute = (
        route: RouteLocationNormalizedLoaded,
        abilityList: Ability[] | undefined,
        translateMethod: (text: string) => string
    ): Array<Array<BreadcrumbItem> | AsyncLoadedBreadCrumbs> | undefined => {
        if (abilityList) {
            const aliasesAndParams = extractRouteElementsFromRoute(route);

            const result: Array<Array<BreadcrumbItem> | AsyncLoadedBreadCrumbs> = [];

            for (let i = 0; i < aliasesAndParams.length; i++) {
                const parentAliasesToCurrentAlias = aliasesAndParams.slice(0, i);

                const breadCrumbs = getBreadCrumbsFromRouteElements(
                    abilityList,
                    parentAliasesToCurrentAlias,
                    aliasesAndParams[i],
                    translateMethod
                );

                if (breadCrumbs) {
                    result.push(breadCrumbs);
                }
            }

            return result;
        } else {
            return undefined;
        }
    };

    const getBreadCrumbsFromRouteElements = (
        abilities: Array<Ability>,
        parentElements: RouteElementPathWithParams,
        currentElement: RouteElementWithParam,
        translateMethod: (text: string) => string
    ): Array<BreadcrumbItem> | AsyncLoadedBreadCrumbs => {
        const route = findRouteForAlias(abilities, currentElement.alias);
        if (route?.getBreadCrumbs) {
            return route.getBreadCrumbs(parentElements, currentElement, translateMethod);
        }
        return [];
    };

    const findRouteForAlias = (abilities: Ability[], alias: string): Route | undefined => {
        for (const ability of abilities) {
            if (ability.getRoutes) {
                for (const route of ability.getRoutes()) {
                    if (route.alias === alias) {
                        return route;
                    }
                }
            }
        }
        return undefined;
    };

    const getCurrentRouteWithNewQueryParam = (param: LocationQueryRaw, keepOldQueryParams = true): RouteLocationRaw => {
        const currentRoute = router.currentRoute.value;

        // Alte und neue Parameter mischen. Falls ein Parameter im neuen undefined ist, dann ist dieser Object.Entry auch undefined
        let newQuery = {
            ...currentRoute.query,
            ...param,
        };

        if (!keepOldQueryParams) {
            newQuery = {
                ...param,
            };
        }

        // Jetzt die undefined Entries entfernen und das Ergebnis in der resultQuery speichern
        let resultQuery = {};
        Object.entries(newQuery).forEach(([key, value]) => {
            if (value) {
                resultQuery = {
                    ...resultQuery,
                    [key]: value,
                };
            }
        });

        if (currentRoute.name) {
            return {
                name: currentRoute.name,
                ...(currentRoute.params && { params: currentRoute.params }),
                query: resultQuery,
            };
        } else {
            return {
                path: currentRoute.path,
                query: resultQuery,
            };
        }
    };

    const addQueryParamToCurrentRoute = async (param: LocationQueryRaw, createNewHistoryEntry: boolean = false) => {
        const newRoute = getCurrentRouteWithNewQueryParam(param);

        if (createNewHistoryEntry) {
            await router.push(newRoute);
        } else {
            await router.replace(newRoute);
        }
    };

    const decodeParamRecordsFromString = (param: string): Record<string, string> => {
        const parsed = qs.parse(param);
        const result: Record<string, string> = {};

        for (const [key, value] of Object.entries(parsed)) {
            if (value && !Array.isArray(value)) result[key] = value?.toString();
        }
        return result;
    };

    /**
     * Path to Root
     * @param queryParams
     */
    const getPathToRoot = (queryParams?: LocationQueryRaw): RouteLocationRaw => {
        return {
            path: "/",
            ...(queryParams && { query: queryParams }),
        };
    };

    /**
     * Pfad zu diesem Child ohne einen Parent
     * @param childPath
     * @param namedParams
     * @param queryParams
     */
    const getPathToChildWithoutParents = (
        childPath: string,
        namedParams: Record<string, string>,
        queryParams?: LocationQueryRaw
    ): RouteLocationRaw => {
        return createRouteFromRouteElements(
            [
                {
                    alias: childPath,
                    namedParams: namedParams,
                },
            ],
            undefined,
            queryParams
        );
    };

    /***
     * Beginnt die aktuelle Route mit den übergebenen Aliasen
     */
    const currentRouteStartsWith = (aliasPath: string[]) => {
        // Baue eine Dummyroute, mit den in der Route angegeben Parametern
        const { name } = getRouteNameAndPathFromAliasPath(
            aliasPath.map((item) => {
                const route = findRouteForAlias(abilityStore.abilities, item);
                return { alias: item, params: route?.params ?? [] };
            })
        );
        return router.currentRoute.value.name?.toString().startsWith(name);
    };

    const getCurrentSearchParams = async () => {
        const routeElements = extractRouteElementsFromRoute(router.currentRoute.value);
        let result: Record<string, string> = {};

        let mergedSearchParams: Record<string, string> = {};
        if (currentRouteStartsWith([CommonRouteAliases.search])) {
            const searchTabParam = router.currentRoute.value.params;
            const searchParams = searchTabParam[CommonRouteAliases.search];
            if (!Array.isArray(searchParams)) {
                const params = decodeParamRecordsFromString(searchParams);
                return { searchParam: params };
            }
        }

        const searchParams = routeElements
            .map((routeElement) => {
                return {
                    currentItem: routeElement,
                    route: findRouteForAlias(abilityStore.abilities, routeElement.alias),
                };
            })
            .filter(({ route }) => {
                return !!route?.getSearchParams;
            })
            .map(({ route, currentItem }) => {
                if (route?.getSearchParams) return route?.getSearchParams(currentItem, router.currentRoute.value.query);
            });

        let searchTab: string | undefined;

        for (const searchParam of searchParams) {
            mergedSearchParams = { ...mergedSearchParams, ...searchParam?.routeParams };
            searchTab = searchParam?.searchTab;
        }

        if (Object.keys(mergedSearchParams).length > 0) {
            result = mergedSearchParams;
        }

        return { searchParam: result, searchTab };
    };

    return {
        extractRouteElementsFromRoute,
        getPathToChild,
        getPathToChildrenWithoutParents,
        createRoutesFromAbilities,
        getRouteNameAndPathFromAliasPath,
        currentRouteStartsWith,
        getBreadCrumbsFromRoute,
        getCurrentSearchParams,
        getPathToChildWithoutParents,
        getPathToRoot,
        decodeParamRecordsFromString,
        getBreadCrumbsFromRouteElements,
        findRouteForAlias,
        addQueryParamToCurrentRoute,
        getCurrentRouteWithNewQueryParam,
        createRouteFromRouteElements,
    };
};
