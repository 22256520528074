<template>
    <q-card class="container-shadow row q-pa-md">
        <div class="col">
            <div class="row">
                <div class="content col q-mx-md">
                    <p class="icon">
                        <i class="fak fa-regular fa-qit-ai-assistant-light icon-light-color fa-2xl" />
                    </p>
                    <div>
                        <h3 class="title">{{ $t("ai-assistant.Ask AI Assistant a question") }}</h3>
                        <div>{{ $t("ai-assistant.Ask a question about this product to AI Assistant") }}</div>
                    </div>
                </div>
                <div class="button-content col">
                    <QitBtn
                        data-qs="open-ai-assistant"
                        :label="$t('ai-assistant.Open AI Assistant')"
                        color="secondary"
                        @click="goToAIAssistant"
                    />
                </div>
            </div>
        </div>
    </q-card>
</template>

<script setup lang="ts">
import QitBtn from "@/shared/components/buttons/qit-button.vue";
import { useRouter } from "vue-router";
import { CommonRouteAliases } from "@/shared/environment/common-route-aliases.ts";
import { WidgetContext } from "@/shared/environment/widgets/widget-context.ts";
import { useAbilityRoute } from "@/shared/environment/ability-route.ts";

const props = defineProps<{
    widgetContext: WidgetContext;
}>();

const router = useRouter();
const { getPathToChildWithoutParents } = useAbilityRoute();

const goToAIAssistant = () => {
    let product = "",
        asset = "";
    if (props.widgetContext.__type === "asset") {
        asset = props.widgetContext.asset;
        product = props.widgetContext.product;
    } else if (props.widgetContext.__type === "product") {
        product = props.widgetContext.productId;
    }

    const pathToChild = getPathToChildWithoutParents(CommonRouteAliases.aiAssistant, {
        ["product"]: product,
        ["asset"]: asset,
    });

    router.push(pathToChild);
};
</script>

<style lang="scss" scoped>
.content {
    display: flex;
    flex: 1 auto;
    p {
        margin: 0;
        .screen--sm &,
        .screen--xs & {
            margin-bottom: $spacing-m;
        }
    }
    .title {
        font-size: 1.14em;
        margin-top: 0;
        margin-bottom: $spacing-m;
    }
}

.button-content {
    padding: $spacing-s;
    .screen--md &,
    .screen--xl &,
    .screen--lg & {
        align-self: self-end;
    }
    .screen--xs & {
        button {
            width: 100%;
        }
    }
    .screen--sm & {
        button {
            width: 50%;
        }
    }
}
i {
    margin-right: $spacing-l;
    display: flex;
    align-items: center;
    width: $icon-xl;
    height: 100%;
}
</style>
