import { gql } from "graphql-tag";

interface ArticlesListQueryOptions {
    withPreviewImages?: boolean;
    datafieldsFragment?: Record<string, string[]>;
    teaserDatafieldsFragment?: Record<string, string[]>;
}

export function createArticlesQuery(options: ArticlesListQueryOptions) {
    const datafieldsFragment = options.datafieldsFragment ?? {};
    const teasersFragment = options.teaserDatafieldsFragment ?? {};

    const allDataFieldFragments = Object.values(datafieldsFragment).join(" ");
    const allLocalizedDataFieldFragments = Object.values(teasersFragment).join(" ");

    let preViewImages = "";
    if (options.withPreviewImages) {
        preViewImages = `previewImage {
            url
            mimeType
        }`;
    }

    const result = `
    query articles(
        $filter: ArticleFilter
        $first: NonNegativeInt
        $after: String
        $sort: [ArticleSort]
        $acceptedLanguages: [LanguageWithWildcard!]
    ) {
        articles(filter: $filter) {
            articles(first: $first, after: $after, sort: $sort) {
                cursor
                node {
                    id
                    articleNumber
                    referenceId
                    orderable
                    teasers {
                        title(acceptedLanguages: $acceptedLanguages)
                        ${allLocalizedDataFieldFragments}
                    }
                    ${preViewImages}
                    ${allDataFieldFragments}
                }
            }
            total {
              count
              countRelation
            }   
        }
            
    }`;
    return gql(result);
}
