import {
    ContentMap,
    Topic,
    useContentInContextQuery,
    useTitleOfContentMapQuery,
} from "@/shared/services/graphql/generated/consumer-graph-types";
import { computed, Ref } from "vue";

type ContentItemForCollection = {
    type: "Topic" | "ContentMap";
    id: string;
    language: string;
    title: string;
    parentTitle?: string;
};

export const useContentItemsForCollection = (contentMapId: Ref<string>, topicId: Ref<string | undefined>) => {
    const { result: context, loading: loadingContext } = useContentInContextQuery(
        computed(() => ({
            rootContentId: contentMapId.value,
            contentId: topicId?.value,
        }))
    );

    const { result: contentMapContext, loading: loadingContentMapContext } = useTitleOfContentMapQuery(
        computed(() => ({
            filter: {
                ids: [`${contentMapId.value}@${contentMapId.value}`],
            },
        }))
    );

    const items = computed(() => {
        let topicTitle = "";
        let topicLanguage = "";

        if (context.value?.contentInContext?.[0]?.node?.__typename === "Topic") {
            const topic = context.value?.contentInContext?.[0].node as Topic;
            topicTitle = topic.teasers?.title ?? "";
            topicLanguage = topic.language || "";
        }

        const contentMap = contentMapContext.value?.contentMaps.contentMaps?.[0]?.node as ContentMap;
        const contentMapTitle = contentMap?.teasers?.title ?? "";
        const contentMapLanguage = contentMap?.language || "";

        const items: ContentItemForCollection[] = [];

        if (topicId?.value && topicTitle)
            items.push({
                type: "Topic",
                id: topicId.value,
                language: topicLanguage,
                title: topicTitle,
                parentTitle: contentMapTitle,
            });

        if (contentMapTitle)
            items.push({
                type: "ContentMap",
                id: contentMapId.value,
                language: contentMapLanguage,
                title: contentMapTitle,
            });

        return items;
    });

    const loading = computed(() => {
        return loadingContext.value || loadingContentMapContext.value;
    });

    return { loading, items };
};
