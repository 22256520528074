import { computed, ref } from "vue";
import { WizardStep } from "./form-types";

export function useWizardNavigation<TModel>(wizardSteps: WizardStep<TModel>[]) {
    const activeStepIndex = ref(0);

    const activeFormValid = ref(Array(wizardSteps.length).fill(false));

    /**
     * @returns The active Form model dependant on the selected activeStepIndex value.
     */
    const activeFormModel = computed(() => {
        return wizardSteps.length > activeStepIndex.value ? wizardSteps[activeStepIndex.value] : undefined;
    });

    /**
     * Sets active Form valid @onFormValidation from form dynamic component.
     * @param valid Whether the form inside the formComponent is valid.
     */
    function setActiveFormValid(valid: boolean) {
        activeFormValid.value[activeStepIndex.value] = valid;
    }

    /**
     * Sets the active value for the selected form in `wizardSteps`, this changes the `activeFormModel`
     * and the state of wizardSteps itself.
     * @param index Index of selected form in wizardSteps array.
     */
    function setActiveValue(index: number) {
        wizardSteps[activeStepIndex.value].active = false;
        activeStepIndex.value = index;
        wizardSteps[activeStepIndex.value].active = true;
    }

    /**
     * Updates the activeFormModel to one step back.
     */
    function goToPreviousStep() {
        setActiveValue(activeStepIndex.value - 1);
    }

    return {
        activeFormModel,
        activeFormValid,
        activeStepIndex,
        setActiveValue,
        setActiveFormValid,
        goToPreviousStep,
    };
}
