<!-- 
    Einfaches Panel, das key und value untereinander anzeigt.
    Wird z.B. in Kacheln als PropertyView verwendet.
    
-->
<template>
    <template v-for="(entry, index) in displayItems" :key="entry.key">
        <div class="property" :style="`${propertyWidth}`" v-if="entry.value" :data-qs="`property-${index}`">
            <div class="qit-property-key" :class="{ 'qit-text-ellipsis': !multiline }">
                {{ entry.title }}
            </div>
            <div v-if="entry.getRoute" class="qit-property-values">
                <div class="product-link-container">
                    <router-link
                        :to="entry.getRoute()"
                        :class="{ 'router-link': true, 'qit-text-ellipsis': !multiline }"
                    >
                        {{ entry.value }}
                    </router-link>
                </div>
            </div>
            <div v-else class="qit-property-values" :class="{ 'qit-text-ellipsis': !multiline }">
                {{ entry.value }}
            </div>
        </div>
    </template>
    <Fragment v-if="isMoreButtonVisible">
        <ToggleCollapseButton :collapsed="toggleState === 'more'" name="property-view-data-config" @on-click="toggle" />
    </Fragment>
</template>

<script setup lang="ts">
import Fragment from "@/shared/components/fragment/fragment.vue";
import { PropertyItem } from "@/shared/components/views/property-item.model";
import { computed, ref } from "vue";
import { RouterLink } from "vue-router";
import ToggleCollapseButton from "@/shared/components/buttons/toggle-collapse-button.vue";
import { useScreenSize } from "@/shared/screen/composables/screen-size";

//Hint: Component is responsive. At screen size XSmall the number of elements to be displayed
//      is set to MAX_DATA_DISPLAY_COMPACT_VIEW and the elements are sorted according to priority flag.
const props = withDefaults(
    defineProps<{
        items: Array<PropertyItem>;
        /**
         * show only count of properties set by fixedDataDisplayCount property without showMoreButton
         */
        fixedDataDisplayCount?: number;
        showMoreButton?: boolean;
        maxPropertyWidth?: number;
        /**
         * show property values as multi line
         */
        multiline?: boolean;
        orderByPriority?: boolean;
    }>(),
    {
        maxDataDisplay: 4,
        showMoreButton: false,
    }
);

const MAX_DATA_DISPLAY_COMPACT_VIEW = 4;
const { isXSmall } = useScreenSize();

const isMoreButtonVisible = computed(() => {
    return props.showMoreButton && !props.fixedDataDisplayCount && props.items.length > MAX_DATA_DISPLAY_COMPACT_VIEW;
});

const displayItems = computed(() => {
    let items: PropertyItem[] = [];

    if (!props.items) return items;

    items = [...props.items];

    if (props.orderByPriority === true || isXSmall.value)
        //order items by priority
        items = items.sort((x, y) => {
            return x.isPriority === y.isPriority ? 0 : x.isPriority ? -1 : 1;
        });

    if (props.fixedDataDisplayCount) {
        //show only as many elements as set in props.fixedDataDisplayCount
        items = items.slice(0, props.fixedDataDisplayCount);

        return items;
    }

    if (isXSmall.value) {
        //responsive behaviour mobile
        //limit visible elements
        //- if isMoreButtonVisible should not be shown eg. prop.showMoreButton is false
        //- if isMoreButtonVisible should be shown and toggleState is more
        if (!isMoreButtonVisible.value || (isMoreButtonVisible.value && toggleState.value === "more"))
            items = items.slice(0, MAX_DATA_DISPLAY_COMPACT_VIEW);
    } else if (isMoreButtonVisible.value && toggleState.value === "more") {
        //responsive behaviour desktop
        items = items.slice(0, MAX_DATA_DISPLAY_COMPACT_VIEW);
    }

    return items;
});

const propertyWidth = computed(() => {
    return props.maxPropertyWidth ? `max-width: ${props.maxPropertyWidth}px;` : "";
});

const toggleState = ref<"more" | "less">("more");

const toggle = () => {
    if (toggleState.value === "more") showMore();
    else showLess();
};

const showMore = () => {
    toggleState.value = "less";
};

const showLess = () => {
    toggleState.value = "more";
};
</script>

<style lang="scss" scoped>
.property {
    padding-top: 12px;
    min-width: 0;
}

.qit-property-values {
    word-break: break-word;
}
</style>
