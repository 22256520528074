<template>
    <q-btn
        unelevated
        :ripple="false"
        padding="sm"
        :icon="isXSmall ? mobileIcon : icon"
        size="13px"
        data-qs="show-info-menu-button"
        class="grey-hover"
    >
        <q-menu v-model="show">
            <q-list dense>
                <q-item clickable v-for="staticPage in staticPages" :key="staticPage.key">
                    <q-item-section :data-qs="`static-pages-${staticPage.key}`">
                        <router-link :to="staticPage.to" class="no-underline">
                            {{ staticPage.title }}
                        </router-link>
                    </q-item-section>
                </q-item>
            </q-list>
        </q-menu>
        <q-tooltip>{{ $t("core.Info") }}</q-tooltip>
    </q-btn>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { useScreenSize } from "@/shared/screen/composables/screen-size";
import { StaticPage } from "@/shared/composables/static-pages";

const show = ref(false);

defineProps<{
    menuTitleTranslationKey: string;
    icon: string;
    mobileIcon: string;
    staticPages: StaticPage[];
}>();

const { isXSmall } = useScreenSize();
</script>
