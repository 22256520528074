import { generateAggregationsFromFacet } from "@/shared/facets/composables/facets-aggregation";
import { Facet } from "@/shared/facets/facets.model";
import gql from "graphql-tag";

export function generateDynamicSearchMechanicArticlesOnlyAggregationsQuery(
    facet: Facet,
    keywordSearchValue: string = ""
) {
    return gql`
            query searchMechanicArticlesOnlyAggregations($filter: MechanicFilter, $assetId: ID, $productId: ID) {
                mechanics(filter: $filter, assetId: $assetId, productId: $productId)
                    ${generateAggregationsFromFacet(facet, keywordSearchValue)}
            }
        `;
}

export function generateDynamicSearchArticlesOnlyAggregationsQuery(facet: Facet, keywordSearchValue: string = "") {
    return gql`
            query searchArticlesOnlyAggregations($filter: ArticleFilter) {
                articles(filter: $filter)
                    ${generateAggregationsFromFacet(facet, keywordSearchValue)}
            }
        `;
}
