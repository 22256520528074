import { computed, onMounted, ref, Ref } from "vue";
import { useAbilityStore } from "@/shared/store/ability.store";
import { useI18n } from "vue-i18n";
import { AbilityMenuEntries, MenuEntryWithMetaData } from "@/shared/environment/ability.types";
import { MenuEntry } from "@/shared/components/menu/menu.model";
import { insertObjectAtPosition } from "@/shell/helpers/sort-helper";
import { MenuContext } from "@/shared/environment/menu/menu.types";

export function useMenuEntriesFromAbilities(menuContext: Ref<MenuContext>) {
    const abilityStore = useAbilityStore();
    const { t } = useI18n();
    const menuEntriesFromAbility = ref<AbilityMenuEntries[]>();

    onMounted(async () => {
        const result: AbilityMenuEntries[] = [];

        for (const ability of abilityStore.abilities) {
            if (ability?.getMenuEntries) {
                const abilityMenuEntries = ability.getMenuEntries(menuContext, t);
                result.push(abilityMenuEntries);
            }
        }

        menuEntriesFromAbility.value = result;
    });

    const menuEntries = computed(() => {
        if (!menuEntriesFromAbility.value) return [];

        const mapped = menuEntriesFromAbility.value.map((abilityMenuEntry: AbilityMenuEntries) => {
            return abilityMenuEntry.menuEntries;
        });

        const sortedMenuEntries: MenuEntry[] = [];

        //@ts-ignore
        mapped.flat().forEach((item: MenuEntryWithMetaData) => {
            if (item) insertObjectAtPosition(sortedMenuEntries, item.entry, item.position);
        });

        return sortedMenuEntries;
    });

    return {
        menuEntries,
    };
}
