<template>
    <PersistentSortDropdown
        v-if="sortSaveKey && sortOrderEntries"
        :sort-order-entries="sortOrderEntries"
        :sort-save-key="sortSaveKey"
        :initial-selected-sort-entry="initialSelectedSortEntry"
        v-model:selected-sort-entry="selectedSortEntry"
    />
    <div v-if="moreMenuEntries" data-qs="list-frame-with-toolbar-three-dot-menu">
        <div class="row items-center more-menu-container">
            <MoreMenu :entries="moreMenuEntries" />
        </div>
    </div>
</template>

<script setup lang="ts">
import MoreMenu from "@/shared/components/menu/more-menu.vue";
import { SortEntry, SortOrderEntry } from "@/shared/components/sort-dropdown/sort-dropdown.model";
import { MenuComponentEntry, MenuTextEntry } from "@/shared/components/menu/menu.model";
import { ref, toRefs } from "vue";
import { SortSaveKey } from "@/shared/components/sort-dropdown/composables/sort-settings";
import PersistentSortDropdown from "@/shared/components/sort-dropdown/persistent-sort-dropdown.vue";
import { watchImmediate } from "@vueuse/core";

const props = defineProps<{
    sortOrderEntries?: SortOrderEntry[];
    initialSelectedSortEntry?: SortEntry;
    moreMenuEntries?: (MenuTextEntry | MenuComponentEntry)[];
    sortSaveKey: SortSaveKey;
}>();

const emits = defineEmits<{
    onSortSelectionChanged: [sortEntry: SortEntry];
}>();
const { moreMenuEntries } = toRefs(props);

const selectedSortEntry = ref<SortEntry>();

watchImmediate(selectedSortEntry, () => {
    if (!selectedSortEntry.value) return;
    emits("onSortSelectionChanged", selectedSortEntry.value);
});
</script>
