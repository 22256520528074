import { useFacetAutocompleteLazyQuery } from "@/shared/services/graphql/generated/consumer-graph-types";
import { Language } from "@/shared/services/graphql/generated/public-graph-types";
import { computed, ComputedRef, Ref } from "vue";
import { CommonSearchTabAliases } from "@/shared/environment/common-route-aliases";
import { intersection } from "lodash";
import { DataDisplayConfigId } from "@/shared/data-display-config/composable/data-display.model";
import { SearchTab } from "@/shared/environment/ability.types";
import { getDatabaseLanguage, getDataQueryLanguages } from "@/shared/services/providers/language-provider";
import { useLazyQuery } from "@vue/apollo-composable";
import { generateSearchSuggestionQuery } from "./graphql/domain-autocomplete.query";

/**
 * Represents a content suggestion with its title and associated search tab.
 */
export interface DomainSuggestion {
    title: string;
    searchTabAlias: CommonSearchTabAliases; // translatable alias of the searchtab
}

/**
 * Represents a facet suggestion with its title, parent title, search tabs, datafield definition ID, and value.
 */
export interface FacetSuggestion {
    title: string;
    parentTitle?: string;
    searchTabAliases?: CommonSearchTabAliases[]; // translatable alias of the searchtab
    datafieldDefinitionId: string;
    value: string;
}

/**
 * Provides autocomplete functionality for search input and search tabs.
 * @param searchInputValue - The value of the search input.
 * @param searchTabs - The available search tabs.
 * @param searchParams - The search state. Contains the search parameters and search tab.
 * @returns An object containing the autocomplete functionality.
 */
export const useAutoComplete = (
    searchInputValue: Ref<string>,
    searchTabs: Ref<SearchTab[]>,
    searchParams: ComputedRef<Record<string, string>>
) => {
    // The search tab dictionary
    const searchTabDictionary = {
        [CommonSearchTabAliases.assets]: [DataDisplayConfigId.assetSelection],
        [CommonSearchTabAliases.commonArticles]: [DataDisplayConfigId.articleSelection],
        [CommonSearchTabAliases.products]: [DataDisplayConfigId.productSelection],
        [CommonSearchTabAliases.documentations]: [DataDisplayConfigId.documentSelection],
        [CommonSearchTabAliases.activities]: [DataDisplayConfigId.activitiesSelection],
    };

    // The search tabs aliases
    const searchTabsAliases = computed(() => {
        return searchTabs.value.map((x) => x.alias);
    });

    const PRODUCT_QUERY_PARAM = "product";

    // The mechanic filter
    const mechanicFilter = computed(() => {
        if (!searchParams.value[PRODUCT_QUERY_PARAM]) return null;
        // If the search state value has a product key, return the product filter
        // In the mechanic query, the product field is product and not productId
        return { equals: { product: searchParams.value[PRODUCT_QUERY_PARAM] } };
    });

    // The asset filter
    const assetFilter = computed(() => {
        if (!searchParams.value[PRODUCT_QUERY_PARAM]) return null;
        return { equals: { productId: searchParams.value[PRODUCT_QUERY_PARAM] } };
    });

    /**
     * The search suggestions result.
     * @returns The search suggestions result.
     * @type {ComputedRef<{ [key: string]: { hits: { suggest: string }[] } }>}
     */
    const {
        result: suggestionsResult,
        loading: suggestionsLoading,
        load: suggestionsLoad,
        error: suggestionsError,
    } = useLazyQuery(
        generateSearchSuggestionQuery(searchTabsAliases),
        computed(() => {
            return {
                query: searchInputValue.value,
                assetFilter: assetFilter.value,
                mechanicFilter: mechanicFilter.value,
            };
        }),
        computed(() => {
            return {
                enabled: searchInputValue.value.length > 2,
            };
        })
    );

    /**
     * The selection of search tabs.
     * @returns The selection of search tabs.
     * @type {ComputedRef<string[]>}
     */
    const selection = computed(() => {
        const val: string[] = [];

        searchTabsAliases.value.forEach((x) => {
            if (searchTabDictionary[x]) val.push(...searchTabDictionary[x]);
        });

        return val;
    });

    /**
     * The facet autocomplete result, including facet suggestions.
     * @returns The facet autocomplete result.
     * @type {ComputedRef<{ facetSuggestions: FacetSuggestion[] }>}
     */
    const {
        result: facetAutocompleteResult,
        loading: facetAutocompleteLoading,
        load: facetAutocompleteLoad,
        error: facetAutocompleteError,
    } = useFacetAutocompleteLazyQuery(
        computed(() => {
            return {
                filter: {
                    filterSelection: selection.value,
                    languages: [getDatabaseLanguage()] as Language[],
                    query: searchInputValue.value,
                },
                acceptedLanguages: getDataQueryLanguages(),
                first: 5,
            };
        }),
        computed(() => {
            return {
                enabled: searchInputValue.value.length > 2,
            };
        })
    );

    /**
     * Loads the autocomplete suggestions.
     * This includes content suggestions and facet suggestions.
     * @returns The autocomplete suggestions.
     * @type {() => void}
     */
    const load = () => {
        suggestionsLoad(generateSearchSuggestionQuery(searchTabsAliases));
        facetAutocompleteLoad();
    };

    /**
     * The autocomplete result, including content suggestions and facet suggestions.
     * @returns The autocomplete result.
     * @type {ComputedRef<{ domainSuggestions: DomainSuggestion[]; facetSuggestions: FacetSuggestion[] }>}
     */
    const result = computed(() => {
        const facetSuggestions: FacetSuggestion[] =
            facetAutocompleteResult.value?.facetDatafieldAutocomplete.hits.map((x) => {
                const parentTitle = x.datafieldDefinition?.teasers?.title ?? "";
                const searchTabsReturn: CommonSearchTabAliases[] = [];

                for (const key of Object.keys(searchTabDictionary)) {
                    if (
                        x.filterSelection &&
                        intersection(searchTabDictionary[key as CommonSearchTabAliases], x.filterSelection as any)
                            .length > 0
                    ) {
                        const searchTab = searchTabs.value.find((x) => x.alias === key) as SearchTab;
                        if (searchTab) searchTabsReturn.push(searchTab.alias);
                    }
                }

                return {
                    value: x.value,
                    title: x.displayName,
                    parentTitle,
                    datafieldDefinitionId: x.datafieldDefinitionId,
                    searchTabAliases: searchTabsReturn,
                };
            }) || [];

        // Constants for limiting the number of hits per context and content suggestions
        const MAX_HITS_PER_CONTEXT = 5;
        const MAX_CONTENT_SUGGESTIONS = 7;
        // Initialize the content suggestions
        const domainSuggestions: DomainSuggestion[] = [];
        // Check if the suggestions result has a value
        // The suggestions result contains the search suggestions for each context key
        // Only add content suggestions if the suggestions result has a value
        if (suggestionsResult.value) {
            // Map context keys to search tabs
            const contextKeyToTabMap: { [key: string]: CommonSearchTabAliases } = {
                productAutocomplete: CommonSearchTabAliases.products,
                assetAutocomplete: CommonSearchTabAliases.assets,
                articleAutocomplete: CommonSearchTabAliases.commonArticles,
                mechanicAutocomplete: CommonSearchTabAliases.commonArticles,
                contentAutocomplete: CommonSearchTabAliases.documentations,
                activityAutocomplete: CommonSearchTabAliases.activities,
            };

            // Add content suggestions
            for (let i = 1; i < MAX_HITS_PER_CONTEXT; i++) {
                // Limit the number of content suggestions
                if (domainSuggestions.length >= MAX_CONTENT_SUGGESTIONS) {
                    break;
                }
                // Iterate over the suggestions result
                Object.entries(suggestionsResult.value).forEach(([contextKey, suggestionResult]: [string, any]) => {
                    // Check if the suggestion result has hits and the hits array has a length greater than i
                    if (suggestionResult?.hits && suggestionResult.hits.length >= i) {
                        // Get the search tab alias for the context key
                        const tabAlias = contextKeyToTabMap[contextKey];
                        // Find the search tab with the alias
                        const searchTab = searchTabs.value.find((x) => x.alias === tabAlias) as SearchTab;
                        if (searchTab && domainSuggestions.length < MAX_CONTENT_SUGGESTIONS) {
                            domainSuggestions.push({
                                // Add the suggestion to the content suggestions
                                // The suggestion is the value of the suggest field in the hits array
                                // use the i-1 index to get the suggestion from the hits array
                                title: suggestionResult.hits[i - 1].suggest,
                                searchTabAlias: searchTab.alias,
                            });
                        }
                    }
                });
            }
        }
        // Sort the content suggestions by tab alias
        domainSuggestions.sort((a, b) => a.searchTabAlias.localeCompare(b.searchTabAlias));

        return {
            domainSuggestions,
            facetSuggestions,
        };
    });

    /**
     * The loading state of the autocomplete.
     * @returns The loading state of the autocomplete.
     * @type {ComputedRef<boolean>}
     */
    const loading = computed(() => {
        return suggestionsLoading.value || facetAutocompleteLoading.value;
    });

    /**
     * The error state of the autocomplete.
     * @returns The error state of the autocomplete.
     * @type {ComputedRef<Error>}
     */
    const error = computed(() => {
        return facetAutocompleteError.value || suggestionsError.value;
    });
    // Return the autocomplete functionality
    return {
        error,
        loading,
        load,
        result,
    };
};
