<template>
    <div class="sort-dropdown">
        <q-btn-dropdown
            dropdown-icon="fa-solid fa-caret-down"
            :ripple="false"
            :label="getTitleById(selectedId)"
            no-caps
            :style="`width: ${width}px`"
            data-qs="sort-combobox"
            text-color="primary"
        >
            <q-list dense>
                <q-item
                    v-for="item in sortOrderItems"
                    :key="item.id"
                    clickable
                    v-close-popup
                    @click="handleDropdownClick(item.id)"
                    :class="{ selected: selectedId === item.id }"
                >
                    <q-item-section>
                        <q-item-label>{{ item.title }}</q-item-label>
                    </q-item-section>
                </q-item>
            </q-list>
        </q-btn-dropdown>
    </div>
</template>

<script setup lang="ts">
import { SortOrderEntry } from "./sort-dropdown.model";

const props = defineProps<{
    sortOrderItems: SortOrderEntry[];
    selectedId: string;
    width?: number;
}>();

function getTitleById(id: string) {
    const item = props.sortOrderItems.find((x) => x.id === id);
    return item ? item.title : "";
}

const emit = defineEmits<{
    (e: "onSelectionChanged", selected: SortOrderEntry): void;
}>();

function handleDropdownClick(itemId: string) {
    const item = props.sortOrderItems.find((x) => x.id === itemId);
    if (item) {
        emit("onSelectionChanged", item);
    }
}
</script>

<style lang="scss" scoped>
.selected {
    background: var(--q-highlight);
}
</style>
