<template>
    <div v-if="isLoadingOrError" class="small-content-container fit">
        <div class="loading-container" v-if="loading">
            <q-spinner-ios size="2em" />
        </div>
        <ErrorMessage v-else-if="error" :error-message="$t('core.Oops, something went wrong')" />
        <ErrorMessage
            v-else-if="noResults"
            :error-message="noResultsErrorMessage ?? t('core.No data available')"
            :icon="noResultIcon"
        />
    </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import ErrorMessage from "@/shared/components/placeholder/error-message.vue";
import { ApolloError } from "@apollo/client/errors";

const props = withDefaults(
    defineProps<{
        noResults?: boolean;
        noResultsErrorMessage?: string;
        loading?: boolean;
        error?: ApolloError | null;
        noResultIcon?: string;
    }>(),
    {
        noResults: false,
        loading: false,
        noResultIcon: "fa-thin fa-cloud-question icon-light-color fa-4x",
    }
);

const { t } = useI18n({ useScope: "global" });

const isLoadingOrError = computed(() => {
    return props.loading || props.error || props.noResults;
});
</script>

<style lang="scss" scoped>
.loading-container {
    left: 50%;
    top: 50%;
    position: absolute;
    text-align: center;

    .screen--xs & {
        padding-top: $spacing-xl;
        position: static;
    }
}
</style>
