<template>
    <div class="row fit">
        <div class="col" style="max-width: 150px" v-if="$q.screen.gt.sm">
            <div>
                <!-- @slot Side navigation for the left `EnumeratedNavgation` component. -->
                <slot name="navigation" />
            </div>
        </div>

        <div class="col fit">
            <div class="form fit">
                <!-- @slot Default slot for displaying the form component. -->
                <slot />
            </div>
        </div>

        <div class="col" style="max-width: 250px" v-if="$q.screen.gt.sm">
            <!-- @slot Slot for displaying the information detail-card component. -->
            <slot name="information" />
        </div>
    </div>
</template>
