<template>
    <div class="warning-box"></div>
</template>

<script setup lang="ts">
import { computed } from "vue";

const props = defineProps({
    height: {
        type: Number,
        default: 200,
    },
});

const boxHeight = computed(() => {
    return `${props.height}px`;
});
</script>

<style lang="scss" scoped>
.warning-box {
    height: v-bind(boxHeight);
    width: 125px;
    background: $warning-bg-color;
}
</style>
