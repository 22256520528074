<template>
    <AssetsOverview
        :product-id="currentProductSelection"
        :show-jump-to-product="false"
        :emit-selection-in-tiles="!isLastLevel || emitLastLevelSelection"
        :selection-data="selectionData"
        @select="onSelect"
        dense
        :is-search="false"
    />
</template>

<script setup lang="ts">
import { QualificationLevelSelectionResult } from "@/shared/environment/ability.types";
import { computed } from "vue";
import AssetsOverview from "./assets-overview.vue";

const props = defineProps<{
    selectionData: QualificationLevelSelectionResult;
    isLastLevel: boolean;
    emitLastLevelSelection?: boolean;
}>();

const emit = defineEmits<{
    (e: "select", selectionResult: QualificationLevelSelectionResult): void;
}>();

const currentProductSelection = computed(() => {
    return props.selectionData.product;
});

const onSelect = (productId: string, assetId: string) => {
    const result: QualificationLevelSelectionResult = {
        ...props.selectionData,
        asset: assetId,
        product: productId,
    };
    emit("select", result);
};
</script>

<style lang="scss" scoped>
:deep(.preview-image) {
    height: 100%;
}
</style>
