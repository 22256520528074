<template>
    <Card
        :title="$t(title)"
        @on-close-button-clicked="$emit('onCloseButtonClicked')"
        :key="locale"
        data-qs="article-import-wizard"
        class="small-content-container"
        white-background
        container
    >
        <div v-show="processesLoading" class="loading">
            <q-spinner-ios size="2em" />
        </div>
        <ConfigurationFormWrapper :key="activeStepIndex">
            <template #navigation>
                <EnumeratedNavigation v-model="wizardSteps" />
            </template>
            <component
                :is="activeFormModel?.formComponentDefinition.getComponent()"
                v-model="wizardSteps"
                v-bind="{ ...activeFormModel?.formComponentDefinition.props, ...{ entryId: entryId } }"
                @on-form-validation="setActiveFormValid"
            />
            <template #information>
                <InformationCard>
                    <component :is="activeFormModel?.informationComponentDefinition.getComponent()" />
                </InformationCard>
            </template>
        </ConfigurationFormWrapper>
        <template #footer>
            <component
                :is="activeFormModel?.footerComponentDefinition.getComponent()"
                v-bind="footerProps"
                :key="footerProps"
                @go-to-previous-step="goToPreviousStep"
                @go-to-next-step="goToNextStep"
            />
        </template>
    </Card>
</template>

<script setup lang="ts">
/**
 * This component manages the active state of the configuration form.
 * @displayName ArticleImportWizard
 * @example `
 *      Used in .ability.ts file.
 *      getDataProcessTypes = (): Array<DataProcessType> => [
 *      {
 *          type: ProcessType.product,
 *          dialogFormComponent: {
 *              getComponent: () => ProductImportWizard,
 *              ...
 * `
 */
import { computed, onMounted, ref, watch } from "vue";
import Card from "@/shared/components/card/card.vue";
import ConfigurationFormWrapper from "@/shared/components/data-process/configuration-form-wrapper.vue";
import InformationCard from "@/shared/components/data-process/information-card.vue";
import { AssignStepModel, useWizardSteps } from "./article-import-wizard.model";
import EnumeratedNavigation from "@/shared/components/data-process/enumerated-navigation.vue";
import {
    ProcessType,
    useCreateProcessMechanicMutation,
    useUpdateProcessMechanicMutation,
} from "@/shared/services/graphql/generated/admin-graph-types";
import { ApolloError } from "@apollo/client/core";
import { useWizardNavigation } from "@/shared/components/data-process/wizard-navigation";
import { useI18n } from "vue-i18n";
import { useMechanicProcessLazyQuery } from "@/shared/services/graphql/generated/consumer-graph-types";
import { getUiLanguage } from "@/shared/services/providers/language-provider";
import { CommonStepModel } from "@/shared/components/data-process/form-types";
import { DEFAULT_DELIMITER, DEFAULT_ENCODING, DEFAULT_ESCAPE } from "@/shared/components/data-process/form-constants";
import { useQuasar } from "quasar";

const props = defineProps({
    /**
     * The Title of the Card.
     * @example Neuen Prozess anlegen (Produktinformationen)
     */
    title: {
        type: String,
        default: "",
    },
    /**
     * When editing the process, an Id should be available from this prop, which prevents
     * invalidating the input name when the process already has a name and only makes a CRUD change.
     * Gets passed onto formComponent.
     * @example '_LYfvjuMw85qP9'
     */
    entryId: {
        type: String,
        default: "",
    },
});

/**
 * @onCreateProcess Process has been created.
 * @onCloseButtonClicked Close Button has been clicked.
 */
const emit = defineEmits(["onCreateProcess", "onCloseButtonClicked"]);

const locale = computed(() => {
    return getUiLanguage();
});

const { notify } = useQuasar();
const { t } = useI18n({ useScope: "global" });

const { wizardSteps } = useWizardSteps();

const {
    mutate: updateProcess,
    onDone: onProcessUpdated,
    onError: onProcessUpdateError,
    loading: onProcessUpdating,
} = useUpdateProcessMechanicMutation({ clientId: "admin" });

const {
    mutate: createProcess,
    onDone: onProcessCreated,
    onError: onProcessCreateError,
    loading: onProcessCreateLoading,
} = useCreateProcessMechanicMutation({ clientId: "admin" });

const {
    load: loadProcesses,
    result: process,
    loading: processesLoading,
} = useMechanicProcessLazyQuery({
    processId: props.entryId,
});

const { activeFormModel, activeFormValid, activeStepIndex, setActiveValue, setActiveFormValid, goToPreviousStep } =
    useWizardNavigation<CommonStepModel & AssignStepModel>(wizardSteps);

onMounted(() => {
    if (props.entryId) {
        loadProcesses();
    }
});

/**
 * The CommonStep model as part of FormModel[].
 */
const commonStepModel = ref(wizardSteps.find((model) => model.id === "commonStep")?.model ?? undefined);

/**
 * @returns The footer properties for footerComponent and continueDisabled, loading & loadingText attributes.
 */
const footerProps = computed(() => {
    return {
        ...(activeFormModel.value && activeFormModel.value.footerComponentDefinition.props),
        ...{
            continueDisabled:
                !activeFormValid.value[activeStepIndex.value] ||
                onProcessCreateLoading.value ||
                onProcessUpdating.value,
        },
    };
});

onProcessCreated((data) => {
    notify({
        message: `${t("core.Process")} '${commonStepModel.value?.name.value ?? ""}' ${t("core.was created")}.`,
    });
    emit("onCloseButtonClicked");
    emit("onCreateProcess", { id: data.data?.createProcess, name: commonStepModel.value?.name.value });
});

onProcessUpdated(() => {
    notify({
        message: `${t("core.Process")} '${commonStepModel.value?.name.value ?? ""}' ${t("core.was updated")}.`,
    });
    emit("onCloseButtonClicked");
});

onProcessCreateError((e: ApolloError) => {
    console.error(e.message);
});

onProcessUpdateError((e: ApolloError) => {
    console.error(e.message);
});

/**
 * Updates the activeFormModel to one step forth and handles the Create Process button at the end of the form,
 * which fires a mutate query to create the process.
 */
function goToNextStep() {
    if (activeStepIndex.value === wizardSteps.length - 1) {
        if (props.entryId) {
            updateProcess({
                id: props.entryId,
                name: commonStepModel.value?.name.value ?? "",
                columns: commonStepModel.value?.headers.value ?? [],
                deleteAll: commonStepModel.value?.deleteAll?.value ?? false,
                delimiter: commonStepModel.value?.delimiter?.value ?? DEFAULT_DELIMITER,
                escape: commonStepModel.value?.escape?.value ?? DEFAULT_ESCAPE,
                encoding: commonStepModel.value?.encoding?.value ?? DEFAULT_ENCODING,
            });
        } else {
            createProcess({
                spec: {
                    processType: ProcessType.article,
                    name: commonStepModel.value?.name.value ?? "",
                },
                columns: commonStepModel.value?.headers.value ?? [],
                deleteAll: commonStepModel.value?.deleteAll?.value ?? false,
                delimiter: commonStepModel.value?.delimiter?.value ?? DEFAULT_DELIMITER,
                escape: commonStepModel.value?.escape?.value ?? '"',
                encoding: commonStepModel.value?.encoding?.value ?? DEFAULT_ENCODING,
            });
        }
    } else {
        setActiveValue(activeStepIndex.value + 1);
    }
}

// sets form values from process query when in editing mode
watch(process, () => {
    if (process.value && commonStepModel.value && commonStepModel.value.deleteAll) {
        commonStepModel.value.name.value = process.value.process?.name ?? "";
        commonStepModel.value.name.valid = true;
        commonStepModel.value.headers.value = (process.value.process?.columns as string[]) ?? [""];
        commonStepModel.value.headers.valid = true;
        commonStepModel.value.headers.messageKey = "core.Columns already exist but can be overwritten";
        commonStepModel.value.deleteAll.value = process.value.process?.deleteAll ?? false;
        commonStepModel.value.delimiter.value = process.value.process?.delimiter ?? DEFAULT_DELIMITER;
        commonStepModel.value.escape.value = process.value.process?.escape ?? DEFAULT_ESCAPE;
        commonStepModel.value.encoding.value = process.value.process?.encoding ?? DEFAULT_ENCODING;

        const assignStepFooter = wizardSteps.find((model) => model.id === "assignStep")?.footerComponentDefinition;
        if (assignStepFooter) {
            assignStepFooter.props = {
                continueText: "core.Update Process",
                showBackButton: true,
                processType: "update-article",
            };
        }
    }
});

defineExpose({ setActiveValue });
</script>

<style lang="scss" scoped>
.loading {
    background: white;
    display: flex;
    width: 100%;
    height: 100%;
    z-index: 99;
    justify-content: center;
    align-items: center;
}
</style>
