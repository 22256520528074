import { useEditableCollectionsQuery } from "@/shared/services/graphql/generated/consumer-graph-types";
import { computed } from "vue";

export const useEditableCollections = () => {
    const { result, loading, refetch } = useEditableCollectionsQuery();

    const collections = computed(() => {
        if (result.value?.editableCollections.collections) {
            return result.value?.editableCollections.collections.map((collection) => {
                return {
                    id: collection?.node?.id!,
                    title: collection?.node?.displayName!,
                };
            });
        }
        return [];
    });

    return { loading, collections, refetch };
};
