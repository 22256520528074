import { ComputedRef, computed, ref } from "vue";
import {
    useMetadataDefinitionsWithLocalizationsQuery,
    ProcessType,
    LanguageWithWildcard,
    useProcessDatafieldMappingsQuery,
} from "@/shared/services/graphql/generated/consumer-graph-types";
import { useI18n } from "vue-i18n";
import { WizardStep } from "./form-types";

export function useAssignStepModel() {
    const { t } = useI18n();

    const columns = computed(() => {
        return [
            {
                name: "columnId",
                label: t("core.Column (template file)"),
                field: "columnId",
                align: "left",
            },
            {
                name: "assign-icon",
                label: "",
                field: "assign-icon",
                align: "center",
            },
            {
                name: "data-field",
                label: t("core.Data field (InfoTwin)"),
                field: "data-field",
                align: "left",
            },
            {
                name: "specification",
                label: t("core.Specification"),
                field: "specification",
                align: "right",
            },
        ];
    });

    function showFromTo(operator: string) {
        if (operator === "gte") {
            return t("core.From");
        } else if (operator === "lte") {
            return t("core.To");
        } else return "";
    }

    return {
        columns,
        showFromTo,
    };
}

export function useProcessDefinitionsAndMetadataDefinitions(
    processType: ProcessType,
    languages: ComputedRef<LanguageWithWildcard[]>
) {
    const queryFilters = computed(() => {
        return {
            first: 200,
            acceptedLanguages: languages.value,
        };
    });

    const { result: metaDataDefinitions, refetch: refetchMetadataDefinitions } =
        useMetadataDefinitionsWithLocalizationsQuery(queryFilters);

    function getProcessDefinitions() {
        if (!metaDataDefinitions.value) {
            return [];
        }
        switch (processType) {
            case ProcessType.product:
                return [getProcessDefinitionsFromMetadata("system_product_id")];
            case ProcessType.asset:
                return [
                    getProcessDefinitionsFromMetadata("system_assetId"),
                    getProcessDefinitionsFromMetadata("system_productId"),
                ];
            case ProcessType.mechanic:
                return [
                    getProcessDefinitionsFromMetadata("system_level"),
                    getProcessDefinitionsFromMetadata("system_parentAssemblyId"),
                    getProcessDefinitionsFromMetadata("system_assemblyId"),
                ];
            case ProcessType.assetOption:
                return [
                    getProcessDefinitionsFromMetadata("system_option"),
                    getProcessDefinitionsFromMetadata("system_productId"),
                    getProcessDefinitionsFromMetadata("system_assetId"),
                ];
            case ProcessType.article:
                return [getProcessDefinitionsFromMetadata("system_article_id")];
            default:
                return [];
        }
    }

    function getProcessDefinitionsFromMetadata(systemField: string) {
        return metaDataDefinitions.value?.fieldDefinitions?.fieldDefinitions.find(
            (definition) => definition?.node.id === systemField
        )?.node;
    }

    return {
        getProcessDefinitions,
        metaDataDefinitions,
        refetchMetadataDefinitions,
    };
}

export function useProcessDatafieldMappings(
    processType: ProcessType,
    languages: ComputedRef<LanguageWithWildcard[]>,
    modelValue: WizardStep<any>[]
) {
    const commonStepHeaders = ref<string[] | undefined>(
        modelValue.find((model) => model.id === "commonStep")?.model?.headers.value ?? undefined
    );

    const {
        result: processDatafieldMappingsResult,
        loading: processDatafieldMappingsLoading,
        restart: restartProcessDatafieldMappings,
    } = useProcessDatafieldMappingsQuery(
        {
            processType: processType,
            columns: commonStepHeaders.value ?? [],
            acceptedLanguages: languages.value,
        },
        { fetchPolicy: "no-cache" }
    );

    return {
        processDatafieldMappingsResult,
        processDatafieldMappingsLoading,
        restartProcessDatafieldMappings,
    };
}
