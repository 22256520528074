import { Ability, NavigationMenuEntry } from "@/shared/environment/ability.types";
import { AccessAction, AccessResource } from "@/shared/access-control/access-control";
import { keyText } from "@/shared/i18n/translation-types";

export const ADMIN_LINK_ALIAS = "admin-link";

export class AdminLinkAbility implements Ability {
    alias = ADMIN_LINK_ALIAS;
    getNavigationMenuEntries = (): Array<NavigationMenuEntry> => [
        {
            icon: "fa-regular fa-sliders-simple",
            title: keyText("admin-link.Administration"),
            externalRoute: {
                link: "/admin",
                access: {
                    accessAction: AccessAction.access,
                    accessResource: AccessResource.admin_area,
                },
            },
        },
    ];
}
