import { RouteLocationRaw } from "vue-router";
import { EMPTY_PARAM } from "@/shared/environment/ability-route-helper";
import { CommonRouteAliases } from "@/shared/environment/common-route-aliases";
import { SearchContext } from "@/shared/search/search.types";
import { RouteElementWithParam } from "@/shared/environment/ability.types";
import { ContentNode } from "@/shared/router/document-route-helper";
import { useAbilityRoute } from "@/shared/environment/ability-route";

export const useDocumentRoute = () => {
    const { getPathToChild, getPathToChildrenWithoutParents } = useAbilityRoute();

    const getPathToDocumentationDetailPage = (contentMapId: string, topicId: string): RouteLocationRaw => {
        return getPathToChild(CommonRouteAliases.documentationDetail, {
            contentMapId,
            topicId,
        });
    };

    const getContentPath = (content: ContentNode, highlights: string[], context?: SearchContext) => {
        const isTopic = (content as any).__typename === "Topic";
        const splitId = content.id.split("@");
        if (isTopic) {
            if (!splitId) return;
            return getPathToDocumentationDetailPageForSearch(splitId[1], splitId[0], highlights, context);
        } else {
            return getPathToDocumentationDetailPageForSearch(content.contentId!, EMPTY_PARAM, highlights, context);
        }
    };

    const getPathToDocumentationDetailPageForSearch = (
        contentMapId: string,
        documentId: string | undefined,
        highlights: string[],
        context?: SearchContext
    ): RouteLocationRaw => {
        const routeElements: RouteElementWithParam[] = [];
        const topicId = documentId ?? EMPTY_PARAM;
        if (context?.product) {
            routeElements.push({
                alias: CommonRouteAliases.product,
                namedParams: { [CommonRouteAliases.product]: context.product },
            });
        }

        if (context?.asset) {
            routeElements.push({
                alias: CommonRouteAliases.asset,
                namedParams: { [CommonRouteAliases.asset]: context.asset },
            });
        }

        if (context?.product || context?.asset) {
            // Beim Product oder Asset gibt es die Übersichtsliste alle Dokumente
            // deshalb den Pfadteil hier noch dazu
            routeElements.push({
                alias: CommonRouteAliases.documentations,
                namedParams: {},
            });
        }

        routeElements.push({
            alias: CommonRouteAliases.documentationDetail,
            namedParams: { contentMapId, topicId },
        });

        if (highlights.length > 0) {
            return getPathToChildrenWithoutParents(routeElements, { highlights: highlights.join(",") });
        }
        return getPathToChildrenWithoutParents(routeElements);
    };

    return {
        getPathToDocumentationDetailPage,
        getContentPath,
        getPathToDocumentationDetailPageForSearch,
    };
};
