<template>
    <div class="row justify-around">
        <div class="col-auto" v-if="showBackButton">
            <QitBtn
                data-qs="configuration-wizard-footer-previous-step-button"
                class="bg-grey-2"
                :label="$t('core.Back')"
                @click="emit('goToPreviousStep')"
            />
        </div>
        <div v-else class="col-auto" />
        <div class="col-auto">
            <QitBtn
                :data-qs="`next-step-button-${processType}`"
                :label="$t(continueText)"
                :disabled="continueDisabled"
                @click="emit('goToNextStep')"
            />
        </div>
    </div>
</template>

<script setup lang="ts">
/**
 * This component can be used as a footer in a data-process form with buttons for navigation and creating the process itself.
 * @displayName ConfigurationWizardFooter
 * @example `
 *  Should be used in the footer slot of the Card component.
 *  <Card>
 *      ...
 *      <template #footer>
 *          <component
 *              :is="activeFormModel.footerComponentDefinition.getComponent()"
 *              v-bind="footerProps"
 *              :key="footerProps"
 *              @goToPreviousStep="goToPreviousStep"
 *              @goToNextStep="goToNextStep"
 *          />
 *      </template>
 *  </Card>
 * `
 */
import QitBtn from "@/shared/components/buttons/qit-button.vue";

withDefaults(
    defineProps<{
        processType: string;
        /**
         * Shows the display back button.
         */
        showBackButton?: boolean;
        /**
         * Sets the continue text, expects a i18n object.
         */

        continueText?: string;
        /**
         * Makes the continue button not clickable.
         */

        continueDisabled?: boolean;
    }>(),
    {
        showBackButton: false,
        continueText: "core.Continue",
        continueDisabled: false,
    }
);
/**
 * @goToPreviousStep Previous button has been clicked.
 * @goToNextStep Continue button has been clicked.
 */
const emit = defineEmits<{
    (e: "goToPreviousStep"): void;
    (e: "goToNextStep"): void;
}>();
</script>

<style scoped lang="scss">
.bg-grey-2 {
    &:deep(.block) {
        color: black !important;
    }
}
</style>
