import { useTitle } from "@vueuse/core";
import { useTheme } from "@/shell/helpers/theme-definition.ts";

export const useBrowserTitle = () => {
    const { themeTitle } = useTheme();

    const setTitle = (title: string) => {
        const newTitle = title ? `${title} | ` : "";
        useTitle(`${newTitle}${themeTitle.value}`);
    };

    return { setTitle };
};
