import { gql } from "graphql-tag";

export const PRODUCT_TITLE_QUERY = gql`
    query productTitle($id: String!, $acceptedLanguages: [LanguageWithWildcard!]) {
        product(id: $id) {
            localizations {
                title(acceptedLanguages: $acceptedLanguages)
            }
        }
    }
`;
