<template>
    <Transition name="fade">
        <div data-qs="popup-modal" class="popup-modal" v-if="show">
            <div
                class="window"
                :class="{ 'window-type-danger': type === 'danger', 'window-type-primary': type === 'primary' }"
            >
                <slot />
            </div>
        </div>
    </Transition>
</template>

<script setup lang="ts">
withDefaults(
    defineProps<{
        type: "primary" | "danger";
        show?: boolean;
    }>(),
    {
        type: "primary",
        show: false,
    }
);

defineExpose({ open, close });
</script>

<style lang="scss" scoped>
/* css class for the transition */
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
    opacity: 0;
}

.popup-modal {
    background-color: $transparent-background-color;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: $spacing-m;
    display: flex;
    align-items: center;
    z-index: 2;
    .window {
        background: $white;
        border-radius: $default-border-radius;
        max-width: $popup-max-width;
        margin-left: auto;
        margin-right: auto;
        padding: $spacing-l;
        border-left: $default-border-radius solid $error-color;
    }

    /* add more types to be able to display more border colors */
    .window-type-danger {
        border-left: $default-border-radius solid $error-color;
    }
    .window-type-primary {
        border-left: $default-border-radius solid var(--q-primary);
    }
}
</style>
