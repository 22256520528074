import { gql } from "graphql-tag";

export function createProductsQuery(
    datafieldsFragment: Record<string, string[]>,
    localizationDatafieldsFragment: Record<string, string[]>
) {
    const allDataFieldFragments = Object.values(datafieldsFragment).join(" ");
    const allLocalizedDataFieldFragments = Object.values(localizationDatafieldsFragment).join(" ");

    const result = `
    query products(
        $filter: ProductFilter
        $first: NonNegativeInt
        $after: String
        $acceptedLanguages: [LanguageWithWildcard!]
        $sort: [ProductSort]
    ) {
        products(filter: $filter) {
            products(first: $first, after: $after, sort: $sort) {
                node {
                    id
                    referenceId
                    teasers {
                        title(acceptedLanguages: $acceptedLanguages)
                        ${allLocalizedDataFieldFragments}
                    }
                    ${allDataFieldFragments}
                    previewImage {
                        url
                        mimeType
                    }
                    assets {
                        total {
                            count
                        }
                    }
                }
                cursor
            }
            total {
               count
               countRelation
            }
        }
    }`;
    return gql(result);
}
