import { gql } from "graphql-tag";

export const updateCollectionCache = (collectionId: string, addedEntryCount: number = 1) => {
    return (cache: any) => {
        const data: any = cache.readFragment({
            id: `Collection:${collectionId}`,
            fragment: gql`
                fragment node on Collection {
                    id
                    entries {
                        total {
                            count
                            countRelation
                        }
                    }
                }
            `,
        });

        //this case only occurs in the unit test
        if (!data) return;

        const entries = {
            total: {
                count: data.entries.total.count + addedEntryCount,
                countRelation: data.entries.total.countRelation,
            },
        };

        cache.writeFragment({
            id: `Collection:${collectionId}`,
            fragment: gql`
                fragment node on Collection {
                    id
                    entries {
                        total {
                            count
                            countRelation
                        }
                    }
                }
            `,
            data: { id: collectionId, entries },
        });
    };
};
