import { AbilityMenuEntries, MenuEntryWithMetaData } from "@/shared/environment/ability.types";
import { AccessAction, AccessFeature, AccessResource } from "@/shared/access-control/access-control";
import { useHasAccess } from "@/shared/access-control/composables/use-has-access";
import { computed, ref, Ref, watchEffect } from "vue";
import ShoppingCartMenu from "../pages/components/menu-items/shopping-cart-menu.vue";
import { MenuContext } from "@/shared/environment/menu/menu.types";
import { useShopClient } from "../graphql/fdi-shop-client";
import { useShopStatusStore } from "../stores/shop-status";

export function useToolBarMenuFDIShopItems(menuContext: Ref<MenuContext>): AbilityMenuEntries {
    const menuEntries = ref<MenuEntryWithMetaData[]>([]);

    const { hasAccess: hasFeatureAccess } = useHasAccess({
        featureID: AccessFeature.fdi_shop_connector,
        resource: AccessResource.shop,
        action: AccessAction.query,
    });

    // Activate shopClient only if feature.fdi_shop_connector is set
    const shopClient = useShopClient(
        computed(() => {
            return hasFeatureAccess.value;
        })
    );

    const getMenuEntries = (): MenuEntryWithMetaData[] => {
        if (menuContext.value.__type !== "toolbarMenuDetail") return [];
        const { isShopOnline } = useShopStatusStore();

        return [
            {
                entry: {
                    component: {
                        getComponent: () => ShoppingCartMenu,
                        props: {
                            icon: "fa-regular fa-cart-shopping icon-color",
                            mobileIcon: "fa-regular fa-cart-shopping text-on-navbar-color",
                            menuTitleTranslationKey: "core.Shopping cart",
                        },
                    },
                    visible: hasFeatureAccess.value && (!!shopClient.value || !isShopOnline),
                },
                position: "last",
            },
        ];
    };

    watchEffect(() => {
        menuEntries.value = shopClient?.value ? getMenuEntries() : [];
    });

    return { menuEntries };
}
