<template>
    <PopupModal :show="showDialog" type="primary">
        <div class="authenticator-container">
            <div class="logo-container">
                <img class="logo" :src="logo" />
            </div>
            <h2>{{ $t("core.Login") }}</h2>
            <div class="button-container">
                <div v-for="authenticator in sortedAuthenticator" :key="JSON.stringify(authenticator)">
                    <QitButton
                        icon="fa-regular fa-key text-on-primary-color"
                        class="authenticator-button"
                        :label="authenticator.displayName"
                        @click="
                            () => {
                                emit('onAuthenticatorSelected', authenticator);
                            }
                        "
                    />
                </div>
            </div>
        </div>
    </PopupModal>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import PopupModal from "@/shared/components/popup/popup-modal.vue";
import { Authenticator } from "@/shared/services/graphql/generated/public-graph-types";
import { publicClient } from "@/shared/services/apollo-clients/public-client";
import { LOGO_QUERY } from "../graphql/logo.query";
import QitButton from "@/shared/components/buttons/qit-button.vue";
import QUANOS_LOGO from "@/shared/assets/images/quanos.svg?url";
import { watchImmediate } from "@vueuse/core";

const loading = ref(true);
const logo = ref<string>("");

const loadLogo = async () => {
    const result = await publicClient.query({ query: LOGO_QUERY });
    const customLogo = result.data.theme.logo;
    if (!customLogo) {
        logo.value = QUANOS_LOGO;
    } else {
        //TODO public blob handling in backend doesn't work yet
        logo.value = customLogo.url.replace("/downloads/", "/downloads/public/");
    }
    loading.value = false;
};
const props = defineProps<{
    authenticators: Authenticator[];
    show: boolean;
}>();

const emit = defineEmits<{
    (e: "onAuthenticatorSelected", data: Authenticator): void;
}>();

watchImmediate(
    () => props.show,
    () => {
        if (props.show) loadLogo();
    }
);

const showDialog = computed(() => {
    return props.authenticators.length > 0 && props.show && !loading.value;
});

const sortedAuthenticator = computed(() => {
    if (props.authenticators.some((x: Authenticator) => x.sortIndex > 0))
        return [...props.authenticators].sort((a, b) => {
            return b.sortIndex - a.sortIndex;
        });

    return props.authenticators;
});
</script>

<style lang="scss" scoped>
.authenticator-container {
    display: flex;
    justify-content: center;
    text-align: center;
    flex-direction: column;

    h2 {
        margin-bottom: 1rem;
        margin-top: 3rem;
    }
}
.logo-container {
    display: flex;
    justify-content: center;
}

.logo {
    max-height: 250px;
    width: 200px;
}
.button-container {
    margin: 0 2rem;
}
.authenticator-button {
    text-align: left;
    margin: $spacing-m;
    width: calc(100% - 8px);
}
</style>
