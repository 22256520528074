import {
    ContentSortFields,
    SortOrder,
    useCollectionsByFilterQuery,
} from "@/shared/services/graphql/generated/consumer-graph-types";
import { computed, ref } from "vue";
import { useCollectionContent } from "@/abilities/collections/composables/collection-content.ts";
import { getDataQueryLanguages } from "@/shared/services/providers/language-provider.ts";

export const useDashboardCollection = () => {
    const collectionId = ref<string>("");

    const { onResult } = useCollectionsByFilterQuery({
        filter: {
            equals: {
                showInDashboard: true,
            },
        },
    });

    onResult((val) => {
        if (val.data?.collections?.collections.length < 1) return;

        const collectionIdForDashboard = val.data?.collections?.collections[0]?.node?.id;
        if (collectionIdForDashboard) {
            //set collectionId to enable collections content composable
            collectionId.value = collectionIdForDashboard;
        }
    });

    const filterVariables = computed(() => {
        return {
            id: collectionId.value,
            first: 20,
            sortId: ContentSortFields.title,
            sortOrder: SortOrder.asc,
            acceptedLanguages: getDataQueryLanguages(),
        };
    });

    const { entries, result } = useCollectionContent(filterVariables);

    const title = computed(() => {
        return result?.value?.collection.displayName;
    });

    return { title, entries };
};
