<template>
    <Drawer :visible="visible" :title="$t('collections.Add to Collection')" @on-close="emits('onClose')">
        <div class="loading-middle" v-if="loading || saving">
            <q-spinner-ios size="2em" />
        </div>
        <div v-else class="q-mx-md column col-grow no-wrap">
            <div class="q-mt-md">
                <slot name="entry" />
            </div>
            <p class="font-semi-bold">{{ $t("collections.Select collection") }}</p>
            <div class="column col-grow">
                <q-scroll-area class="fit">
                    <div class="column">
                        <QitBtn
                            class="q-pb-md q-pt-sm q-pl-sm"
                            flat
                            color="primary"
                            align="left"
                            icon="fa-regular fa-plus"
                            :label="$t('collections.Create new collection')"
                            @click="openCreateCollectionDialog"
                        />
                        <CollectionsOverlayCheckbox
                            v-for="collection in collections"
                            :key="collection.id"
                            @on-check="
                                (val) => {
                                    if (collection.id) onCheck(val, collection.id);
                                }
                            "
                            :is-selected="selected[collection.id] || false"
                            :id="collection.id"
                            :title="collection.title"
                        />
                    </div>
                </q-scroll-area>
            </div>
            <template v-if="collections.length > 1">
                <q-separator />
                <q-checkbox
                    class="all-collections-checkbox"
                    @update:model-value="toggleSelectAll"
                    :model-value="allSelected"
                    :label="$t('core.Select all')"
                />
            </template>
            <QitBtn
                class="q-mb-sm q-mt-sm save-btn"
                :disabled="!isAnySelected"
                @click="onSave"
                :label="$t('documentation.Add')"
                data-qs="collection-overlay-save-btn"
            />
        </div>
    </Drawer>
    <CollectionCreateDialog
        :visible="newCollection"
        @on-close="closeCollectionDialog"
        @on-create="onCreateCollectionDialog"
    />
</template>

<script setup lang="ts">
import QitBtn from "@/shared/components/buttons/qit-button.vue";
import { computed, ref, toRefs, watchEffect } from "vue";
import CollectionsOverlayCheckbox from "./collections-overlay-checkbox.vue";
import Drawer from "@/shared/components/drawer/title-content-drawer.vue";
import CollectionCreateDialog from "@/abilities/collections/components/collection-create-dialog.vue";

const saving = ref<boolean>(false);
const allSelected = ref<boolean>(false);
const newCollection = ref<boolean>(false);

const props = defineProps<{
    visible: boolean;
    loading: boolean;
    collections: any[];
    selected: Record<string, boolean>;
}>();

const emits = defineEmits<{
    onClose: [];
    onSave: [];
    onCollectionCreated: [];
    onCollectionSelectionChanged: [newSelected: Record<string, boolean>];
    onSelectAll: [value: boolean];
}>();

const { visible, loading, collections, selected } = toRefs(props);

watchEffect(() => {
    //toggle all selected box if necessary
    const isAllSelected =
        Object.entries(selected.value).length > 0 && Object.entries(selected.value).length === collections.value.length;

    if (isAllSelected !== allSelected.value) allSelected.value = isAllSelected;
});

const openCreateCollectionDialog = () => {
    newCollection.value = true;
};

const onCreateCollectionDialog = (createdCollectionId: string) => {
    newCollection.value = false;
    selected.value[createdCollectionId] = true;
    emits("onCollectionCreated");
};

const closeCollectionDialog = async () => {
    newCollection.value = false;
};

const isAnySelected = computed(() => {
    const countSelected = Object.values(selected.value).filter((x) => x);
    return countSelected.length > 0;
});

const onSave = () => {
    emits("onSave");
};

const onCheck = (value: boolean, collectionId: string) => {
    const copySelected = { ...selected.value };
    if (value) copySelected[collectionId] = value;
    else delete copySelected[collectionId];

    emits("onCollectionSelectionChanged", copySelected);
};

const toggleSelectAll = (value: boolean) => {
    allSelected.value = value;

    if (!collections.value || !value) {
        emits("onCollectionSelectionChanged", {});
        return;
    }

    const copySelected = { ...selected.value };
    for (const collection of collections.value) {
        const id = collection?.id!;
        copySelected[id] = value;
    }

    emits("onCollectionSelectionChanged", copySelected);
};
</script>

<style lang="scss" scoped>
.all-collections-checkbox {
    padding-left: 12px;
}

:deep(.card-small-mode:hover) {
    background: $white;
}
:deep(.q-checkbox) {
    padding: $spacing-m 0 $spacing-m 0;
}
</style>
