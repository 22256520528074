<!-- 
    This number input is optimized for use in the fdi-shop widget
    If there are additional requirements, consider creating a new component
-->
<template>
    <q-input
        type="number"
        v-model.number="inputValue"
        @input="handleInput"
        @keydown="validateKey"
        :min="props.min"
        :max="props.max"
        :disable="disable"
        :color="props.warningHighlight ? 'warning' : 'primary'"
        outlined
    />
</template>

<script setup lang="ts">
import { get, set } from "@vueuse/core";
import { computed, ref, watch } from "vue";

interface EventData {
    key: string;
    code: string;
    ctrlKey: boolean;
    metaKey: boolean;
    preventDefault(): void;
}

// Define component props with default values
const props = withDefaults(
    defineProps<{
        min?: number;
        max?: number;
        warningHighlight?: boolean;
        disabled?: boolean;
    }>(),
    {
        min: Number.NEGATIVE_INFINITY,
        max: Infinity,
        warningHighlight: false,
        disabled: false,
    }
);

// Define emit event
const emit = defineEmits(["onChange"]);

// Computed property to determine if the input should be disabled
const disable = computed(() => {
    return props.max === 0 || props.disabled;
});

// Computed property to determine the initial value of the input
const startVal = computed(() => {
    if (props.max === 0) {
        return 0;
    } else {
        return 1;
    }
});

// Reactive reference for the input value
const inputValue = ref<number>(get(startVal));

// Watch for changes in the max prop and adjust inputValue accordingly
watch(
    () => props.max,
    () => {
        if (props.max > 0 && get(inputValue) === 0) {
            set(inputValue, 1);
        } else if (props.max === 0 && get(inputValue) > 0) {
            set(inputValue, 0);
        }
    }
);

// Emit initial value on component mount
emit("onChange", inputValue.value);

// Watch for changes in inputValue and emit onChange event
watch(
    () => get(inputValue),
    () => {
        emit("onChange", get(inputValue));
    }
);

// Handle input event to ensure value stays within min and max bounds
const handleInput = () => {
    if (inputValue.value !== null) {
        if (inputValue.value < props.min) {
            inputValue.value = props.min;
        } else if (inputValue.value > props.max) {
            inputValue.value = props.max;
        }
    }
};

// Validate keydown event to allow only numeric input and control keys
const validateKey = (event: EventData) => {
    const { key, code, ctrlKey, metaKey } = event;

    // Allow navigation and control keys
    if (
        ["ArrowLeft", "ArrowRight", "ArrowUp", "ArrowDown", "Home", "End", "Backspace", "Delete"].includes(code) ||
        ctrlKey ||
        metaKey
    ) {
        return;
    }

    // Allow copy, paste, and cut shortcuts
    if (
        (key === "c" && (ctrlKey || metaKey)) ||
        (key === "v" && (ctrlKey || metaKey)) ||
        (key === "x" && (ctrlKey || metaKey))
    ) {
        return;
    }

    // Prevent non-numeric input
    if (!/^\d*$/.test(key)) {
        event.preventDefault();
    }
};
</script>
