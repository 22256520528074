import { gql } from "graphql-tag";

export function createAssetsQuery(
    datafieldsFragment: Record<string, string[]>,
    teaserDatafieldsFragment: Record<string, string[]>
) {
    const allDataFieldFragments = Object.values(datafieldsFragment).join(" ");
    const allLocalizedDataFieldFragments = Object.values(teaserDatafieldsFragment).join(" ");

    const result = `
    query assets(
        $filter: AssetFilter
        $first: NonNegativeInt
        $after: String
        $acceptedLanguages: [LanguageWithWildcard!]
        $sort: [AssetSort]
    ) {
        assets(filter: $filter) {
            assets(first: $first, after: $after, sort: $sort) {
                cursor
                node {
                    id
                    assetId
                    productId
                    referenceId
                    teasers {
                        title(acceptedLanguages: $acceptedLanguages)
                        ${allLocalizedDataFieldFragments}
                    }
                    ${allDataFieldFragments}
                    previewImage {
                        url
                        mimeType
                    }
                }
            }
            total {
               count
               countRelation
            }
        }
    }`;
    return gql(result);
}
