<template>
    <DocumentationList :text="text" :product="product" :asset="asset" />
</template>

<script setup lang="ts">
import { computed } from "vue";
import DocumentationList from "../pages/documentation-list.vue";

const props = defineProps<{
    search: Record<string, string>;
}>();

const text = computed(() => {
    return props.search.text;
});

const product = computed(() => {
    return props.search.product;
});
const asset = computed(() => {
    return props.search.asset;
});
</script>
